import { UseFormResetField } from 'react-hook-form';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisDetailDto, PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePostBulkDetails = ({
  resetField,
}: {
  resetField?: UseFormResetField<{
    prognosisDetails: PrognosisDetailDto[];
  }>;
}) => {
  const usingId = usePrognosisIDFromParams();

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ details }: { details: PrognosisDetailDto[] }) => {
      return await PrognosisDetailService.postApiV1PrognosesDetailsList(
        details
      );
    },
    onMutate: async ({ details }: { details: PrognosisDetailDto[] }) => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId],
      });

      const previousDetails: PrognosisDetailDto[] =
        queryClient.getQueryData([PROGNOSIS_DETAIL, usingId]) ?? [];

      const updatedDetails = [...previousDetails, ...details];

      queryClient.setQueryData([PROGNOSIS_DETAIL, usingId], updatedDetails);

      if (resetField) {
        resetField('prognosisDetails', {
          defaultValue: updatedDetails,
        });
      }

      return {
        previousDetails,
        optimisticDetail: updatedDetails,
        usingId,
        newDetails: details,
      };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, context?.usingId],
        context?.previousDetails ?? []
      );
      showSnackbar('Failed to create details: ' + err.message);

      if (resetField) {
        resetField('prognosisDetails', {
          defaultValue: context?.previousDetails,
        });
      }
    },
    onSuccess: (responseIds, values, context) => {
      const cleanedDetailsWithId: PrognosisDetailDto[] =
        context?.newDetails.map((detail, index) => {
          return {
            ...detail,
            id: responseIds[index],
          };
        });

      const updatedDetailsWithId = [
        ...(context?.previousDetails ?? []),
        ...cleanedDetailsWithId,
      ];

      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, context?.usingId],
        updatedDetailsWithId
      );

      if (resetField) {
        resetField('prognosisDetails', {
          defaultValue: updatedDetailsWithId,
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId],
      });
    },
  });
};
