import { SkeletonBase, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  align-items: center;
  margin-top: ${spacings.medium};
  gap: ${spacings.medium};
  width: max-content;
  input {
    min-width: 240px;
  }
`;

export const ButtonWrapper = styled.div`
  text-wrap: none;
  width: 100%;
  margin-bottom: ${spacings.small};
`;

export const SkeletonForm = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacings.medium};
  gap: ${spacings.medium};
`;

export const SkeletonInput = styled(SkeletonBase)`
  width: 240px;
  height: 76px;
`;

export const SkeletonButton = styled(SkeletonBase)`
  height: 36px;
  width: 108px;
  margin-bottom: ${spacings.small};
`;
