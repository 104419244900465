import { useQuery } from '@tanstack/react-query';

import { PrognosisHistoryService } from 'src/api';
import { PROGNOSIS_HISTORY_KEY } from 'src/constants/queryKeys';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const useGetPrognosisHistory = () => {
  const usingId = usePrognosisIDFromParams();

  return useQuery({
    queryKey: [PROGNOSIS_HISTORY_KEY, usingId],
    queryFn: () => PrognosisHistoryService.getApiV1PrognosesHistory(usingId),
  });
};
