import { FC } from 'react';

import { OptionalTooltip, Typography } from '@equinor/amplify-component-lib';

import { TabType } from 'src/constants';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  max-width: 120px;
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 100%;
`;

interface ApproveValueProps {
  approver: string | null | undefined;
  mainApprover: string | null | undefined;
  backupApprover: string | null | undefined;
  currentTab: TabType;
}

export const ApproveValue: FC<ApproveValueProps> = ({
  approver = 'N/A',
  mainApprover = 'N/A',
  backupApprover = 'N/A',
  currentTab,
}) => {
  const showApprover =
    currentTab === TabType.APPROVED || currentTab === TabType.HISTORICAL;

  const isBackupApproved = approver === backupApprover;

  return (
    <Container>
      {showApprover && isBackupApproved ? (
        <OptionalTooltip
          placement="top"
          title={`Backup approver for ${mainApprover}`}
        >
          <StyledTypography group="table" variant="cell_text">
            {backupApprover}
          </StyledTypography>
        </OptionalTooltip>
      ) : (
        <StyledTypography group="table" variant="cell_text">
          {mainApprover}
        </StyledTypography>
      )}
    </Container>
  );
};
