import { FC } from 'react';

import { colors } from '@equinor/amplify-component-lib';

import { MainContent } from '../Steps.styles';
import { PreviewFooter } from './PreviewFooter/PreviewFooter';
import { ViewPrognosis } from 'src/pages/ViewPrognosis/ViewPrognosis';

export const PreviewStep: FC = () => {
  return (
    <>
      <MainContent $backgroundColor={`${colors.ui.background__light.rgba}`}>
        <ViewPrognosis isPreview={true} />
      </MainContent>
      <PreviewFooter />
    </>
  );
};
