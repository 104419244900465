/**
 * Delays the execution of code by the specified number of milliseconds.
 * @param ms - The number of milliseconds to delay.
 * @returns A promise that resolves after the specified delay.
 */
export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Simulates an artificial loading delay.
 *
 * @param {number} [delayTime=100] - The amount of time in milliseconds to delay.
 * @param {(isLoading: boolean) => void} setIsLoading - A callback function to set the loading state.
 * @returns {Promise<void>} A promise that resolves after the delay.
 */
export async function artificialLoad(
  delayTime = 100,
  setIsLoading: (isLoading: boolean) => void
): Promise<void> {
  setIsLoading(true);
  await delay(delayTime);
  setIsLoading(false);
}
