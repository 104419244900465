import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { FieldSelector } from '@equinor/amplify-component-lib';

import { useApp } from '../hooks';

import { useGetUserAccess } from 'hooks/useGetUserAccess';

export const SelectField: FC = () => {
  const { formattedFieldNameForUrl, setField } = useApp();
  const navigate = useNavigate();
  const { fields } = useGetUserAccess();

  const onChangedField = () => {
    navigate(`${formattedFieldNameForUrl}/prognosis`);
  };

  return (
    <FieldSelector
      setField={setField}
      fields={fields ?? []}
      onChangedField={onChangedField}
      finishedText="Taking you to the prognosis dashboard page"
    />
  );
};
