import { FC, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  SelectOptionRequired,
  SingleSelect,
} from '@equinor/amplify-component-lib';

import { ConfirmDialog } from '../../../ConfimDialog/ConfirmDialog';
import { useZoneSelectionStepForm } from 'src/pages/EditPrognosis/hooks/form/useZoneSelectionStepForm';
import { useZoneSelector } from 'src/pages/EditPrognosis/hooks/util/useZoneSelector';
import { ZoneSelectionStepFormType } from 'src/utils';

import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import { useGetAllStratColumns } from 'hooks/useGetStratigraphicValues';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1 0 0;
  max-width: 520px;
  min-width: 320px;
`;

interface NextValueProps {
  key: keyof Pick<ZoneSelectionStepFormType, 'stratColumnIdentifier'>;
  item: string | undefined;
}

interface SelectColumnProps {
  isInitializing: boolean;
}
export const SelectColumn: FC<SelectColumnProps> = ({ isInitializing }) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useZoneSelectionStepForm();
  const { data: stratColumn, isLoading } = useGetAllStratColumns();

  const { data: prognosisDetails } = useGetPrognosisDetails();
  const { setSelectedStratUnits } = useZoneSelector();

  const [openDialog, setOpenDialog] = useState(false);
  const [nextValue, setNextValue] = useState<NextValueProps>();

  const handleUpdateStratColumn = (item: SelectOptionRequired | undefined) => {
    if (!item) return;

    const newValue = item.value;
    const previousValue = getValues('stratColumnIdentifier');

    const isFieldDirty = newValue !== previousValue;

    if (isFieldDirty) {
      setNextValue({ key: 'stratColumnIdentifier', item: newValue });

      // TODO: only remove details if they don’t overlap with the new list of subzones

      if (prognosisDetails && prognosisDetails.length > 0) {
        setOpenDialog(true);
      } else {
        setValue('stratColumnIdentifier', newValue, {
          shouldDirty: true,
        });
        setSelectedStratUnits([]);
      }
    }
  };

  const handleFormChange = () => {
    setOpenDialog(false);
    setSelectedStratUnits([]);

    if (!nextValue) return;
    const { key, item } = nextValue;

    if (!item) return;
    setValue(key, item, { shouldDirty: true });
    trigger(key);
  };

  const identifierItems: SelectOptionRequired[] = useMemo(() => {
    return (
      stratColumn?.map(({ identifier }) => ({
        value: identifier ?? '',
        label: identifier ?? '',
      })) ?? []
    );
  }, [stratColumn]);

  return (
    <>
      <Container>
        <Controller
          name={'stratColumnIdentifier'}
          control={control}
          render={({ field }) => {
            return (
              <SingleSelect
                items={identifierItems}
                placeholder="Select column..."
                value={identifierItems.find(
                  (item) => item.value === field.value
                )}
                onSelect={(item) => {
                  handleUpdateStratColumn(item);
                }}
                variant={errors.stratColumnIdentifier ? 'error' : undefined}
                helperText={errors.stratColumnIdentifier?.message}
                clearable={false}
                loading={isInitializing || isLoading}
              />
            );
          }}
        />
      </Container>
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        detailsToDelete={prognosisDetails ?? []}
        handleFormChange={handleFormChange}
      />
    </>
  );
};
