import { SelectOptionRequired } from '@equinor/amplify-component-lib';

import { Equipment, HelpLineInput, MeasurementType } from 'src/api';
import { Sources } from 'src/pages/EditMeasuredData/components/ManageHelpLines/ManageHelpLines.types';

export enum DateRangeEnum {
  ONE_MONTH = 'One month',
  TWO_MONTHS = 'Two months',
  THREE_MONTHS = 'Three months',
  ONE_YEAR = 'One year',
  THREE_YEARS = 'Past 3 years',
}

export interface DateRange {
  label: DateRangeEnum;
  range: number;
}

export enum IndefiniteTimeStringType {
  PAST = 'past',
  FUTURE = 'future',
}

export enum MeasuredDataPage {
  MAP = 'map',
  PLOT = 'plot',
  EDIT = 'edit',
}

export interface MapDateRangeOptions {
  range: number;
  dateForRange: Date;
  selectedDate: Date;
}

export interface PlotDateRangeOptions {
  fromDate: Date;
  toDate: Date;
}

export type MapColor =
  | [number, number, number]
  | [number, number, number, number];

export interface Measurement extends SelectOptionRequired {
  dhpg: number;
  wellboreUuid?: string;
}

export interface PressureTypeValues {
  label: string;
  background: string;
  color: string;
}

export enum WellborePurpose {
  INJECTION = 'injection',
  PRODUCTION = 'production',
}

export enum RightAxisEnum {
  INSTANT = 'Instant',
  CUMULATIVE = 'Cumulative',
  GRADIENT = 'Gradient',
  ON_STREAM_HOURS = 'On stream hours',
  PRODUCTIVITY_INDEX = 'Productivity index',
}

export interface LineStyle {
  borderDash: number[];
  borderCapStyle: string;
}

export type HelpLineType = HelpLineInput & {
  source?: Sources;
  measureType?: MeasurementType;
  name: string;
};

export interface LineStyleType {
  label: string;
  strokeDasharray: string;
  isMainLineLegend?: boolean;
}

export enum EquipmentType {
  GAUGE = 'gauge',
  DHPG = 'dhpg',
}

export interface EquipmentMapping {
  type: EquipmentType;
  fromMd: number;
  toMd: number;
}

export type EquipmentOption = SelectOptionRequired & Equipment;
