import { FC } from 'react';

import {
  colors,
  Dialog,
  SingleSelect,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { useCorrectionDialog } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/EquipmentMapping/CorrectionDialog/useCorrectionDialog';

import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 800px;
`;

const InfoText = styled(Typography)`
  padding: ${spacings.medium};
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacings.medium_small};
  border-top: 1px solid ${colors.ui.background__medium.rgba};
  padding: ${spacings.medium};
  > p {
    grid-column: 1 / -1;
  }
`;

interface CorrectionDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CorrectionDialog: FC<CorrectionDialogProps> = ({
  open,
  onClose,
}) => {
  const {
    hasChangedScreenPerf,
    hasChangedGauge,
    gaugeOptions,
    screenPerfOptions,
    selectedGauge,
    selectedScreenOrPerforation,
    isGaugeOriginalValue,
    isScreenOrPerfOriginalValue,
    handleOnSelectGauge,
    handleOnSelectScreenOrPerforation,
    handleOnSubmit,
  } = useCorrectionDialog(onClose);
  return (
    <StyledDialog
      open={open}
      withBorders
      onClose={onClose}
      title="Gauge and screen/perf corrections"
      withContentPadding={{ horizontal: false, vertical: false }}
      actions={[
        { text: 'Cancel', onClick: onClose, variant: 'outlined' },
        {
          text: 'Save changes',
          onClick: handleOnSubmit,
          disabled: !(hasChangedScreenPerf || hasChangedGauge),
        },
      ]}
    >
      <InfoText variant="caption">
        Adjust the gauge and screen/perforation selections made for this
        measurement point. Press X to return to original value.
      </InfoText>
      <Content>
        <Typography>Measured depth (MD)</Typography>
        <SingleSelect
          label="Gauge"
          value={selectedGauge}
          items={gaugeOptions}
          onSelect={handleOnSelectGauge}
          helperText={
            isGaugeOriginalValue ? 'Original value' : 'Corrected value'
          }
          variant={hasChangedGauge ? 'dirty' : undefined}
        />
        <SingleSelect
          label="Screen/perf"
          items={screenPerfOptions}
          value={selectedScreenOrPerforation}
          onSelect={handleOnSelectScreenOrPerforation}
          helperText={
            isScreenOrPerfOriginalValue ? 'Original value' : 'Corrected value'
          }
          variant={hasChangedScreenPerf ? 'dirty' : undefined}
        />
      </Content>
    </StyledDialog>
  );
};
