import { colors, shape, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

interface MainContentProps {
  $backgroundColor?: string;
}

export const MainContent = styled.div<MainContentProps>`
  display: flex;
  padding: ${spacings.large};
  margin: ${spacings.large};
  margin-top: 0;
  align-items: flex-start;
  gap: ${spacings.large};
  flex: 1 0 0;
  align-self: stretch;
  border-radius: ${shape.corners.borderRadius};
  border: 1px solid ${colors.ui.background__heavy.rgba};
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? colors.ui.background__default.rgba};
`;

export const Separator = styled.hr`
  background-color: ${colors.ui.background__medium.rgba};
  width: 1px;
  align-self: stretch;
  flex-shrink: 0;
  margin-bottom: ${spacings.medium};
`;

export const MainContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 2fr;
  padding: ${spacings.large};
  margin: ${spacings.large};
  margin-top: 0;
  align-items: flex-start;
  gap: ${spacings.large};
  border-radius: ${shape.corners.borderRadius};
  border: 1px solid ${colors.ui.background__heavy.rgba};
  background-color: ${colors.ui.background__default.rgba};
  flex: 1;
`;

export const OperationTitleWrapper = styled.div`
  margin-bottom: ${spacings.small};
`;
