import { FC, useState } from 'react';

import { Button, Icon, spacings } from '@equinor/amplify-component-lib';
import { external_link } from '@equinor/eds-icons';

import { ListTitle } from '../FilterItems.styles';
import { CorrectionItem } from './CorrectionItem';
import { CorrectionDialog } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/EquipmentMapping/CorrectionDialog/CorrectionDialog';
import { useEquipmentMapping } from 'src/pages/EditMeasuredData/hooks/useEquipmentMapping';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const MakeCorrectionsButton = styled(Button)`
  margin: ${spacings.medium};
  width: fit-content;
`;

export const EquipmentMapping: FC = () => {
  const [open, setOpen] = useState(false);
  const { equipmentForPressurePoint } = useEquipmentMapping();

  const handleOnToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Wrapper>
        <ListTitle group="input" variant="label">
          Gauge and screen/perf corrections
        </ListTitle>

        {equipmentForPressurePoint.map((equipment) => (
          <CorrectionItem key={equipment.type} {...equipment} />
        ))}
        <MakeCorrectionsButton variant="outlined" onClick={handleOnToggle}>
          Make corrections
          <Icon data={external_link} />
        </MakeCorrectionsButton>
      </Wrapper>
      {open && <CorrectionDialog open={open} onClose={handleOnToggle} />}
    </>
  );
};
