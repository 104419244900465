import { FC } from 'react';

import {
  colors,
  OptionalTooltip,
  spacings,
} from '@equinor/amplify-component-lib';

import { StyledTypography } from '../BaseTable/BaseTable.styles';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: ${spacings.small};
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  width: 50px;
  flex-grow: 1;
  align-items: center;
`;

interface SegmentCellProps {
  segmentName: string;
}

export const SegmentCell: FC<SegmentCellProps> = ({ segmentName }) => {
  return (
    <Container>
      <TextContainer>
        <OptionalTooltip title={segmentName} placement="top" enterDelay={1000}>
          <StyledTypography
            data-testid="segment-cell"
            variant="cell_text"
            group="table"
            color={colors.interactive.primary__resting.rgba}
          >
            {segmentName}
          </StyledTypography>
        </OptionalTooltip>
      </TextContainer>
    </Container>
  );
};
