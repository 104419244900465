import { FC, useState } from 'react';

import { SubmitDialog } from '../../../ActionFooter/SubmitDialog';
import { StepFooter } from '../../StepFooter/StepFooter';

import { useGetPrognosis } from 'hooks/useGetPrognosis';

export const PreviewFooter: FC = () => {
  const { data: prognosisData } = useGetPrognosis();
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);

  const handleSubmit = () => {
    setOpenSubmitDialog(true);
  };

  const handleOnCloseSubmitDialog = () => setOpenSubmitDialog(false);

  return (
    <>
      <StepFooter
        handleSubmit={handleSubmit}
        isMutating={false}
        isPreview={true}
      />
      <SubmitDialog
        open={openSubmitDialog}
        title={prognosisData?.title ?? 'N/A'}
        onClose={handleOnCloseSubmitDialog}
      />
    </>
  );
};
