import { FC, useMemo, useState } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { ParentSize } from '@visx/responsive';

import { AxisTextX, AxisTextY, Card, ChartContainer } from '../Chart.styles';
import { Chart } from './Chart';
import { PrognosisDetailDto } from 'src/api';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { CHART_HEIGHT } from 'src/pages/ViewPrognosis/constants/chart';
import { Unit } from 'src/types/prognosisDetails';
import { convertPrognosisToSG } from 'src/utils/unitConversion';

import { ChartHeader } from 'components/Charts/XYChart/Header/ChartHeader';
import {
  ChartDataCategory,
  ChartSelect,
} from 'components/Charts/XYChart/Utils/Chart.types';
import { useApp } from 'hooks/useApp';
import { useGetWellboreByIdentifier } from 'hooks/useGetWellbores';

interface PrognosisChartProps {
  prognosisDetailData: PrognosisDetailDto[];
}

export const PrognosisChart: FC<PrognosisChartProps> = ({
  prognosisDetailData,
}) => {
  const { data: wellbore } = useGetWellboreByIdentifier();
  const { unit } = useApp();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const [cardElement, setCardElement] = useState<HTMLDivElement | null>(null);
  const [selectedData, setSelectedData] = useState<ChartSelect>({
    id: ChartDataCategory.REFERENCE_ID as number,
    name: ChartDataCategory.REFERENCE as string,
  });

  const dataCopy = useMemo(() => {
    return prognosisDetailData?.map((prognosis) => {
      if (unit === Unit.SG && wellbore) {
        return convertPrognosisToSG({
          prognosis,
          depthReferenceElevation: wellbore.depthReferenceElevation || 0,
        });
      }
      return prognosis;
    });
  }, [unit, prognosisDetailData, wellbore]);

  const elements = [
    {
      id: ChartDataCategory.REFERENCE_ID as number,
      name: ChartDataCategory.REFERENCE as string,
    },
  ];

  if (optionalAttributes?.datumDepth) {
    elements.push({
      id: ChartDataCategory.DATUM_ID as number,
      name: ChartDataCategory.DATUM as string,
    });
  }
  return (
    <Card ref={setCardElement}>
      <ChartHeader
        cardElement={cardElement}
        selected={selectedData}
        setSelected={setSelectedData}
        elements={elements}
        title="Overview"
      />
      <ChartContainer>
        <ParentSize ignoreDimensions="height">
          {({ width }) => (
            <Chart
              data={dataCopy}
              width={width}
              height={CHART_HEIGHT}
              useDatumData={selectedData.id === ChartDataCategory.DATUM_ID}
            />
          )}
        </ParentSize>
        <AxisTextY
          group="input"
          variant="label"
          color={colors.text.static_icons__default.hex}
        >
          {selectedData.id === ChartDataCategory.DATUM_ID
            ? 'Zone'
            : unit === Unit.SG
              ? 'm TVD RKB'
              : 'm TVD MSL'}
        </AxisTextY>
        <AxisTextX
          group="input"
          variant="label"
          color={colors.text.static_icons__default.hex}
        >
          Pressure
        </AxisTextX>
      </ChartContainer>
    </Card>
  );
};
