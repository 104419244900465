import { FC } from 'react';
import { Path } from 'react-hook-form';

import { Icon, TextField, Typography } from '@equinor/amplify-component-lib';
import { error_filled } from '@equinor/eds-icons';

import {
  Container,
  FullTextField,
  TextFieldContainer,
  WideTextField,
} from '../DataInputDialog.styles';
import { Skeleton } from '../Skeleton/Skeleton';
import { PrognosisDetailDto } from 'src/api';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';
import { getPrognosisDetailError } from 'src/utils/error';
import { EditDetailsStepFormType } from 'src/utils/zodSchema';

interface GeneralProps {
  label: string;
  index: number;
  isLoading: boolean;
  isTotalDepthRow: boolean;
}

export const General: FC<GeneralProps> = ({
  label,
  index,
  isLoading,
  isTotalDepthRow,
}) => {
  const {
    register,
    trigger,
    getValues,
    clearErrors,
    formState: { errors },
  } = useEditDetailsStepForm();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const segment: Path<EditDetailsStepFormType> = `prognosisDetails.${index}.segment`;
  const comment: Path<EditDetailsStepFormType> = `prognosisDetails.${index}.comment`;
  const nickname: Path<EditDetailsStepFormType> = `prognosisDetails.${index}.reservoirZoneNickname`;

  const handleError = (key: keyof PrognosisDetailDto) => {
    return getPrognosisDetailError(errors, index, key);
  };

  const handleOnBlur = (fieldPath: Path<EditDetailsStepFormType>) => {
    const value = getValues(fieldPath);

    if (value) {
      trigger(fieldPath);
    } else {
      clearErrors(fieldPath);
    }
  };

  if (isLoading) {
    return <Skeleton label={label} />;
  }

  return (
    <Container>
      <Typography variant="h6">{label}</Typography>
      <TextFieldContainer>
        {!isTotalDepthRow && (
          <>
            <FullTextField
              {...register(nickname)}
              id={'Nickname'}
              placeholder="Add nickname..."
              label="Nickname"
              data-testid={'reservoirZoneNickname'}
              onBlur={() => handleOnBlur(nickname)}
              helperText={handleError('reservoirZoneNickname')?.message}
              helperIcon={
                handleError('reservoirZoneNickname') && (
                  <Icon data={error_filled} size={16} />
                )
              }
              variant={
                handleError('reservoirZoneNickname') ? 'error' : undefined
              }
              type="string"
            />
            {optionalAttributes?.segment && (
              <TextField
                {...register(`prognosisDetails.${index}.segment`)}
                id={'Segment'}
                placeholder="Add segment..."
                label="Segment"
                helperIcon={
                  handleError('segment') && (
                    <Icon data={error_filled} size={16} />
                  )
                }
                data-testid={'segment'}
                onBlur={() => handleOnBlur(segment)}
                helperText={handleError('segment')?.message}
                variant={handleError('segment') ? 'error' : undefined}
                disabled={!optionalAttributes.segment}
              />
            )}
          </>
        )}
        <WideTextField
          id={'Comment'}
          {...register(comment)}
          placeholder="Add comment..."
          label="Comment"
          data-testid={'comment'}
          helperIcon={
            handleError('comment') && <Icon data={error_filled} size={16} />
          }
          onBlur={() => handleOnBlur(comment)}
          helperText={handleError('comment')?.message}
          variant={handleError('comment') ? 'error' : undefined}
        />
      </TextFieldContainer>
    </Container>
  );
};
