/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WellTarget } from '../models/WellTarget';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CiService {
  /**
   * Get well target
   * @param targetUuid
   * @returns WellTarget Success
   * @throws ApiError
   */
  public static getApiV1CiWellTargets(
    targetUuid: string
  ): CancelablePromise<Array<WellTarget>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Ci/wellTargets/{targetUuid}',
      path: {
        targetUuid: targetUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get well targets connected to a wellbore
   * @param fieldIdentifier
   * @param wellboreUuid
   * @returns WellTarget Success
   * @throws ApiError
   */
  public static getApiV1CiWellTargetsFieldWellbore(
    fieldIdentifier: string,
    wellboreUuid: string
  ): CancelablePromise<Array<WellTarget>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Ci/wellTargets/field/{fieldIdentifier}/wellbore/{wellboreUuid}',
      path: {
        fieldIdentifier: fieldIdentifier,
        wellboreUuid: wellboreUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get well targets connected to a wellbore and non-connected targets for the field
   * @param fieldIdentifier
   * @param wellboreUuid
   * @returns WellTarget Success
   * @throws ApiError
   */
  public static getApiV1CiWellTargetsFieldWellboreAll(
    fieldIdentifier: string,
    wellboreUuid: string
  ): CancelablePromise<Array<WellTarget>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Ci/wellTargets/field/{fieldIdentifier}/wellbore/{wellboreUuid}/all',
      path: {
        fieldIdentifier: fieldIdentifier,
        wellboreUuid: wellboreUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get well targets for a field
   * @param fieldIdentifier
   * @returns WellTarget Success
   * @throws ApiError
   */
  public static getApiV1CiWellTargetsField(
    fieldIdentifier: string
  ): CancelablePromise<Array<WellTarget>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Ci/wellTargets/field/{fieldIdentifier}',
      path: {
        fieldIdentifier: fieldIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
