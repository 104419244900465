import { colors } from '@equinor/amplify-component-lib';

import { ChartDataPointCategory } from './Chart.types';
import { AddedPrognosisDto } from './Chart.types';

const PAD_PERCENTAGE = 0.2;

export function allPressureValuesFromDataPoint(
  dataPoint: AddedPrognosisDto
): number[] {
  const values = [dataPoint.expected, dataPoint.high, dataPoint.low];
  if (dataPoint.pressureInitial) {
    values.push(Number(dataPoint?.initial));
  }
  return values;
}

export function dataPointColor(
  category: ChartDataPointCategory,
  color?: string,
  graphColors?: string[]
) {
  const colorString = graphColors?.map((color) => `${color}`).join(',');

  let fillColor = color || colors.ui.background__medium.rgba;
  if (colorString) {
    fillColor = `conic-gradient(from 180deg at 50% 50%, ${colorString})`;
  }

  switch (category) {
    case ChartDataPointCategory.EXPECTED:
      return {
        stroke: colors.ui.background__scrim.hex,
        fill: colors.ui.background__default.rgba,
      };

    case ChartDataPointCategory.LOW_HIGH:
      return {
        stroke: color,
        fill: fillColor,
      };
    case ChartDataPointCategory.INITIAL:
      return {
        stroke: colors.text.static_icons__default.hex,
        fill: colors.text.static_icons__default.hex,
      };
  }
}

export function getDepthDomain(data: AddedPrognosisDto[]): [number, number] {
  const min = Math.min(...data.map((d) => d.depth));
  const max = Math.max(...data.map((d) => d.depth));

  const diff = (max - min) * PAD_PERCENTAGE;

  return [max + diff, min - diff];
}

export function getPressureDomain(data: AddedPrognosisDto[]): [number, number] {
  const min = Math.min(...data.flatMap(allPressureValuesFromDataPoint));
  const max = Math.max(...data.flatMap(allPressureValuesFromDataPoint));

  const diff = (max - min) * PAD_PERCENTAGE;

  return [Math.max(min - diff, 0), max + diff];
}

export function getNameDomain(data: AddedPrognosisDto[]): string[] {
  return data
    .map((d) => d.lithostratSubzone)
    .filter((d): d is string => d !== null && d !== undefined);
}
