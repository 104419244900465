import { createContext, FC, ReactNode, useMemo, useReducer } from 'react';

import { Field } from '@equinor/amplify-component-lib';

import { Unit } from 'src/types/prognosisDetails';
import { getFormattedFieldNameForUrl } from 'src/utils';
import { ViewPrognosesOptions } from 'src/utils/prognosisDashboard';

const localStorageKey = 'client-appstatecontext';

interface AppState {
  field: Field | undefined;
  unit: Unit;
  draftGroup: string;
  togglePrognosisChartXY: boolean;
}

interface AppContextState {
  field: Field | undefined;
  setField: (val: Field) => void;
  formattedFieldNameForUrl: string;
  draftGroup: ViewPrognosesOptions;
  setDraftGroup: (val: ViewPrognosesOptions) => void;
  unit: Unit;
  setUnit: (val: Unit) => void;
  togglePrognosisChartXY: boolean;
  setTogglePrognosisChartXY: (val: boolean) => void;
}

const getDefaultState = (): AppState => {
  const localStorageData = localStorage.getItem(localStorageKey);

  if (localStorageData) {
    return JSON.parse(localStorageData) as AppState;
  }
  return {
    field: undefined,
    unit: Unit.BAR,
    draftGroup: ViewPrognosesOptions.SUBMITTED_TO_ME,
    togglePrognosisChartXY: false,
  };
};

const updateLocalStorage = (state: AppState) => {
  localStorage.setItem(localStorageKey, JSON.stringify(state));
};

export const AppContext = createContext<AppContextState | undefined>(undefined);

const appStateReducer = (state: AppState, newState: AppState) => {
  updateLocalStorage(newState);
  return newState;
};

interface AppStateContextProviderProps {
  children: ReactNode;
}

export const AppStateContextProvider: FC<AppStateContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appStateReducer, getDefaultState());

  const formattedFieldNameForUrl = useMemo(() => {
    if (!state.field?.name) return '';
    return getFormattedFieldNameForUrl(state.field);
  }, [state.field]);

  const setField = (val: Field) => {
    dispatch({
      field: val,
      unit: state.unit,
      draftGroup: state.draftGroup,
      togglePrognosisChartXY: state.togglePrognosisChartXY,
    });
  };

  const setDraftGroup = (val: ViewPrognosesOptions) => {
    dispatch({
      field: state.field,
      unit: state.unit,
      draftGroup: val,
      togglePrognosisChartXY: state.togglePrognosisChartXY,
    });
  };

  const setUnit = (val: Unit) => {
    dispatch({
      field: state.field,
      unit: val,
      draftGroup: state.draftGroup,
      togglePrognosisChartXY: state.togglePrognosisChartXY,
    });
  };

  const setTogglePrognosisChartXY = (val: boolean) => {
    dispatch({
      field: state.field,
      unit: state.unit,
      draftGroup: state.draftGroup,
      togglePrognosisChartXY: val,
    });
  };

  return (
    <AppContext.Provider
      value={{
        field: state.field,
        setField,
        formattedFieldNameForUrl,
        unit: state.unit ?? Unit.BAR,
        setUnit,
        draftGroup:
          (state.draftGroup as ViewPrognosesOptions) ??
          ViewPrognosesOptions.SUBMITTED_TO_ME,
        setDraftGroup,
        togglePrognosisChartXY: state.togglePrognosisChartXY,
        setTogglePrognosisChartXY,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
