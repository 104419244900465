import { useMutation } from '@tanstack/react-query';

import {
  OptionalAttributesDto,
  OptionalPrognosisAttributesService,
} from 'src/api';

export const useCopyOptionalAttributes = () => {
  return useMutation({
    mutationFn: async ({
      body,
      id,
    }: {
      body: OptionalAttributesDto;
      id: number;
    }) => {
      return await OptionalPrognosisAttributesService.putApiV1OptionalAttributes(
        id,
        body
      );
    },
  });
};
