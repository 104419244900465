import {
  capitalize,
  colors,
  formatDate,
  SelectOptionRequired,
} from '@equinor/amplify-component-lib';

import { OptionalAttributes } from './zodSchema';
import {
  PrognosisDetailCreateDto,
  PrognosisDetailDto,
  PrognosisDetailUpdateDto,
} from 'src/api';
import { PrognosisFilterKeys } from 'src/pages/PrognosisDashboard/components/PrognosisFilter/PrognosisFilter';
import { StatusIdEnum } from 'src/types/status';

/**
 * Cleans the given prognosis detail object by removing specified attributes based on the provided arguments.
 *
 * @param detail - The partial prognosis detail object to be cleaned.
 * @param args - Optional attributes that specify which fields to remove from the detail object.
 *   - `segment`: If true, removes the `segment` field.
 *   - `datumDepth`: If true, removes the `datumDepth`, `pressureDatumExpected`, `pressureDatumHigh` and `pressureDatumLow`fields.
 *   - `pressureInitial`: If true, removes the `pressureInitial` field.
 *   - `pressureMin`: If true, removes the `pressureMin` field.
 *   - `pressureMax`: If true, removes the `pressureMax` field.
 * @returns The cleaned prognosis detail object.
 */
export function cleanPrognosisDetail(
  detail: Partial<PrognosisDetailCreateDto>,
  args?: OptionalAttributes
): PrognosisDetailDto {
  const copy: Partial<PrognosisDetailDto> = structuredClone(detail);

  if (!args?.datumDepth) {
    delete copy.datumDepth;
    delete copy.pressureDatumExpected;
    delete copy.pressureDatumHigh;
    delete copy.pressureDatumLow;
  }

  if (!args?.segment) {
    delete copy.segment;
  }

  if (!args?.pressureInitial) {
    delete copy.pressureInitial;
  }

  if (!args?.pressureMin) {
    delete copy.pressureMin;
  }

  if (!args?.pressureMax) {
    delete copy.pressureMax;
  }

  return copy as PrognosisDetailDto;
}

/**
 * Cleans the given prognosis detail object by removing specified attributes based on the provided arguments.
 *
 * @param details - The partial prognosis details object to be cleaned.
 * @param args - Optional attributes that specify which fields to remove from the detail object.
 *   - `segment`: If true, removes the `segment` field.
 *   - `datumDepth`: If true, removes the `datumDepth`, `pressureDatumExpected`, `pressureDatumHigh` and `pressureDatumLow` fields.
 *   - `pressureInitial`: If true, removes the `pressureInitial` field.
 *   - `pressureMin`: If true, removes the `pressureMin` field.
 *   - `pressureMax`: If true, removes the `pressureMax` field.
 * @returns The cleaned prognosis detail object.
 */
export function cleanBulkPrognosisDetails(
  details: PrognosisDetailDto[],
  args?: OptionalAttributes
): PrognosisDetailUpdateDto[] {
  const copy: PrognosisDetailDto[] = structuredClone(details);
  const cleanedDetails: PrognosisDetailUpdateDto[] = [];

  for (const detail of copy) {
    if (!args?.datumDepth) {
      delete detail.datumDepth;
      delete detail.pressureDatumExpected;
      delete detail.pressureDatumHigh;
      delete detail.pressureDatumLow;
    }

    if (!args?.segment) {
      delete detail.segment;
    }

    if (!args?.pressureInitial) {
      delete detail.pressureInitial;
    }

    if (!args?.pressureMin) {
      delete detail.pressureMin;
    }

    if (!args?.pressureMax) {
      delete detail.pressureMax;
    }

    cleanedDetails.push(detail as PrognosisDetailUpdateDto);
  }

  return cleanedDetails;
}

/**
 * Determines the stroke color based on the given prognosis status ID and valid from date.
 *
 * @param statusId - The ID of the prognosis status.
 * @param validFromDate - The valid from date.
 * @returns The stroke color as a string.
 */
export function selectStrokeColor({
  statusId,
  validFromDate,
}: {
  statusId: StatusIdEnum;
  validFromDate: string;
}) {
  switch (statusId) {
    case StatusIdEnum.DRAFT:
      return colors.dataviz.lightgray.lighter;
    case StatusIdEnum.SUBMITTED:
      return colors.dataviz.lightblue.default;
    case StatusIdEnum.REVISION_REQUIRED:
      return colors.dataviz.lightpink.default;
    case StatusIdEnum.OUTDATED:
      return colors.dataviz.lightyellow.default;
    case StatusIdEnum.APPROVED:
      if (validFromDate > new Date().toISOString()) {
        return colors.dataviz.lightblue.darker;
      } else {
        return colors.dataviz.lightgreen.default;
      }
    default:
      return 'transparent';
  }
}

export enum OperationValues {
  NEW_WELL = 0,
  INTERVENTION = 1,
  P_N_A = 2,
  OTHER = 3,
  TARGET = 4,
}

export function stringToSelectOptionRequired(
  value: string | null | undefined,
  key?: keyof PrognosisFilterKeys
): SelectOptionRequired[] {
  if (!value) return [];
  let label = capitalize(value.replace('_', ' '));

  if (key === 'lastModifiedFrom' || key === 'lastModifiedTo') {
    const prefix = key === 'lastModifiedFrom' ? 'From:' : 'To:';

    label = `${prefix} ${formatDate(new Date(value), { format: 'YYYY-MM-DD' })}`;
  }
  if (key === 'isSubscribed') {
    label = 'My subscriptions only';
  }
  if (key === 'createdBy') {
    label = `Created by: ${label.toUpperCase()}`;
  }
  if (key === 'approver') {
    label = `Approver: ${label.toUpperCase()}`;
  }

  return [
    {
      value,
      label,
    },
  ];
}

export type OptionalAttributeKeys =
  | 'datumDepth'
  | 'segment'
  | 'pressureInitial'
  | 'pressureMin'
  | 'pressureMax'
  | 'totalDepthRow'
  | 'useExistingFieldValues'
  | 'commingledZones';
