import { ChangeEvent, FC } from 'react';

import { TextField } from '@equinor/amplify-component-lib';

import { ModifiedRow } from '../FilterSidebar.styles';
import { usePrognosisDashboard } from 'src/pages/PrognosisDashboard/hooks/useDashboard';

export const ModifiedDateRow: FC = () => {
  const { filterValue, updateFilterValue } = usePrognosisDashboard();

  const handleOnModifiedFromChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFilterValue({ lastModifiedFrom: event.target.value ?? undefined });
  };

  const handleOnModifiedToChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFilterValue({ lastModifiedTo: event.target.value ?? undefined });
  };

  return (
    <ModifiedRow>
      <TextField
        id="fromDate"
        label="From"
        type="date"
        value={filterValue.lastModifiedFrom ?? ''}
        onChange={handleOnModifiedFromChange}
      />
      <TextField
        id="toDate"
        label="To"
        type="date"
        value={filterValue.lastModifiedTo ?? ''}
        onChange={handleOnModifiedToChange}
      />
    </ModifiedRow>
  );
};
