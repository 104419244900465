import { FC } from 'react';

import { Status } from '@equinor/amplify-component-lib';

import { Container } from './NoItemsFound.styles';
import { TabType } from 'src/constants/tab';
import { tabTypeToGenericName } from 'src/utils/tab';

interface NoItemsFoundProps {
  tabType: TabType;
}

export const NoItemsFound: FC<NoItemsFoundProps> = ({ tabType }) => {
  const name = tabTypeToGenericName(tabType);

  return (
    <Container>
      <Status center={false}>
        <Status.Title title={`There are no ${name} to display.`} />
      </Status>
    </Container>
  );
};
