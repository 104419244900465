import { useMemo } from 'react';

import {
  colors,
  formatDate,
  OptionalTooltip,
  Typography,
} from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { UnitToggle } from '../UnitToggle/UnitToggle';
import {
  FlexWrapper,
  Info,
  Metadata,
  MetaDataItem,
} from './PrognosisInfo.styles';
import { PrognosisInfoSkeleton } from './PrognosisInfoSkeleton';
import { TOGGLE_UNITS_FEATURE_KEY } from 'src/constants';
import { useFullName, useGetPrognosis } from 'src/hooks';
import { getOperationPhaseLabel } from 'src/pages/EditPrognosis/hooks/util/useCreateFormOptions';
import { StatusIdEnum } from 'src/types';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';

export const PrognosisInfo = () => {
  const { data, isLoading } = useGetPrognosis();
  const { data: createdBy, isLoading: isLoadingCreatedBy } = useFullName(
    data?.createUser
  );

  const { data: backupApprover, isLoading: isLoadingBackupApprover } =
    useFullName(data?.backupApprover);

  const { data: mainApprover } = useFullName(data?.mainApprover);

  const formatOperation = lookupValueToComboBoxRequired(
    data?.operation,
    true
  ).label;

  const formatOperationPhase = lookupValueToComboBoxRequired(
    data?.operationPhase,
    true
  ).label;

  const { showContent } = useFeatureToggling(TOGGLE_UNITS_FEATURE_KEY);

  const showBackupApproverColumn =
    data?.statusId === StatusIdEnum.DRAFT ||
    data?.statusId === StatusIdEnum.REVISION_REQUIRED ||
    data?.statusId === StatusIdEnum.SUBMITTED;

  const isBackupApproved = data?.approveUser === data?.backupApprover;

  const operationId = data?.operationId ?? 0;

  const operationPhaseLabel = useMemo(() => {
    return getOperationPhaseLabel(operationId);
  }, [operationId]);

  if (
    isLoading ||
    isLoadingCreatedBy ||
    isLoadingBackupApprover ||
    data == undefined
  )
    return <PrognosisInfoSkeleton />;

  return (
    <FlexWrapper>
      <Info>
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Validity
          </Typography>
          <Typography group="table" variant="cell_text" color="warning">
            {formatDate(data.validFromDate ?? 'N/A', {
              format: 'DD. month YYYY',
              month: 'short',
            })}
            {' - '}
            {formatDate(data.validToDate ?? 'N/A', {
              format: 'DD. month YYYY',
              month: 'short',
            })}
          </Typography>
        </Metadata>
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Created by
          </Typography>
          <Typography group="table" variant="cell_text">
            {createdBy}
          </Typography>
        </Metadata>
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Approver
          </Typography>
          {isBackupApproved ? (
            <OptionalTooltip
              placement="bottom"
              title={`Backup approver for ${mainApprover}`}
            >
              <MetaDataItem group="table" variant="cell_text">
                {backupApprover}
              </MetaDataItem>
            </OptionalTooltip>
          ) : (
            <Typography group="table" variant="cell_text">
              {mainApprover}
            </Typography>
          )}
        </Metadata>
        {showBackupApproverColumn && (
          <Metadata>
            <Typography
              group="navigation"
              variant="label"
              color={colors.text.static_icons__tertiary.rgba}
            >
              Backup approver
            </Typography>
            <Typography group="table" variant="cell_text">
              {backupApprover}
            </Typography>
          </Metadata>
        )}
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            Operation
          </Typography>
          <Typography group="table" variant="cell_text">
            {formatOperation}
          </Typography>
        </Metadata>
        {data?.operationPhase && (
          <Metadata>
            <Typography
              group="navigation"
              variant="label"
              color={colors.text.static_icons__tertiary.rgba}
            >
              {operationPhaseLabel}
            </Typography>
            <Typography group="table" variant="cell_text">
              {formatOperationPhase}
            </Typography>
          </Metadata>
        )}
        <Metadata>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.rgba}
          >
            {data?.wellboreIdentifier ? 'Wellbore' : 'Target'}
          </Typography>
          <Typography group="table" variant="cell_text">
            {data?.wellboreIdentifier ?? data?.ciTargetName}
          </Typography>
        </Metadata>
      </Info>
      {showContent && <UnitToggle />}
    </FlexWrapper>
  );
};
