import { FC } from 'react';
import { useNavigate } from 'react-router';

import { Dialog, Typography } from '@equinor/amplify-component-lib';

import { usePatchStatus } from 'src/pages/ViewPrognosis/hooks/usePatchStatus';

import { useApp } from 'hooks/useApp';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

interface SubmitDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
}

export const SubmitDialog: FC<SubmitDialogProps> = ({
  open,
  title,
  onClose,
}) => {
  const { submitPrognosis } = usePatchStatus();
  const { mutateAsync: submitPrognosisMutate, isPending } = submitPrognosis;
  const navigate = useNavigate();
  const { formattedFieldNameForUrl } = useApp();
  const usingId = usePrognosisIDFromParams();

  const handleOnSubmit = async () => {
    await submitPrognosisMutate();
    onClose();
    navigate(`${formattedFieldNameForUrl}/prognosis/view/${usingId}`);
  };

  if (!open) return null;

  return (
    <Dialog
      title={`Confirm submit prognosis - ${title}`}
      open={open}
      onClose={onClose}
      width={500}
      withContentPadding={{ vertical: true, horizontal: true }}
      withBorders
      actions={[
        {
          position: 'right',
          onClick: onClose,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          position: 'right',
          onClick: handleOnSubmit,
          text: 'Submit',
          color: 'primary',
          disabled: isPending,
          isLoading: isPending,
        },
      ]}
    >
      <Typography variant="body_short">
        This action will notify the approver you selected and will be available
        for review until an action is taken.
      </Typography>
    </Dialog>
  );
};
