import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

import {
  Switch,
  Typography,
  useSnackbar,
} from '@equinor/amplify-component-lib';
import { Dialog } from '@equinor/amplify-component-lib';

import { EditView } from './EditView/EditView';
import { LoadView } from './LoadView/LoadView';
import { SwitchContainer } from './FilterViewEditDialog.styles';
import { usePrognosisDashboard } from 'src/pages/PrognosisDashboard/hooks/useDashboard';
import { useDeleteFilterView } from 'src/pages/PrognosisDashboard/hooks/useDeleteFilterView';
import { useGetFilterViews } from 'src/pages/PrognosisDashboard/hooks/useGetFilterViews';
import { usePatchFilterView } from 'src/pages/PrognosisDashboard/hooks/usePatchFilterView';
import { MainDialogContent } from 'src/pages/ViewPrognosis/components/Actions/Actions.styles';

interface FilterViewEditDialogProps {
  open: boolean;
  toggleFilterViewEditDialog: () => void;
}

export const FilterViewEditDialog: FC<FilterViewEditDialogProps> = ({
  open,
  toggleFilterViewEditDialog,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<string | undefined>(
    undefined
  );
  const [patchList, setPatchList] = useState<{ id: string; value: string }[]>(
    []
  );
  const [deleteList, setDeleteList] = useState<string[]>([]);

  const { showSnackbar } = useSnackbar();
  const { updateFilterValue, activeTab } = usePrognosisDashboard();
  const { data: filterViews } = useGetFilterViews();
  const { mutateAsync: patchItem } = usePatchFilterView(patchList);
  const { mutateAsync: deleteFilterView } = useDeleteFilterView(deleteList);

  const handleOnDeleteAdd = (itemId: string) => {
    setDeleteList((prevList) => [...prevList, itemId]);
  };

  const handleOnPatch = (id: string, value: string) => {
    setPatchList((prevList) => {
      if (prevList.find((item) => item.id === id)) {
        return prevList.map((item) => (item.id === id ? { id, value } : item));
      } else {
        return [...prevList, { id, value }];
      }
    });
  };

  const handleOnSave = async () => {
    await deleteFilterView();
    await patchItem();
    setIsEditing(false);
  };

  const handleOnClose = () => {
    setIsEditing(false);
    toggleFilterViewEditDialog();
  };

  const handleSetSelected = (id: string) => {
    if (id === selectedView) {
      setSelectedView(undefined);
    } else {
      setSelectedView(id);
    }
  };

  const handleOnLoad = () => {
    const selectedFilterView = filterViews?.find(
      (item) => item.id === selectedView
    );
    if (!selectedFilterView) return;
    // remove unwanted properties from the object
    const {
      id: _id,
      title: _title,
      createdByEmail: _createdByEmail,
      fieldIdentifier: _fieldIdentifier,
      ...picked
    } = selectedFilterView;

    // if the active tab is not "My actionable items", then remove the status property
    if (activeTab !== 0) {
      picked.status = null;
    }

    updateFilterValue(picked);
    showSnackbar('Filter loaded');
    toggleFilterViewEditDialog();
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsEditing(e.target.checked);
  };

  useEffect(() => {
    setDeleteList([]);
    setPatchList([]);
    setSelectedView(undefined);
  }, [isEditing, open]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      title={isEditing ? 'Edit views' : 'Load a view'}
      width={500}
      withContentPadding={{ vertical: true, horizontal: true }}
      withBorders
      actions={[
        {
          position: 'right',
          onClick: handleOnClose,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          position: 'right',
          onClick: isEditing ? handleOnSave : handleOnLoad,
          text: isEditing ? 'Save' : 'Load',
          disabled:
            (isEditing && deleteList.length === 0 && patchList.length === 0) ||
            (!isEditing && selectedView === undefined),
        },
      ]}
    >
      <>
        <SwitchContainer>
          <Typography>Edit</Typography>
          <Switch
            aria-label="edit"
            onChange={handleSwitchChange}
            checked={isEditing}
          />
        </SwitchContainer>
        <MainDialogContent>
          {filterViews?.map((item, index) => (
            <Fragment key={index}>
              {isEditing ? (
                <EditView
                  item={item}
                  isDisabled={deleteList.includes(item.id ?? 'N/A')}
                  handleOnDelete={handleOnDeleteAdd}
                  handleOnPatch={handleOnPatch}
                />
              ) : (
                <LoadView
                  item={item}
                  isSelected={item.id === selectedView}
                  handleSetSelected={handleSetSelected}
                />
              )}
            </Fragment>
          ))}
        </MainDialogContent>
      </>
    </Dialog>
  );
};
