import { useQuery } from '@tanstack/react-query';

import { ReservoirZoneService } from 'src/api';
import { LAST_USED_ZONES_KEY } from 'src/constants/queryKeys';

interface LastUsedZones {
  wellboreIdentifier: string;
  stratColumnIdentifier: string;
  operationId: number | undefined;
  targetUuid: string;
}

export const useGetLastUsedZones = ({
  wellboreIdentifier,
  stratColumnIdentifier,
  operationId,
  targetUuid,
}: LastUsedZones) => {
  return useQuery({
    queryKey: [
      LAST_USED_ZONES_KEY,
      wellboreIdentifier,
      stratColumnIdentifier,
      operationId,
      targetUuid,
    ],
    queryFn: () => {
      const wellboreId = targetUuid ? '' : wellboreIdentifier;
      const targetId = targetUuid ? targetUuid : '';
      return ReservoirZoneService.getApiV1ReservoirZonesLastUsed(
        wellboreId,
        targetId,
        stratColumnIdentifier,
        operationId
      );
    },
    enabled:
      !!stratColumnIdentifier &&
      (!!wellboreIdentifier || !!targetUuid) &&
      operationId !== undefined,
  });
};
