import { FC } from 'react';

import { BaseTableCell } from '../BaseTableCell/BaseTableCell';
import { PrognosisDetailDto } from 'src/api';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';
import { determineCellColors } from 'src/utils/color';
import { getPrognosisDetailError } from 'src/utils/error';

interface EditTableCellProps {
  detailValue?: string;
  detailKey: string;
  index: number;
}

export const EditTableCell: FC<EditTableCellProps> = ({
  detailValue,
  detailKey,
  index,
}) => {
  const {
    formState: { errors },
  } = useEditDetailsStepForm();
  const showPlaceholder = detailValue == undefined || detailValue == '';

  const hasError = Boolean(
    getPrognosisDetailError(
      errors,
      index,
      detailKey as keyof PrognosisDetailDto
    )
  );

  const { textColor, backgroundColor } = determineCellColors({
    isUpdated: false,
    showPlaceholder,
    hasError,
  });

  return (
    <BaseTableCell
      backgroundColor={backgroundColor}
      textColor={textColor}
      detailValue={detailValue ?? '0.0'}
    />
  );
};
