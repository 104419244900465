import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, spacings } from '@equinor/amplify-component-lib';

import { PressureDataSimple } from 'src/api';
import { useApp, useMeasuredData } from 'src/hooks';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { getMeasurementFromPressureDataSimple } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  gap: ${spacings.medium_small};
`;

const CompareButton = styled(Button)`
  width: 92px;
`;

export const Actions: FC<PressureDataSimple> = (props) => {
  const { formattedFieldNameForUrl } = useApp();
  const { addMeasurements, selectedMeasurements, removeMeasurement } =
    useMeasuredData();
  const { isSelectedWellboresOpen, setIsSelectedWellboresOpen } =
    useMapOptions();
  const navigate = useNavigate();

  const handleOpen = () => {
    const measurement = getMeasurementFromPressureDataSimple(props);
    addMeasurements([measurement]);

    navigate(`${formattedFieldNameForUrl}/measured-data/plot`);
  };
  const handleOnClick = () => {
    const measurement = getMeasurementFromPressureDataSimple(props);

    if (!isSelectedWellboresOpen) {
      setIsSelectedWellboresOpen(true);
    }
    if (isSelected) {
      removeMeasurement(measurement);
    } else {
      addMeasurements([measurement]);
    }
  };

  const isSelected = useMemo(() => {
    return selectedMeasurements.some(
      (measurement) =>
        measurement.value === props.wellboreUuid &&
        measurement.dhpg === props.dhpg
    );
  }, [selectedMeasurements, props]);

  return (
    <Wrapper>
      <CompareButton variant="ghost" onClick={handleOnClick}>
        {isSelected ? 'Unselect' : 'Compare'}
      </CompareButton>
      <Button onClick={handleOpen}>Open</Button>
    </Wrapper>
  );
};
