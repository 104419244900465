export const sliderRangeToDate = (
  rangeValue: number,
  fromDate: Date,
  toDate: Date
) => {
  const date = new Date(fromDate);
  date.setDate(fromDate.getDate() + rangeValue);
  if (date > toDate) {
    return toDate;
  }
  return date;
};
