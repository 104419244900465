import { FC } from 'react';

import { spacings, Typography } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { AttributeChip } from '../../AttributeChip/AttributeChip';
import {
  RESET_ATTRIBUTES,
  SHOW_ATTRIBUTE_CHIPS,
  SHOW_COMINGLED_CHIP,
  SHOW_FIELD_VALUES_CHIP,
} from 'src/constants';
import { useZoneSelectionStepForm } from 'src/pages/EditPrognosis/hooks/form/useZoneSelectionStepForm';
import { OptionalAttributeKeys } from 'src/utils';

import { styled } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
  min-width: 400px;
`;

const Row = styled.div`
  display: flex;
  gap: ${spacings.medium};
  flex-wrap: wrap;
`;

interface OptionalAttributesProps {
  isInitializing: boolean;
}

export const OptionalAttributes: FC<OptionalAttributesProps> = ({
  isInitializing,
}) => {
  const { getValues, setValue } = useZoneSelectionStepForm();
  const optionalAttributes = getValues('optionalAttributes');
  const { showContent: showAttributeChips } =
    useFeatureToggling(SHOW_ATTRIBUTE_CHIPS);

  const { showContent: showCoMingledChip } =
    useFeatureToggling(SHOW_COMINGLED_CHIP);

  const { showContent: showFieldValuesChip } = useFeatureToggling(
    SHOW_FIELD_VALUES_CHIP
  );

  const onToggleAttribute = (key: OptionalAttributeKeys) => {
    const previousValue = getValues(`optionalAttributes.${key}`);

    if (key === 'commingledZones') {
      RESET_ATTRIBUTES.forEach((attribute) => {
        const value = getValues(`optionalAttributes.${attribute}`);
        if (value) {
          setValue(`optionalAttributes.${attribute}`, false, {
            shouldDirty: true,
          });
        }
      });
    }

    setValue(`optionalAttributes.${key}`, !previousValue, {
      shouldDirty: true,
    });
  };

  return (
    <Wrapper>
      <Typography variant="h4">Select prognosis attributes</Typography>
      <ChipContainer>
        <Row>
          {showAttributeChips && (
            <>
              <AttributeChip
                disabled
                label="Use prognosed depth to zone top from Pozo"
              />
              <AttributeChip disabled label="Use STAT wellpick for top zone" />
              <AttributeChip disabled label="Use existing reference data" />
            </>
          )}
        </Row>
        <Row>
          {showCoMingledChip && (
            <AttributeChip
              onClick={() => onToggleAttribute('commingledZones')}
              disabled={isInitializing}
              label="Co-mingled zones"
              isSelected={optionalAttributes.commingledZones}
            />
          )}
          <AttributeChip
            onClick={() => onToggleAttribute('datumDepth')}
            label="Datum depth"
            isSelected={optionalAttributes.datumDepth}
            disabled={isInitializing}
          />
          <AttributeChip
            onClick={() => onToggleAttribute('segment')}
            label="Segments"
            isSelected={optionalAttributes.segment}
            disabled={isInitializing || optionalAttributes.commingledZones}
          />
          <AttributeChip
            onClick={() => onToggleAttribute('pressureInitial')}
            label="Initial pressure"
            isSelected={optionalAttributes.pressureInitial}
            disabled={isInitializing}
          />
          <AttributeChip
            onClick={() => onToggleAttribute('pressureMin')}
            label="Min pressure"
            isSelected={optionalAttributes.pressureMin}
            disabled={isInitializing}
          />
          <AttributeChip
            onClick={() => onToggleAttribute('pressureMax')}
            label="Max pressure"
            isSelected={optionalAttributes.pressureMax}
            disabled={isInitializing}
          />
          <AttributeChip
            onClick={() => onToggleAttribute('totalDepthRow')}
            label="Total depth"
            isSelected={optionalAttributes.totalDepthRow}
            disabled={isInitializing || optionalAttributes.commingledZones}
          />
          {showFieldValuesChip && (
            <AttributeChip
              onClick={() => onToggleAttribute('useExistingFieldValues')}
              label="Use existing field values"
              isSelected={optionalAttributes.useExistingFieldValues}
              disabled={isInitializing || optionalAttributes.commingledZones}
            />
          )}
        </Row>
      </ChipContainer>
    </Wrapper>
  );
};
