import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { formatDate } from '@equinor/amplify-component-lib';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Position } from '@turf/helpers';

import { PressureDataSimple } from 'src/api';
import { PressureService } from 'src/api';
import {
  MEASURED_DATA_MAP_KEY,
  PRES_DATA_BY_FIELD_FLAT_KEY,
} from 'src/constants';
import { useApp, useMeasuredData } from 'src/hooks';
import { getNearestPoint } from 'src/pages/MapView/MapView.utils';

export interface MapDataContextProps {
  pressurePoints: PressureDataSimple[];
  pressurePointsWithValue: PressureDataSimple[];
  fromRangeDate: Date;
  toRangeDate: Date;
  daysBetweenFromToRangeDate: number;
  nearestNeighbor: Map<string, Position>;
  loading: boolean;
}

export const MapDataContext = createContext<MapDataContextProps>(
  {} as MapDataContextProps
);

export const MapDataProvider = ({ children }: { children: ReactNode }) => {
  const { field } = useApp();
  const { mapDateRangeOptions } = useMeasuredData();

  const [nearestPointMap, setNearestPointMap] = useState<Map<string, Position>>(
    new Map<string, Position>()
  );

  const fromRangeDate = useMemo(
    () =>
      new Date(
        mapDateRangeOptions.dateForRange.getTime() -
          1000 * 3600 * 24 * mapDateRangeOptions.range
      ),
    [mapDateRangeOptions]
  );

  const toRangeDate = useMemo(
    () =>
      new Date(
        mapDateRangeOptions.dateForRange.getTime() +
          1000 * 3600 * 24 * mapDateRangeOptions.range
      ),
    [mapDateRangeOptions]
  );

  const daysBetweenFromToRangeDate = useMemo(() => {
    return Math.round(
      (toRangeDate.getTime() - fromRangeDate.getTime()) / (1000 * 3600 * 24) // Unix time between fromDate/toDate divided by unix time for a single day
    );
  }, [fromRangeDate, toRangeDate]);

  const { data: pressurePoints = [], isLoading } = useQuery({
    queryKey: [
      MEASURED_DATA_MAP_KEY,
      PRES_DATA_BY_FIELD_FLAT_KEY,
      field?.uuid,
      mapDateRangeOptions.selectedDate,
    ],
    queryFn: () =>
      PressureService.getpressureDataByFieldFlat(
        field?.uuid ?? '',
        formatDate(mapDateRangeOptions.selectedDate, { format: 'YYYY-MM-DD' })
      ),
    refetchOnWindowFocus: false,
    retry: false,
    gcTime: 1000 * 60 * 60 * 24,
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (nearestPointMap.size < pressurePoints.length) {
      const tempMap = new Map<string, Position>();
      pressurePoints.forEach((item) => {
        const nearest = getNearestPoint(item, pressurePoints);
        tempMap.set(`${item.wellboreUuid}-${item.dhpg}`, nearest);
      });

      setNearestPointMap(tempMap);
    }
  }, [nearestPointMap.size, pressurePoints, mapDateRangeOptions.selectedDate]);

  const pressurePointsWithValue = useMemo(
    () => pressurePoints.filter((point) => point.pressureFinal > 0),
    [pressurePoints]
  );

  return (
    <MapDataContext.Provider
      value={{
        pressurePoints,
        pressurePointsWithValue,
        fromRangeDate,
        toRangeDate,
        daysBetweenFromToRangeDate,
        loading: isLoading,
        nearestNeighbor: nearestPointMap,
      }}
    >
      {children}
    </MapDataContext.Provider>
  );
};
