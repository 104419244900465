import { useEffect, useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  useGetInterventionPhases,
  useGetNewWellPhases,
  useGetTargetPhases,
} from '../api/useGetOperationPhases';
import { usePutStep } from '../api/usePutStep';
import { getInitialCreateOptions } from './useCreateFormOptions';
import {
  useGetAllWellbores,
  useGetDrilledWellbores,
  useGetPlannedWellbores,
  useGetPrognosis,
  useGetPrognosisTypes,
} from 'src/hooks';
import { CreationStepFormType } from 'src/utils/zodSchema';

import { useGetCiWellTargetsByField } from 'hooks/useGetCiTargets';

interface UseInitializeCreationStep {
  formReset: UseFormReset<CreationStepFormType>;
}

export function useInitializeCreationStep({
  formReset,
}: UseInitializeCreationStep) {
  const { prognosisId } = useParams();
  const { data: operations } = useGetPrognosisTypes();
  const { data: newWellPhases } = useGetNewWellPhases();
  const { data: interventionPhases } = useGetInterventionPhases();
  const { data: targetPhases } = useGetTargetPhases();
  const { data: ciWellTargets } = useGetCiWellTargetsByField();
  const { data: existingPrognosis } = useGetPrognosis();
  const { data: allWellbores } = useGetAllWellbores();
  const { data: plannedWellbores } = useGetPlannedWellbores();
  const { data: drilledWellbores } = useGetDrilledWellbores();
  const { mutate: putStep } = usePutStep();

  const [isInitializing, setIsInitializing] = useState(
    prognosisId !== undefined && prognosisId !== ''
  );

  useEffect(() => {
    const initializeForm = () => {
      const selectedOperation = operations?.find(
        (item) => item.id === existingPrognosis?.operationId
      );

      const { selectedWellbore, selectedOperationPhase, selectedCiWellTarget } =
        getInitialCreateOptions({
          selectedOperation,
          existingPrognosis,
          newWellPhases,
          interventionPhases,
          targetPhases,
          ciWellTargets,
          allWellbores,
          plannedWellbores,
          drilledWellbores,
        });

      const formValues: CreationStepFormType = {
        operationId: selectedOperation?.id ?? -1,
        operationPhaseId: selectedOperationPhase?.id ?? null,
        wellboreIdentifier: selectedWellbore?.uniqueWellboreIdentifier ?? null,
        ciTargetUuid: selectedCiWellTarget?.targetUuid ?? null,
      };

      formReset(formValues, {
        keepErrors: true,
      });
      setIsInitializing(false);
    };

    if (
      existingPrognosis &&
      operations &&
      newWellPhases &&
      interventionPhases &&
      allWellbores &&
      plannedWellbores &&
      drilledWellbores &&
      isInitializing
    ) {
      initializeForm();
      putStep();
    }
  }, [
    allWellbores,
    drilledWellbores,
    existingPrognosis,
    formReset,
    interventionPhases,
    isInitializing,
    newWellPhases,
    operations,
    plannedWellbores,
    targetPhases,
    ciWellTargets,
    putStep,
  ]);

  return { isInitializing };
}
