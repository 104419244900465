import { FC } from 'react';

import { formatDate, Icon, Typography } from '@equinor/amplify-component-lib';
import { trending_flat } from '@equinor/eds-icons';

import { FilterChange } from 'src/api';
import { FilterType } from 'src/api';
import { FilterTypeIcon } from 'src/pages/EditMeasuredData/components/FilterPanel/components/FilterTypeIcon';
import { roundTo } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  > p {
    line-height: normal;
  }
`;

const RightSection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-gap: 8px;
  width: 110px;

  > p {
    line-height: normal;
  }

  p:first-child {
    text-align: right;
  }
`;

export const DisabledLabel = styled(Typography)`
  font-size: 14px;
`;

interface ChangeItemProps {
  change: FilterChange;
  disabled: boolean;
  type: FilterType | undefined;
}

export const ChangeItem: FC<ChangeItemProps> = ({ change, disabled, type }) => {
  const { date, from, to } = change;

  return (
    <Wrapper>
      <LeftSection>
        <FilterTypeIcon type={type} />
        <div>
          <Typography group="input" variant="label">
            {formatDate(date)}
          </Typography>

          {disabled && <DisabledLabel>Disabled</DisabledLabel>}
        </div>
      </LeftSection>
      <RightSection>
        <Typography group="input" variant="label">
          {roundTo(from, 2)}
        </Typography>
        <Icon data={trending_flat} />
        <Typography group="input" variant="label">
          {roundTo(to, 2)}
        </Typography>
      </RightSection>
    </Wrapper>
  );
};
