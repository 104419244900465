import { PrognosisDto } from 'src/api';
import { TabType } from 'src/constants/tab';

/**
 * Converts a TabType to its corresponding generic name.
 *
 * @param tabType - The TabType to convert.
 * @returns The generic name corresponding to the given TabType.
 */
export function tabTypeToGenericName(tabType: TabType) {
  switch (tabType) {
    case TabType.MY_ITEMS:
      return 'actionable items';
    case TabType.APPROVED:
      return 'approved items';
    case TabType.HISTORICAL:
      return 'historical items';
    case TabType.OTHER_SUBMITTED:
      return 'submitted items';
    case TabType.AWAITING_APPROVAL:
      return 'items awaiting approval';
    case TabType.OTHER_ITEMS_ON_THE_FIELD:
      return 'other items on the field';
    case TabType.ALL_ITEMS_ON_THE_FIELD:
      return 'other items on the field';
  }
}

/**
 * Groups an array of PrognosisDto objects by wellbore identifier.
 *
 * @param data - The array of PrognosisDto objects to be grouped.
 * @returns An object where the keys are wellbore identifiers and the values are arrays of PrognosisDto objects.
 */
export function groupByWellBore(data: PrognosisDto[] | undefined | null) {
  if (!data) return {};
  return data.reduce(
    (acc, curr) => {
      const wellbore = curr.wellboreIdentifier ?? curr.ciTargetUuid;
      if (wellbore) {
        if (!acc[wellbore]) {
          acc[wellbore] = [];
        }
        acc[wellbore].push(curr);
      }
      return acc;
    },
    {} as Record<string, PrognosisDto[]>
  );
}

/**
 * Sorts the grouped data by the validFromDate property in ascending order.
 *
 * @param groupedData - The grouped data to be sorted.
 * @returns The sorted grouped data.
 */
export function sortGroupedData(
  groupedData: Record<string, PrognosisDto[]> | undefined
): Record<string, PrognosisDto[]> {
  if (!groupedData) return {};

  return Object.fromEntries(
    Object.entries(groupedData).map(([key, value]) => [
      key,
      value.sort((a, b) => {
        const dateA = new Date(a.validFromDate ?? '').getTime();
        const dateB = new Date(b.validFromDate ?? '').getTime();

        return dateA - dateB;
      }),
    ])
  );
}

/**
 * Sorts the given data object by the validFromDate property in each array of PrognosisDto.
 * @param data - The data object to be sorted.
 * @param sortDirection - The sort direction, either 'asc' (ascending) or 'desc' (descending). Default is 'asc'.
 * @returns The sorted data object.
 */
export function sortByValid(
  data: Record<string, PrognosisDto[]> | undefined,
  sortDirection: 'asc' | 'desc' = 'asc'
): Record<string, PrognosisDto[]> {
  if (!data) return {};

  return Object.fromEntries(
    Object.entries(data).sort(([, valueA], [, valueB]) => {
      const dateA = new Date(valueA[0].validFromDate ?? '').getTime();
      const dateB = new Date(valueB[0].validFromDate ?? '').getTime();

      if (sortDirection === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    })
  );
}
