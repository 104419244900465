import { useFormContext } from 'react-hook-form';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  FieldValues as BackendFieldValues,
  FieldValuesDto,
  FieldValuesService,
  PrognosisDetailDto,
} from 'src/api';
import {
  CREATE_FIELD_VALUES_KEY,
  FIELD_VALUES_BY_FIELD,
  FIELD_VALUES_BY_STRAT_UNIT,
  UPDATE_FIELD_VALUES_KEY,
} from 'src/constants';
import { FieldValues } from 'src/pages/FieldValues/PrognosisValues/PrognosisValues.utils';

import { useApp } from 'hooks/useApp';

export const usePostFieldValues = () => {
  const { field } = useApp();
  const { getValues, reset } = useFormContext<FieldValues>();
  const queryClient = useQueryClient();

  const fieldValues = getValues();

  if (!fieldValues) {
    throw new Error('No field values');
  }

  const body: FieldValuesDto = {
    fieldUuid: field?.uuid ?? 'N/A',
    fieldIdentifier: field?.name ?? 'N/A',
    isGeneral: fieldValues.isGeneral,
    isActive: true,
    ...(fieldValues?.stratColumn?.stratColumnUuid && {
      stratColumnUuid: fieldValues.stratColumn.stratColumnUuid,
    }),
    ...(fieldValues?.stratColumn?.stratColumnIdentifier && {
      stratColumnIdentifier: fieldValues.stratColumn.stratColumnIdentifier,
    }),
    ...(fieldValues?.stratUnit?.stratUnitUuid && {
      stratUnitUuid: fieldValues.stratUnit.stratUnitUuid,
    }),
    ...(fieldValues?.stratUnit?.stratUnitIdentifier && {
      stratUnitIdentifier: fieldValues.stratUnit.stratUnitIdentifier,
    }),
    ...(fieldValues.datumDepth && { datumDepth: fieldValues.datumDepth }),
    ...(fieldValues.datumPressure && {
      pressureDatum: fieldValues.datumPressure,
    }),
    ...(fieldValues.initialPressure && {
      pressureInitial: fieldValues.initialPressure,
    }),
    ...(fieldValues.oilGradient && { gradientOil: fieldValues.oilGradient }),
    ...(fieldValues.gasGradient && { gradientGas: fieldValues.gasGradient }),
    ...(fieldValues.waterGradient && {
      gradientWater: fieldValues.waterGradient,
    }),
    ...(fieldValues.oilWaterContactDepth && {
      oilWaterContactDepth: fieldValues.oilWaterContactDepth,
    }),
    ...(fieldValues.gasOilContactDepth && {
      oilGasContactDepth: fieldValues.gasOilContactDepth,
    }),
    ...(fieldValues.gasWaterContactDepth && {
      gasWaterContactDepth: fieldValues.gasWaterContactDepth,
    }),
    ...(fieldValues.gasFormationVolFactor && {
      gasFormationVolFactor: fieldValues.gasFormationVolFactor,
    }),
    ...(fieldValues.gasSolution && {
      gasSolution: fieldValues.gasSolution,
    }),
    ...(fieldValues.oilFormationVolFactor && {
      oilFormationVolFactor: fieldValues.oilFormationVolFactor,
    }),
    ...(fieldValues.waterFormationVolFactor && {
      waterFormationVolFactor: fieldValues.waterFormationVolFactor,
    }),
  };

  return useMutation({
    mutationKey: [CREATE_FIELD_VALUES_KEY],
    mutationFn: async () => {
      return await FieldValuesService.postApiV1FieldValues(body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FIELD_VALUES_BY_FIELD, field?.uuid],
      });
      reset();
    },
  });
};

export const useGetFieldValues = (enabled?: boolean) => {
  const isEnabled = enabled === undefined ? true : enabled;
  const { field } = useApp();

  if (!field) {
    throw new Error('No field');
  }

  return useQuery({
    queryKey: [FIELD_VALUES_BY_FIELD, field.uuid],
    queryFn: () => FieldValuesService.getApiV1FieldValuesField(field.uuid!),
    enabled: !!field.uuid && isEnabled,
    staleTime: 5 * 60 * 1000,
  });
};

export const useGetFieldValuesByStratUnit = (
  detail: PrognosisDetailDto,
  enabled?: boolean
) => {
  const isEnabled = enabled === undefined ? true : enabled;
  const { field } = useApp();

  if (!field) {
    throw new Error('No field');
  }

  return useQuery({
    queryKey: [
      FIELD_VALUES_BY_STRAT_UNIT,
      field.uuid,
      detail.stratColumnIdentifier,
      detail.reservoirZone,
    ],
    queryFn: () =>
      FieldValuesService.getApiV1FieldValuesFieldStratColumnStratUnit(
        field.uuid!,
        detail.stratColumnIdentifier,
        detail.reservoirZone
      ),
    enabled:
      !!field.uuid &&
      !!detail.stratColumnIdentifier &&
      !!detail.reservoirZone &&
      isEnabled,
    staleTime: 5 * 60 * 1000,
  });
};

export const useDeleteFieldValues = () => {
  const { field } = useApp();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [CREATE_FIELD_VALUES_KEY],
    mutationFn: async (fieldId: number) => {
      const response = await FieldValuesService.deleteApiV1FieldValues(fieldId);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FIELD_VALUES_BY_FIELD, field?.uuid],
      });
    },
  });
};

export const usePutFieldValues = () => {
  const { field } = useApp();
  const queryClient = useQueryClient();
  const { getValues } = useFormContext<FieldValues>();

  const fieldValues = getValues();

  if (!fieldValues) {
    throw new Error('No field values');
  }

  const body: FieldValuesDto = {
    fieldUuid: field?.uuid ?? 'N/A',
    fieldIdentifier: field?.name ?? 'N/A',
    isActive: true,
    isGeneral: fieldValues.isGeneral,
    stratColumnUuid: fieldValues?.stratColumn?.stratColumnUuid,
    stratColumnIdentifier: fieldValues?.stratColumn?.stratColumnIdentifier,
    stratUnitUuid: fieldValues?.stratUnit?.stratUnitUuid,
    stratUnitIdentifier: fieldValues?.stratUnit?.stratUnitIdentifier,
    datumDepth: fieldValues.datumDepth,
    pressureDatum: fieldValues.datumPressure,
    pressureInitial: fieldValues.initialPressure,
    gradientOil: fieldValues.oilGradient,
    gradientGas: fieldValues.gasGradient,
    gradientWater: fieldValues.waterGradient,
    oilWaterContactDepth: fieldValues.oilWaterContactDepth,
    oilGasContactDepth: fieldValues.gasOilContactDepth,
    gasWaterContactDepth: fieldValues.gasWaterContactDepth,
    gasSolution: fieldValues.gasSolution,
    gasFormationVolFactor: fieldValues.gasFormationVolFactor,
    oilFormationVolFactor: fieldValues.oilFormationVolFactor,
    waterFormationVolFactor: fieldValues.waterFormationVolFactor,
  };

  return useMutation({
    mutationKey: [UPDATE_FIELD_VALUES_KEY],
    mutationFn: async (fieldId: number) => {
      const response = await FieldValuesService.putApiV1FieldValues(
        fieldId,
        body
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FIELD_VALUES_BY_FIELD, field?.uuid],
      });
    },
  });
};

export const useUpdateFieldActiveStatus = () => {
  const { field } = useApp();
  const queryClient = useQueryClient();

  const body = {
    fieldUuid: field?.uuid ?? 'N/A',
    fieldIdentifier: field?.name ?? 'N/A',
  };

  return useMutation({
    mutationKey: [UPDATE_FIELD_VALUES_KEY],
    mutationFn: async (field: BackendFieldValues) => {
      const response = await FieldValuesService.putApiV1FieldValues(field.id, {
        ...body,
        ...field,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FIELD_VALUES_BY_FIELD, field?.uuid],
      });
    },
  });
};
