import { flexRender, Row } from '@tanstack/react-table';

import { CustomColumnMeta } from '../BaseTable/BaseTable';
import { TableCell } from '../BaseTable/BaseTable.styles';
import { PrognosisDetailDto } from 'src/api';

import { styled } from 'styled-components';

const TableRow = styled.tr`
  height: 45px;
`;

export const PinnedRow = ({ row }: { row: Row<PrognosisDetailDto> }) => {
  const isSelected = row.getIsSelected();
  return (
    <TableRow key={row.id}>
      {row.getVisibleCells().map((cell) => {
        return (
          <TableCell
            key={cell.id}
            $isLastRow={true}
            $isSelected={isSelected}
            {...(cell.column.columnDef.meta as CustomColumnMeta)?.cellProps}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
