import { useState } from 'react';

import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { FilterType } from 'src/api';
import { SHOW_ADVANCED_FILTERS } from 'src/constants';
import { LoadingBlur } from 'src/pages/EditMeasuredData/components/FilterPanel/components/LoadingBlur';
import { EquipmentMapping } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/EquipmentMapping/EquipmentMapping';
import { FilterItem } from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItem/FilterItem';
import {
  FilterItemsWrapper,
  ListTitle,
} from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/FilterItems.styles';
import {
  GlobalFilterTab,
  GlobalFilterTabs,
} from 'src/pages/EditMeasuredData/components/FilterPanel/FilterItems/GlobalFilterTabs';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useGetFiltersGlobal } from 'src/pages/EditMeasuredData/hooks/useGetFiltersGlobal';
import { useIsFiltersLoading } from 'src/pages/EditMeasuredData/hooks/useIsFiltersLoading';
import { sortMeasuredDataFilters } from 'src/utils';

export const FilterItems = () => {
  const { selectedFilters } = useEditMeasuredData();
  const [activeGlobalTab, setActiveGlobalTab] = useState(
    GlobalFilterTab.THIS_WELL
  );

  const { data: filtersForWell = [] } = useGetFiltersForWell();
  const { data: globalFilters = [] } = useGetFiltersGlobal();
  const { showContent } = useFeatureToggling(SHOW_ADVANCED_FILTERS);
  const { filtersLoading } = useIsFiltersLoading();
  return (
    <FilterItemsWrapper>
      <GlobalFilterTabs
        activeTab={activeGlobalTab}
        setActiveTab={setActiveGlobalTab}
      />
      {filtersLoading && <LoadingBlur />}
      {activeGlobalTab === GlobalFilterTab.THIS_WELL ? (
        <>
          {showContent && (
            <>
              <ListTitle group="input" variant="label">
                Advanced filters
              </ListTitle>
              {filtersForWell
                .filter((filter) => filter.type === FilterType.RULE)
                .sort(sortMeasuredDataFilters)
                .map((filter) => (
                  <FilterItem
                    key={filter.id}
                    filter={filter}
                    selected={selectedFilters.some((c) => c.id === filter.id)}
                  />
                ))}
            </>
          )}
          <ListTitle group="input" variant="label">
            Manual edits
          </ListTitle>
          {filtersForWell
            .filter(
              (filter) =>
                filter.type === FilterType.MANUAL ||
                filter.type === FilterType.DRAG
            )
            .sort(sortMeasuredDataFilters)
            .map((filter) => (
              <FilterItem
                key={`${filter.id}-${filter.changes?.at(0)?.date}`}
                filter={filter}
                selected={selectedFilters.some((c) => c.id === filter.id)}
              />
            ))}
          <EquipmentMapping />
        </>
      ) : (
        globalFilters.sort(sortMeasuredDataFilters).map((filter) => {
          return (
            <FilterItem
              key={filter.id}
              filter={filter}
              selected={selectedFilters.some((c) => c.id === filter.id)}
            />
          );
        })
      )}
    </FilterItemsWrapper>
  );
};
