import {
  Checkbox,
  colors,
  shape,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const TableContainer = styled.table`
  border-spacing: 0;
  overflow-y: hidden;
  width: 100%;
`;

export const ScrollableDiv = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid ${colors.ui.background__medium.rgba};
  border-radius: ${shape.corners.borderRadius} ${shape.corners.borderRadius} 0 0;
  position: relative;
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 100%;
  overflow: hidden;
`;

interface TableCellProps {
  $isLastRow: boolean;
  $isSelected?: boolean;
}

export const TableCell = styled.td<TableCellProps>`
  border-bottom: ${({ $isLastRow, $isSelected }) =>
    $isLastRow
      ? 'none'
      : $isSelected
        ? `1px solid ${colors.interactive.primary__pressed.rgba}`
        : `1px solid ${colors.ui.background__medium.rgba}`};
  background-color: ${({ $isSelected }) =>
    $isSelected
      ? colors.interactive.primary__selected_hover.rgba
      : colors.ui.background__default.rgba};

  &:last-child {
    border-right: none !important;
  }
  padding: 0;

  &:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 2;
  }
  &:nth-child(2) {
    position: sticky;
    left: 161px;
    z-index: 2;
  }
  &:nth-child(3) {
    position: sticky;
    left: calc(161px + 50px);
    z-index: 2;
  }
`;

export const TableRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;

export const TableHeader = styled.th`
  &:last-child {
    border-right: none !important;
  }
  background-color: ${colors.ui.background__default.rgba};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
`;

export const StyledCheckbox = styled(Checkbox)`
  height: 24px;
  > span {
    padding: 0px !important;
    padding-right: ${spacings.small} !important;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;
