import { FC } from 'react';

import { Template } from '@equinor/amplify-component-lib';

import { MapSettings } from '../MapSettings/MapSettings';
import { MapInner } from './MapInner';
import { ActiveMapTool } from 'src/pages/MapView/components/ActiveMapTool/ActiveMapTool';
import { Footer } from 'src/pages/MapView/components/Footer/Footer';
import { LoadingScrim } from 'src/pages/MapView/components/LoadingScrim/LoadingScrim';

import styled from 'styled-components';

const MapOuter = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; // TODO: Look into solving the unwanted vertical scrollbar some other way.
`;

export const Map: FC = () => {
  return (
    <MapOuter
      className={`${Template.FullWidth} ${Template.WithoutScrollGutter}`}
    >
      <MapInner />
      <LoadingScrim />
      <MapSettings />
      <ActiveMapTool />
      <Footer />
    </MapOuter>
  );
};
