import { FC } from 'react';

import { Button } from '@equinor/amplify-component-lib';

import {
  datumKeys,
  referenceWithCalculationKeys,
} from '../DataInputDialog.utils';
import { Gradients } from '../Gradients/Gradients';
import { Readonly } from '../Readonly/Readonly';
import { TextFields } from '../TextFields/TextFields';
import { FieldValues, PrognosisDetailDto } from 'src/api';
import { vitalPrognosisKeys } from 'src/constants';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';

interface FieldsWithCalculateProps {
  index: number;
  isLoading: boolean;
  formDetail: PrognosisDetailDto | null;
  formPrognosisDetails: PrognosisDetailDto[];
  fieldValues?: FieldValues;
  missingVitalKeys: boolean;
}

export const FieldsWithCalculate: FC<FieldsWithCalculateProps> = ({
  index,
  isLoading,
  formDetail,
  formPrognosisDetails,
  fieldValues,
  missingVitalKeys,
}) => {
  const { getValues, setValue, trigger } = useEditDetailsStepForm();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const datumDepth = fieldValues?.datumDepth;
  const datumBar = fieldValues?.pressureDatum;
  const mTVDMSL = getValues(`prognosisDetails.${index}.referenceDepth`);
  const gradient = getValues(`prognosisDetails.${index}.pressureGradientDepth`);
  const datumLow = getValues(`prognosisDetails.${index}.pressureDatumLow`);
  const datumHigh = getValues(`prognosisDetails.${index}.pressureDatumHigh`);
  const datumExpected = getValues(
    `prognosisDetails.${index}.pressureDatumExpected`
  );

  const calculateReferenceBar = (
    datumBarValue: number,
    key: keyof PrognosisDetailDto
  ) => {
    if (mTVDMSL && datumDepth && gradient && datumBarValue) {
      const referenceBar = parseFloat(
        (datumBarValue - (datumDepth - mTVDMSL) * gradient).toFixed(2)
      );
      setValue(`prognosisDetails.${index}.${key}`, referenceBar);
      trigger(`prognosisDetails.${index}.${key}`);
    }
  };

  const calculateValues = () => {
    if (optionalAttributes?.pressureInitial) {
      calculateReferenceBar(Number(datumBar), 'pressureInitial');
    }

    calculateReferenceBar(Number(datumExpected), 'pressureExpected');
    calculateReferenceBar(Number(datumLow), 'pressureLow');
    calculateReferenceBar(Number(datumHigh), 'pressureHigh');
  };

  const handleCalculate = () => {
    const calculationButtonDisabled: boolean = vitalPrognosisKeys.some(
      (key) => !formPrognosisDetails[index][key]
    );

    if (!calculationButtonDisabled) {
      calculateValues();
      setValue(`prognosisDetails.${index}.datumDepth`, datumDepth);
    } else {
      trigger(`prognosisDetails.${index}`);
    }
  };

  return (
    <>
      <TextFields
        label="Reference"
        index={index}
        keys={referenceWithCalculationKeys}
        isLoading={isLoading}
      />
      <Gradients
        label="Gradients"
        index={index}
        fieldValues={fieldValues!}
        missingVitalKeys={missingVitalKeys}
        pressureGradientDepthUnit={formDetail?.pressureGradientDepthUnit ?? ''}
        isLoading={isLoading}
      />
      <TextFields
        label="Datum"
        index={index}
        keys={datumKeys}
        isLoading={isLoading}
        hideDatumDepth
      />
      <div>
        <Button onClick={handleCalculate}>Calculate reference fields</Button>
      </div>
      <Readonly detailRow={formDetail} label="Calculated values" />
    </>
  );
};
