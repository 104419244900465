import { useContext } from 'react';

import { ViewPrognosisContext } from '../provider/ViewPrognosisProvider';

export function useViewPrognosis() {
  const context = useContext(ViewPrognosisContext);

  if (context === undefined) {
    throw new Error(
      'useViewPrognosis must be used within a ViewPrognosisProvider'
    );
  }

  return context;
}
