import { colors, shape, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacings.medium};
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 680px;
  padding: ${spacings.large};
  background-color: ${colors.ui.background__default.rgba};
  border: 1px solid ${colors.ui.background__heavy.rgba};
  border-radius: ${shape.corners.borderRadius};
  gap: ${spacings.large};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  align-self: center;
  padding: ${spacings.large};
  justify-content: center;
  gap: ${spacings.large};
`;
