/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Area } from '../models/Area';
import type { PolygonFeatureCollection } from '../models/PolygonFeatureCollection';
import type { WellboreLines } from '../models/WellboreLines';
import type { WellBoreMaster } from '../models/WellBoreMaster';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FieldService {
  /**
   * Get outlines for a field
   * @param fieldUuid
   * @returns PolygonFeatureCollection Success
   * @throws ApiError
   */
  public static getFieldOutlines(
    fieldUuid: string
  ): CancelablePromise<PolygonFeatureCollection> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/Outlines',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get fault lines for a field
   * @param fieldUuid
   * @returns PolygonFeatureCollection Success
   * @throws ApiError
   */
  public static getFieldFaultLines(
    fieldUuid: string
  ): CancelablePromise<PolygonFeatureCollection> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/FaultLines',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * GetArea outlines for a field
   * @param fieldUuid
   * @returns Area Success
   * @throws ApiError
   */
  public static getAreas(fieldUuid: string): CancelablePromise<Array<Area>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/areas',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get Wells in area
   * @param fieldUuId
   * @param area
   * @returns WellBoreMaster Success
   * @throws ApiError
   */
  public static getWellsInArea(
    fieldUuId: string,
    area: string
  ): CancelablePromise<Array<WellBoreMaster>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuId}/areas/{area}/wells',
      path: {
        fieldUuId: fieldUuId,
        area: area,
      },
    });
  }
  /**
   * wellpositions for a field
   * @param fieldUuid
   * @returns WellboreLines Success
   * @throws ApiError
   */
  public static getWellpositions(
    fieldUuid: string
  ): CancelablePromise<Array<WellboreLines>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Field/{fieldUuid}/wellpositions',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
