import { ChangeEvent, FC, useMemo, useState } from 'react';

import {
  Button,
  Chip,
  colors,
  DateRangePicker,
  Filter,
  Icon,
  OptionalTooltip,
  SelectOptionRequired,
  SingleSelect,
  Typography,
  useAuth,
} from '@equinor/amplify-component-lib';
import {
  checkbox,
  checkbox_outline,
  notifications,
  person,
} from '@equinor/eds-icons';

import { usePrognosisDashboard } from '../../hooks/useDashboard';
import {
  checkKeyAgainstFilterKeys,
  filterValueKeyToLabel,
  readableValue,
} from '../../PrognosisDashboard.utils';
import { FilterValue } from '../../providers/PrognosisDashboardProvider';
import {
  ChipContainer,
  ChipWrapper,
  Container,
  FlexWrapper,
  LabelWrapper,
  SingleSelectWrapper,
} from './PrognosisFilter.styles';
import { LookupValues } from 'src/api';
import { getUsernameFromEmail, stringToSelectOptionRequired } from 'src/utils';
import { lookupValuesToComboBoxRequired } from 'src/utils/lookup';

import { useLookupValues } from 'hooks/useLookupValues';

const STATUS_KEY = 'status';
const SEARCH_KEY = 'search';

const SELECT_FILTERS: (keyof FilterValue)[] = [
  'approver',
  'operation',
  'wellbore',
  'reservoirZone',
];

export type PrognosisFilterKeys = Pick<
  FilterValue,
  | 'status'
  | 'search'
  | 'wellbore'
  | 'reservoirZone'
  | 'approver'
  | 'operation'
  | 'lastModifiedFrom'
  | 'lastModifiedTo'
  | 'createdBy'
  | 'isSubscribed'
>;

export const PrognosisFilter: FC = () => {
  const { data } = useLookupValues();
  const { account } = useAuth();
  const { filterValue, updateFilterValue, clearFilterValue } =
    usePrognosisDashboard();
  const currentUser = getUsernameFromEmail(account?.username ?? '');

  const [search, setSearch] = useState('');

  const values = useMemo(() => {
    const item: Record<keyof PrognosisFilterKeys, SelectOptionRequired[]> = {
      status: stringToSelectOptionRequired(filterValue.status),
      search: stringToSelectOptionRequired(filterValue.search),
      wellbore: stringToSelectOptionRequired(filterValue.wellbore),
      reservoirZone: stringToSelectOptionRequired(filterValue.reservoirZone),
      operation: stringToSelectOptionRequired(filterValue.operation),
      approver: stringToSelectOptionRequired(filterValue.approver, 'approver'),
      lastModifiedFrom: stringToSelectOptionRequired(
        filterValue.lastModifiedFrom,
        'lastModifiedFrom'
      ),
      lastModifiedTo: stringToSelectOptionRequired(
        filterValue.lastModifiedTo,
        'lastModifiedTo'
      ),
      createdBy: stringToSelectOptionRequired(
        filterValue.createdBy,
        'createdBy'
      ),
      isSubscribed: stringToSelectOptionRequired(
        filterValue.isSubscribed,
        'isSubscribed'
      ),
    };
    return item;
  }, [filterValue]);

  const filterOptions: Record<string, SelectOptionRequired[]> = useMemo(() => {
    return {
      approver: lookupValuesToComboBoxRequired(data?.approveUsers),
      operation: lookupValuesToComboBoxRequired(data?.operations, true),
      wellbore: lookupValuesToComboBoxRequired(data?.wellbores),
      reservoirZone: lookupValuesToComboBoxRequired(data?.reservoirZones),
    };
  }, [data]);

  const searchableData = useMemo(() => {
    if (!data) return [];
    return [
      ...data.approveUsers,
      ...data.operations,
      ...data.wellbores,
      ...data.reservoirZones,
      ...data.statuses,
    ];
  }, [data]);

  const dataCopy = { ...data };
  delete dataCopy.createUsers;
  delete dataCopy.mainApprovers;
  delete dataCopy.backupApprovers;
  delete dataCopy.operationPhases;

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleOnSearchEnter = (value: string) => {
    const foundItem = searchableData.find((item) => {
      const normalizedItem = item.toLowerCase().replace(/[^a-z0-9]/gi, '');
      const normalizedValue = value.toLowerCase().replace(/[^a-z0-9]/gi, '');
      return normalizedItem.includes(normalizedValue);
    });

    if (foundItem && data) {
      const key = Object.keys(data).find((key) =>
        dataCopy[key as keyof LookupValues]?.includes(foundItem)
      );

      const filterKey = checkKeyAgainstFilterKeys(key as keyof LookupValues);

      if (filterKey) {
        updateFilterValue({ [filterKey]: foundItem });
      } else {
        updateFilterValue({ [SEARCH_KEY]: value });
      }
    } else {
      updateFilterValue({ [SEARCH_KEY]: value });
    }
  };

  return (
    <Container>
      <Filter
        placeholder="Search by keyword, user, wellbore..."
        values={values}
        onClearAllFilters={() => {
          clearFilterValue();
        }}
        onClearFilter={(e) => {
          updateFilterValue({ [e]: null });
        }}
        search={search}
        onSearchChange={handleOnSearchChange}
        onSearchEnter={handleOnSearchEnter}
      >
        <ChipContainer>
          <Typography
            group="input"
            variant="label"
            color={colors.text.static_icons__tertiary.hex}
          >
            Filter by status
          </Typography>
          <ChipWrapper>
            {data?.statuses.map((status) => (
              <Chip
                key={status}
                selected={filterValue.status === status}
                onClick={() => {
                  updateFilterValue({
                    [STATUS_KEY]: filterValue.status === status ? null : status,
                  });
                }}
              >
                {readableValue(status)}
              </Chip>
            ))}
          </ChipWrapper>
        </ChipContainer>
        <SingleSelectWrapper>
          {SELECT_FILTERS.map((filterValueKey) => (
            <SingleSelect
              key={`filter-option-${filterValueKey}`}
              clearable
              label={filterValueKeyToLabel(filterValueKey)}
              onSelect={(value) => {
                if (value) {
                  updateFilterValue({ [filterValueKey]: value.value });
                } else {
                  updateFilterValue({ [filterValueKey]: null });
                }
              }}
              items={filterOptions[filterValueKey] ?? []}
              value={
                filterValue[filterValueKey]
                  ? {
                      value: filterValue[filterValueKey],
                      label: readableValue(
                        filterValue[filterValueKey],
                        filterValueKey
                      ),
                    }
                  : undefined
              }
            />
          ))}
        </SingleSelectWrapper>
        {/* Last modified */}
        <FlexWrapper>
          <DateRangePicker
            label="Last modified"
            value={{
              from: filterValue.lastModifiedFrom
                ? new Date(filterValue.lastModifiedFrom)
                : null,
              to: filterValue.lastModifiedTo
                ? new Date(filterValue.lastModifiedTo)
                : null,
            }}
            onChange={(e) => {
              updateFilterValue({
                lastModifiedFrom: e?.from ? e.from.toISOString() : null,
                lastModifiedTo: e?.to ? e.to.toISOString() : null,
              });
            }}
          />
          <Button
            color="primary"
            variant="ghost"
            onClick={() => {
              if (!filterValue.createdBy) {
                updateFilterValue({ createdBy: currentUser });
              } else {
                updateFilterValue({ createdBy: null });
              }
            }}
          >
            <LabelWrapper>
              <Icon
                data={filterValue.createdBy ? checkbox : checkbox_outline}
              />
              Created by me
              <Icon data={person} />
            </LabelWrapper>
          </Button>
          <OptionalTooltip
            title="This filter is not available yet"
            placement="bottom"
          >
            <Button
              color="primary"
              variant="ghost"
              onClick={() => {
                if (!filterValue.isSubscribed) {
                  updateFilterValue({ isSubscribed: 'true' });
                } else {
                  updateFilterValue({ isSubscribed: null });
                }
              }}
            >
              <LabelWrapper>
                <Icon
                  data={filterValue.isSubscribed ? checkbox : checkbox_outline}
                />
                My subscriptions only
                <Icon data={notifications} />
              </LabelWrapper>
            </Button>
          </OptionalTooltip>
        </FlexWrapper>
      </Filter>
    </Container>
  );
};
