import { PrognosisDetailDto } from 'src/api';

export const datumColumnGroup: (keyof PrognosisDetailDto)[] = [
  'datumDepth',
  'pressureDatumExpected',
  'pressureDatumLow',
  'pressureDatumHigh',
];

export const gradientColumnGroup: (keyof PrognosisDetailDto)[] = [
  'fluidType',
  'pressureGradientDepth',
];

export const minColumnGroup: (keyof PrognosisDetailDto)[] = ['pressureMin'];

export const maxColumnGroup: (keyof PrognosisDetailDto)[] = ['pressureMax'];

export const initialColumnGroup: (keyof PrognosisDetailDto)[] = [
  'pressureInitial',
];
