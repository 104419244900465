import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  SelectOption,
  SelectOptionRequired,
  SingleSelect,
  spacings,
  TextField,
  Typography,
} from '@equinor/amplify-component-lib';

import { OverlappingNotification } from '../../../OverlappingNotification/OverlappingNotification';
import { usePutValidityForm } from 'src/pages/EditPrognosis/hooks/api/usePutValidityForm';
import { useCommentStepForm } from 'src/pages/EditPrognosis/hooks/form/useCommentStepForm';
import { CommentsStepFormType } from 'src/utils';

import { styled } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
  min-width: 300px;
`;

interface ValidityFormProps {
  isInitializing: boolean;
  mainApproverOptions: SelectOptionRequired[];
  backupApproverOptions: SelectOptionRequired[];
}

export const ValidityForm: FC<ValidityFormProps> = ({
  isInitializing,
  mainApproverOptions,
  backupApproverOptions,
}) => {
  const {
    control,
    trigger,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useCommentStepForm();
  const { mutate: putPrognosis } = usePutValidityForm();

  const [backupApprovers, setBackupApprovers] = useState<
    SelectOptionRequired[]
  >(backupApproverOptions);

  const mainApprover = getValues('mainApprover');

  const handleUpdateApprover = async (
    item: SelectOption<SelectOptionRequired> | undefined,
    key: keyof Pick<CommentsStepFormType, 'mainApprover' | 'backupApprover'>
  ) => {
    if (!item) return;

    const newValue = item.value;
    const previousValue = getValues(key);

    const isFieldDirty = newValue !== previousValue;

    if (isFieldDirty) {
      if (key === 'mainApprover') {
        const filteredBackupApprovers = backupApproverOptions.filter(
          (approver) => approver.value !== newValue
        );
        const selectedBackupApprover = getValues('backupApprover');

        if (
          selectedBackupApprover &&
          !filteredBackupApprovers.find(
            (approver) => approver.value === selectedBackupApprover
          )
        ) {
          setValue('backupApprover', '');
          await trigger('backupApprover');
        }
        if (filteredBackupApprovers.length !== backupApproverOptions.length) {
          setBackupApprovers(filteredBackupApprovers);
        }
      }

      setValue(key, newValue ?? null);
      const isValid = await trigger(key);

      if (isValid) {
        putPrognosis();
      }
    }
  };

  const handleUpdateValidDate = async (
    event: React.FocusEvent<HTMLInputElement>,
    key: keyof Pick<CommentsStepFormType, 'validFrom' | 'validTo'>
  ) => {
    if (!event.target) return;

    const newValue = event.target.value;
    setValue(key, newValue ?? null);

    // Trigger validation for the other date field if it is already filled out
    if (key === 'validFrom' && getValues('validTo') !== '') {
      trigger('validTo');
    }

    const validToValidity = await trigger('validTo');
    const validFromValidity = await trigger('validFrom');

    if (validToValidity && validFromValidity) {
      putPrognosis();
    }
  };

  return (
    <Wrapper>
      <Container>
        <Typography variant="h4">Select range of validity</Typography>
        <TextField
          id="validFrom"
          label="Valid from"
          type="date"
          disabled={isInitializing}
          {...register('validFrom', {
            onChange: (event: React.FocusEvent<HTMLInputElement>) => {
              trigger('validFrom');
              handleUpdateValidDate(event, 'validFrom');
            },
          })}
          meta="Required"
          variant={errors.validFrom ? 'error' : undefined}
          helperText={errors.validFrom?.message}
          data-testid="valid-from-input"
        />
        <TextField
          id="validTo"
          label="Valid to"
          type="date"
          disabled={isInitializing}
          {...register('validTo', {
            onChange: (event: React.FocusEvent<HTMLInputElement>) => {
              trigger('validTo');
              handleUpdateValidDate(event, 'validTo');
            },
          })}
          meta="Required"
          variant={errors.validTo ? 'error' : undefined}
          helperText={errors.validTo?.message}
          data-testid="valid-to-input"
        />
        <Typography style={{ marginTop: `${spacings.medium}` }} variant="h4">
          Select approvers
        </Typography>
        <Controller
          name="mainApprover"
          control={control}
          render={({ field }) => (
            <SingleSelect
              label="Approver"
              items={mainApproverOptions}
              placeholder="Select one..."
              value={mainApproverOptions.find(
                (option) => option.value === field.value
              )}
              onSelect={(item) => {
                handleUpdateApprover(item, 'mainApprover');
              }}
              loading={isInitializing}
              meta="Required"
              variant={errors.mainApprover ? 'error' : undefined}
              helperText={errors.mainApprover?.message}
              data-testid="mainApprover-input"
              clearable={false}
            />
          )}
        />
        <Controller
          name="backupApprover"
          control={control}
          render={({ field }) => (
            <SingleSelect
              label="Backup approver"
              items={backupApprovers}
              placeholder="Select one..."
              value={backupApprovers.find(
                (option) => option.value === field.value
              )}
              onSelect={(item) => {
                handleUpdateApprover(item, 'backupApprover');
              }}
              loading={isInitializing}
              meta="Required"
              variant={errors.backupApprover ? 'error' : undefined}
              helperText={errors.backupApprover?.message}
              data-testid="backupApprover-input"
              clearable={false}
              disabled={mainApprover === ''}
            />
          )}
        />
        <OverlappingNotification />
      </Container>
    </Wrapper>
  );
};
