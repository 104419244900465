/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetPrognosesResponse } from '../models/GetPrognosesResponse';
import type { PrognosisCreateDto } from '../models/PrognosisCreateDto';
import type { PrognosisDto } from '../models/PrognosisDto';
import type { PrognosisPatchDto } from '../models/PrognosisPatchDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PrognosisService {
  /**
   * Get prognoses
   * @param wellboreIdentifier
   * @param ciTargetName
   * @param fieldIdentifier
   * @param status
   * @param operation
   * @param operationPhase
   * @param stratColumnIdentifier
   * @param reservoirZone
   * @param search
   * @param comment
   * @param createUser
   * @param mainApprover
   * @param backupApprover
   * @param approveUser
   * @param isSubscribed
   * @param updateDateFrom
   * @param updateDateTo
   * @param validDateFrom
   * @param validDateTo
   * @param sortColumn
   * @param sortDirection
   * @param page
   * @param pageSize
   * @returns GetPrognosesResponse Success
   * @throws ApiError
   */
  public static getApiV1Prognoses(
    wellboreIdentifier?: string,
    ciTargetName?: string,
    fieldIdentifier?: string,
    status?: string,
    operation?: string,
    operationPhase?: string,
    stratColumnIdentifier?: string,
    reservoirZone?: string,
    search?: string,
    comment?: string,
    createUser?: string,
    mainApprover?: string,
    backupApprover?: string,
    approveUser?: string,
    isSubscribed?: boolean,
    updateDateFrom?: string,
    updateDateTo?: string,
    validDateFrom?: string,
    validDateTo?: string,
    sortColumn?: string,
    sortDirection?: string,
    page?: number,
    pageSize?: number
  ): CancelablePromise<GetPrognosesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses',
      query: {
        WellboreIdentifier: wellboreIdentifier,
        CiTargetName: ciTargetName,
        FieldIdentifier: fieldIdentifier,
        Status: status,
        Operation: operation,
        OperationPhase: operationPhase,
        StratColumnIdentifier: stratColumnIdentifier,
        ReservoirZone: reservoirZone,
        Search: search,
        Comment: comment,
        CreateUser: createUser,
        MainApprover: mainApprover,
        BackupApprover: backupApprover,
        ApproveUser: approveUser,
        IsSubscribed: isSubscribed,
        UpdateDateFrom: updateDateFrom,
        UpdateDateTo: updateDateTo,
        ValidDateFrom: validDateFrom,
        ValidDateTo: validDateTo,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Page: page,
        PageSize: pageSize,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Create a new prognosis
   * @param requestBody
   * @returns number Created
   * @throws ApiError
   */
  public static postApiV1Prognoses(
    requestBody?: PrognosisCreateDto
  ): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Prognoses',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get prognosis by id
   * @param id
   * @returns PrognosisDto Success
   * @throws ApiError
   */
  public static getApiV1Prognoses1(
    id: number
  ): CancelablePromise<PrognosisDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Patch draft prognosis
   * @param id
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static patchApiV1Prognoses(
    id: number,
    requestBody?: PrognosisPatchDto
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Prognoses/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Delete prognosis
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static deleteApiV1Prognoses(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/Prognoses/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Submit prognosis
   * @param id
   * @param emailComment
   * @returns void
   * @throws ApiError
   */
  public static patchApiV1PrognosesSubmit(
    id: number,
    emailComment?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Prognoses/{id}/submit',
      path: {
        id: id,
      },
      query: {
        emailComment: emailComment,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Return prognosis to draft
   * @param id
   * @param emailComment
   * @returns void
   * @throws ApiError
   */
  public static patchApiV1PrognosesReturnToDraft(
    id: number,
    emailComment?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Prognoses/{id}/returnToDraft',
      path: {
        id: id,
      },
      query: {
        emailComment: emailComment,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Reject prognosis
   * @param id
   * @param emailComment
   * @returns void
   * @throws ApiError
   */
  public static patchApiV1PrognosesReject(
    id: number,
    emailComment?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Prognoses/{id}/reject',
      path: {
        id: id,
      },
      query: {
        emailComment: emailComment,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Approve prognosis
   * @param id
   * @param emailComment
   * @returns void
   * @throws ApiError
   */
  public static patchApiV1PrognosesApprove(
    id: number,
    emailComment?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Prognoses/{id}/approve',
      path: {
        id: id,
      },
      query: {
        emailComment: emailComment,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Expire prognosis
   * @param id
   * @param emailComment
   * @returns void
   * @throws ApiError
   */
  public static patchApiV1PrognosesExpire(
    id: number,
    emailComment?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Prognoses/{id}/expire',
      path: {
        id: id,
      },
      query: {
        emailComment: emailComment,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
