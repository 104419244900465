import { FC } from 'react';

import {
  Button,
  colors,
  Icon,
  OptionalTooltip,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { copy, delete_forever, info_circle } from '@equinor/eds-icons';
import { useIsMutating } from '@tanstack/react-query';

import { OptionalAttributesDto } from 'src/api';
import { useDeleteBulkDetails } from 'src/pages/EditPrognosis/hooks/api/useDeleteBulkDetails';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { usePostBulkDetails } from 'src/pages/EditPrognosis/hooks/api/usePostBulkDetails';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';
import { createNewDetails } from 'src/pages/EditPrognosis/utils/formUtils';

import { motion } from 'framer-motion';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.hr`
  background-color: ${colors.ui.background__medium.rgba};
  width: 1px;
  align-self: stretch;
  flex-shrink: 0;
  margin-left: ${spacings.small};
`;

const AnimateEditButtons = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

interface EditGroupProps {
  selectedRows: Record<string, boolean>;
  resetRowSelection: (defaultState?: boolean) => void;
}

export const EditGroup: FC<EditGroupProps> = ({
  selectedRows,
  resetRowSelection,
}) => {
  const isMutating = useIsMutating();
  const { resetField } = useEditDetailsStepForm();
  const { data: prognosisDetails } = useGetPrognosisDetails();
  const { mutate: deleteDetails } = useDeleteBulkDetails();
  const { mutate: postDetails } = usePostBulkDetails({
    resetField,
  });
  const usingId = usePrognosisIDFromParams();
  const { data: optionalAttributes } = useGetOptionalAttributes();
  const showButtons = Object.values(selectedRows).some((value) => value);
  const ids: number[] = Object.keys(selectedRows).map(Number);

  const coMingled = optionalAttributes?.commingledZones ?? false;

  const handleOnRemove = () => {
    if (selectedRows && !coMingled) {
      deleteDetails(ids);
      resetRowSelection();
    } else if (prognosisDetails && coMingled) {
      const detailIds = prognosisDetails.map((detail) => detail.id);
      deleteDetails(detailIds);
      resetRowSelection();
    }
  };

  const handleCopy = () => {
    if (isMutating) return;
    if (selectedRows) {
      const details = prognosisDetails?.filter((detail) =>
        ids.includes(detail.id)
      );

      if (details) {
        const newDetails = createNewDetails({
          details,
          newPrognosisId: usingId,
          optionalAttributes:
            optionalAttributes ?? ({} as OptionalAttributesDto),
        });
        postDetails({ details: newDetails });
        resetRowSelection();
      }
    }
  };

  return (
    <Container>
      <OptionalTooltip title="Select rows to perform actions." placement="left">
        <Icon data={info_circle} />
      </OptionalTooltip>
      <AnimateEditButtons
        animate={{
          scale: showButtons ? 1 : 0,
          opacity: showButtons ? 1 : 0,
          width: showButtons ? 'auto' : 0,
          transition: {
            duration: 0.25,
          },
        }}
      >
        <Separator />
        <Typography variant="caption">Selection</Typography>
        <Button variant="ghost" onClick={handleOnRemove}>
          <Icon data={delete_forever} />
          Delete
        </Button>
        <Button
          variant="ghost"
          onClick={handleCopy}
          disabled={optionalAttributes?.commingledZones}
        >
          <Icon data={copy} />
          Duplicate
        </Button>
      </AnimateEditButtons>
    </Container>
  );
};
