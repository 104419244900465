import { FC } from 'react';

import {
  Button,
  colors,
  Icon,
  shape,
  SkeletonBase,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { add } from '@equinor/eds-icons';

import { MappedStratigraphicUnit } from '../ZoneNavigator/ZoneNavigator';
import { AddButtonWrapper, WellboreText } from '../ZoneSelector.styles';
import { StratigraphicUnit } from 'src/api';
import { useGetLastUsedZones, useGetPrognosis } from 'src/hooks';
import { useZoneSelectionStepForm } from 'src/pages/EditPrognosis/hooks/form/useZoneSelectionStepForm';
import { useZoneSelector } from 'src/pages/EditPrognosis/hooks/util/useZoneSelector';

import styled from 'styled-components';

const ButtonSkeleton = styled(SkeletonBase)`
  height: 36px;
  width: 150px;
  border-radius: ${shape.corners.borderRadius};
  margin-bottom: ${spacings.medium};
`;

const StyledText = styled(Typography)`
  align-self: center;
  margin-bottom: ${spacings.medium};
`;

const ButtonStyle = styled(Button)`
  white-space: nowrap;
`;
interface LastUsedZonesProps {
  isInitializing: boolean;
  savedSelectedItems: MappedStratigraphicUnit[];
}
export const LastUsedZones: FC<LastUsedZonesProps> = ({
  isInitializing,
  savedSelectedItems,
}) => {
  const { getValues } = useZoneSelectionStepForm();
  const { data: prognosis } = useGetPrognosis();
  const { selectedStratUnits, setSelectedStratUnits } = useZoneSelector();
  const { stratColumnIdentifier } = getValues();
  const { data: lastUsedZones, isFetching: isFetchingLastUsed } =
    useGetLastUsedZones({
      wellboreIdentifier: prognosis?.wellboreIdentifier ?? '',
      stratColumnIdentifier: stratColumnIdentifier ?? '',
      operationId: prognosis?.operationId ?? undefined,
      targetUuid: (prognosis?.ciTargetUuid as string) ?? '',
    });

  const filteredLastUsedZones = lastUsedZones?.filter(
    (zone) => zone.stratColumnIdentifier !== 'Total depth'
  );

  const handleButtonClick = (zone: StratigraphicUnit) => {
    const newUnit = {
      lithostratSubzone: zone.stratUnitIdentifier ?? '',
      stratColumnIdentifier: zone.stratColumnIdentifier ?? '',
      topAge: zone.topAge ?? undefined,
      colorHtml: zone.colorHtml ?? undefined,
    };

    if (
      selectedStratUnits.length === 0 ||
      !selectedStratUnits.some(
        (unit) => unit.stratUnitIdentifier === newUnit.lithostratSubzone
      )
    ) {
      setSelectedStratUnits([
        ...selectedStratUnits,
        {
          ...newUnit,
          id: newUnit.lithostratSubzone,
          label: newUnit.lithostratSubzone,
          value: newUnit.lithostratSubzone,
          stratigraphicUnits: [],
        },
      ]);
    }
  };

  const checkIfDisabled = (zone: StratigraphicUnit) => {
    return (
      savedSelectedItems?.some(
        (unit) => unit.value === zone.stratUnitIdentifier
      ) ||
      selectedStratUnits.some((unit) => unit.id === zone.stratUnitIdentifier)
    );
  };

  return (
    <>
      <StyledText
        group="navigation"
        variant="button"
        color={colors.text.static_icons__tertiary.rgba}
      >
        Suggested
      </StyledText>
      {isInitializing || isFetchingLastUsed ? (
        <ButtonSkeleton />
      ) : (
        <>
          {filteredLastUsedZones?.length ? (
            <AddButtonWrapper>
              {filteredLastUsedZones?.map(
                (zone: StratigraphicUnit, index: number) => {
                  if (!zone.stratUnitIdentifier) return null;

                  return (
                    <ButtonStyle
                      key={index}
                      onClick={() => handleButtonClick(zone)}
                      data-testid={`add-last-used-zone-${index}`}
                      variant="outlined"
                      disabled={checkIfDisabled(zone)}
                    >
                      <Icon data={add} />
                      {zone.stratUnitIdentifier}
                    </ButtonStyle>
                  );
                }
              )}
            </AddButtonWrapper>
          ) : (
            <Typography>
              No previously used zones found for{' '}
              <WellboreText>
                {prognosis?.wellboreIdentifier ?? prognosis?.ciTargetName}
              </WellboreText>
            </Typography>
          )}
        </>
      )}
    </>
  );
};
