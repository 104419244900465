import {
  colors,
  elevation,
  shape,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import {
  SLIDER_HALF_SELECTED_THUMB_WIDTH,
  SLIDER_TOOLTIP_WIDTH,
} from 'src/pages/MapView/components/Footer/NewSlider/Slider.constants';

import { css, styled } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0
    calc(${spacings.medium_small} + ${SLIDER_HALF_SELECTED_THUMB_WIDTH}px - 6px);
`;

export const SliderBackground = styled.div`
  border-radius: ${shape.rounded.borderRadius};
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 ${spacings.x_small};
  background-color: ${colors.ui.background__light.rgba};
  border: 1px solid ${colors.ui.background__medium.rgba};
  cursor: pointer;
`;

export const FromDateLabel = styled(Typography)`
  justify-self: start;
  align-self: center;
  pointer-events: none;
  background: ${colors.ui.background__light.rgba};
  padding: 0 ${spacings.medium_small};
  z-index: 100;
`;

export const SelectedDateForRangeLabel = styled(Typography)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  background: ${colors.ui.background__light.rgba};
  padding: 0 ${spacings.medium_small};
  z-index: 100;
`;

export const ToDateLabel = styled(Typography)`
  position: absolute;
  right: ${spacings.x_small};
  pointer-events: none;
  background: ${colors.ui.background__light.rgba};
  padding: 0 ${spacings.medium_small};
  z-index: 100;
`;

export const SliderTooltip = styled.div`
  position: absolute;
  box-shadow: ${elevation.raised};
  background-color: ${colors.text.static_icons__default.rgba};
  padding: ${spacings.xx_small};
  display: flex;
  justify-content: center;
  width: ${`${SLIDER_TOOLTIP_WIDTH}px`};
  border-radius: 4px;
  top: -24px;
  transform: translateX(-50%);
  > p {
    color: ${colors.text.static_icons__primary_white.rgba};
  }
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${colors.text.static_icons__default.rgba} transparent
      transparent transparent;
  }
`;

const getThumbStyling = (hideThumb: boolean) => css`
  -webkit-appearance: none;
  border: none;
  height: 34px;
  width: 2px;
  border-radius: 0;
  background: ${hideThumb ? 'transparent' : colors.ui.background__scrim.rgba};
  cursor: pointer;
  pointer-events: none;
`;

interface SliderInputThumbProps {
  $hideThumb: boolean;
}

export const SliderInput = styled.input<SliderInputThumbProps>`
  z-index: 200;
  width: 100%;
  height: 36px;
  font-size: 40px;
  appearance: none;
  background: transparent;
  pointer-events: none;

  /* Special styling for WebKit/Blink */
  &::-webkit-slider-thumb {
    ${({ $hideThumb }) => getThumbStyling($hideThumb)}
  }

  /* All the same stuff for Firefox */
  &::-moz-range-thumb {
    ${({ $hideThumb }) => getThumbStyling($hideThumb)}
  }

  /* All the same stuff for IE */
  &::-ms-thumb {
    ${({ $hideThumb }) => getThumbStyling($hideThumb)}
  }
`;
