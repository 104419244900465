import { FC, useState } from 'react';

import {
  Button,
  CircularProgress,
  Icon,
  useAuth,
} from '@equinor/amplify-component-lib';
import {
  check_circle_outlined,
  close_circle_outlined,
  edit,
  notifications,
  notifications_off,
} from '@equinor/eds-icons';

import { useSubscription } from '../../hooks/useSubscribe';
import {
  getShowApproveAction,
  getShowEditActions,
  getShowRejectAction,
} from '../../utils/showActions';
import { ActionsMenu } from './ActionsMenu/ActionsMenu';
import { ApproveDialog } from './ApproveDialog/ApproveDialog';
import { EditDialog } from './EditDialog/EditDialog';
import { RejectDialog } from './RejectDialog/RejectDialog';
import { ShareMenu } from './ShareMenu/ShareMenu';
import { Container } from './Actions.styles';
import { DeleteDialog } from 'src/components/DeleteDialog/DeleteDialog';
import { useGetPrognosis } from 'src/hooks';
import { getUsernameFromEmail } from 'src/utils';

import { StatusChip } from 'components/BaseChip/StatusChip/StatusChip';

export const Actions: FC = () => {
  const { data } = useGetPrognosis();
  const { handleToggleSubscribe, isPending } = useSubscription();
  const { account } = useAuth();
  const currentUserShortName = getUsernameFromEmail(account?.username);

  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleOnOpenApproveDialog = () => setOpenApproveDialog(true);
  const handleOnCloseApproveDialog = () => setOpenApproveDialog(false);

  const handleOnOpenRejectDialog = () => setOpenRejectDialog(true);
  const handleOnCloseRejectDialog = () => setOpenRejectDialog(false);

  const handleOnOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleOnCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleOnOpenEditDialog = () => setOpenEditDialog(true);
  const handleOnCloseEditDialog = () => setOpenEditDialog(false);

  const showApproveButton = getShowApproveAction({
    mainApprover: data?.mainApprover ?? '',
    backupApprover: data?.backupApprover ?? '',
    statusId: data?.statusId ?? -1,
    userShortName: currentUserShortName,
  });

  const showEditButton = getShowEditActions({
    createUser: data?.createUser ?? '',
    statusId: data?.statusId ?? -1,
    userShortName: currentUserShortName,
  });

  const showRejectButton = getShowRejectAction({
    mainApprover: data?.mainApprover ?? '',
    backupApprover: data?.backupApprover ?? '',
    statusId: data?.statusId ?? -1,
    userShortName: currentUserShortName,
  });

  const isBackupApprover =
    currentUserShortName === data?.backupApprover &&
    currentUserShortName !== data?.mainApprover;

  return (
    <>
      <Container>
        <StatusChip statusId={data?.statusId ?? 0} />
        {showApproveButton && (
          <Button onClick={handleOnOpenApproveDialog} title="Approve">
            <Icon data={check_circle_outlined} /> Approve
          </Button>
        )}
        {showRejectButton && (
          <Button
            onClick={handleOnOpenRejectDialog}
            color="danger"
            title="Return for revision"
          >
            <Icon data={close_circle_outlined} />
            Return for revision
          </Button>
        )}
        {showEditButton && (
          <Button
            variant="outlined"
            onClick={handleOnOpenEditDialog}
            title="Edit"
          >
            <Icon data={edit} /> Edit
          </Button>
        )}
        <ShareMenu statusId={data?.statusId} />
        <Button
          variant={'outlined'}
          color={'primary'}
          onClick={handleToggleSubscribe}
        >
          {isPending ? (
            <CircularProgress size={16} />
          ) : (
            <Icon
              data={data?.isSubscribed ? notifications_off : notifications}
            />
          )}
          {data?.isSubscribed ? 'Unsubscribe' : 'Subscribe'}
        </Button>
        <ActionsMenu handleDelete={handleOnOpenDeleteDialog} />
      </Container>
      <ApproveDialog
        open={openApproveDialog}
        title={data?.title ?? 'N/A'}
        isBackupApprover={isBackupApprover}
        onClose={handleOnCloseApproveDialog}
      />
      <RejectDialog
        open={openRejectDialog}
        title={data?.title ?? 'N/A'}
        isBackupApprover={isBackupApprover}
        onClose={handleOnCloseRejectDialog}
      />
      <DeleteDialog
        open={openDeleteDialog}
        title={data?.title ?? 'N/A'}
        onClose={handleOnCloseDeleteDialog}
      />
      <EditDialog
        open={openEditDialog}
        title={data?.title ?? 'N/A'}
        onClose={handleOnCloseEditDialog}
      />
    </>
  );
};
