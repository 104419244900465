import { formatDate } from '@equinor/amplify-component-lib';

import { PressureDataSimple } from 'src/api';
import { getNameFromMeasurement } from 'src/utils';

export const downloadFile = (
  pressureData: PressureDataSimple[][],
  suggestedName: string
) => {
  const headers =
    [
      ['Date'],
      pressureData.map((items) => getNameFromMeasurement(items[0])),
    ].join(',') + '\n';

  const days = pressureData[0].map((item) => item.prodDay);

  const data = days
    .map((day) =>
      [
        formatDate(day, { format: 'YYYY-MM-DD' }),
        ...pressureData.map(
          (items) =>
            items.find((item) => item.prodDay === day)?.pressureFinal ?? ''
        ),
      ].join(',')
    )
    .join('\n');

  const blob = new Blob([headers, data], {
    type: 'text/csv',
  });

  const a = document.createElement('a');
  a.download = suggestedName;
  a.href = URL.createObjectURL(blob);
  a.addEventListener('click', () => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};