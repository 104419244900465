/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CorrectivePressureDto } from '../models/CorrectivePressureDto';
import type { PressureDataSimple } from '../models/PressureDataSimple';
import type { ProductivityIndex } from '../models/ProductivityIndex';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PressureService {
  /**
   * GetCorrective pressure for a field
   * @param fieldUuid
   * @returns CorrectivePressureDto Success
   * @throws ApiError
   */
  public static getpressureCorrectionByField(
    fieldUuid: string
  ): CancelablePromise<Array<CorrectivePressureDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Pressure/pressurecorrection/{fieldUuid}',
      path: {
        fieldUuid: fieldUuid,
      },
    });
  }
  /**
   * Get pressuredata for a field flat
   * @param fieldUuid
   * @param measureDate
   * @returns PressureDataSimple Success
   * @throws ApiError
   */
  public static getpressureDataByFieldFlat(
    fieldUuid: string,
    measureDate?: string
  ): CancelablePromise<Array<PressureDataSimple>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Pressure/Field/{fieldUuid}/pressureField',
      path: {
        fieldUuid: fieldUuid,
      },
      query: {
        measureDate: measureDate,
      },
    });
  }
  /**
   * Get pressuredata for a field grouped by area
   * @param fieldUuid
   * @param measureDate
   * @param formation
   * @param requestBody
   * @returns PressureDataSimple Success
   * @throws ApiError
   */
  public static getpressureDataByAreaFlat(
    fieldUuid: string,
    measureDate?: string,
    formation?: string,
    requestBody?: Array<string>
  ): CancelablePromise<Array<PressureDataSimple>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Pressure/Field/{fieldUuid}/pressureArea',
      path: {
        fieldUuid: fieldUuid,
      },
      query: {
        measureDate: measureDate,
        formation: formation,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * Get pressuredata for a list of wellbores flat
   * @param fieldUuid
   * @param fromDate
   * @param toDate
   * @param requestBody
   * @returns PressureDataSimple Success
   * @throws ApiError
   */
  public static getpressureDataForWellboresFlat(
    fieldUuid: string,
    fromDate?: string,
    toDate?: string,
    requestBody?: Array<string>
  ): CancelablePromise<Array<PressureDataSimple>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Pressure/Field/{fieldUuid}/pressureWellbores',
      path: {
        fieldUuid: fieldUuid,
      },
      query: {
        fromDate: fromDate,
        toDate: toDate,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * GetCorrective pressure for a wellbore
   * @param fieldUuid
   * @param wellboreUuid
   * @param fromDate
   * @param toDate
   * @returns CorrectivePressureDto Success
   * @throws ApiError
   */
  public static getpressureCorrectionByWellbore(
    fieldUuid: string,
    wellboreUuid: string,
    fromDate?: string,
    toDate?: string
  ): CancelablePromise<Array<CorrectivePressureDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Pressure/pressurecorrection/{fieldUuid}/wellbore/{wellboreUuid}',
      path: {
        fieldUuid: fieldUuid,
        wellboreUuid: wellboreUuid,
      },
      query: {
        fromDate: fromDate,
        toDate: toDate,
      },
    });
  }
  /**
   * Creates corrective pressure
   * @param requestBody
   * @returns CorrectivePressureDto Success
   * @throws ApiError
   */
  public static createCorrectivePressure(
    requestBody?: CorrectivePressureDto
  ): CancelablePromise<Array<CorrectivePressureDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Pressure/pressurecorrection',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * Creates multiple corrective pressures
   * @param requestBody
   * @returns CorrectivePressureDto Success
   * @throws ApiError
   */
  public static batchCreateCorrectivePressure(
    requestBody?: Array<CorrectivePressureDto>
  ): CancelablePromise<Array<CorrectivePressureDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Pressure/pressurecorrectionlist',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * deletes corrective pressure by id
   * @param fieldUuid
   * @param pressureId
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteCorrectivePressure(
    fieldUuid: string,
    pressureId: string
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/Pressure/pressurecorrection/{fieldUuid}/Id/{pressureId}',
      path: {
        fieldUuid: fieldUuid,
        pressureId: pressureId,
      },
    });
  }
  /**
   * Gets interpolated PI
   * @param fieldUuid
   * @param fromDate
   * @param toDate
   * @param requestBody
   * @returns ProductivityIndex Success
   * @throws ApiError
   */
  public static getPiLinePlot(
    fieldUuid: string,
    fromDate?: string,
    toDate?: string,
    requestBody?: Array<string>
  ): CancelablePromise<Array<ProductivityIndex>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Pressure/pressurecorrection/{fieldUuid}/GetPiLinePlot',
      path: {
        fieldUuid: fieldUuid,
      },
      query: {
        fromDate: fromDate,
        toDate: toDate,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
