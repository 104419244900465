import { FC } from 'react';

import { Button, Icon, spacings } from '@equinor/amplify-component-lib';
import { edit } from '@equinor/eds-icons';
import { useQueryClient } from '@tanstack/react-query';

import { FieldValuesService, PrognosisDetailDto } from 'src/api';
import {
  FIELD_VALUES_BY_FIELD,
  FIELD_VALUES_BY_STRAT_UNIT,
} from 'src/constants';

import { useApp } from 'hooks/useApp';
import { styled } from 'styled-components';

const ActionColumnContainer = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  padding: ${spacings.xx_small};
  justify-content: center;
`;

interface ActionsCell {
  detailRow: PrognosisDetailDto;
  handleOnOpenEditModal: (detailRow: PrognosisDetailDto) => void;
}

export const ActionsCell: FC<ActionsCell> = ({
  detailRow,
  handleOnOpenEditModal,
}) => {
  const { field } = useApp();
  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleOnOpenEditModal(detailRow);
  };

  const queryClient = useQueryClient();

  const prefetch = () => {
    if (field) {
      queryClient.prefetchQuery({
        queryKey: [
          FIELD_VALUES_BY_STRAT_UNIT,
          field.uuid,
          detailRow.stratColumnIdentifier,
          detailRow.reservoirZone,
        ],
        queryFn: () =>
          FieldValuesService.getApiV1FieldValuesFieldStratColumnStratUnit(
            field.uuid!,
            detailRow.stratColumnIdentifier,
            detailRow.reservoirZone
          ),
        staleTime: 5 * 60 * 1000,
      });

      queryClient.prefetchQuery({
        queryKey: [FIELD_VALUES_BY_FIELD, field.uuid],
        queryFn: () => FieldValuesService.getApiV1FieldValuesField(field.uuid!),
        staleTime: 5 * 60 * 1000,
      });
    }
  };

  return (
    <ActionColumnContainer>
      <Button
        name="editDetailsButton"
        data-testid="edit-details-button"
        variant="ghost_icon"
        onClick={(event) => handleOnClick(event)}
        onMouseOver={prefetch}
        disabled={detailRow.id < 0}
      >
        <Icon data={edit} />
      </Button>
    </ActionColumnContainer>
  );
};
