import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, colors, Icon, spacings } from '@equinor/amplify-component-lib';
import { arrow_drop_left, timeline } from '@equinor/eds-icons';

import { SelectWellboreComboBox } from 'components/SelectWellboreComboBox/SelectWellboreComboBox';
import { useApp } from 'hooks/useApp';
import { styled } from 'styled-components';

const Container = styled.div`
  background-color: ${colors.ui.background__default.rgba};
  padding: ${spacings.small};
  display: flex;
  gap: ${spacings.small};
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const CloseButton = styled(Button)`
  height: 36px;
`;

interface WellboreSelectorProps {
  onClose: () => void;
}

export const WellboreSelector: FC<WellboreSelectorProps> = ({ onClose }) => {
  const { formattedFieldNameForUrl } = useApp();
  const navigate = useNavigate();

  const handleInspectOnClick = () => {
    navigate(`${formattedFieldNameForUrl}/measured-data/plot`);
  };

  return (
    <Container>
      <CloseButton variant="ghost_icon" onClick={onClose}>
        <Icon data={arrow_drop_left} />
      </CloseButton>
      <Wrapper>
        <SelectWellboreComboBox />
      </Wrapper>
      <Button onClick={handleInspectOnClick}>
        <Icon data={timeline} />
        Inspect
      </Button>
    </Container>
  );
};
