import { FC } from 'react';

import { Template, Typography } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { MeasuredValues } from './MeasuredValues/MeasuredValues';
import { PrognosisValues } from './PrognosisValues/PrognosisValues';
import {
  Container,
  MainContent,
  Spacings,
  Wrapper,
} from './FieldValues.styles';
import {
  FIELD_VALUES_FOR_PROGNOSIS_FEATURE_KEY,
  SHOW_MEASURED_DATA_ALL_FIELDS,
} from 'src/constants';

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useApp } from 'hooks/useApp';

export const FieldValues: FC = () => {
  const { field } = useApp();
  const title = field?.name;
  const { showContent: showPrognosis } = useFeatureToggling(
    FIELD_VALUES_FOR_PROGNOSIS_FEATURE_KEY
  );
  const { showContent: showMeasuredData } = useFeatureToggling(
    SHOW_MEASURED_DATA_ALL_FIELDS
  );

  return (
    <Container>
      <MainContent className={Template.FullWidth}>
        <Breadcrumbs pageTitle="Field values" />
        <Spacings>
          <Typography variant="h1_bold">
            {title} general field values
          </Typography>
        </Spacings>
        <Wrapper>
          {showMeasuredData && <MeasuredValues />}
          {showPrognosis && <PrognosisValues />}
        </Wrapper>
      </MainContent>
    </Container>
  );
};
