import {
  SkeletonBase,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { TextFieldContainer } from '../DataInputDialog.styles';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
`;

const SkeletonStyle = styled(SkeletonBase)`
  height: 76px;
`;

export const Skeleton = ({ label }: { label: string }) => {
  return (
    <Container>
      <Typography variant="h6">{label}</Typography>
      <TextFieldContainer>
        <SkeletonStyle />
        <SkeletonStyle />
        <SkeletonStyle />
      </TextFieldContainer>
    </Container>
  );
};
