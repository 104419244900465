import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGetOptionalAttributes } from './useGetOptionalAttributes';
import { PrognosisDetailDto, PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants';
import { cleanBulkPrognosisDetails } from 'src/utils';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePutBulkDetails = () => {
  const usingId = usePrognosisIDFromParams();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  return useMutation({
    mutationFn: async (details: PrognosisDetailDto[]) => {
      const cleanedDetails = cleanBulkPrognosisDetails(
        details,
        optionalAttributes
      );

      return await PrognosisDetailService.putApiV1PrognosesDetailsList(
        cleanedDetails
      );
    },
    onMutate: async (details: PrognosisDetailDto[]) => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId],
      });

      const previousDetails: PrognosisDetailDto[] =
        queryClient.getQueryData([PROGNOSIS_DETAIL, usingId]) ?? [];

      const cleanedDetails = cleanBulkPrognosisDetails(
        details,
        optionalAttributes
      );

      const indexes = cleanedDetails.map((d) =>
        previousDetails.findIndex((pd) => pd.id === d.id)
      );

      const updatedDetails = [...previousDetails];
      for (const index of indexes) {
        if (index !== -1) {
          updatedDetails[index] = cleanedDetails[index];
        }
      }

      queryClient.setQueryData([PROGNOSIS_DETAIL, usingId], updatedDetails);

      return {
        previousDetails,
        optimisticDetail: cleanedDetails,
        updatedDetails,
      };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, usingId],
        context?.previousDetails ?? []
      );
      showSnackbar('Failed to update detail: ' + err.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId],
      });
    },
  });
};
