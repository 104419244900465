import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Button,
  Icon,
  Menu,
  useAuth,
  useSnackbar,
} from '@equinor/amplify-component-lib';
import { delete_forever, file_copy, more_vertical } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';

import { OptionalAttributesDto } from 'src/api';
import { useCopyOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useCopyOptionalAttributes';
import { useCopyPrognosis } from 'src/pages/EditPrognosis/hooks/api/useCopyPrognosis';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { usePostBulkDetails } from 'src/pages/EditPrognosis/hooks/api/usePostBulkDetails';
import { createNewDetails } from 'src/pages/EditPrognosis/utils/formUtils';
import { getShowEditActions } from 'src/pages/ViewPrognosis/utils/showActions';
import { getUsernameFromEmail } from 'src/utils';

import { useApp } from 'hooks/useApp';
import { useGetPrognosis } from 'hooks/useGetPrognosis';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';

const { colors } = tokens;

interface ActionMenuProps {
  handleDelete: () => void;
}

export const ActionsMenu: FC<ActionMenuProps> = ({ handleDelete }) => {
  const { mutateAsync: copyPrognosis, isPending } = useCopyPrognosis();
  const { mutateAsync: copyPrognosisDetails } = usePostBulkDetails({});
  const { mutateAsync: copyOptionalAttributes } = useCopyOptionalAttributes();
  const { data } = useGetPrognosis();
  const { data: prognosisDetailsData, isLoading } = useGetPrognosisDetails();
  const { data: optionalAttributes } = useGetOptionalAttributes();
  const navigate = useNavigate();
  const { formattedFieldNameForUrl } = useApp();
  const { showSnackbar } = useSnackbar();
  const { account } = useAuth();

  const [open, setOpen] = useState(false);

  const currentUserShortName = getUsernameFromEmail(account?.username);

  const showDeleteAction = getShowEditActions({
    createUser: data?.createUser ?? '',
    statusId: data?.statusId ?? -1,
    userShortName: currentUserShortName,
  });

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleOnClick = () => setOpen((prev) => !prev);
  const handleOnClose = () => setOpen(false);

  const handleCopyPrognosis = async () => {
    const newID = await copyPrognosis();
    const newDetails = createNewDetails({
      details: prognosisDetailsData ?? [],
      newPrognosisId: newID,
      optionalAttributes: optionalAttributes ?? ({} as OptionalAttributesDto),
    });

    // we don’t need to wait for the details to be created
    copyPrognosisDetails({
      details: newDetails ?? [],
    });

    copyOptionalAttributes({
      body: optionalAttributes ?? ({} as OptionalAttributesDto),
      id: newID,
    });

    showSnackbar('Prognosis copied successfully');
    navigate(`${formattedFieldNameForUrl}/prognosis/edit/${newID}/0`);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        variant="outlined"
        onClick={handleOnClick}
        aria-label="Options"
        disabled={isPending || isLoading}
      >
        <Icon data={more_vertical} />
      </Button>
      {open && (
        <Menu
          open
          anchorEl={anchorRef.current}
          placement="bottom-start"
          onClose={handleOnClose}
        >
          <Menu.Section>
            <Menu.Item onClick={handleCopyPrognosis} disabled={isPending}>
              <Icon
                data={file_copy}
                color={colors.interactive.primary__resting.rgba}
              />
              Copy to a new draft
            </Menu.Item>
            {showDeleteAction ? (
              <Menu.Item onClick={handleDelete}>
                <Icon
                  data={delete_forever}
                  color={colors.interactive.primary__resting.rgba}
                />
                Delete prognosis
              </Menu.Item>
            ) : (
              <></>
            )}
          </Menu.Section>
        </Menu>
      )}
    </>
  );
};
