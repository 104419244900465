import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { useGetPressureDataWellboresFlat } from '../hooks/useGetPressureDataWellboresFlat';
import { RightAxisEnum } from '../types/editMode';
import {
  FilterType,
  HelpLine,
  MeasuredDataFilter,
  PressureDataSimple,
} from 'src/api';
import { MEASURED_DATA_HELPLINES_KEY } from 'src/constants';
import { useGetMeasuredDataHelpLines } from 'src/hooks/useGetMeasuredDataHelpLines';
import { useBulkUpdateFilter } from 'src/pages/EditMeasuredData/hooks/useBulkUpdateFilter';
import { useGetFiltersForWell } from 'src/pages/EditMeasuredData/hooks/useGetFiltersForWell';
import { useSaveFilter } from 'src/pages/EditMeasuredData/hooks/useSaveFilter';
import { HelpLineType } from 'src/types';
import { compareHelpLine } from 'src/utils';

import { useApp } from 'hooks/useApp';
import { useMeasuredData } from 'hooks/useMeasuredData';

export interface LinePlotContextProps {
  filteredData: PressureDataSimple[] | undefined;
  setFilteredData: (filteredData: PressureDataSimple[]) => void;
  helpLines: HelpLine[];
  selectedRightAxis: RightAxisEnum | undefined;
  setSelectedRightAxis: (value: RightAxisEnum | undefined) => void;
  isDragActive: boolean;
  setIsDragActive: (isDragDisabled: boolean) => void;
  handleChange: (newFilters: MeasuredDataFilter[]) => void;
  isLoading: boolean;
  selectedHelpLines: HelpLineType[];
  setSelectedHelpLines: Dispatch<SetStateAction<HelpLineType[]>>;
  selectedHelpLinesForQuery: HelpLineType[];
  setSelectedHelpLinesForQuery: Dispatch<SetStateAction<HelpLineType[]>>;
  helplinesDialogOpen: boolean;
  setHelplinesDialogOpen: Dispatch<SetStateAction<boolean>>;
  removeHelpLines: (helpLine: HelpLine[]) => void;
}

export const LinePlotContext = createContext<LinePlotContextProps | undefined>(
  undefined
);

interface LinePlotProviderProps {
  children: ReactNode;
}

export const LinePlotProvider: FC<LinePlotProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const { field } = useApp();
  const { plotDateRangeOptions } = useMeasuredData();
  const { wellId, dhpg } = useParams();
  const { data: filtersForWell = [] } = useGetFiltersForWell();
  const { mutate: saveFilter } = useSaveFilter();
  const { mutate: bulkUpdateFilter } = useBulkUpdateFilter();
  const { pressureData, isLoading: pressureDataLoading } =
    useGetPressureDataWellboresFlat();
  const [helplinesDialogOpen, setHelplinesDialogOpen] = useState(false);
  const [selectedRightAxis, setSelectedRightAxis] = useState<RightAxisEnum>();
  const [isDragActive, setIsDragActive] = useState(false);
  const [filteredData, setFilteredData] = useState<PressureDataSimple[]>([]);
  const [selectedHelpLines, setSelectedHelpLines] = useState<HelpLineType[]>(
    []
  );
  const [selectedHelpLinesForQuery, setSelectedHelpLinesForQuery] = useState<
    HelpLineType[]
  >([]);
  const { data: helpLines = [], isLoading: helpLineLoading } =
    useGetMeasuredDataHelpLines(selectedHelpLinesForQuery, helplinesDialogOpen);

  useEffect(() => {
    if (pressureData) {
      const selectedWellboreData = [
        ...pressureData.filter(
          (item) => item.wellboreUuid === wellId && item.dhpg === Number(dhpg)
        ),
      ];
      setFilteredData(selectedWellboreData);
    }
  }, [dhpg, pressureData, wellId]);

  const findExistingFilter = (filter: MeasuredDataFilter) => {
    if (filteredData) {
      return filtersForWell.find(
        (f) =>
          f.changes?.some(
            (change) => change.date === filter.changes?.at(0)?.date
          ) &&
          (f.type === FilterType.MANUAL || f.type === FilterType.DRAG)
      );
    }
  };

  const handleSetSelectedRightAxis = useCallback(
    (rightAxis: RightAxisEnum | undefined) => {
      if (rightAxis !== selectedRightAxis) {
        setSelectedRightAxis(rightAxis);
      }
    },
    [selectedRightAxis, setSelectedRightAxis]
  );

  const handleChange = (newFilters: MeasuredDataFilter[]) => {
    if (filteredData) {
      const filtersToUpdate = newFilters
        .map((newFilter) => {
          const existingFilter = findExistingFilter(newFilter);
          if (!existingFilter) return;
          return {
            ...existingFilter,
            type: newFilter.type,
            changes: [newFilter.changes?.at(0)],
          } as MeasuredDataFilter;
        })
        .filter((f) => f !== undefined);

      const newFiltersToSave = newFilters.filter((filter) => {
        return !findExistingFilter(filter);
      });

      if (filtersToUpdate.length > 0) {
        bulkUpdateFilter(filtersToUpdate);
      }

      for (const filter of newFiltersToSave) {
        saveFilter(filter);
      }
    }
  };

  const removeHelpLines = (helpLine: HelpLine[]) => {
    setSelectedHelpLines(() =>
      selectedHelpLines.filter((helpLineType) => {
        return !helpLine.some(
          (helpLine) =>
            helpLineType.name === helpLine.name &&
            helpLineType.measureType === helpLine.measureType &&
            helpLineType.dataTypes.some((dataType) =>
              dataType.includes(helpLine.type)
            )
        );
      })
    );

    queryClient.setQueryData(
      [
        MEASURED_DATA_HELPLINES_KEY,
        field?.uuid,
        plotDateRangeOptions.fromDate.toDateString(),
        plotDateRangeOptions.toDate.toDateString(),
        selectedHelpLinesForQuery,
      ],
      (prevHelpLines: HelpLine[]) => {
        if (!prevHelpLines) return [];
        return prevHelpLines.filter(
          (prevHelpLine) =>
            !helpLine.some((helpLine) =>
              compareHelpLine(prevHelpLine, helpLine)
            )
        );
      }
    );
  };

  const isLoading = useMemo(() => {
    return helpLineLoading || pressureDataLoading;
  }, [helpLineLoading, pressureDataLoading]);

  return (
    <LinePlotContext.Provider
      value={{
        filteredData,
        setFilteredData,
        helpLines,
        selectedRightAxis,
        setSelectedRightAxis: handleSetSelectedRightAxis,
        isDragActive,
        setIsDragActive,
        handleChange,
        isLoading,
        selectedHelpLines,
        setSelectedHelpLines,
        selectedHelpLinesForQuery,
        setSelectedHelpLinesForQuery,
        helplinesDialogOpen,
        setHelplinesDialogOpen,
        removeHelpLines,
      }}
    >
      {children}
    </LinePlotContext.Provider>
  );
};
