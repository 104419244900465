import { spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrognosesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
`;

export const TabRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
  padding-bottom: ${spacings.medium};
  justify-content: space-between;
`;
