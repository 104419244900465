import { spacings, Typography } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column: span 2;
`;

export const Info = styled.div`
  display: flex;
  gap: ${spacings.large};
  flex-wrap: wrap;
`;
export const Metadata = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MetaDataItem = styled(Typography)`
  cursor: default;
`;
