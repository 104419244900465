import { useContext } from 'react';

import { ZoneSelectionContext } from '../../providers/ZoneSelectionProvider';

export function useZoneSelector() {
  const context = useContext(ZoneSelectionContext);

  if (context === undefined) {
    throw new Error(
      'useZoneSelector must be used within a ZoneSelectionProvider'
    );
  }

  return context;
}
