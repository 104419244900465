import { FC, Fragment } from 'react';

import { DotProgress, Typography } from '@equinor/amplify-component-lib';

import { MappedStratigraphicUnit, ZoneNavigator } from '../ZoneNavigator';
import { Stratigraphy } from 'src/api';
import { useZoneSelectionStepForm } from 'src/pages/EditPrognosis/hooks/form/useZoneSelectionStepForm';
import { getStratigraphicData } from 'src/pages/EditPrognosis/utils/formUtils';
import { OperationValues } from 'src/utils';

import { useGetPrognosis } from 'hooks/useGetPrognosis';
import {
  useGetStratigraphicValues,
  useGetStratigraphicValuesDrilled,
  useGetStratigraphicValuesPlanned,
} from 'hooks/useGetStratigraphicValues';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

interface NavigatorWrapperProps {
  isInitializing: boolean;
  savedSelectedItems: MappedStratigraphicUnit[];
}
export const NavigatorWrapper: FC<NavigatorWrapperProps> = ({
  isInitializing,
  savedSelectedItems,
}) => {
  const { watch } = useZoneSelectionStepForm();
  const { data: prognosis } = useGetPrognosis();
  const { stratColumnIdentifier } = watch();

  const { data: all, isFetching: isFetchingAll } = useGetStratigraphicValues(
    stratColumnIdentifier,
    prognosis?.operationId as OperationValues
  );

  const { data: planned, isFetching: isFetchingPlanned } =
    useGetStratigraphicValuesPlanned(
      prognosis?.wellboreUuid ?? '',
      stratColumnIdentifier,
      prognosis?.operationId as OperationValues
    );

  const { data: drilled, isFetching: isFetchingDrilled } =
    useGetStratigraphicValuesDrilled(
      prognosis?.wellboreUuid ?? '',
      stratColumnIdentifier,
      prognosis?.operationId as OperationValues
    );

  const isFetchingData =
    isInitializing || isFetchingAll || isFetchingPlanned || isFetchingDrilled;

  const stratigraphy: Stratigraphy[] = getStratigraphicData({
    operationId: prognosis?.operationId ?? undefined,
    all,
    planned,
    drilled,
  });

  if (isFetchingData) {
    return (
      <SpinnerWrapper>
        <DotProgress color="primary" size={48} />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      {stratigraphy.length > 0 ? (
        stratigraphy?.map((column: Stratigraphy) => (
          <Fragment key={column.stratColumnIdentifier}>
            <ZoneNavigator
              column={column.stratColumnIdentifier!}
              data={stratigraphy}
              savedSelectedItems={savedSelectedItems}
            />
          </Fragment>
        ))
      ) : (
        <Typography variant="body_short">
          No data found in the stratigraphic column
        </Typography>
      )}
    </>
  );
};
