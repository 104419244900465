import { createContext, FC, ReactNode, useState } from 'react';

import { MappedStratigraphicUnit } from '../components/FormData/ZoneSelector/ZoneNavigator/ZoneNavigator';

interface ZoneSelectionContextType {
  selectedStratUnits: MappedStratigraphicUnit[];
  setSelectedStratUnits: (stratUnits: MappedStratigraphicUnit[]) => void;
}
export const ZoneSelectionContext = createContext<
  ZoneSelectionContextType | undefined
>(undefined);

interface ZoneSelectionProviderProps {
  children: ReactNode;
}

export const ZoneSelectionProvider: FC<ZoneSelectionProviderProps> = ({
  children,
}) => {
  const [selectedStratUnits, setSelectedStratUnits] = useState<
    MappedStratigraphicUnit[]
  >([]);

  return (
    <ZoneSelectionContext.Provider
      value={{
        selectedStratUnits,
        setSelectedStratUnits,
      }}
    >
      {children}
    </ZoneSelectionContext.Provider>
  );
};
