import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { Form } from './Form';
import { FieldValues, fieldValuesSchema } from './PrognosisValues.utils';
import { FieldValues as BackendFieldValues } from 'src/api';

interface FormInitialiserProps {
  editValues?: BackendFieldValues | undefined;
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const FormInitialiser: FC<FormInitialiserProps> = ({
  editValues,
  open,
  setOpen,
}) => {
  const methods = useForm<FieldValues>({
    defaultValues: editValues
      ? {
          stratColumn: {
            stratColumnIdentifier:
              editValues.stratColumnIdentifier ?? undefined,
            stratColumnUuid: editValues.stratColumnUuid ?? undefined,
          },
          stratUnit: {
            stratUnitIdentifier: editValues.stratUnitIdentifier ?? undefined,
            stratUnitUuid: editValues.stratUnitUuid ?? undefined,
          },
          isGeneral: editValues.isGeneral ?? false,
          datumDepth: editValues.datumDepth ?? undefined,
          datumPressure: editValues.pressureDatum ?? undefined,
          initialPressure: editValues.pressureInitial ?? undefined,
          oilGradient: editValues.gradientOil ?? undefined,
          gasGradient: editValues.gradientGas ?? undefined,
          waterGradient: editValues.gradientWater ?? undefined,
          oilWaterContactDepth: editValues.oilWaterContactDepth ?? undefined,
          gasOilContactDepth: editValues.oilGasContactDepth ?? undefined,
          gasWaterContactDepth: editValues.gasWaterContactDepth ?? undefined,
          gasFormationVolFactor: editValues.gasFormationVolFactor ?? undefined,
          gasSolution: editValues.gasSolution ?? undefined,
          oilFormationVolFactor: editValues.oilFormationVolFactor ?? undefined,
          waterFormationVolFactor:
            editValues.waterFormationVolFactor ?? undefined,
        }
      : {
          isGeneral: false,
        },
    resolver: zodResolver(fieldValuesSchema),
  });

  return (
    <FormProvider {...methods}>
      <Form open={open} setOpen={setOpen} editValues={editValues} />
    </FormProvider>
  );
};
