export const FIELD_VALUES_FOR_PROGNOSIS_FEATURE_KEY =
  '5ecb3158-4d79-4922-bf55-09de56730842';
export const FIELD_VALUES_GENERAL_FEATURE_KEY =
  '623649e8-a3f1-40ce-9fb4-8d6795477bf6';
export const PROGNOSIS_FILTER_FEATURE_KEY =
  '12608c9b-4c29-4b1d-a823-382a952b14ee';
export const MEASURED_DATA_FEATURE_KEY = '11015f40-bca0-47de-aaaf-4da622aaf6bc';
export const UNDO_REDO_FEATURE_KEY = '889b6450-0cab-479f-b9f7-1b703296b12d';
export const TIME_CHART_FEATURE_KEY = 'b7a1d74b-6384-4fcc-9cec-be10ee633742';
export const TOTAL_DEPTH_ROW_FEATURE_KEY =
  '3d35b5d6-e930-413a-83bb-8d8042857c7e';
export const TOGGLE_UNITS_FEATURE_KEY = 'e86fd49e-6927-4616-b595-e254cb1aa431';
export const TOGGLE_APPROVER_OPTIONS = '5b988f85-768a-4154-b162-54b4e7116998';
export const SHOW_ADVANCED_FILTERS = '6d41d4b6-e0d5-4726-9b7b-a2eae9286486';
export const SHOW_ATTRIBUTE_CHIPS = 'a15d91b5-3950-48c0-b198-18cfc915ce5b';
export const SHOW_COMINGLED_CHIP = '3686047b-ff7d-40c8-8403-6c957346ea16';
export const SHOW_FIELD_VALUES_CHIP = '7032f672-ea7e-4ec8-b1ab-bb145868295d';
export const SHOW_MEASURED_DATA_ALL_FIELDS =
  'b1773051-184c-4a0e-a639-24399117aea0';
export const TOPBAR_GUIDELINES = '6029da9e-3562-4fcd-8162-ed06408aa245';
export const IMAGE_UPLOAD = '07b04f62-cb3c-44d3-9867-edec789152e7';
export const TARGET_OPERATION = 'f3a76a8f-f87d-4f5e-8b83-26ec7a468029';
