import { FC } from 'react';

import { formatDate } from '@equinor/amplify-component-lib';

import {
  FromDateLabel,
  SelectedDateForRangeLabel,
  ToDateLabel,
} from 'src/pages/MapView/components/Footer/NewSlider/Slider.styles';
import { useMapData } from 'src/pages/MapView/hooks';

import { useMeasuredData } from 'hooks/useMeasuredData';

export const DateLabels: FC = () => {
  const { mapDateRangeOptions } = useMeasuredData();
  const { fromRangeDate, toRangeDate } = useMapData();

  return (
    <>
      <FromDateLabel group="input" variant="label">
        {formatDate(fromRangeDate, {
          format: 'DD. month YYYY',
          month: 'short',
        })}
      </FromDateLabel>
      <SelectedDateForRangeLabel group="input" variant="label">
        {formatDate(mapDateRangeOptions.dateForRange, {
          format: 'DD. month YYYY',
          month: 'short',
        })}
      </SelectedDateForRangeLabel>
      <ToDateLabel group="input" variant="label">
        {formatDate(toRangeDate, {
          format: 'DD. month YYYY',
          month: 'short',
        })}
      </ToDateLabel>
    </>
  );
};
