import { FC } from 'react';

import { colors, formatDate } from '@equinor/amplify-component-lib';
import { Tooltip } from '@visx/xychart';

import { AddedPrognosisDto } from '../Utils/Chart.types';
import { PrognosisDetailDto } from 'src/api';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';

import styled from 'styled-components';

const TooltipContainer = styled.div`
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const TooltipTitle = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  margin-bottom: 5px;
`;

const TooltipItem = styled.div`
  margin-bottom: 3px;
  color: ${colors.text.static_icons__tertiary.rgba};
  font-size: 12px;
`;

interface DividerColorProps {
  $color: string;
}

const DividerWithColor = styled.div<DividerColorProps>`
  width: 100%;
  height: 2px;
  background: ${({ $color }) => $color};
  margin-bottom: 5px;
`;

interface XyTooltipProps {
  verticalCrossBar?: boolean;
  useDateAsTitle?: boolean;
}

export const XyTooltip: FC<XyTooltipProps> = ({
  useDateAsTitle = false,
  verticalCrossBar,
}) => {
  const { data: optionalAttributes } = useGetOptionalAttributes();

  return (
    <Tooltip<PrognosisDetailDto>
      snapTooltipToDatumX={verticalCrossBar}
      snapTooltipToDatumY={!verticalCrossBar}
      showVerticalCrosshair={verticalCrossBar}
      showHorizontalCrosshair={!verticalCrossBar}
      detectBounds
      renderTooltip={({ tooltipData }) => {
        if (!tooltipData) return null;

        const datum = tooltipData.nearestDatum?.datum as AddedPrognosisDto;

        return (
          <TooltipContainer>
            <TooltipTitle>
              {useDateAsTitle
                ? formatDate(datum?.approveDate)
                : datum?.reservoirZone}
            </TooltipTitle>
            <DividerWithColor
               
              $color={tooltipData?.nearestDatum?.datum.colorHtml || '#000'}
            />
            <TooltipItem>Depth: {datum?.depth}</TooltipItem>
            {optionalAttributes?.pressureInitial && (
              <TooltipItem>Initial: {datum?.initial}</TooltipItem>
            )}
            <TooltipItem>Expected: {datum?.expected}</TooltipItem>
            <TooltipItem>Low: {datum?.low}</TooltipItem>
            <TooltipItem>High: {datum?.high}</TooltipItem>
          </TooltipContainer>
        );
      }}
    />
  );
};
