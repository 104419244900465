import { FC } from 'react';

import { StepperProvider } from '@equinor/amplify-component-lib';

import { Steps } from './components/Steps/Steps';

export const EditPrognosis: FC = () => {
  return (
    <StepperProvider
      syncToURLParam
      steps={[
        {
          label: 'Operation',
        },
        {
          label: 'Attributes / Zones',
        },
        {
          label: 'Prognosis details',
        },
        {
          label: 'Assumptions / Validity',
        },
        {
          label: 'Preview / Submission',
        },
      ]}
    >
      <Steps />
    </StepperProvider>
  );
};
