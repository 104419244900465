import { useCallback, useMemo } from 'react';

import { VisibilityState } from '@tanstack/react-table';

import { PrognosisDetailDto } from 'src/api';

export const useColumnGroupVisibility = (
  columnGroup: (keyof PrognosisDetailDto)[],
  columnVisibility: VisibilityState,
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
) => {
  const isChecked = useMemo(() => {
    return columnGroup.every((column) => columnVisibility[column]);
  }, [columnGroup, columnVisibility]);

  const toggleVisibility = useCallback(
    (isVisible: boolean) => {
      setColumnVisibility((prev) => {
        const newVisibility = { ...prev };
        columnGroup.forEach((column) => {
          newVisibility[column] = isVisible;
        });
        return newVisibility;
      });
    },
    [columnGroup, setColumnVisibility]
  );

  return { isChecked, toggleVisibility };
};
