import { useContext } from 'react';

import { PrognosisDashboardContext } from '../providers/PrognosisDashboardProvider';

export function usePrognosisDashboard() {
  const context = useContext(PrognosisDashboardContext);

  if (context === undefined) {
    throw new Error(
      'usePrognosisDashboard must be used within a PrognosisDashboardProvider'
    );
  }

  return context;
}
