import { colors } from '@equinor/amplify-component-lib';
import isPointInPolygon from '@turf/boolean-point-in-polygon';
import {
  featureCollection,
  point as turfPoint,
  polygon,
  Position,
} from '@turf/helpers';
import interpolate from '@turf/interpolate';
import nearestPoint from '@turf/nearest-point';

import { PressureDataSimple } from 'src/api';
import {
  HEATMAP_GRID_CORNER_COORDS_OFFSET,
  MAP_ZOOM_MAX,
  MAP_ZOOM_MIN,
  TURF_CELL_SIZE_MAX,
  TURF_CELL_SIZE_MIN,
  TURF_INTERPOLATE_WEIGHT,
} from 'src/pages/MapView/MapView.constants';
import { DataSet, FieldOutlineFeature } from 'src/pages/MapView/MapView.types';
import { WellborePurpose } from 'src/types';
import { isBetweenNumbers } from 'src/utils';

import { round } from 'lodash';

const CLOSE_PRECISION = 3;

export function getClosestDatapointValue(
  dataSet: DataSet | undefined,
  coordinate: number[] | undefined
): number | undefined {
  if (dataSet === undefined || coordinate === undefined) return undefined;

  const roundedCoordinates = [
    round(coordinate[0], CLOSE_PRECISION),
    round(coordinate[1], CLOSE_PRECISION),
  ];

  for (const point of dataSet) {
    const roundedPoint = [
      round(point[1], CLOSE_PRECISION),
      round(point[0], CLOSE_PRECISION),
    ];
    if (
      roundedPoint[0] === roundedCoordinates[0] &&
      roundedPoint[1] === roundedCoordinates[1]
    ) {
      return point[2];
    }
  }
}

export const isInSelectedArea = (
  [posLat, posLng]: number[],
  [startLng, startLat]: number[],
  [endLng, endLat]: number[]
) => {
  return (
    isBetweenNumbers(posLat, [startLat, endLat]) &&
    isBetweenNumbers(posLng, [startLng, endLng])
  );
};

export const getNearestPoint = (
  pressurePoint: PressureDataSimple,
  pressurePoints: PressureDataSimple[]
): Position => {
  const points = featureCollection(
    pressurePoints
      .filter((pp) => pp !== pressurePoint)
      .map((item) => {
        const [lat, lng] = item.measurementPosition?.coordinates ?? [0, 0];
        return turfPoint([lng, lat]);
      })
  );

  const [lat, lng] = pressurePoint.measurementPosition?.coordinates ?? [0, 0];
  const targetPoint = turfPoint([lng, lat]);

  const nearest = nearestPoint(targetPoint, points);

  return nearest.geometry.coordinates;
};

export const getDataGridCorners = (
  pressurePoints: PressureDataSimple[],
  mainFieldOutline?: FieldOutlineFeature[]
) => {
  const values = pressurePoints.map((point) => point.pressureFinal);

  const pressurePointsMedian =
    Math.min(...values) + (Math.max(...values) - Math.min(...values)) / 2;

  const flatCoordinates =
    mainFieldOutline?.flatMap(
      (outline) => outline.feature.geometry?.coordinates[0] ?? []
    ) ?? [];

  const longitudes = flatCoordinates.map((coord) => coord[0]) ?? [];

  const latitudes = flatCoordinates.map((coord) => coord[1]) ?? [];

  const topLeftCorner = [
    Math.max(...latitudes) + HEATMAP_GRID_CORNER_COORDS_OFFSET,
    Math.min(...longitudes) - HEATMAP_GRID_CORNER_COORDS_OFFSET,
  ];
  const bottomRightCorner = [
    Math.min(...latitudes) - HEATMAP_GRID_CORNER_COORDS_OFFSET,
    Math.max(...longitudes) + HEATMAP_GRID_CORNER_COORDS_OFFSET,
  ];

  return [
    turfPoint(topLeftCorner, {
      value: pressurePointsMedian,
    }),
    turfPoint(bottomRightCorner, {
      value: pressurePointsMedian,
    }),
  ];
};

const getInterpolationCellSize = (zoom: number) => {
  if (zoom < 10) return TURF_CELL_SIZE_MAX;
  if (zoom > 13) return TURF_CELL_SIZE_MIN;
  return (
    ((zoom - MAP_ZOOM_MIN) * (TURF_CELL_SIZE_MIN - TURF_CELL_SIZE_MAX)) /
      (MAP_ZOOM_MAX - MAP_ZOOM_MIN) +
    TURF_CELL_SIZE_MAX
  );
};

export const getDataGridInPolygon = (
  pressurePoints: PressureDataSimple[] | undefined,
  zoom: number | undefined,
  fieldOutlines?: FieldOutlineFeature[]
) => {
  if (!pressurePoints) return { data: [], min: 0, max: 1 };

  const filteredPressurePoints = pressurePoints?.filter(
    (point) => point.pressureFinal > 0
  );

  const pressurePointsFeatureCollection = featureCollection([
    ...getDataGridCorners(filteredPressurePoints, fieldOutlines),
    ...filteredPressurePoints.map((point) => {
      return turfPoint(point.measurementPosition?.coordinates ?? [], {
        value: point.pressureFinal,
      });
    }),
  ]);

  const interpolatedValues = interpolate(
    pressurePointsFeatureCollection,
    getInterpolationCellSize(zoom ?? 10), // Default to most zoomed out
    {
      gridType: 'point',
      property: 'value',
      units: 'meters',
      weight: TURF_INTERPOLATE_WEIGHT,
    }
  );

  let min = Infinity;
  let max = -Infinity;
  const pointsInsidePolygon: DataSet = [];

  for (const point of interpolatedValues.features) {
    if (
      fieldOutlines?.some((outline) => {
        return isPointInPolygon(
          [point.geometry.coordinates[1], point.geometry.coordinates[0]],
          polygon(outline.feature.geometry?.coordinates ?? [])
        );
      })
    ) {
      const value = point.properties?.value as number;
      pointsInsidePolygon.push([
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
        value,
      ]);
      if (value < min) {
        min = value;
      }
      if (value > max) {
        max = value;
      }
    }
  }

  return { data: pointsInsidePolygon, min, max };
};

export const getMarkerFillColor = (purpose: WellborePurpose) => {
  // Using .hex to maintain same style in dark mode
  if (purpose === WellborePurpose.PRODUCTION) {
    return colors.text.static_icons__default.hex;
  }
  return colors.ui.background__default.hex;
};

export const getMarkerBorderColor = (
  purpose: WellborePurpose,
  selected?: boolean
) => {
  if (selected) {
    return colors.interactive.success__resting.rgba;
  } else if (purpose === WellborePurpose.PRODUCTION) {
    return colors.ui.background__default.hex;
  }
  return colors.text.static_icons__default.hex;
};

export const getHoverFillColor = (selected: boolean) => {
  if (selected) {
    return colors.interactive.primary__hover_alt.rgba;
  }
  return colors.interactive.primary__hover.rgba;
};
