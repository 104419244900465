import { FC, useRef, useState } from 'react';

import {
  Button,
  colors,
  Icon,
  Menu,
  spacings,
} from '@equinor/amplify-component-lib';
import { link, share } from '@equinor/eds-icons';

import styled from 'styled-components';

const MenuContent = styled.div`
  display: flex;
  padding: 0 ${spacings.small};
  gap: ${spacings.small};
  > div {
    background-color: ${colors.ui.background__light.rgba};
    border-bottom: 1px solid ${colors.text.static_icons__tertiary.rgba};
    display: flex;
    gap: ${spacings.small};
    align-items: center;
    padding: ${spacings.small};
  }
`;

interface ShareMenuProps {
  statusId: number | undefined;
}

export const ShareMenu: FC<ShareMenuProps> = () => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleOnClick = () => setOpen((prev) => !prev);
  const handleOnClose = () => setOpen(false);

  const handleOnCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    handleOnClose();
  };

  return (
    <>
      <Button ref={anchorRef} variant="outlined" onClick={handleOnClick}>
        <Icon data={share} /> Share
      </Button>
      {open && (
        <Menu
          open
          anchorEl={anchorRef.current}
          placement="bottom-start"
          onClose={handleOnClose}
        >
          <MenuContent>
            <div>
              <Icon data={link} />
              {window.location.href}
            </div>
            <Button variant="outlined" onClick={handleOnCopy}>
              Copy
            </Button>
          </MenuContent>
        </Menu>
      )}
    </>
  );
};
