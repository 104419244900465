import { useQuery } from '@tanstack/react-query';

import { usePrognosisIDFromParams } from './usePrognosisIDFromParams';
import { PrognosisService } from 'src/api';
import { PROGNOSIS_KEY } from 'src/constants/queryKeys';

export const useGetPrognosis = () => {
  const usingId = usePrognosisIDFromParams();

  return useQuery({
    queryKey: [PROGNOSIS_KEY, usingId],
    queryFn: () => PrognosisService.getApiV1Prognoses1(usingId),
    enabled: !Number.isNaN(usingId),
    staleTime: 0,
  });
};
