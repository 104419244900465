/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OperationPhase } from '../models/OperationPhase';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OperationPhaseService {
  /**
   * Get new well phases
   * @returns OperationPhase Success
   * @throws ApiError
   */
  public static getApiV1PrognosesOperationPhasesNewWell(): CancelablePromise<
    Array<OperationPhase>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/OperationPhases/NewWell',
      errors: {
        500: `Server Error`,
      },
    });
  }
  /**
   * Get intervention phases
   * @returns OperationPhase Success
   * @throws ApiError
   */
  public static getApiV1PrognosesOperationPhasesIntervention(): CancelablePromise<
    Array<OperationPhase>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/OperationPhases/Intervention',
      errors: {
        500: `Server Error`,
      },
    });
  }
  /**
   * Get target phases
   * @returns OperationPhase Success
   * @throws ApiError
   */
  public static getApiV1PrognosesOperationPhasesTarget(): CancelablePromise<
    Array<OperationPhase>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/OperationPhases/Target',
      errors: {
        500: `Server Error`,
      },
    });
  }
}
