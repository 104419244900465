/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OptionalAttributesDto } from '../models/OptionalAttributesDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OptionalPrognosisAttributesService {
  /**
   * Get optional attributes for a prognosis
   * @param prognosisId
   * @returns OptionalAttributesDto Success
   * @throws ApiError
   */
  public static getApiV1OptionalAttributes(
    prognosisId: number
  ): CancelablePromise<OptionalAttributesDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/OptionalAttributes/{prognosisId}',
      path: {
        prognosisId: prognosisId,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Create optional attributes for a prognosis
   * @param prognosisId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static postApiV1OptionalAttributes(
    prognosisId: number,
    requestBody?: OptionalAttributesDto
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/OptionalAttributes/{prognosisId}',
      path: {
        prognosisId: prognosisId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Update optional attributes for a prognosis
   * @param prognosisId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static putApiV1OptionalAttributes(
    prognosisId: number,
    requestBody?: OptionalAttributesDto
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/OptionalAttributes/{prognosisId}',
      path: {
        prognosisId: prognosisId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
