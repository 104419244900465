import { FC } from 'react';

import {
  colors,
  Dialog,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { useDeleteBulkDetails } from '../../hooks/api/useDeleteBulkDetails';
import { useArtificialLoading } from '../../hooks/util/useArtificialLoading';
import { PrognosisDetailDto } from 'src/api';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  detailsToDelete: PrognosisDetailDto[];
  handleFormChange: () => void;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  onClose,
  detailsToDelete,
  handleFormChange,
}) => {
  const { mutate: deleteDetails } = useDeleteBulkDetails();
  const [isLoading, startLoading] = useArtificialLoading(500);

  const handleConfirm = async () => {
    const ids = detailsToDelete.map((detail) => detail.id);
    deleteDetails(ids);
    handleFormChange();
    await startLoading();
    onClose();
  };
  return (
    <Dialog
      open={open}
      title={
        <Typography variant="h3" color={colors.interactive.danger__text.hex}>
          Reset details table
        </Typography>
      }
      withBorders
      width={500}
      onClose={() => onClose()}
      actions={[
        {
          text: 'Cancel',
          onClick: () => onClose(),
          color: 'primary',
          variant: 'outlined',
          position: 'left',
        },
        {
          text: 'Confirm',
          onClick: handleConfirm,
          color: 'danger',
          isLoading: isLoading,
        },
      ]}
    >
      <Container>
        <Typography variant="body_long">
          This action will clear your current work. Changes to the operation
          after adding details to the prognosis are not possible. If you
          &quot;Confirm&quot;, you will have to start over.
        </Typography>
        <Typography variant="body_short_bold">
          This cannot be undone. Are you sure you want to proceed?
        </Typography>
      </Container>
    </Dialog>
  );
};
