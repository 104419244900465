import { useQuery } from '@tanstack/react-query';

import { OptionalPrognosisAttributesService } from 'src/api';
import { GET_OPTIONAL_ATTRIBUTES_KEY } from 'src/constants';
import { FIVE_MINUTES } from 'src/constants/staleTime';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const useGetOptionalAttributes = () => {
  const usingId = usePrognosisIDFromParams();

  return useQuery({
    queryKey: [GET_OPTIONAL_ATTRIBUTES_KEY, usingId],
    queryFn: () =>
      OptionalPrognosisAttributesService.getApiV1OptionalAttributes(usingId),
    staleTime: FIVE_MINUTES,
  });
};
