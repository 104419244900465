import { FC } from 'react';

import {
  spacings,
  Stepper,
  Template,
  useStepper,
} from '@equinor/amplify-component-lib';

import { CommentsStep } from './CommentsStep/CommentsStep';
import { CreationStep } from './CreationStep/CreationStep';
import { EditDetailsStep } from './EditDetailsStep/EditDetailsStep';
import { PreviewStep } from './PreviewStep/PreviewStep';
import { ZoneSelectionStep } from './ZoneSelectionStep/ZoneSelectionStep';
import { Title } from 'src/pages/EditPrognosis/components/Title/Title';

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StaticContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacings.large};
  gap: ${spacings.large};
`;

export const Steps: FC = () => {
  const { currentStep } = useStepper();

  return (
    <PageContainer className={Template.FullWidth}>
      <StaticContentWrapper>
        <Breadcrumbs pageTitle="Prognosis editor" />
        <Title />
        <Stepper />
      </StaticContentWrapper>
      {currentStep === 0 && <CreationStep />}
      {currentStep === 1 && <ZoneSelectionStep />}
      {currentStep === 2 && <EditDetailsStep />}
      {currentStep === 3 && <CommentsStep />}
      {currentStep === 4 && <PreviewStep />}
    </PageContainer>
  );
};
