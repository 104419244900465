import { colors, spacings, Typography } from '@equinor/amplify-component-lib';

import { motion } from 'framer-motion';
import { styled } from 'styled-components';

interface ContainerProps {
  $backgroundColor: string;
  $textColor: string;
  $hoverEnabled: boolean;
  $borderColor?: string;
  disabled?: boolean;
}

export const Container = styled(motion.div)<ContainerProps>`
  display: flex;
  padding: ${spacings.x_small};
  align-items: center;
  /* justify-content: center; */
  border-radius: ${spacings.x_large};
  border: 1px solid ${({ $borderColor }) => $borderColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $textColor }) => $textColor};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  overflow: hidden;
  min-width: 26px;
  height: 26px;

  ${({ $hoverEnabled }) =>
    $hoverEnabled &&
    `
    &:hover {
      background-color: ${colors.interactive.primary__hover_alt.rgba};
      border-color: ${colors.interactive.primary__hover.rgba};
      color: ${colors.interactive.primary__hover.rgba};
    }
  `}
`;

interface TypographyProps {
  $hasIcon: boolean;
}
export const StyledTypography = styled(Typography)<TypographyProps>`
  padding: ${({ $hasIcon }) => ($hasIcon ? `0 ${spacings.x_small}` : '0')};
  line-height: 16px;
  width: 100%;
  overflow: hidden;
  text-align: center;
`;
