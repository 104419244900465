import { useAuth, useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCreationStepForm } from '../form/useCreationStepForm';
import { PrognosisCreateDto, PrognosisService } from 'src/api';
import { PROGNOSIS_DETAIL, PROGNOSIS_KEY } from 'src/constants';
import { StatusIdEnum } from 'src/types';
import { getUsernameFromEmail } from 'src/utils';

import { useGetCiWellTargetsByField } from 'hooks/useGetCiTargets';

export const usePostCreatePrognosis = () => {
  const { watch } = useCreationStepForm();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { account } = useAuth();
  const currentUser = getUsernameFromEmail(account?.username ?? '');
  const { data: ciWellTargets } = useGetCiWellTargetsByField();

  const {
    operationId,
    operationPhaseId,
    wellboreIdentifier: wellbore,
    ciTargetUuid,
  } = watch();

  const wellTarget = ciWellTargets?.find(
    (target) => target.targetUuid === ciTargetUuid
  );

  const title: string = wellbore ? wellbore : (wellTarget?.targetName ?? '');

  const body: PrognosisCreateDto = {
    title: title,
    operationId: operationId,
    operationPhaseId: operationPhaseId,
    statusId: StatusIdEnum.DRAFT,
    wellboreIdentifier: wellbore,
    ciTargetUuid: ciTargetUuid,
  };

  return useMutation({
    mutationFn: async () => {
      return await PrognosisService.postApiV1Prognoses(body);
    },
    onSuccess: (newID) => {
      queryClient.setQueryData([PROGNOSIS_KEY, newID], {
        ...body,
        createUser: currentUser,
        id: newID,
      });

      // setting the query data for the new prognosis so that we don’t have to refetch it
      // the new prognosis has no details yet
      queryClient.setQueryData([PROGNOSIS_DETAIL, newID], []);
    },
    onError: (err) => {
      showSnackbar('Failed to create prognosis: ' + err.message);
    },
  });
};
