import { useQuery } from '@tanstack/react-query';

import { usePrognosisIDFromParams } from './usePrognosisIDFromParams';
import { PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants/queryKeys';

export function useGetPrognosisDetails() {
  const usingId = usePrognosisIDFromParams();

  return useQuery({
    queryKey: [PROGNOSIS_DETAIL, usingId],
    queryFn: () => PrognosisDetailService.getApiV1PrognosesDetails1(usingId),
    enabled: !Number.isNaN(usingId),
  });
}
