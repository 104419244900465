import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useZoneSelectionStepForm } from '../form/useZoneSelectionStepForm';
import {
  OptionalAttributesDto,
  OptionalPrognosisAttributesService,
} from 'src/api';
import { GET_OPTIONAL_ATTRIBUTES_KEY } from 'src/constants';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePutOptionalAttributes = () => {
  const usingId = usePrognosisIDFromParams();
  const { getValues, resetField } = useZoneSelectionStepForm();
  const { optionalAttributes } = getValues();
  const queryClient = useQueryClient();

  const body: OptionalAttributesDto = {
    datumDepth: optionalAttributes.datumDepth,
    segment: optionalAttributes.segment,
    pressureInitial: optionalAttributes.pressureInitial,
    pressureMin: optionalAttributes.pressureMin,
    pressureMax: optionalAttributes.pressureMax,
    totalDepthRow: optionalAttributes.totalDepthRow,
    useExistingFieldValues: optionalAttributes.useExistingFieldValues,
    commingledZones: optionalAttributes.commingledZones,
    usePozoZoneTops: false,
    statWellpick: false,
  };

  return useMutation({
    mutationFn: async () => {
      return await OptionalPrognosisAttributesService.putApiV1OptionalAttributes(
        usingId,
        body
      );
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [GET_OPTIONAL_ATTRIBUTES_KEY, usingId],
      });

      const previousOptionalAttributes: OptionalAttributesDto | undefined =
        queryClient.getQueryData([GET_OPTIONAL_ATTRIBUTES_KEY, usingId]);

      queryClient.setQueryData([GET_OPTIONAL_ATTRIBUTES_KEY, usingId], {
        ...previousOptionalAttributes,
        ...body,
      } as OptionalAttributesDto);

      return { previousOptionalAttributes };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [GET_OPTIONAL_ATTRIBUTES_KEY, usingId],
        context?.previousOptionalAttributes ?? []
      );

      resetField('optionalAttributes', {
        defaultValue: context?.previousOptionalAttributes,
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_OPTIONAL_ATTRIBUTES_KEY, usingId],
      });
    },
  });
};
