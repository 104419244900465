import { FC } from 'react';

import { useSnackbar, useStepper } from '@equinor/amplify-component-lib';

import { StepFooter } from '../../StepFooter/StepFooter';
import { PrognosisDetailDto } from 'src/api';
import { useDeleteBulkDetails } from 'src/pages/EditPrognosis/hooks/api/useDeleteBulkDetails';
import { usePostBulkDetails } from 'src/pages/EditPrognosis/hooks/api/usePostBulkDetails';
import { usePutBulkDetails } from 'src/pages/EditPrognosis/hooks/api/usePutBulkDetails';
import { usePutOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/usePutOptionalAttributes';
import { usePutZoneSelection } from 'src/pages/EditPrognosis/hooks/api/usePutZoneSelection';
import { useZoneSelectionStepForm } from 'src/pages/EditPrognosis/hooks/form/useZoneSelectionStepForm';
import { useArtificialLoading } from 'src/pages/EditPrognosis/hooks/util/useArtificialLoading';
import { useZoneSelector } from 'src/pages/EditPrognosis/hooks/util/useZoneSelector';
import {
  calculateReferenceDepth,
  convertToDetails,
  createNewDetails,
} from 'src/pages/EditPrognosis/utils/formUtils';
import { OperationValues, OptionalAttributes } from 'src/utils';

import { useGetPrognosis } from 'hooks/useGetPrognosis';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import { useGetAllWellbores } from 'hooks/useGetWellbores';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

interface ZoneFooterProps {
  isInitializing: boolean;
}

export const ZoneFooter: FC<ZoneFooterProps> = ({ isInitializing }) => {
  const {
    trigger,
    getValues,
    formState: { dirtyFields },
  } = useZoneSelectionStepForm();
  const { showSnackbar } = useSnackbar();
  const { data: prognosisData } = useGetPrognosis();
  const { selectedStratUnits } = useZoneSelector();
  const { mutateAsync: postDetails, isPending } = usePostBulkDetails({});
  const { mutate: deletePrognosisDetail } = useDeleteBulkDetails();
  const { data: apiPrognosisDetails } = useGetPrognosisDetails();
  const { mutate: putPrognosis } = usePutZoneSelection();
  const { mutate: putAttributes } = usePutOptionalAttributes();
  const { mutate: putBulkDetails } = usePutBulkDetails();
  const { data: wellboreData } = useGetAllWellbores();
  const { goToNextStep } = useStepper();
  const usingId = usePrognosisIDFromParams();

  const [isLoading, startLoading] = useArtificialLoading(500);

  const handleSubmit = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    if (selectedStratUnits?.length === 0 && apiPrognosisDetails?.length === 0) {
      showSnackbar('Please add at least one detail');
      return;
    }

    const {
      optionalAttributes: dirtyOptionalAttributes,
      stratColumnIdentifier,
    } = dirtyFields;
    const { optionalAttributes } = getValues();

    if (stratColumnIdentifier) {
      putPrognosis();
    }

    let totalDepthRow: PrognosisDetailDto[] = [];
    if (dirtyOptionalAttributes?.totalDepthRow !== undefined) {
      totalDepthRow = handleTotalDepthRow(optionalAttributes);
    }

    handleOptionalAttributesUpdate(
      dirtyOptionalAttributes as OptionalAttributes
    );

    if (selectedStratUnits.length > 0) {
      const convertedDetails = convertToDetails(selectedStratUnits);
      const cleanDetails = createNewDetails({
        details: convertedDetails ?? [],
        newPrognosisId: usingId,
        optionalAttributes: optionalAttributes,
      });

      const details = [...cleanDetails, ...totalDepthRow];

      await postDetails({ details });
    } else if (totalDepthRow.length > 0) {
      await postDetails({ details: totalDepthRow });
    } else if (Object.keys(dirtyFields).length) {
      await startLoading();
    }

    goToNextStep();
  };

  const handleTotalDepthRow = (
    optionalAttributes: OptionalAttributes
  ): PrognosisDetailDto[] => {
    if (optionalAttributes.totalDepthRow) {
      const selectedWellboreData = wellboreData?.find(
        (item) => item.wellboreUuid === prognosisData?.wellboreUuid
      );

      const totalDepth = selectedWellboreData?.totalDepthDrillerTvd;
      const elevation = selectedWellboreData?.depthReferenceElevation;
      const isNewWell = prognosisData?.operationId === OperationValues.NEW_WELL;

      const totalDepthRowDetail: Partial<PrognosisDetailDto> = {
        lithostratSubzone: 'Total depth',
        reservoirZone: 'Total depth',
        stratColumnIdentifier: 'Total depth',
        referenceDepth: calculateReferenceDepth({
          isNewWell,
          totalDepth: totalDepth ?? undefined,
          elevation: elevation ?? undefined,
        }),
        fluidType: 'Oil',
      };

      return createNewDetails({
        details: [totalDepthRowDetail],
        newPrognosisId: usingId,
        optionalAttributes: optionalAttributes,
      });
    } else {
      const detailToDelete = apiPrognosisDetails?.find(
        (detail) => detail.reservoirZone === 'Total depth'
      );

      if (detailToDelete) {
        deletePrognosisDetail([detailToDelete.id]);
      }

      return [];
    }
  };

  const handleOptionalAttributesUpdate = (
    dirtyOptionalAttributes: OptionalAttributes | undefined
  ) => {
    if (dirtyOptionalAttributes && !dirtyOptionalAttributes.totalDepthRow) {
      if (apiPrognosisDetails) {
        putBulkDetails(apiPrognosisDetails);
      }
    }

    putAttributes();
  };

  return (
    <StepFooter
      handleSubmit={handleSubmit}
      isMutating={isLoading || isPending}
      isInitializing={isInitializing}
    />
  );
};
