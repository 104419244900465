import { FC } from 'react';

import {
  Button,
  colors,
  Icon,
  OptionalTooltip,
  spacings,
} from '@equinor/amplify-component-lib';
import { comment_solid } from '@equinor/eds-icons';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: ${spacings.xx_small};
  justify-content: end;
`;

interface CommentCellProps {
  detailComment: string | null | undefined;
  handleOnOpenComment: (detailComment: string | null | undefined) => void;
}

export const CommentCell: FC<CommentCellProps> = ({
  detailComment,
  handleOnOpenComment,
}) => {
  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleOnOpenComment(detailComment);
  };

  return (
    <Container>
      <OptionalTooltip
        title={!detailComment ? 'No comment found' : undefined}
        placement="top"
        enterDelay={500}
      >
        <Button
          variant="ghost_icon"
          disabled={!detailComment}
          onClick={(event) => handleOnClick(event)}
        >
          <Icon
            data={comment_solid}
            color={
              detailComment
                ? `${colors.interactive.primary__resting.rgba}`
                : undefined
            }
          />
        </Button>
      </OptionalTooltip>
    </Container>
  );
};
