import { FC } from 'react';
import { useNavigate } from 'react-router';

import { Button, useStepper } from '@equinor/amplify-component-lib';

import { SavingChip } from '../../ActionFooter/SavingChip';
import {
  ButtonContainer,
  Container,
} from 'src/pages/EditPrognosis/components/Steps/StepFooter/StepFooter.styles';

import { SaveButton } from 'components/SaveButton/SaveButton';
import { useApp } from 'hooks/useApp';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

interface StepFooterProps {
  onCreateStep?: boolean;
  isMutating: boolean;
  isInitializing?: boolean;
  showSavingChip?: boolean;
  isAutoSaving?: boolean;
  isPreview?: boolean;
  handleSubmit: () => void;
}

export const StepFooter: FC<StepFooterProps> = ({
  onCreateStep,
  isMutating,
  isInitializing = false,
  showSavingChip = false,
  isAutoSaving = false,
  isPreview = false,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  const usingId = usePrognosisIDFromParams();
  const { goToPreviousStep, currentStep } = useStepper();
  const { formattedFieldNameForUrl } = useApp();

  const handlePreviousStep = () => {
    if (currentStep === 1) {
      navigate(`${formattedFieldNameForUrl}/prognosis/edit/${usingId}/0`);
      return;
    }
    goToPreviousStep();
  };

  return (
    <Container>
      <Button
        variant="ghost"
        onClick={() => navigate(`${formattedFieldNameForUrl}/prognosis`)}
      >
        Return to prognosis dashboard
      </Button>
      <ButtonContainer>
        {showSavingChip && <SavingChip isSaving={isAutoSaving} />}
        {!onCreateStep && (
          <Button variant="outlined" onClick={handlePreviousStep}>
            Go to previous step
          </Button>
        )}
        <SaveButton
          onClick={handleSubmit}
          label={isPreview ? 'Submit for approval' : 'Save and continue'}
          isSaving={isMutating}
          isInitializing={isInitializing}
        />
      </ButtonContainer>
    </Container>
  );
};
