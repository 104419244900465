import { FC, useMemo } from 'react';
import { Path } from 'react-hook-form';

import { Icon, TextField, Typography } from '@equinor/amplify-component-lib';
import { error_filled } from '@equinor/eds-icons';

import { Container, TextFieldContainer } from '../DataInputDialog.styles';
import { PrognosisDetailKeys } from '../DataInputDialog.utils';
import { Skeleton } from '../Skeleton/Skeleton';
import { PrognosisDetailDto } from 'src/api';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';
import { filterFieldKeys } from 'src/pages/EditPrognosis/utils/formUtils';
import { getPrognosisDetailError } from 'src/utils/error';
import { EditDetailsStepFormType } from 'src/utils/zodSchema';

interface TextFieldsProps {
  label: string;
  index: number;
  keys: PrognosisDetailKeys;
  isDisabled?: boolean;
  isLoading: boolean;
  hideDatumDepth?: boolean;
}

export const TextFields: FC<TextFieldsProps> = ({
  label,
  index,
  keys,
  isDisabled,
  isLoading,
  hideDatumDepth,
}) => {
  const {
    register,
    trigger,
    getValues,
    clearErrors,
    formState: { errors },
  } = useEditDetailsStepForm();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const filteredKeys = useMemo(
    () => filterFieldKeys({ keys, optionalAttributes, hideDatumDepth }),
    [hideDatumDepth, keys, optionalAttributes]
  );

  const handleError = (key: keyof PrognosisDetailDto) => {
    return getPrognosisDetailError(errors, index, key);
  };

  const handleOnBlur = (fieldPath: Path<EditDetailsStepFormType>) => {
    const value = getValues(fieldPath);

    if (value) {
      trigger(fieldPath);
    } else {
      clearErrors(fieldPath);
    }
  };

  if (isLoading) {
    return <Skeleton label={label} />;
  }

  return (
    <Container>
      <Typography variant="h6">{label}</Typography>
      <TextFieldContainer>
        {Object.entries(filteredKeys).map(([key, value]) => {
          const fieldPath =
            `prognosisDetails.${index}.${key}` as Path<EditDetailsStepFormType>;

          return (
            <TextField
              {...register(fieldPath)}
              key={key}
              id={key}
              placeholder={value?.placeholder ?? ''}
              helperIcon={
                handleError(key as keyof PrognosisDetailDto) && (
                  <Icon data={error_filled} size={16} />
                )
              }
              meta="Required"
              label={value.label}
              data-testid={key}
              onBlur={() => handleOnBlur(fieldPath)}
              helperText={
                handleError(key as keyof PrognosisDetailDto)?.message ||
                value.helperText
              }
              variant={
                handleError(key as keyof PrognosisDetailDto)
                  ? 'error'
                  : undefined
              }
              disabled={isDisabled}
            />
          );
        })}
      </TextFieldContainer>
    </Container>
  );
};
