import { ChangeEvent, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@equinor/amplify-component-lib';
import { add } from '@equinor/eds-icons';

import { usePrognosisDashboard } from '../../hooks/useDashboard';
import {
  HeaderContent,
  StyledTypography,
} from '../../PrognosisDashboard.styles';
import { useApp } from 'src/hooks';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';
import { Search } from 'src/pages/PrognosisDashboard/components/Search/Search';

export const Header: FC = () => {
  const { field, formattedFieldNameForUrl } = useApp();
  const { filterValue, updateFilterValue } = usePrognosisDashboard();
  const { userAccessForField } = useGetUserAccess();
  const navigate = useNavigate();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFilterValue({ search: event.target.value });
  };

  const handleOnCreate = () => {
    navigate(`${formattedFieldNameForUrl}/prognosis/create/0`);
  };

  const showButton = userAccessForField?.userRoles?.submitter ?? false;

  return (
    <HeaderContent id="header">
      <StyledTypography variant="h1_bold">
        Prognoses in {field?.name}
      </StyledTypography>
      <Search value={filterValue.search} handleOnChange={handleOnChange} />
      {showButton && (
        <Button onClick={handleOnCreate}>
          Create new prognosis <Icon data={add} />
        </Button>
      )}
    </HeaderContent>
  );
};
