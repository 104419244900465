import { z } from 'zod';

export const measuredValuesSchema = z.object({
  minimumPressure: z
    .union([
      z
        .number()
        .int()
        .positive()
        .min(10, { message: 'Pressure must be at least 10 bar' })
        .max(1000, { message: 'Pressure must be less than 1000 bar' }),
      z.nan(),
    ])
    .optional(),
  maximumPressure: z
    .union([
      z
        .number()
        .int()
        .positive()
        .min(10, { message: 'Pressure must be at least 10 bar' })
        .max(1000, { message: 'Pressure must be less than 1000 bar' }),
      z.nan(),
    ])
    .optional(),
});

export type MeasuredValuesTypes = z.infer<typeof measuredValuesSchema>;
