import { OperationValues } from './prognosis';
import { PrognosisDetailDto } from 'src/api';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';

import { z } from 'zod';

/**
 * Creates a function that validates if a given number or string value
 * conforms to the specified precision and scale.
 *
 * @param precision - The total number of digits allowed, including both integer and fractional parts.
 * @param scale - The number of digits allowed after the decimal point.
 * @returns A function that takes a number or string value and returns a boolean indicating
 *          whether the value conforms to the specified precision and scale.
 *
 * @example
 * const validate = precisionScale(5, 2);
 * validate(123.45); // true
 * validate(1234.5); // true
 * validate(12345.67); // false
 * validate('123.45'); // true
 * validate('1234.567'); // false
 */
export const precisionScale =
  (precision: number, scale: number) => (value: number | string) => {
    const normalizedValue = value.toString().replace(',', '.');
    const [integerPart, decimalPart] = normalizedValue.split('.');
    return (
      integerPart.length <= precision - scale &&
      (!decimalPart || decimalPart.length <= scale)
    );
  };

// schema for creating a new prognosis
export const createPrognosisSchema = z
  .object({
    operationId: z.number().nonnegative('Operation is required'),
    operationPhaseId: z
      .number()
      .nonnegative('Operation phase is required')
      .nullable(),
    wellboreIdentifier: z.string().min(1, 'Wellbore is required').nullable(),
    ciTargetUuid: z.string().min(1, 'Target is required').nullable(),
  })
  .refine(
    (data) => {
      return (
        ((data.operationId as OperationValues) !== OperationValues.NEW_WELL &&
          (data.operationId as OperationValues) !==
            OperationValues.INTERVENTION &&
          (data.operationId as OperationValues) !== OperationValues.TARGET) ||
        data.operationPhaseId !== null
      );
    },
    {
      message:
        'Operation phase is required for new well, intervention and target operations',
      path: ['operationPhaseId'],
    }
  )
  .refine(
    (data) => {
      return (
        (data.operationId as OperationValues) !== OperationValues.TARGET ||
        data.ciTargetUuid !== null
      );
    },
    {
      message: 'Target is required for target operation',
      path: ['ciTargetUuid'],
    }
  )
  .refine(
    (data) => {
      return (data.operationId as OperationValues) !== OperationValues.TARGET
        ? data.wellboreIdentifier !== null
        : data.wellboreIdentifier === null;
    },
    {
      message: 'Wellbore is required',
      path: ['wellboreIdentifier'],
    }
  );

const createPrognosisDetailSchema = (
  optionalAttributes?: OptionalAttributes
): z.ZodType<PrognosisDetailDto> => {
  return z.object({
    id: z.number(),
    prognosisId: z.number(),
    lithostratSubzone: z.string(),
    reservoirZoneNickname: z.coerce
      .string()
      .max(30, 'Nickname must be less than 30 characters')
      .optional(),
    referenceDepth: z.coerce
      .number()
      .positive('Reference depth must be greater than 0')
      .refine(
        precisionScale(7, 3),
        'Reference depth must have a precision of 7, 3'
      ),
    pressureInitial: optionalAttributes?.pressureInitial
      ? z.coerce
          .number()
          .positive('Initial reference pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Initial reference pressure must have a precision of 7, 3'
          )
      : z.null().optional(),
    pressureExpected: z.coerce
      .number()
      .positive('Expected reference pressure must be greater than 0')
      .refine(
        precisionScale(7, 3),
        'Expected reference pressure must have a precision of 7, 3'
      ),
    pressureLow: z.coerce
      .number()
      .positive('Low reference pressure must be greater than 0')
      .refine(
        precisionScale(7, 3),
        'Low reference pressure must have a precision of 7, 3'
      ),
    pressureHigh: z.coerce
      .number()
      .positive('High reference pressure must be greater than 0')
      .refine(
        precisionScale(7, 3),
        'High reference pressure must have a precision of 7, 3'
      ),
    pressureMin: optionalAttributes?.pressureMin
      ? z.coerce
          .number()
          .positive('Minimum reference pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Minimum reference pressure must have a precision of 7, 3'
          )
      : z.null().optional(),
    pressureMax: optionalAttributes?.pressureMax
      ? z.coerce
          .number()
          .positive('Maximum reference pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Maximum reference pressure must have a precision of 7, 3'
          )
      : z.null().optional(),
    pressureGradientDepth: z.coerce
      .number()
      .positive('Pressure gradient depth must be greater than 0')
      .refine(
        precisionScale(7, 4),
        'Pressure gradient depth must have a precision of 7, 4'
      ),
    fluidType: z.string().min(1, 'Fluid type is required'),
    comment: z.coerce
      .string()
      .max(100, 'Comment must be less than 100 characters')
      .optional(),
    segment: optionalAttributes?.segment
      ? z
          .string()
          .max(30, 'Segment must be less than 30 characters')
          .nullable()
          .optional()
      : z.null().optional(),
    datumDepth: optionalAttributes?.datumDepth
      ? z.coerce
          .number()
          .positive('Datum depth must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Datum depth must have a precision of 7, 3'
          )
      : z.null().optional(),
    pressureDatumExpected: optionalAttributes?.datumDepth
      ? z.coerce
          .number()
          .positive('Expected datum pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Expected datum pressure must have a precision of 7, 3'
          )
      : z.null().optional(),
    pressureDatumLow: optionalAttributes?.datumDepth
      ? z.coerce
          .number()
          .positive('Low datum pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Low datum pressure must have a precision of 7, 3'
          )
      : z.coerce.number().optional(),
    pressureDatumHigh: optionalAttributes?.datumDepth
      ? z.coerce
          .number()
          .positive('High datum pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'High datum pressure must have a precision of 7, 3'
          )
      : z.null().optional(),
    stratColumnIdentifier: z.string(),
    reservoirZone: z.string(),
    depthUnit: z.string(),
    pressureUnit: z.string(),
    temperatureUnit: z.string(),
    pressureGradientDepthUnit: z.string(),
  });
};

const optionalAttributesSchema = z.object({
  datumDepth: z.boolean(),
  segment: z.boolean(),
  pressureInitial: z.boolean(),
  pressureMin: z.boolean(),
  pressureMax: z.boolean(),
  totalDepthRow: z.boolean(),
  useExistingFieldValues: z.boolean(),
  commingledZones: z.boolean(),
});

// Infer the TypeScript type from the schema
export type OptionalAttributes = z.infer<typeof optionalAttributesSchema>;

export const zoneSelectionSchema = z.object({
  stratColumnIdentifier: z.string().min(1, 'Stratigraphic column is required'),
  optionalAttributes: optionalAttributesSchema,
});

const today = new Date();
today.setHours(0, 0, 0, 0); // Set today to 00:00

export const commentSchema = z
  .object({
    comment: z.string(),
    validFrom: z
      .string()
      .min(1, `'Valid from' date is required`)
      .refine(
        (val) => {
          const validFromDate = new Date(val);
          return validFromDate >= today;
        },
        {
          message: `'Valid from' date must be today or later`,
        }
      ),
    validTo: z
      .string()
      .min(1, `'Valid to' date is required`)
      .refine(
        (val) => {
          const validToDate = new Date(val);
          return validToDate >= today;
        },
        {
          message: `'Valid to' date must be today or later`,
        }
      ),
    mainApprover: z.string().min(1, 'Approver is required'),
    backupApprover: z.string().min(1, 'Backup approver is required'),
  })
  .refine(
    (data) => {
      if (!data.validFrom || !data.validTo) {
        return;
      }

      const validFromDate = new Date(data.validFrom);
      const validToDate = new Date(data.validTo);
      return validFromDate < validToDate;
    },
    {
      message: `'Valid To' date must be later than 'Valid From' date`,
      path: ['validTo'],
    }
  );

export const usePrognosisDetailsSchema = () => {
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const prognosisDetailSchema = createPrognosisDetailSchema(optionalAttributes);
  return z.object({ prognosisDetails: z.array(prognosisDetailSchema) });
};

export type CreationStepFormType = z.infer<typeof createPrognosisSchema>;

export type ZoneSelectionStepFormType = z.infer<typeof zoneSelectionSchema>;

export type EditDetailsStepFormType = z.infer<
  ReturnType<typeof usePrognosisDetailsSchema>
>;

export type CommentsStepFormType = z.infer<typeof commentSchema>;
