import { FC, useMemo } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { AxisScaleOutput } from '@visx/axis';
import { scaleBand, ScaleConfig, scaleLinear } from '@visx/scale';
import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedGrid,
  DataContext,
  XYChart,
} from '@visx/xychart';

import { PrognosisDetailDto } from 'src/api';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { useViewPrognosis } from 'src/pages/ViewPrognosis/hooks/useViewPrognosis';

import { RectGlyph } from 'components/Charts/XYChart/Glyphs/RectGlyph';
import { Legend } from 'components/Charts/XYChart/Legend/Legend';
import { XyTooltip } from 'components/Charts/XYChart/Tooltip/XyTooltip';
import { ChartProps } from 'components/Charts/XYChart/Utils/Chart.types';
import {
  getDepthDomain,
  getNameDomain,
  getPressureDomain,
} from 'components/Charts/XYChart/Utils/Chart.utils';

export interface AddedPrognosisDto extends PrognosisDetailDto {
  high: number;
  low: number;
  expected: number;
  initial: number;
  depth: number;
}

export const Chart: FC<ChartProps> = ({
  width,
  height,
  data,
  useDatumData,
}) => {
  const { contextRowSelection } = useViewPrognosis();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const selectedRow = Number(Object.keys(contextRowSelection)[0]); // It will always only be one row

  const filteredData: AddedPrognosisDto[] = (() => {
    if (useDatumData) {
      return data.map((d) => ({
        ...d,
        high: d.pressureDatumHigh ?? 0,
        low: d.pressureDatumLow ?? 0,
        expected: d.pressureDatumExpected ?? 0,
        initial: d.pressureInitial ?? 0,
        depth: d.datumDepth ?? 0,
      }));
    } else {
      return data.map((d) => ({
        ...d,
        high: d.pressureHigh ?? 0,
        low: d.pressureLow ?? 0,
        expected: d.pressureExpected ?? 0,
        initial: d.pressureInitial ?? 0,
        depth: d.referenceDepth ?? 0,
      }));
    }
  })();

  const sortedData = filteredData.sort((a, b) => a?.depth - b?.depth);

  const graphColors = data
    .map((d) => d.colorHtml)
    .filter((color): color is string => color !== undefined);

  const XScale = useMemo(() => {
    return scaleLinear({
      range: [0, width],
      domain: getPressureDomain(sortedData),
      zero: false,
      clamp: true,
    });
  }, [width, sortedData]);

  const YScale = useMemo(() => {
    if (useDatumData) {
      return scaleBand<string>({
        domain: getNameDomain(sortedData),
        padding: 0.2,
      });
    }

    return scaleLinear({
      range: [height, 0],
      domain: getDepthDomain(sortedData),
      zero: false,
      clamp: true,
    });
  }, [sortedData, height, useDatumData]);

  const InnerScaleX = ():
    | ScaleConfig<AxisScaleOutput, string, string>
    | undefined => {
    if (useDatumData) {
      return {
        type: 'linear',
        domain: getPressureDomain(sortedData),
        zero: false,
        clamp: true,
      };
    }
    return {
      type: 'linear',
      domain: getPressureDomain(sortedData),
      zero: false,
      clamp: true,
    };
  };

  const InnerScaleY = ():
    | ScaleConfig<AxisScaleOutput, string, string>
    | undefined => {
    if (useDatumData) {
      return {
        type: 'band',
        domain: getNameDomain(sortedData),
        padding: 0.2,
      };
    }
    return {
      type: 'linear',
      domain: getDepthDomain(sortedData),
      zero: false,
      clamp: true,
    };
  };

  return (
    <DataContext.Provider
      value={{
        xScale: XScale,
        yScale: YScale,
      }}
    >
      <XYChart
        height={height}
        width={width}
        xScale={InnerScaleX()}
        yScale={InnerScaleY()}
        margin={{ top: 0, right: 0, bottom: 80, left: useDatumData ? 100 : 0 }}
        horizontal={true}
      >
        <AnimatedGrid
          columns={false}
          numTicks={6}
          stroke={colors.ui.background__medium.hex}
        />

        <AnimatedAxis
          orientation="bottom"
          top={height - 40}
          stroke={colors.ui.background__medium.hex}
          tickStroke={colors.ui.background__medium.hex}
          hideZero
          numTicks={5}
        />
        <AnimatedAxis
          orientation="left"
          hideZero
          hideAxisLine
          left={useDatumData ? 95 : 45}
          top={useDatumData ? 0 : 14}
          numTicks={5}
          hideTicks
        />
        <AnimatedGlyphSeries
          dataKey="highlow"
          enableEvents={false}
          data={sortedData}
          yAccessor={(d) => (useDatumData ? d.lithostratSubzone : d.depth)}
          xAccessor={(d) => (useDatumData ? d.low : d.low)}
          renderGlyph={(glyphProps) => {
            const isSelected = selectedRow === glyphProps.datum.id;
            return (
              <RectGlyph
                datum={glyphProps.datum}
                color={glyphProps.datum?.colorHtml ?? undefined}
                y={isSelected ? -6 : -3}
                x={isSelected ? -6 : -3}
                selectedZone={selectedRow}
                vertical={false}
              />
            );
          }}
          size={10}
        />

        <AnimatedGlyphSeries
          dataKey="expected"
          enableEvents
          data={sortedData}
          yAccessor={(d) => (useDatumData ? d.lithostratSubzone : d?.depth)}
          xAccessor={(d) => (useDatumData ? d.expected : d?.expected)}
          renderGlyph={(glyphProps) => {
            const isSelected = selectedRow === glyphProps.datum.id;
            return (
              <g>
                <circle
                  cx={glyphProps.x}
                  cy={glyphProps.y}
                  r={isSelected ? 7 : 5}
                  fill="black"
                  opacity="0.4"
                />
                <circle
                  cx={glyphProps.x}
                  cy={glyphProps.y}
                  r={isSelected ? 3 : 2}
                  stroke="white"
                  fill="white"
                />
              </g>
            );
          }}
        />

        {optionalAttributes?.pressureInitial && (
          <AnimatedGlyphSeries
            enableEvents={false}
            dataKey="initial"
            data={sortedData.filter((d) => d.initial !== 0)}
            yAccessor={(d) => (useDatumData ? d.lithostratSubzone : d?.depth)}
            xAccessor={(d) => (useDatumData ? d?.initial : d?.initial)}
            colorAccessor={() => colors.text.static_icons__default.hex}
          />
        )}

        <XyTooltip verticalCrossBar={false} />
      </XYChart>
      <Legend graphColors={graphColors} />
    </DataContext.Provider>
  );
};
