import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { RowSelectionState } from '@tanstack/react-table';

export interface ViewPrognosisContextType {
  contextRowSelection: RowSelectionState;
  setContextRowSelection: (rows: RowSelectionState) => void;
  hasUpdatedCell: boolean;
  setHasUpdatedCell: Dispatch<SetStateAction<boolean>>;
}

export const ViewPrognosisContext = createContext<
  ViewPrognosisContextType | undefined
>(undefined);

interface ViewPrognosisProviderProps {
  children: ReactNode;
}

export const ViewPrognosisProvider: FC<ViewPrognosisProviderProps> = ({
  children,
}) => {
  const [contextRowSelection, setContextRowSelection] =
    useState<RowSelectionState>({});
  const [hasUpdatedCell, setHasUpdatedCell] = useState(false);

  return (
    <ViewPrognosisContext.Provider
      value={{
        contextRowSelection,
        setContextRowSelection,
        hasUpdatedCell,
        setHasUpdatedCell,
      }}
    >
      {children}
    </ViewPrognosisContext.Provider>
  );
};
