import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisCreateDto, PrognosisService } from 'src/api';
import { PROGNOSIS_KEY } from 'src/constants';
import { StatusIdEnum } from 'src/types';

import { useGetCiWellTargetsByField } from 'hooks/useGetCiTargets';
import { useGetPrognosis } from 'hooks/useGetPrognosis';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';

export const useCopyPrognosis = () => {
  const { data: prognosisDetailsData } = useGetPrognosisDetails();
  const { data: prognosisData } = useGetPrognosis();
  const queryClient = useQueryClient();
  const { data: ciWellTargets } = useGetCiWellTargetsByField();

  const { showSnackbar } = useSnackbar();

  const wellTarget = ciWellTargets?.find(
    (target) => target.targetUuid === prognosisData?.ciTargetUuid
  );

  const title: string = prognosisData?.wellboreIdentifier
    ? prognosisData.wellboreIdentifier
    : (wellTarget?.targetName ?? '');

  const body: PrognosisCreateDto = {
    ...prognosisData,
    statusId: StatusIdEnum.DRAFT,
    approveUser: undefined,
    title: title,
  };

  return useMutation({
    mutationFn: async () => {
      if (!prognosisDetailsData || !prognosisData) {
        throw new Error('Prognosis data is not available');
      }

      const newId = await PrognosisService.postApiV1Prognoses({
        ...body,
      });

      await queryClient.setQueryData([PROGNOSIS_KEY, newId], body);

      return newId;
    },
    onError: (err) => {
      showSnackbar('Failed to copy prognosis' + err.message);
    },
  });
};
