import { FC } from 'react';
import { useNavigate } from 'react-router';

import {
  amplify_wireline,
  Button,
  Icon,
  spacings,
  TopBar,
  Typography,
} from '@equinor/amplify-component-lib';
import { SettingsProps } from '@equinor/amplify-component-lib/dist/organisms/TopBar';
import { chevron_right } from '@equinor/eds-icons';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import {
  FIELD_VALUES_GENERAL_FEATURE_KEY,
  TOGGLE_APPROVER_OPTIONS,
} from 'src/constants';
import { useApp } from 'src/hooks';
import { ViewPrognosesOptions } from 'src/utils/prognosisDashboard';

import { useGetUserAccess } from 'hooks/useGetUserAccess';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: ${spacings.medium};
`;

const ButtonElement = styled(Button)`
  span {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
  }
  padding: 0;
`;

export const Settings: FC = () => {
  const { draftGroup, setDraftGroup, formattedFieldNameForUrl } = useApp();
  const { userAccessForField } = useGetUserAccess();
  const { showContent } = useFeatureToggling(TOGGLE_APPROVER_OPTIONS);
  const { showContent: showFieldValues } = useFeatureToggling(
    FIELD_VALUES_GENERAL_FEATURE_KEY
  );

  const navigate = useNavigate();

  const settingsOptions: SettingsProps = {
    allSettings: [],
  };

  if (userAccessForField?.userRoles?.approver && showContent) {
    (settingsOptions.allSettings ?? []).push({
      title: 'Approver view options',
      value: draftGroup,
      onChange: (val: string) => setDraftGroup(val as ViewPrognosesOptions),
      items: [
        {
          label: 'Prognoses submitted to me',
          name: 'draft-group',
          value: ViewPrognosesOptions.SUBMITTED_TO_ME,
        },
        {
          label: 'All prognoses assigned to me',
          name: 'draft-group',
          value: ViewPrognosesOptions.ASSIGNED_TO_ME,
        },
        {
          label: 'All prognoses on the field',
          name: 'draft-group',
          value: ViewPrognosesOptions.ALL_PROGNOSES,
        },
      ],
    });
  }

  return (
    <>
      <TopBar.Settings allSettings={settingsOptions.allSettings}>
        {showFieldValues && (
          <Wrapper>
            <Typography group="paragraph" variant="meta">
              FIELD ADMINISTRATOR
            </Typography>
            <ButtonElement
              variant="ghost"
              onClick={() =>
                navigate(`${formattedFieldNameForUrl}/prognosis/field-values`)
              }
            >
              <Icon data={amplify_wireline} />
              <Typography group="navigation" variant="menu_title">
                Field values
              </Typography>
              <Icon data={chevron_right} />
            </ButtonElement>
          </Wrapper>
        )}
      </TopBar.Settings>
    </>
  );
};
