/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PrognosisSubscriptionService {
  /**
   * Subscribe to prognosis email notifications
   * @param prognosisId
   * @param email
   * @returns void
   * @throws ApiError
   */
  public static postApiV1PrognosisSubscription(
    prognosisId: number,
    email: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/PrognosisSubscription',
      query: {
        prognosisId: prognosisId,
        email: email,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Unsubscribe from prognosis email notifications
   * @param prognosisId
   * @param email
   * @returns void
   * @throws ApiError
   */
  public static deleteApiV1PrognosisSubscription(
    prognosisId: number,
    email: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/PrognosisSubscription',
      query: {
        prognosisId: prognosisId,
        email: email,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
