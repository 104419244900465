import { spacings, TextField } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

export const TextFieldContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacings.medium};
`;

export const WideTextField = styled(TextField)`
  grid-column: span 2;
`;

export const FullTextField = styled(TextField)`
  grid-column: span 1;
`;
