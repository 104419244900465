import { FC, useState } from 'react';

import {
  Banner,
  Button,
  colors,
  Icon,
  Typography,
} from '@equinor/amplify-component-lib';
import { add_box } from '@equinor/eds-icons';

import { FlexContainer } from '../FieldValues.styles';
import { useGetFieldValues } from '../hooks/useFieldValues';
import { FormInitialiser } from './FormInitialiser';
import { ValuesView } from './ValuesView';
import { FieldValues } from 'src/api';

import { BorderedSection } from 'components/BorderedSection/BorderedSection';
import { useGetUserAccess } from 'hooks/useGetUserAccess';
import styled from 'styled-components';

const MinButton = styled(Button)`
  width: max-content;
`;

export const PrognosisValues: FC = () => {
  const { data: values } = useGetFieldValues();
  const [open, setOpen] = useState<boolean>(false);
  const { userAccessForField } = useGetUserAccess();

  const [currentEditValues, setCurrentEditValues] = useState<FieldValues>();
  const isApprover = userAccessForField?.userRoles?.approver;

  const setCurrentValuesById = (id: number) => {
    const currentValues: FieldValues | undefined = values?.find(
      (v) => v.id === id
    );
    setCurrentEditValues(currentValues);
    setOpen(true);
  };

  const handleOpenNew = () => {
    setCurrentEditValues(undefined);
    setOpen(true);
  };

  return (
    <BorderedSection title="Field values">
      <FlexContainer>
        <Typography
          variant="text"
          group="input"
          color={colors.text.static_icons__secondary.hex}
        >
          Create automated data sets, to support
        </Typography>

        <Banner variant="info">
          <Typography variant="text" group="input">
            Please add the relevant values for your field. This will be used for
            automatic calculations related to the reservoir pressure prognosis.
            This feature is coming soon.
          </Typography>
        </Banner>

        {isApprover && (
          <MinButton
            variant="contained"
            color="primary"
            onClick={handleOpenNew}
          >
            Add a new data set
            <Icon data={add_box} size={24} />
          </MinButton>
        )}
        <ValuesView setEditId={setCurrentValuesById} />
        {open && (
          <FormInitialiser
            editValues={currentEditValues}
            open={open}
            setOpen={setOpen}
          />
        )}
      </FlexContainer>
    </BorderedSection>
  );
};
