import { Button, colors, Icon, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const SpaceBetweenWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
`;

export const IndefiniteIconInText = styled(Icon)`
  padding: 0 ${spacings.small};
  margin-bottom: -8px;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacings.medium_small};
  > div {
    width: 100%;
  }
`;

interface IndefiniteButtonProps {
  $isIndefinite: boolean;
}

export const IndefiniteButton = styled(Button)<IndefiniteButtonProps>`
  align-self: flex-end;
  flex-shrink: 0;
  svg {
    fill: ${({ $isIndefinite }) =>
      $isIndefinite
        ? colors.interactive.primary__resting.rgba
        : colors.text.static_icons__tertiary.rgba};
  }
`;

export const RuleOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  justify-content: space-between;
  gap: 16px;
`;

export const AddNewCriteriaButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const DeleteRuleWrapper = styled.div`
  position: absolute;
  margin-right: -21px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const DeleteRuleButton = styled(Button)`
  background-color: #ffffff;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.14),
    0 3px 4px rgba(0, 0, 0, 0.12);
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  gap: 24px;
  max-height: 700px;
  padding: 24px;
  overflow-y: auto;
`;
export const AddNewRuleButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  margin: ${spacings.large} 0;
`;
export const AddNewRuleButton = styled(Button)`
  width: 148px;
  background: #ffffff;
`;
export const Divider = styled.div`
  margin-top: -18px;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
`;
