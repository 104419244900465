import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { PrognosisService } from 'src/api';
import { useApp } from 'src/hooks';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const useConvertToDraftPrognosis = () => {
  const { formattedFieldNameForUrl } = useApp();
  const usingId = usePrognosisIDFromParams();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: () => {
      return PrognosisService.patchApiV1PrognosesReturnToDraft(usingId);
    },
    onSuccess: () => {
      navigate(`${formattedFieldNameForUrl}/prognosis/edit/${usingId}`);
    },
  });
};
