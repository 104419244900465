import { useCallback, useState } from 'react';

import { artificialLoad } from 'src/utils/delay';

export const useArtificialLoading = (
  delayTime = 100
): [boolean, () => Promise<void>] => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = useCallback(async () => {
    await artificialLoad(delayTime, setIsLoading);
  }, [delayTime]);

  return [isLoading, startLoading];
};
