import { FC } from 'react';

import { DatePicker } from '@equinor/amplify-component-lib';

import { DateRangeOptions } from './DateRangeOptions';
import { johanSverdrupStartDateUnix } from 'src/constants';
import { useMeasuredData } from 'src/hooks';
import { getDateForDaysIntoPast } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 1rem;
  > div {
    width: 100%;
  }
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const DateRangeMenu: FC = () => {
  const { plotDateRangeOptions, handleSetPlotDateRangeOptions } =
    useMeasuredData();

  const handleFromOnChange = (date: Date | null) => {
    if (!date) return;
    handleSetPlotDateRangeOptions({
      ...plotDateRangeOptions,
      fromDate: date,
    });
  };

  const handleToOnChange = (date: Date | null) => {
    if (!date) return;
    handleSetPlotDateRangeOptions({
      ...plotDateRangeOptions,
      toDate: date,
    });
  };

  return (
    <Wrapper>
      <DatePickerWrapper>
        <DatePicker
          value={plotDateRangeOptions.fromDate}
          label="From date"
          onChange={handleFromOnChange}
          locale="en-GB"
          formatOptions={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
          hideClearButton
          // Set the min to the first year of the data we are using for johan sverdrup
          // TODO: This needs to be made dynamic based on field
          minValue={new Date(johanSverdrupStartDateUnix)}
          maxValue={plotDateRangeOptions.toDate}
        />
        <DatePicker
          value={plotDateRangeOptions.toDate}
          label="To date"
          onChange={handleToOnChange}
          locale="en-GB"
          formatOptions={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
          hideClearButton
          // Set the min to the first year of the data we are using for johan sverdrup
          // TODO: This needs to be made dynamic based on field
          minValue={plotDateRangeOptions.fromDate}
          maxValue={getDateForDaysIntoPast(1)}
        />
      </DatePickerWrapper>
      <DateRangeOptions />
    </Wrapper>
  );
};
