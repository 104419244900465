import { FC, useMemo } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { scaleBand, scaleLinear } from '@visx/scale';
import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedGrid,
  AnimatedLineSeries,
  DataContext,
  XYChart,
} from '@visx/xychart';

import { ErrorMessage } from '../Chart.styles';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { addIndexHoursToDate, formatTick } from 'src/utils';

import { RectGlyph } from 'components/Charts/XYChart/Glyphs/RectGlyph';
import { Legend } from 'components/Charts/XYChart/Legend/Legend';
import { XyTooltip } from 'components/Charts/XYChart/Tooltip/XyTooltip';
import { AddedPrognosisDto } from 'components/Charts/XYChart/Utils/Chart.types';
import { getPressureDomain } from 'components/Charts/XYChart/Utils/Chart.utils';
import { useApp } from 'hooks/useApp';

interface ChartProps {
  width: number;
  height: number;
  data: AddedPrognosisDto[];
  selectedZone: number;
  useDatumData?: boolean;
}

export const Chart: FC<ChartProps> = ({
  data,
  width,
  height,
  selectedZone,
  useDatumData,
}) => {
  const { unit } = useApp();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const graphColors = data
    .map((d) => d.colorHtml)
    .filter((color): color is string => color !== undefined);

  const filteredData: AddedPrognosisDto[] = (() => {
    if (useDatumData) {
      return data
        .filter(
          (d) => d.approveDate !== null && d.pressureDatumExpected !== null
        )
        .map((d, index) => ({
          ...d,
          high: d.pressureDatumHigh ?? 0,
          low: d.pressureDatumLow ?? 0,
          expected: d.pressureDatumExpected ?? 0,
          initial: d.pressureInitial ?? 0,
          depth: d.datumDepth ?? 0,
          approveDate: addIndexHoursToDate(
            d?.approveDate ?? '',
            index
          ).toString(),
        }));
    } else {
      return data
        .filter((d) => d.approveDate !== null)
        .map((d, index) => ({
          ...d,
          high: d.pressureHigh ?? 0,
          low: d.pressureLow ?? 0,
          expected: d.pressureExpected ?? 0,
          initial: d.pressureInitial ?? 0,
          depth: d.referenceDepth ?? 0,
          approveDate: addIndexHoursToDate(
            d?.approveDate ?? '',
            index
          ).toString(),
        }));
    }
  })();

  const XScale = useMemo(() => {
    return scaleBand({
      range: [0, width],
      domain: filteredData.map((d) => {
        return d.approveDate!;
      }),
    });
  }, [filteredData, width]);

  const YScale = useMemo(() => {
    return scaleLinear({
      range: [height, 0],
      domain: getPressureDomain(filteredData),
      clamp: true,
      zero: false,
    });
  }, [filteredData, height]);

  if (filteredData.length === 0) {
    return <ErrorMessage>No data</ErrorMessage>;
  }

  return (
    <DataContext.Provider
      value={{
        xScale: XScale,
        yScale: YScale,
      }}
    >
      <XYChart
        height={height}
        width={width}
        xScale={{
          type: 'band',
          domain: filteredData.map((d) => `${d.approveDate!}`),
        }}
        yScale={{
          type: 'linear',
          domain: getPressureDomain(filteredData),
          zero: false,
          clamp: true,
        }}
        margin={{ top: 0, right: 0, bottom: 80, left: 0 }}
      >
        <AnimatedGrid
          columns={false}
          numTicks={5}
          stroke={colors.ui.background__medium.hex}
        />
        <AnimatedAxis
          orientation="bottom"
          top={height - 40}
          label="Date"
          tickFormat={formatTick}
          stroke={colors.ui.background__medium.hex}
          tickStroke={colors.ui.background__medium.hex}
        />
        <AnimatedAxis
          orientation="left"
          hideTicks
          hideAxisLine
          label={`Pressure (${unit})`}
          left={30}
          top={14}
          numTicks={5}
          labelOffset={28}
        />

        <AnimatedGlyphSeries
          dataKey="highlow"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.high}
          renderGlyph={(glyphProps) => {
            const isSelected = selectedZone === glyphProps.datum.id;
            return (
              <RectGlyph
                datum={glyphProps.datum}
                x={glyphProps.x - (isSelected ? 6 : 3)}
                y={glyphProps.y}
                color={glyphProps.datum?.colorHtml ?? undefined}
                selectedZone={selectedZone}
                vertical
              />
            );
          }}
        />

        <AnimatedLineSeries
          strokeDasharray={'4,4'}
          dataKey="expected"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.initial}
          colorAccessor={() => colors.ui.background__scrim.hex}
        />

        <AnimatedLineSeries
          dataKey="initial"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.initial}
          stroke={colors.ui.background__scrim.hex}
          strokeOpacity={0.4}
        />

        <AnimatedGlyphSeries
          dataKey="expected"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.expected}
          renderGlyph={(glyphProps) => {
            const isSelected = selectedZone === glyphProps.datum.id;
            return (
              <g>
                <circle
                  cx={glyphProps.x}
                  cy={glyphProps.y}
                  r={isSelected ? 7 : 5}
                  fill="black"
                  opacity="0.4"
                />
                <circle
                  cx={glyphProps.x}
                  cy={glyphProps.y}
                  r={isSelected ? 3 : 2}
                  stroke="white"
                  fill="white"
                />
              </g>
            );
          }}
        />

        {optionalAttributes?.pressureInitial && (
          <AnimatedGlyphSeries
            dataKey="initial"
            data={filteredData.filter((d) => d.initial !== 0)}
            xAccessor={(d) => d.approveDate}
            yAccessor={(d) => d.initial}
            size={10}
            renderGlyph={({ x, y }) => (
              <g>
                <circle
                  cx={x}
                  cy={y}
                  r={3}
                  fill={colors.text.static_icons__default.hex}
                  strokeOpacity={0.4}
                  strokeWidth={4}
                />
              </g>
            )}
          />
        )}
        <XyTooltip useDateAsTitle verticalCrossBar />
      </XYChart>
      <Legend graphColors={graphColors} />
    </DataContext.Provider>
  );
};
