import { SkeletonBase, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { shape } = tokens;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const TitleSkeleton = styled(SkeletonBase)`
  height: 40px;
  width: 185px;
  border-radius: ${shape.corners.borderRadius};
`;

export const LeftGroup = styled.div`
  display: flex;
  gap: ${spacings.small};
  align-items: center;
`;
