import { useCallback, useMemo } from 'react';

import { PolygonLayer } from '@deck.gl/layers';
import { Theme, useThemeProvider } from '@equinor/amplify-component-lib';

import { PolygonFeature } from 'src/api';
import { useGetFieldOutlineFeatureCollection } from 'src/pages/MapView/components/Map/hooks/useGetFieldOutlineFeatureCollection';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground, MapLayer } from 'src/pages/MapView/MapView.types';

interface FieldOutlineFeature {
  feature: PolygonFeature;
  isInsideMainOutline: boolean;
}

export function useFieldOutline() {
  const { filteredOutlineFeatures } = useGetFieldOutlineFeatureCollection();
  const { backgroundLayer } = useMapOptions();
  const { theme } = useThemeProvider();

  const handleGetFillColor = useCallback(
    (d: FieldOutlineFeature): [number, number, number] => {
      if (d.isInsideMainOutline) {
        return theme === Theme.LIGHT ? [220, 220, 220] : [44, 64, 79]; // ui.background.medium
      } else {
        return theme === Theme.LIGHT ? [255, 255, 255] : [19, 38, 52]; // ui.background.default
      }
    },
    [theme]
  );

  return useMemo(
    () =>
      new PolygonLayer({
        id: MapLayer.FIELD_OUTLINE,
        filled: backgroundLayer !== MapBackground.INTERPOLATED,
        getFillColor: handleGetFillColor,
        data: filteredOutlineFeatures,
        getPolygon: (data: FieldOutlineFeature) =>
          data.feature.geometry?.coordinates,
        getLineColor: [111, 111, 111], // Equinor text - static icons tertiary
        getLineWidth: 1,
        lineWidthMinPixels: 2,
        updateTriggers: { getFillColor: [handleGetFillColor] },
      }),
    [backgroundLayer, filteredOutlineFeatures, handleGetFillColor]
  );
}
