import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, useAuth } from '@equinor/amplify-component-lib';
import { chevron_right, notifications } from '@equinor/eds-icons';
import { tokens } from '@equinor/eds-tokens';
import { useQueryClient } from '@tanstack/react-query';

import { RelatedPrognosis } from './RelatedPrognosis/RelatedPrognosis';
import { ValidityDate } from './ValidityDate/ValidityDate';
import { ApproveValue } from './ApproveValue';
import {
  CardContent,
  CardWrapper,
  StyledTypography,
  TitleContainer,
  TitleRow,
} from './PrognosisCard.styles';
import { PrognosisValues } from './PrognosisValues';
import { PrognosisDto, PrognosisService } from 'src/api';
import { PROGNOSIS_KEY } from 'src/constants';
import { ONE_MINUTE } from 'src/constants/staleTime';
import { TabType } from 'src/constants/tab';
import { StatusIdEnum } from 'src/types/status';
import { getUsernameFromEmail } from 'src/utils';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';
import { selectStrokeColor } from 'src/utils/prognosis';

import { StatusChip } from 'components/BaseChip/StatusChip/StatusChip';

const { colors } = tokens;

interface PrognosisCardProps extends PrognosisDto {
  currentTab: TabType;
  relatedPrognoses?: PrognosisDto[];
}

export const PrognosisCard: FC<PrognosisCardProps> = ({
  currentTab,
  approveUser,
  mainApprover,
  backupApprover,
  createUser,
  id,
  operation,
  operationPhase,
  statusId,
  relatedPrognoses,
  wellboreIdentifier,
  validFromDate,
  validToDate,
  isSubscribed,
  currentStep,
  ciTargetName,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { account } = useAuth();

  const strokeColor = selectStrokeColor({
    statusId: statusId,
    validFromDate: validFromDate ?? '',
  });

  const formattedOperation = lookupValueToComboBoxRequired(
    operation,
    true
  ).label;

  const isValid: boolean | undefined =
    validFromDate &&
    validToDate &&
    statusId === Number(StatusIdEnum.APPROVED) &&
    new Date().getTime() >= new Date(validFromDate).getTime() &&
    new Date().getTime() <= new Date(validToDate).getTime()
      ? true
      : undefined;

  const userShortName = getUsernameFromEmail(account?.username);

  const handleOnClick = () => {
    if (id != undefined) {
      const path =
        statusId === Number(StatusIdEnum.DRAFT) && createUser === userShortName
          ? `edit/${id}/${currentStep}`
          : `view/${id}`;
      navigate(path);
    }
  };

  const prefetch = () => {
    // prefetch data

    if (id == undefined) return;
    queryClient.prefetchQuery({
      queryKey: [PROGNOSIS_KEY, id],
      queryFn: () => PrognosisService.getApiV1Prognoses1(id),
      staleTime: ONE_MINUTE,
    });
  };

  return (
    <CardWrapper $statusColor={strokeColor}>
      <CardContent
        onClick={handleOnClick}
        $approvedTab={currentTab === TabType.APPROVED}
        onMouseEnter={prefetch}
      >
        <TitleContainer>
          <div>
            <StyledTypography variant="h4">
              {wellboreIdentifier ?? ciTargetName ?? 'N/A'}
            </StyledTypography>
            <TitleRow>
              <StatusChip statusId={statusId} />
              <StyledTypography variant="body_long_bold">
                {operationPhase}
              </StyledTypography>
            </TitleRow>
          </div>
          {isSubscribed && (
            <Icon
              size={16}
              data={notifications}
              color={colors.interactive.primary__resting.hex}
            />
          )}
        </TitleContainer>
        <ValidityDate
          isValid={isValid}
          statusId={statusId}
          validFromDate={validFromDate}
          validToDate={validToDate}
        />
        <PrognosisValues label={formattedOperation} />
        <PrognosisValues label={createUser} isShortName />
        <ApproveValue
          approver={approveUser}
          mainApprover={mainApprover}
          backupApprover={backupApprover}
          currentTab={currentTab}
        />
        <Icon
          data={chevron_right}
          color={colors.interactive.primary__resting.rgba}
        />
      </CardContent>
      {currentTab === TabType.APPROVED &&
        relatedPrognoses?.map((relatedPrognoses) => (
          <RelatedPrognosis
            key={relatedPrognoses.id}
            currentTab={currentTab}
            {...relatedPrognoses}
          />
        ))}
    </CardWrapper>
  );
};
