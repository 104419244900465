import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCommentStepForm } from '../form/useCommentStepForm';
import { PrognosisDto, PrognosisPatchDto, PrognosisService } from 'src/api';
import { GET_IMAGE, PROGNOSIS_KEY } from 'src/constants';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePutValidityForm = () => {
  const { reset, watch } = useCommentStepForm();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const usingId = usePrognosisIDFromParams();
  const { validFrom, validTo, mainApprover, backupApprover, comment } = watch();

  const body: PrognosisPatchDto = {
    validFromDate: validFrom,
    validToDate: validTo,
    mainApprover: mainApprover,
    backupApprover: backupApprover,
    comment: comment,
  };

  return useMutation({
    mutationFn: async () => {
      return await PrognosisService.patchApiV1Prognoses(usingId, body);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_KEY, usingId],
      });

      const previousPrognosis: PrognosisDto | undefined =
        queryClient.getQueryData([PROGNOSIS_KEY, usingId]);

      queryClient.setQueryData([PROGNOSIS_KEY, usingId], {
        ...previousPrognosis,
        ...body,
      } as PrognosisDto);

      return { previousPrognosis };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_KEY, usingId],
        context?.previousPrognosis ?? []
      );
      showSnackbar('Failed to update prognosis:' + err.message);
      reset();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_KEY, usingId],
      });
      // fetches images from blob storage
      queryClient.invalidateQueries({
        queryKey: [GET_IMAGE, usingId],
      });
    },
  });
};
