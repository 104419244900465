 
import { FC } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';

import {
  Banner,
  Checkbox,
  colors,
  Dialog,
  Divider,
  SingleSelect,
  Typography,
} from '@equinor/amplify-component-lib';

import { usePostFieldValues, usePutFieldValues } from '../hooks/useFieldValues';
import { InputElements } from './InputElements';
import {
  DoubleBanner,
  FormHeaderGrid,
  PaddingWrap,
} from './PrognosisValues.styles';
import { FieldValues } from './PrognosisValues.utils';
import { FieldValues as BackendFieldValues, StratigraphicUnit } from 'src/api';

import { useApp } from 'hooks/useApp';
import {
  useGetAllStratColumns,
  useGetStratigraphicValues,
} from 'hooks/useGetStratigraphicValues';

interface FormProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  editValues?: BackendFieldValues | undefined;
}

export const Form: FC<FormProps> = ({ open, setOpen, editValues }) => {
  const { handleSubmit, control, watch, reset, resetField } =
    useFormContext<FieldValues>();

  const { field } = useApp();

  const {
    mutateAsync: saveFieldValues,
    isPending,
    error,
    reset: resetMutation,
  } = usePostFieldValues();

  const { mutate: updateFieldValues, isPending: isUpdatePending } =
    usePutFieldValues();

  const { data: stratColumns } = useGetAllStratColumns();

  const { stratColumn, isGeneral } = watch();

  const fieldTitle = field?.name;

  const { data: stratigraphicValues, isLoading: fetchingStratUnits } =
    useGetStratigraphicValues(stratColumn?.stratColumnIdentifier ?? '');
  let stratUnits: StratigraphicUnit[] = [];
  if (stratigraphicValues && stratigraphicValues?.length > 0) {
    stratUnits = [...stratigraphicValues[0].stratUnits];
  }

  const handleClearValues = () => reset();
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    if (editValues) {
      updateFieldValues(editValues.id);
    } else {
      await saveFieldValues();
      handleClose();
    }
  };

  return (
    <Dialog
      title={`Field values for ${fieldTitle}`}
      open={open}
      onClose={handleClose}
      width={850}
      withContentPadding={{ vertical: true, horizontal: false }}
      withBorders
      actions={[
        {
          position: 'left',
          onClick: handleClearValues,
          text: 'Clear values',
          variant: 'ghost',
          color: 'danger',
        },
        {
          position: 'right',
          onClick: handleClose,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          position: 'right',
          onClick: handleSubmit(onSubmit),
          disabled: error?.message || isPending || isUpdatePending,
          isLoading: isPending || isUpdatePending,
          text: editValues ? 'Update' : 'Save data',
          color: 'primary',
        },
      ]}
    >
      <form>
        <PaddingWrap>
          <Typography variant="h6">Automated data granularity</Typography>
          <Typography
            group="table"
            variant="cell_text"
            color={colors.text.static_icons__tertiary.hex}
          >
            Define at what level would you like to input the data, select
            general for the data to be the same in all groups.
          </Typography>
          <FormHeaderGrid>
            <Controller
              name="isGeneral"
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    label="General (affected the whole field)"
                    checked={field.value}
                    outlined
                    onChange={(e) => {
                      resetField('stratColumn');
                      resetField('stratUnit');
                      resetMutation();
                      field.onChange(e.target.checked);
                    }}
                  />
                );
              }}
            />
            <div />
            {!isGeneral && (
              <>
                {error && (
                  <DoubleBanner data-spacing-mode="extra-compact">
                    <Banner variant="danger">
                      <Typography group="ui" variant="tooltip">
                        {/* @ts-expect-error - no types value for this */}
                        {error?.body?.userMessage}
                      </Typography>
                    </Banner>
                  </DoubleBanner>
                )}
                <Controller
                  name="stratColumn"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SingleSelect
                        label="Target Stratigraphic column"
                        items={
                          stratColumns?.map((column) => ({
                            label: column.identifier!,
                            value: column.uuid!,
                          })) ?? []
                        }
                        placeholder="Select a column"
                        value={
                          field.value
                            ? {
                                label: field.value?.stratColumnIdentifier || '',
                                value: field.value?.stratColumnUuid || '',
                              }
                            : undefined
                        }
                        clearable={false}
                        onSelect={(value) => {
                          resetField('stratUnit');
                          resetMutation();
                          field.onChange({
                            stratColumnIdentifier: value?.label,
                            stratColumnUuid: value?.value,
                          });
                        }}
                      />
                    );
                  }}
                />
                <Controller
                  name="stratUnit"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SingleSelect
                        label="Target Group"
                        placeholder="Select a group"
                        clearable={false}
                        items={
                          stratUnits?.map((unit) => ({
                            label: unit.stratUnitIdentifier!,
                            value: unit.uuid!,
                          })) ?? []
                        }
                        value={
                          field.value
                            ? {
                                label: field.value?.stratUnitIdentifier || '',
                                value: field.value?.stratUnitUuid || '',
                              }
                            : undefined
                        }
                        loading={fetchingStratUnits}
                        disabled={!stratColumn?.stratColumnIdentifier}
                        onSelect={(value) => {
                          resetMutation();
                          field.onChange({
                            stratUnitIdentifier: value?.label,
                            stratUnitUuid: value?.value,
                          });
                        }}
                      />
                    );
                  }}
                />
              </>
            )}

            <DoubleBanner data-spacing-mode="extra-compact">
              <Banner variant={isGeneral ? 'info' : 'warning'}>
                {isGeneral ? (
                  <Typography group="ui" variant="tooltip">
                    You can set these values as overall and apply exceptions by
                    creating a new data set in an specific group.
                  </Typography>
                ) : (
                  <Typography group="ui" variant="tooltip">
                    These values will override any general values applied in
                    another data set. Keep fields empty to use general values.
                  </Typography>
                )}
              </Banner>
            </DoubleBanner>
          </FormHeaderGrid>
          <Divider />
          <InputElements />
        </PaddingWrap>
      </form>
    </Dialog>
  );
};
