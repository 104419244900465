/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessCheckDto } from '../models/AccessCheckDto';
import type { DataType } from '../models/DataType';
import type { FieldConfig } from '../models/FieldConfig';
import type { FieldConfigDto } from '../models/FieldConfigDto';
import type { MeasurementType } from '../models/MeasurementType';
import type { PressureType } from '../models/PressureType';
import type { SourceTypes } from '../models/SourceTypes';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ConfigService {
  /**
   * @returns DataType Success
   * @throws ApiError
   */
  public static getDataTypes(): CancelablePromise<Array<DataType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Config/datatypes',
    });
  }
  /**
   * @returns SourceTypes Success
   * @throws ApiError
   */
  public static getSourceTypes(): CancelablePromise<Array<SourceTypes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Config/sourcetypes',
    });
  }
  /**
   * @returns PressureType Success
   * @throws ApiError
   */
  public static getPressureTypes(): CancelablePromise<Array<PressureType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Config/pressureTypes',
    });
  }
  /**
   * @returns MeasurementType Success
   * @throws ApiError
   */
  public static getMeasurementTypes(): CancelablePromise<
    Array<MeasurementType>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Config/measurementtypes',
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public static getConfig(): CancelablePromise<Record<string, Array<any>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Config',
    });
  }
  /**
   * Checks what role current user is in, can be Write or Read
   * @returns string Success
   * @throws ApiError
   */
  public static roleCheck(): CancelablePromise<Record<string, string>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Config/role',
    });
  }
  /**
   * Checks what accesses current user has, PDM, SMDA, SSDL
   * @param fieldUuid
   * @returns AccessCheckDto Success
   * @throws ApiError
   */
  public static accessCheck(
    fieldUuid: string
  ): CancelablePromise<Array<AccessCheckDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Config/accessCheck/{fieldUuid}',
      path: {
        fieldUuid: fieldUuid,
      },
    });
  }
  /**
   * Retrieves field config for given fieldId
   * @param fieldUuid
   * @returns FieldConfig Success
   * @throws ApiError
   */
  public static getFieldConfig(
    fieldUuid: string
  ): CancelablePromise<FieldConfig> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Config/fieldConfig/{fieldUuid}',
      path: {
        fieldUuid: fieldUuid,
      },
    });
  }
  /**
   * Update field config for given fieldId
   * @param fieldGuid
   * @param requestBody
   * @returns FieldConfig Success
   * @throws ApiError
   */
  public static updateFieldConfig(
    fieldGuid: string,
    requestBody?: FieldConfigDto
  ): CancelablePromise<FieldConfig> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Config/fieldConfig/{fieldGuid}',
      path: {
        fieldGuid: fieldGuid,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
