import { FC, useMemo } from 'react';

import { colors, OptionalTooltip } from '@equinor/amplify-component-lib';

import { useMapData } from 'src/pages/MapView/hooks';
import { getDateForDaysIntoPast, getDaysBetweenDates } from 'src/utils';

import { styled } from 'styled-components';

interface BlockerProps {
  $width: number;
}

const Blocker = styled.div<BlockerProps>`
  position: absolute;
  right: 0;
  height: 36px;
  background: ${colors.ui.background__scrim.rgba};
  opacity: 0.3;
  z-index: 1000;
  border-radius: 0 18px 18px 0;
  width: ${({ $width }) => `${$width}px`};
`;

const Tooltip = styled(OptionalTooltip)`
  white-space: pre;
`;

interface SliderInputBlockerProps {
  daysBuckets: number[];
}

export const SliderInputBlocker: FC<SliderInputBlockerProps> = ({
  daysBuckets,
}) => {
  const { toRangeDate } = useMapData();

  const blockSliderInputPxValue = useMemo(() => {
    if (getDateForDaysIntoPast(1).getTime() > toRangeDate.getTime()) return;

    const halfBucketSize = (daysBuckets[2] - daysBuckets[1]) / 2;
    const amountOfDays = Math.floor(
      getDaysBetweenDates(getDateForDaysIntoPast(1), toRangeDate)
    );
    return daysBuckets[amountOfDays + 1] - halfBucketSize * 0.5;
  }, [toRangeDate, daysBuckets]);

  if (!blockSliderInputPxValue) return null;

  return (
    <Tooltip title="We currently have no data for the current and future dates">
      <Blocker $width={blockSliderInputPxValue} />
    </Tooltip>
  );
};
