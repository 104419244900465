import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { getSelectOptionRequired } from './CorrectionDialog.utils';
import { EquipmentCollectionDto, WellboreDetailed } from 'src/api';
import {
  DRILLED_WELLS_FEATURES_KEY,
  PRES_DATA_BY_FIELD_FLAT_KEY,
  PRES_DATA_FOR_WELLBORES_FLAT_KEY,
} from 'src/constants';
import { useEquipmentMapping } from 'src/pages/EditMeasuredData/hooks/useEquipmentMapping';
import { EquipmentOption } from 'src/types';

import { useApp } from 'hooks/useApp';

export const useCorrectionDialog = (onClose: () => void) => {
  const queryClient = useQueryClient();
  const { field } = useApp();
  const { wellId, dhpg } = useParams();
  const {
    addEquipment,
    equipmentMappingOptionsForWell,
    equipmentCollectionForCurrentPressurePoint,
    originalValuesForPressurePoint,
  } = useEquipmentMapping();

  const [selectedEquipment, setSelectedEquipment] =
    useState<EquipmentCollectionDto>(
      equipmentCollectionForCurrentPressurePoint
    );

  const gaugeOptions: EquipmentOption[] = useMemo(
    () =>
      equipmentMappingOptionsForWell?.gauges?.map((gauge) => {
        return { ...getSelectOptionRequired(gauge), ...gauge };
      }) ?? [],
    [equipmentMappingOptionsForWell?.gauges]
  );

  const screenPerfOptions: EquipmentOption[] = useMemo(
    () =>
      equipmentMappingOptionsForWell?.screensAndPerforations?.map(
        (screenPerf) => {
          return { ...getSelectOptionRequired(screenPerf), ...screenPerf };
        }
      ) ?? [],
    [equipmentMappingOptionsForWell?.screensAndPerforations]
  );

  const selectedGauge = useMemo(
    () =>
      gaugeOptions.find(
        (option) =>
          option.value === selectedEquipment.gauge?.gaugeMdCorrected?.toString()
      ),
    [gaugeOptions, selectedEquipment.gauge?.gaugeMdCorrected]
  );

  const selectedScreenOrPerforation = useMemo(
    () =>
      screenPerfOptions.find((option) => {
        return (
          option.value ===
          selectedEquipment.screenOrPerforation?.mdCorrected?.toString()
        );
      }),
    [screenPerfOptions, selectedEquipment.screenOrPerforation?.mdCorrected]
  );

  const isGaugeOriginalValue = useMemo(
    () =>
      originalValuesForPressurePoint?.gauge.md ===
      selectedEquipment.gauge?.gaugeMdCorrected,
    [originalValuesForPressurePoint, selectedEquipment]
  );

  const isScreenOrPerfOriginalValue = useMemo(
    () =>
      originalValuesForPressurePoint?.screenOrPerf.md ===
      selectedEquipment.screenOrPerforation?.mdCorrected,
    [originalValuesForPressurePoint, selectedEquipment]
  );

  const hasChangedGauge = useMemo(
    () =>
      selectedGauge?.mdTop !==
      equipmentCollectionForCurrentPressurePoint.gauge?.gaugeMdCorrected,
    [equipmentCollectionForCurrentPressurePoint, selectedGauge]
  );

  const hasChangedScreenPerf = useMemo(
    () =>
      selectedScreenOrPerforation?.mdTop !==
      equipmentCollectionForCurrentPressurePoint.screenOrPerforation
        ?.mdCorrected,
    [equipmentCollectionForCurrentPressurePoint, selectedScreenOrPerforation]
  );

  const handleOnSelectGauge = (option: EquipmentOption | undefined) => {
    if (option) {
      setSelectedEquipment({
        ...selectedEquipment,
        gauge: {
          gaugeMdCorrected: option.mdTop,
          gaugeTvdCorrected: option.tvdTop,
        },
      });
    } else {
      setSelectedEquipment({
        ...selectedEquipment,
        gauge: {
          gaugeMdCorrected: originalValuesForPressurePoint?.gauge.md,
          gaugeTvdCorrected: originalValuesForPressurePoint?.gauge.tvd,
        },
      });
    }
  };

  const handleOnSelectScreenOrPerforation = (
    option: EquipmentOption | undefined
  ) => {
    if (option) {
      setSelectedEquipment({
        ...selectedEquipment,
        screenOrPerforation: {
          gaugeNo: selectedEquipment.screenOrPerforation?.gaugeNo ?? 1,
          mdCorrected: option.mdTop,
          tvdCorrected: option.tvdTop,
          pointTypeCorrected: option.type,
        },
      });
    } else {
      setSelectedEquipment({
        ...selectedEquipment,
        screenOrPerforation: {
          gaugeNo: selectedEquipment.screenOrPerforation?.gaugeNo ?? 1,
          mdCorrected: originalValuesForPressurePoint?.screenOrPerf.md,
          tvdCorrected: originalValuesForPressurePoint?.screenOrPerf.tvd,
          pointTypeCorrected:
            originalValuesForPressurePoint?.screenOrPerf.pointType,
        },
      });
    }
  };

  const handleOnSubmit = async () => {
    if (hasChangedGauge || hasChangedScreenPerf) {
      await addEquipment(selectedEquipment);
      onClose();
      queryClient.setQueryData(
        [DRILLED_WELLS_FEATURES_KEY, field?.uuid],
        (prev: WellboreDetailed[]) => {
          return prev.map((wellbore) => {
            if (wellbore.wellboreUuid === wellId) {
              return {
                ...wellbore,
                gaugeMdFinal: selectedEquipment.gauge?.gaugeMdCorrected,
                gaugeTvdFinal: selectedEquipment.gauge?.gaugeTvdCorrected,
                [`md${dhpg}Final`]:
                  selectedEquipment.screenOrPerforation?.mdCorrected,
                [`tvd${dhpg}Final`]:
                  selectedEquipment.screenOrPerforation?.tvdCorrected,
                dhpg1PointTypeFinal:
                  selectedEquipment.screenOrPerforation?.pointTypeCorrected,
              };
            }
            return wellbore;
          });
        }
      );
      await queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes(PRES_DATA_BY_FIELD_FLAT_KEY) ||
          query.queryKey.includes(PRES_DATA_FOR_WELLBORES_FLAT_KEY),
      });
    }
  };

  return {
    hasChangedGauge,
    hasChangedScreenPerf,
    isGaugeOriginalValue,
    isScreenOrPerfOriginalValue,
    gaugeOptions,
    screenPerfOptions,
    selectedGauge,
    selectedScreenOrPerforation,
    handleOnSelectGauge,
    handleOnSelectScreenOrPerforation,
    handleOnSubmit,
  };
};
