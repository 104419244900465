import { useEffect } from 'react';
import { useLocation } from 'react-router';

import {
  auth,
  environment,
  EnvironmentType,
  setupIcons,
  Template,
  useSideBar,
} from '@equinor/amplify-component-lib';
import { OpenAPI_APP } from '@equinor/subsurface-app-management';

import { OpenAPI } from 'src/api';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';
import { AppRoutes } from 'src/pages/Routes';

import { ApplicationSideBar } from 'components/ApplicationSideBar/ApplicationSideBar';
import { ApplicationTopBar } from 'components/ApplicationTopBar/ApplicationTopBar';
import { AuthorizationError } from 'components/AuthorizationError/AuthorizationError';

const { getToken } = auth;
const { getAppName } = environment;

const CURRENT_ENVIRONMENT = environment.getEnvironmentName(
  import.meta.env.VITE_ENVIRONMENT_NAME
);

if (CURRENT_ENVIRONMENT !== EnvironmentType.PRODUCTION) {
  OpenAPI.HEADERS = { userImpersonation: '' };
}

OpenAPI_APP.TOKEN = getToken;
OpenAPI.TOKEN = getToken;

const PAGES_WITHOUT_SIDEBAR = ['/select-field'];

setupIcons('favicon-light', 'favicon-dark');

export const App = () => {
  const { fields } = useGetUserAccess();
  const { isOpen } = useSideBar();
  const location = useLocation();

  const showSidebar =
    !PAGES_WITHOUT_SIDEBAR.includes(location.pathname) &&
    !PAGES_WITHOUT_SIDEBAR.some((page) => location.pathname.includes(page));

  useEffect(() => {
    document.title = getAppName(import.meta.env.VITE_NAME);
  }, []);

  return (
    <Template>
      <Template.GlobalStyles />
      <ApplicationTopBar />
      <Template.Container>
        {showSidebar && <ApplicationSideBar />}
        <Template.Content $open={isOpen} id="content">
          {fields?.length === 0 ? <AuthorizationError /> : <AppRoutes />}
        </Template.Content>
      </Template.Container>
    </Template>
  );
};
