import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router';

import {
  Button,
  capitalize,
  colors,
  Icon,
  Menu,
  Typography,
} from '@equinor/amplify-component-lib';
import { delete_forever, more_vertical } from '@equinor/eds-icons';

import { Container, LeftGroup, TitleSkeleton } from './Title.styles';
import { useGetPrognosis } from 'src/hooks';

import { DeleteDialog } from 'components/DeleteDialog/DeleteDialog';

export const Title: FC = () => {
  const { data: prognosisData, isLoading } = useGetPrognosis();
  const { prognosisId } = useParams();

  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const onCreatePage = prognosisId === undefined;

  const handleOnClick = () => setOpen((prev) => !prev);
  const handleOnClose = () => setOpen(false);

  const handleOnOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleOnCloseDeleteDialog = () => setOpenDeleteDialog(false);

  if (isLoading) {
    return (
      <Container>
        <TitleSkeleton />
      </Container>
    );
  }

  if (onCreatePage) {
    return (
      <Container>
        <Typography variant="h1" bold>
          Create new prognosis
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <LeftGroup>
          <Typography variant="h1" bold>
            {prognosisData?.title}
          </Typography>
          <Typography variant="h2">
            {capitalize(prognosisData?.operation ?? '').replaceAll('_', ' ')} /{' '}
            {prognosisData?.operationPhase}
          </Typography>
        </LeftGroup>
        {!onCreatePage && (
          <>
            <Button
              ref={anchorRef}
              variant="ghost"
              onClick={handleOnClick}
              aria-label="Options"
            >
              Options
              <Icon data={more_vertical} />
            </Button>
            {open && (
              <Menu
                open
                anchorEl={anchorRef.current}
                placement="bottom-start"
                onClose={handleOnClose}
              >
                <Menu.Section>
                  <Menu.Item onClick={handleOnOpenDeleteDialog}>
                    <Icon
                      data={delete_forever}
                      color={colors.interactive.danger__resting.rgba}
                    />
                    <Typography color={colors.interactive.danger__text.rgba}>
                      Delete this prognosis
                    </Typography>
                  </Menu.Item>
                </Menu.Section>
              </Menu>
            )}
            <DeleteDialog
              open={openDeleteDialog}
              title={prognosisData?.title ?? 'No title found'}
              onClose={handleOnCloseDeleteDialog}
            />
          </>
        )}
      </Container>
    </>
  );
};
