import { FC, useMemo } from 'react';
import { useLocation } from 'react-router';

import { SideBar, SideBarMenuItem } from '@equinor/amplify-component-lib';
import { pressure, timeline, view_list } from '@equinor/eds-icons';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import {
  FIELDS_WITH_MEASURED_DATA,
  SHOW_MEASURED_DATA_ALL_FIELDS,
} from 'src/constants';

import { useApp } from 'hooks/useApp';

export const ApplicationSideBar: FC = () => {
  const { formattedFieldNameForUrl, field } = useApp();
  const location = useLocation();
  const { showContent } = useFeatureToggling(SHOW_MEASURED_DATA_ALL_FIELDS);

  const menuItems: SideBarMenuItem[] = useMemo(() => {
    // TODO: inpress: look into how url naming should be, if it should differentiate between measured data and progonosis data etc
    const items: SideBarMenuItem[] = [
      {
        name: 'Prognosis',
        icon: view_list,
        link: `${formattedFieldNameForUrl}/prognosis`,
      },
    ];
    // TODO: remove the 'FIELDS_WITH_MEASURED_DATA' check when all fields have measured data
    if (showContent || FIELDS_WITH_MEASURED_DATA.includes(field?.name ?? '')) {
      items.unshift(
        {
          name: 'Map',
          icon: pressure,
          link: `${formattedFieldNameForUrl}/measured-data/map`,
        },
        {
          name: 'Plot',
          icon: timeline,
          link: `${formattedFieldNameForUrl}/measured-data/plot`,
        }
      );
    }
    return items;
  }, [field, formattedFieldNameForUrl, showContent]);

  return (
    <SideBar>
      {menuItems.map((m) => (
        <SideBar.Item key={m.name} {...m} currentUrl={location.pathname} />
      ))}
    </SideBar>
  );
};
