import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

export interface EditTableContextType {
  hasUpdatedCell: boolean;
  setHasUpdatedCell: Dispatch<SetStateAction<boolean>>;
}

export const EditTableContext = createContext<EditTableContextType | undefined>(
  undefined
);

interface EditTableProviderProps {
  children: ReactNode;
}

export const EditTableProvider: FC<EditTableProviderProps> = ({ children }) => {
  const [hasUpdatedCell, setHasUpdatedCell] = useState(false);

  return (
    <EditTableContext.Provider
      value={{
        hasUpdatedCell,
        setHasUpdatedCell,
      }}
    >
      {children}
    </EditTableContext.Provider>
  );
};
