import { FC } from 'react';

import { Button, colors, Typography } from '@equinor/amplify-component-lib';
import { DotProgress as ACLDotProgress } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

interface SavingProps {
  $isSaving?: boolean;
}

const DotProgress = styled(ACLDotProgress)<SavingProps>`
  position: absolute;
  opacity: ${({ $isSaving }) => ($isSaving ? 1 : 0)};
`;

const ButtonText = styled(Typography)<SavingProps>`
  opacity: ${({ $isSaving }) => ($isSaving ? 0 : 1)};
  color: inherit;
`;

const StyledButton = styled(Button)<SavingProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ $isSaving }) => ($isSaving ? 'none' : 'auto')};

  &:hover {
    background-color: ${({ $isSaving }) =>
      $isSaving ? 'inherit' : `${colors.interactive.primary__hover.rgba}`};
  }
`;

interface SaveButtonProps {
  onClick: () => void;
  label: string;
  isSaving: boolean;
  isInitializing: boolean;
}

export const SaveButton: FC<SaveButtonProps> = ({
  onClick,
  label,
  isSaving,
  isInitializing,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      $isSaving={isSaving}
      disabled={isInitializing}
    >
      <DotProgress $isSaving={isSaving} />
      <ButtonText $isSaving={isSaving} variant="button" group="navigation">
        {label}
      </ButtonText>
    </StyledButton>
  );
};
