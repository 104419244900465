import { useQuery } from '@tanstack/react-query';

import { useMeasuredData } from './useMeasuredData';
import { HelpLineService } from 'src/api';
import { MEASURED_DATA_HELPLINES_KEY } from 'src/constants';
import { TWELVE_HOURS } from 'src/constants/staleTime';
import { useApp } from 'src/hooks/useApp';
import { HelpLineType } from 'src/types';

export const useGetMeasuredDataHelpLines = (
  selectedHelpLines: HelpLineType[],
  helplinesDialogOpen: boolean
) => {
  const { field } = useApp();
  const { plotDateRangeOptions } = useMeasuredData();
  return useQuery({
    queryKey: [
      MEASURED_DATA_HELPLINES_KEY,
      field?.uuid,
      plotDateRangeOptions.fromDate.toDateString(),
      plotDateRangeOptions.toDate.toDateString(),
      selectedHelpLines,
    ],
    queryFn: () =>
      HelpLineService.getHelpLines(
        field?.uuid ?? '',
        plotDateRangeOptions.fromDate.toDateString(),
        plotDateRangeOptions.toDate.toDateString(),
        selectedHelpLines
      ),
    enabled: !helplinesDialogOpen && selectedHelpLines.length > 0,
    gcTime: TWELVE_HOURS,
    staleTime: TWELVE_HOURS,
  });
};
