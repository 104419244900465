import { useNavigate } from 'react-router';

import { useSnackbar, useStepper } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCreationStepForm } from '../form/useCreationStepForm';
import { PrognosisDto, PrognosisPatchDto, PrognosisService } from 'src/api';
import { PROGNOSIS_KEY } from 'src/constants';

import { useApp } from 'hooks/useApp';
import { useGetCiWellTargetsByField } from 'hooks/useGetCiTargets';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePutCreatePrognosis = () => {
  const { reset, watch } = useCreationStepForm();
  const { showSnackbar } = useSnackbar();
  const { formattedFieldNameForUrl } = useApp();
  const { currentStep } = useStepper();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const usingId = usePrognosisIDFromParams();
  const { data: ciWellTargets } = useGetCiWellTargetsByField();
  const { operationId, operationPhaseId, wellboreIdentifier, ciTargetUuid } =
    watch();

  const wellTarget = ciWellTargets?.find(
    (target) => target.targetUuid === ciTargetUuid
  );

  const title: string = wellboreIdentifier
    ? wellboreIdentifier
    : (wellTarget?.targetName ?? '');

  const body: PrognosisPatchDto = {
    operationId: operationId,
    operationPhaseId: operationPhaseId,
    wellboreIdentifier: wellboreIdentifier,
    title: title,
    ciTargetUuid: ciTargetUuid,
  };

  return useMutation({
    mutationFn: async () => {
      return await PrognosisService.patchApiV1Prognoses(usingId, body);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_KEY, usingId],
      });

      const previousPrognosis: PrognosisDto | undefined =
        queryClient.getQueryData([PROGNOSIS_KEY, usingId]);

      queryClient.setQueryData([PROGNOSIS_KEY, usingId], {
        ...previousPrognosis,
        ...body,
      } as PrognosisDto);

      return { previousPrognosis };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_KEY, usingId],
        context?.previousPrognosis ?? []
      );
      showSnackbar('Failed to update prognosis.');
      reset();
      navigate(
        `${formattedFieldNameForUrl}/prognosis/edit/${usingId}/${currentStep}`
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_KEY, usingId],
      });
    },
  });
};
