import { FC } from 'react';

import {
  CircularProgress,
  Template,
  Typography,
} from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { Actions } from './components/Actions/Actions';
import { PrognosisChart } from './components/Chart/PrognosisChart/PrognosisChart';
import { TimeChart } from './components/Chart/TimeChart/TimeChart';
import { HistoryCard } from './components/HistoryCard/HistoryCard';
import { MainAssumptionsCard } from './components/MainAssumptionsCard/MainAssumptionsCard';
import { PrognosisInfo } from './components/PrognosisInfo/PrognosisInfo';
import { ViewPrognosisProvider } from './provider/ViewPrognosisProvider';
import {
  CardContainer,
  Container,
  Content,
  Header,
  LoadingContainer,
  MainContent,
  Wrapper,
} from './ViewPrognosis.styles';
import { TIME_CHART_FEATURE_KEY } from 'src/constants';
import { StatusIdEnum } from 'src/types';

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { ViewDetailsTable } from 'components/Table/ViewDetailsTable';
import { useGetPrognosis } from 'hooks/useGetPrognosis';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';

interface ViewPrognosisProps {
  isPreview?: boolean;
}

export const ViewPrognosis: FC<ViewPrognosisProps> = ({
  isPreview = false,
}) => {
  const { data } = useGetPrognosis();
  const { data: prognosisDetailData, isLoading } = useGetPrognosisDetails();
  const { showContent } = useFeatureToggling(TIME_CHART_FEATURE_KEY);

  const isApproved = data?.statusId === StatusIdEnum.APPROVED;

  return (
    <ViewPrognosisProvider>
      <Container className={Template.FullWidth}>
        <MainContent>
          {!isPreview && (
            <>
              <Breadcrumbs pageTitle="Prognosis editor" />
              <Header>
                <Typography variant="h1_bold">{data?.title}</Typography>
                <Actions />
              </Header>
            </>
          )}
          <Content>
            <PrognosisInfo />
            {isLoading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <PrognosisChart prognosisDetailData={prognosisDetailData ?? []} />
            )}
            {showContent &&
              (isLoading ? (
                <LoadingContainer>
                  <CircularProgress />
                </LoadingContainer>
              ) : (
                <TimeChart prognosisDetailData={prognosisDetailData ?? []} />
              ))}
            <Wrapper>
              <ViewDetailsTable />
            </Wrapper>
            <CardContainer>
              <MainAssumptionsCard />
            </CardContainer>
            <CardContainer>
              <HistoryCard isApproved={isApproved} />
            </CardContainer>
          </Content>
        </MainContent>
      </Container>
    </ViewPrognosisProvider>
  );
};
