import { colors, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const TextFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacings.medium};
  margin-top: ${spacings.large};
`;

export const FormHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${spacings.medium};
  margin-top: ${spacings.large};
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacings.medium};
  margin-top: ${spacings.large};
  align-items: end;
`;

export const PaddingWrap = styled.div`
  padding: 0 ${spacings.medium};
`;

export const DoubleBanner = styled.div`
  grid-column: span 2;
  div,
  p {
    align-items: center;
  }
`;

export const WarningBanner = styled.div`
  display: flex;
  gap: ${spacings.medium};
  align-items: center;
  margin: auto;
  width: 100%;
  justify-content: center;
  p {
    background-color: ${colors.ui.background__warning.hex};
    padding: ${spacings.small} ${spacings.medium};
    border-radius: ${spacings.x_small};
    align-items: center;
  }
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${spacings.medium};
  margin-top: ${spacings.large};
  align-items: end;
`;

export const Container = styled.div`
  display: flex;
  gap: ${spacings.medium};
  flex-wrap: wrap;
`;

export const EditBox = styled.div<{ hidden?: boolean }>`
  align-self: stretch;
  align-items: center;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  padding: 0 ${spacings.small};
  border-left: 1px solid ${colors.ui.background__medium.hex};
`;
