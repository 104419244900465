import { Button, Icon, OptionalTooltip } from '@equinor/amplify-component-lib';
import { delete_to_trash } from '@equinor/eds-icons';

import { useBulkDeleteFilter } from 'src/pages/EditMeasuredData/hooks/useBulkDeleteFilter';
import { useDeleteFilter } from 'src/pages/EditMeasuredData/hooks/useDeleteFilter';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

export const DeleteFilterButton = () => {
  const {
    selectedFilter,
    selectedFilters,
    setSelectedFilters,
    setSelectedFilter,
  } = useEditMeasuredData();
  const { mutate: deleteFilter } = useDeleteFilter();
  const { mutate: bulkDeleteFilter } = useBulkDeleteFilter();
  const noFiltersSelected =
    selectedFilter === undefined && selectedFilters.length === 0;

  const handleDeleteClick = () => {
    if (selectedFilter) {
      if (selectedFilter.id) {
        deleteFilter(selectedFilter.id);
      }
    } else {
      const filtersToDelete: string[] = [];
      selectedFilters.forEach((filter) => {
        if (filter.id) {
          filtersToDelete.push(filter.id);
        }
      });
      bulkDeleteFilter(filtersToDelete);
    }
    setSelectedFilter(undefined);
    setSelectedFilters([]);
  };

  return (
    <OptionalTooltip
      title={
        noFiltersSelected ? 'Please select one or more changes to delete' : ''
      }
    >
      <Button
        disabled={noFiltersSelected}
        variant="ghost_icon"
        onClick={handleDeleteClick}
      >
        <Icon data={delete_to_trash} />
      </Button>
    </OptionalTooltip>
  );
};
