/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WellboreDetailed } from '../models/WellboreDetailed';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WellService {
  /**
   * GetDrilled wells for a field
   * @param fieldUuId
   * @returns WellboreDetailed Success
   * @throws ApiError
   */
  public static getDrilledWellsFeaturesByField(
    fieldUuId: string
  ): CancelablePromise<Array<WellboreDetailed>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Well/{fieldUuId}/drilledwells',
      path: {
        fieldUuId: fieldUuId,
      },
    });
  }
}
