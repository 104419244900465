import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { CreateForm } from './CreateForm/CreateForm';
import { MainContent, Wrapper } from './CreationStep.styles';
import { CreateFooter } from 'src/pages/EditPrognosis/components/Steps/CreationStep/CreateFooter/CreateFooter';
import { useInitializeCreationStep } from 'src/pages/EditPrognosis/hooks/util/useInitializeCreationStep';
import drillingPlatform from 'src/resources/drilling-platform.svg';
import { createPrognosisSchema, CreationStepFormType } from 'src/utils';

import styled from 'styled-components';

const DrillingPlatformImage = styled.img`
  display: flex;
  align-self: center;
`;

export const CreationStep: FC = () => {
  const methods = useForm<CreationStepFormType>({
    resolver: zodResolver(createPrognosisSchema),
    defaultValues: {
      operationId: -1,
      operationPhaseId: null,
      wellboreIdentifier: null,
      ciTargetUuid: null,
    },
  });

  const { isInitializing } = useInitializeCreationStep({
    formReset: methods.reset,
  });

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <DrillingPlatformImage
          src={drillingPlatform}
          width="240"
          height="240"
        />
        <MainContent>
          <CreateForm isInitializing={isInitializing} />
        </MainContent>
      </Wrapper>
      <CreateFooter isInitializing={isInitializing} />
    </FormProvider>
  );
};
