import { FC, useEffect, useMemo, useState } from 'react';

import { Checkbox, spacings, Typography } from '@equinor/amplify-component-lib';
import {
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowSelectionState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';

import { BaseTable } from './BaseTable/BaseTable';
import { Header, Wrapper } from './BaseTable/BaseTable.styles';
import { defaultColumns } from 'src/constants/table';
import {
  datumColumnGroup,
  gradientColumnGroup,
  initialColumnGroup,
  maxColumnGroup,
  minColumnGroup,
} from 'src/constants/tableColumns';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { useViewPrognosis } from 'src/pages/ViewPrognosis/hooks/useViewPrognosis';
import { Unit } from 'src/types';
import { convertPrognosisToSG } from 'src/utils/unitConversion';

import { useApp } from 'hooks/useApp';
import { useColumnGroupVisibility } from 'hooks/useColumnGroupVisibility';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import { useGetWellboreByIdentifier } from 'hooks/useGetWellbores';
import styled from 'styled-components';

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

const StyledCheckbox = styled(Checkbox)`
  height: 24px;
  > span {
    padding: 0px !important;
    padding-right: ${spacings.small} !important;
  }
`;

export const ViewDetailsTable: FC = () => {
  const { data: optionalAttributes } = useGetOptionalAttributes();
  const { data: prognosisData } = useGetPrognosisDetails();
  const { hasUpdatedCell, setContextRowSelection } = useViewPrognosis();
  const { unit } = useApp();
  const { data: wellbore } = useGetWellboreByIdentifier();

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    fluidType: true,
    pressureGradientDepth: true,
    pressureMin: false,
    pressureMax: false,
    pressureInitial: false,
    datumDepth: false,
    pressureDatumExpected: false,
    pressureDatumLow: false,
    pressureDatumHigh: false,
    actions: false,
  });
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [detailComment, setDetailComment] = useState<
    string | null | undefined
  >();

  const apiPrognosisDetailCopy = useMemo(() => {
    return prognosisData?.map((prognosis) => {
      if (unit === Unit.SG && wellbore) {
        return convertPrognosisToSG({
          prognosis,
          depthReferenceElevation: wellbore?.depthReferenceElevation || 0,
        });
      }
      return prognosis;
    });
  }, [unit, prognosisData, wellbore]);

  const totalDepthRowId = useMemo(() => {
    return (
      apiPrognosisDetailCopy
        ?.find((detail) => detail.reservoirZone === 'Total depth')
        ?.id.toString() ?? ''
    );
  }, [apiPrognosisDetailCopy]);

  const datumVisibility = useColumnGroupVisibility(
    datumColumnGroup,
    columnVisibility,
    setColumnVisibility
  );
  const gradientVisibility = useColumnGroupVisibility(
    gradientColumnGroup,
    columnVisibility,
    setColumnVisibility
  );
  const minVisibility = useColumnGroupVisibility(
    minColumnGroup,
    columnVisibility,
    setColumnVisibility
  );
  const maxVisibility = useColumnGroupVisibility(
    maxColumnGroup,
    columnVisibility,
    setColumnVisibility
  );
  const initialVisibility = useColumnGroupVisibility(
    initialColumnGroup,
    columnVisibility,
    setColumnVisibility
  );

  const handleRowSelectionChange: OnChangeFn<RowSelectionState> = (updater) => {
    setRowSelection(updater);
    setContextRowSelection?.(updater as RowSelectionState);
  };

  const table = useReactTable({
    data: apiPrognosisDetailCopy ?? [],
    columns,
    initialState: {
      sorting: [
        {
          id: 'referenceDepth',
          desc: false,
        },
      ],
    },
    state: {
      rowSelection,
      columnVisibility: {
        ...columnVisibility,
      },
      rowPinning: {
        bottom: totalDepthRowId ? [totalDepthRowId] : [],
      },
    },
    meta: {
      handleOnOpenComment: (detailComment: string | null | undefined) => {
        setOpenCommentModal(true);
        setDetailComment(detailComment);
      },
      isStatusSubmitted: true,
      isEditing: false,
      unit: unit,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: handleRowSelectionChange,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id.toString(),
    enableRowPinning: true,
  });

  useEffect(() => {
    if (optionalAttributes) {
      setColumnVisibility((prev) => {
        return {
          ...prev,
          pressureMin: optionalAttributes.pressureMin ?? false,
          pressureMax: optionalAttributes.pressureMax ?? false,
          pressureInitial: optionalAttributes.pressureInitial ?? false,
          datumDepth: optionalAttributes.datumDepth ?? false,
          pressureDatumExpected: optionalAttributes.datumDepth ?? false,
          pressureDatumLow: optionalAttributes.datumDepth ?? false,
          pressureDatumHigh: optionalAttributes.datumDepth ?? false,
        };
      });
    }
  }, [optionalAttributes]);

  return (
    <Wrapper>
      <Header>
        <Typography variant="h4">Details</Typography>
        <CheckboxGroup>
          <StyledCheckbox
            label="Min"
            checked={minVisibility.isChecked}
            onChange={() =>
              minVisibility.toggleVisibility(!minVisibility.isChecked)
            }
          />
          <StyledCheckbox
            label="Max"
            checked={maxVisibility.isChecked}
            onChange={() =>
              maxVisibility.toggleVisibility(!maxVisibility.isChecked)
            }
          />
          <StyledCheckbox
            label="Initial"
            checked={initialVisibility.isChecked}
            onChange={() =>
              initialVisibility.toggleVisibility(!initialVisibility.isChecked)
            }
          />
          <StyledCheckbox
            label="Gradients"
            checked={gradientVisibility.isChecked}
            onChange={() =>
              gradientVisibility.toggleVisibility(!gradientVisibility.isChecked)
            }
          />
          <StyledCheckbox
            label="Datum"
            checked={datumVisibility.isChecked}
            onChange={() =>
              datumVisibility.toggleVisibility(!datumVisibility.isChecked)
            }
          />
        </CheckboxGroup>
      </Header>
      <BaseTable
        table={table}
        comment={detailComment ?? ''}
        openCommentModal={openCommentModal}
        setOpenCommentModal={setOpenCommentModal}
        hasUpdatedCells={hasUpdatedCell}
      />
    </Wrapper>
  );
};
