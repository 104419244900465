import { FC, useMemo } from 'react';

import {
  colors,
  Icon,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { checkbox, checkbox_outline, IconData } from '@equinor/eds-icons';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${spacings.medium};
  padding: ${spacings.x_small} ${spacings.small};
  border-radius: 4px;
  transition: background-color 150ms;
  &:hover {
    background-color: ${colors.interactive.primary__hover_alt.rgba};
  }
`;

interface MapOptionMenuItemProps {
  text: string;
  onClick: () => void;
  icon?: IconData;
  checked?: boolean;
}

export const MapOptionsMenuItem: FC<MapOptionMenuItemProps> = ({
  text,
  icon,
  onClick,
  checked,
}) => {
  const iconToShow = useMemo(() => {
    if (icon) return icon;
    else return checked ? checkbox : checkbox_outline;
  }, [checked, icon]);

  return (
    <Wrapper onClick={onClick}>
      <Icon
        color={colors.interactive.primary__resting.rgba}
        data={iconToShow}
      />
      <Typography>{text}</Typography>
    </Wrapper>
  );
};
