import { useQuery } from '@tanstack/react-query';

import { useApp } from './useApp';
import { SmdaService } from 'src/api';
import {
  ALL_STRAT_COLUMN_KEY,
  STRAT_COLUMN_KEY,
  STRATIGRAPHIC_UNIT_ALL_KEY,
  STRATIGRAPHIC_UNIT_DRILLED_KEY,
  STRATIGRAPHIC_UNIT_PLANNED_KEY,
} from 'src/constants/queryKeys';
import { FIVE_MINUTES } from 'src/constants/staleTime';
import { OperationValues } from 'src/utils';

export const useGetAllStratColumns = () => {
  const { field } = useApp();
  const fieldUuid = field?.uuid;

  return useQuery({
    queryKey: [ALL_STRAT_COLUMN_KEY],
    queryFn: () => SmdaService.getApiV1SmdaFieldsStratColumns(fieldUuid!),
    enabled: !!fieldUuid,
  });
};

export const useGetStratColumnsByWell = (wellboreUuid: string) => {
  return useQuery({
    queryKey: [STRAT_COLUMN_KEY, wellboreUuid],
    queryFn: () => SmdaService.getApiV1SmdaWellboresStratColumns(wellboreUuid),
    enabled: !!wellboreUuid,
  });
};

export const useGetStratigraphicValues = (
  stratColumnIdentifier: string,
  operationId?: OperationValues
) => {
  return useQuery({
    queryKey: [STRATIGRAPHIC_UNIT_ALL_KEY, stratColumnIdentifier],
    queryFn: () =>
      SmdaService.getApiV1SmdaWellboresStratColumnsStratUnitsAll(
        stratColumnIdentifier
      ),
    // If you want to enable the query only when operationId is OTHER, you can pass it as a prop, otherwise it will be enabled by default
    enabled:
      !!stratColumnIdentifier &&
      (operationId === OperationValues.OTHER ||
        operationId === OperationValues.TARGET ||
        operationId === undefined),
    staleTime: FIVE_MINUTES,
  });
};

export const useGetStratigraphicValuesPlanned = (
  wellboreUuid: string,
  stratColumnIdentifier: string,
  operationId: OperationValues
) => {
  return useQuery({
    queryKey: [
      STRATIGRAPHIC_UNIT_PLANNED_KEY,
      wellboreUuid,
      stratColumnIdentifier,
    ],
    queryFn: () =>
      SmdaService.getApiV1SmdaWellboresStratColumnsStratUnitsPlanned(
        wellboreUuid,
        stratColumnIdentifier
      ),
    enabled:
      stratColumnIdentifier !== null &&
      operationId === OperationValues.NEW_WELL,
    staleTime: FIVE_MINUTES,
  });
};

export const useGetStratigraphicValuesDrilled = (
  wellboreUuid: string,
  stratColumnIdentifier: string,
  operationId: OperationValues
) => {
  return useQuery({
    queryKey: [
      STRATIGRAPHIC_UNIT_DRILLED_KEY,
      wellboreUuid,
      stratColumnIdentifier,
    ],
    queryFn: () =>
      SmdaService.getApiV1SmdaWellboresStratColumnsStratUnitsDrilled(
        wellboreUuid,
        stratColumnIdentifier
      ),
    enabled:
      (!!stratColumnIdentifier &&
        operationId === OperationValues.INTERVENTION) ||
      operationId === OperationValues.P_N_A,
  });
};
