import { useNavigate } from 'react-router-dom';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisService } from 'src/api';
import { TabType } from 'src/constants';

import { useApp } from 'hooks/useApp';
import { useGetPrognoses } from 'hooks/useGetPrognoses';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export function useDeletePrognosis() {
  const { formattedFieldNameForUrl } = useApp();
  const usingId = usePrognosisIDFromParams();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { queryKey } = useGetPrognoses({
    currentTab: TabType.MY_ITEMS,
  });

  return useMutation({
    mutationFn: async () => {
      return PrognosisService.deleteApiV1Prognoses(usingId);
    },

    onMutate: () => {
      queryClient.invalidateQueries({
        queryKey: queryKey,
      });

      // TODO: filter cache to remove the deleted item
    },
    onSuccess: () => {
      showSnackbar('Prognosis deleted');
      navigate(`${formattedFieldNameForUrl}/prognosis`);
    },
  });
}
