import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  SelectOption,
  SelectOptionRequired,
  Typography,
} from '@equinor/amplify-component-lib';
import { useQueryClient } from '@tanstack/react-query';

import { ConfirmDialog } from '../../../ConfimDialog/ConfirmDialog';
import { OperationTitleWrapper } from '../../Steps.styles';
import { FormRow } from '../CreationStep.styles';
import { SmdaService } from 'src/api';
import { STRAT_COLUMN_KEY } from 'src/constants';
import { ONE_MINUTE } from 'src/constants/staleTime';
import { useGetPrognosisTypes } from 'src/hooks/useGetPrognosisTypes';
import { SingleSelect } from 'src/pages/EditPrognosis/components/SingleSelect/SingleSelect';
import {
  useGetInterventionPhases,
  useGetNewWellPhases,
  useGetTargetPhases,
} from 'src/pages/EditPrognosis/hooks/api/useGetOperationPhases';
import { useCreationStepForm } from 'src/pages/EditPrognosis/hooks/form/useCreationStepForm';
import { useCreateFormOptions } from 'src/pages/EditPrognosis/hooks/util/useCreateFormOptions';
import {
  getOperationPhaseOptions,
  getWellboreOptions,
  updateOperationHelper,
} from 'src/pages/EditPrognosis/utils/formUtils';
import { OperationValues } from 'src/utils';
import { CreationStepFormType } from 'src/utils/zodSchema';

import { useGetCiWellTargetsByField } from 'hooks/useGetCiTargets';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import {
  useGetAllWellbores,
  useGetDrilledWellbores,
  useGetPlannedWellbores,
} from 'hooks/useGetWellbores';
import styled from 'styled-components';

interface NextValueProps {
  key: keyof CreationStepFormType;
  item: string | number | undefined;
}

interface ComboBoxProps {
  $width?: string;
}

const ComboboxContainer = styled.div<ComboBoxProps>`
  width: 100%;
  max-width: ${({ $width }) => $width || '100%'};
`;

interface CreateFormProps {
  isInitializing: boolean;
}

export const CreateForm: FC<CreateFormProps> = ({ isInitializing }) => {
  const {
    control,
    trigger,
    getValues,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useCreationStepForm();
  const queryClient = useQueryClient();
  const { data: operations } = useGetPrognosisTypes();
  const { data: newWellPhases } = useGetNewWellPhases();
  const { data: interventionPhases } = useGetInterventionPhases();
  const { data: targetPhases } = useGetTargetPhases();
  const { data: ciWellTargets } = useGetCiWellTargetsByField();
  const { data: allWellbores } = useGetAllWellbores();
  const { data: plannedWellbores } = useGetPlannedWellbores();
  const { data: drilledWellbores } = useGetDrilledWellbores();
  const { data: prognosisDetails } = useGetPrognosisDetails();
  const [nextValue, setNextValue] = useState<NextValueProps>();

  const [openDialog, setOpenDialog] = useState(false);

  const { operationId } = watch();

  const {
    operationOptions,
    lockedValueText,
    newWellPhasesOptions,
    interventionPhasesOptions,
    targetPhasesOptions,
    ciWellTargetsOptions,
    allWellboresOptions,
    drilledWellboresOptions,
    plannedWellboresOptions,
    operationPhaseLabel,
    showTargetFeature,
  } = useCreateFormOptions({
    operations,
    newWellPhases,
    interventionPhases,
    targetPhases,
    ciWellTargets,
    allWellbores,
    plannedWellbores,
    drilledWellbores,
    operationId,
  });

  const operationPhaseOptions = getOperationPhaseOptions({
    operationId,
    newWellPhasesOptions,
    interventionPhasesOptions,
    targetPhasesOptions,
  });

  const wellboreOptions = getWellboreOptions({
    operationId,
    plannedWellboresOptions,
    drilledWellboresOptions,
    allWellboresOptions,
  });

  const handleUpdateField = (
    item: SelectOption<SelectOptionRequired> | undefined,
    key: keyof CreationStepFormType
  ) => {
    if (!item) return;

    const newLabel = item.label;
    const newValue = item.value;
    const previousValue = getValues(key);

    const isFieldDirty =
      newValue !== previousValue || newLabel !== previousValue;

    if (isFieldDirty) {
      if (key == 'operationId') {
        setNextValue({ key, item: Number(newValue) });
        if (prognosisDetails && prognosisDetails?.length > 0) {
          // TODO: trigger from intervention to pna and vice versa?
          setOpenDialog(true);
        } else {
          setValue('operationId', Number(newValue), { shouldDirty: true });
          trigger('operationId');

          // reset operationPhaseId and wellboreIdentifier when operationId changes because they are dependent on operationId
          updateOperationHelper({
            previousValue: previousValue as OperationValues,
            newValue: Number(newValue) as OperationValues,
            setValue,
            unregister,
          });
        }
      } else if (key == 'wellboreIdentifier') {
        setNextValue({ key, item: newLabel });
        if (prognosisDetails && prognosisDetails?.length > 0) {
          setOpenDialog(true);
        } else {
          setValue('wellboreIdentifier', newLabel, {
            shouldDirty: true,
          });
          trigger('wellboreIdentifier');
        }

        const wellboreUuid = wellboreOptions.find(
          (option) => option.label === newLabel
        )?.value;

        if (wellboreUuid) {
          prefetchStratColumns(wellboreUuid);
        }
      } else if (key === 'ciTargetUuid') {
        setValue(key, newValue, { shouldDirty: true });
        trigger(key);
      } else {
        setValue(key, Number(newValue), { shouldDirty: true });
        trigger(key);
      }
    }
  };

  const handleFormChange = () => {
    setOpenDialog(false);
    if (!nextValue) return;
    const { key, item } = nextValue;

    if (key === 'operationId') {
      const previousValue = getValues('operationId');

      updateOperationHelper({
        previousValue: previousValue as OperationValues,
        newValue: item as OperationValues,
        setValue,
        unregister,
      });
    }

    if (!item) return;
    setValue(key, item, { shouldDirty: true });
    trigger(key);
  };

  const prefetchStratColumns = (wellboreUuid: string) => {
    queryClient.prefetchQuery({
      queryKey: [STRAT_COLUMN_KEY, wellboreUuid],
      queryFn: () =>
        SmdaService.getApiV1SmdaWellboresStratColumns(wellboreUuid),
      staleTime: ONE_MINUTE,
    });
  };

  return (
    <>
      <div>
        <OperationTitleWrapper>
          <Typography variant="h4">Select an operation</Typography>
        </OperationTitleWrapper>
        <FormRow>
          <Controller
            name="operationId"
            control={control}
            render={({ field }) => (
              <SingleSelect
                label="Operation"
                items={operationOptions}
                placeholder="Select one..."
                value={operationOptions.find(
                  (option) => Number(option.value) === field.value
                )}
                onSelect={(item) => {
                  handleUpdateField(item, 'operationId');
                }}
                loading={isInitializing}
                meta="Required"
                variant={errors.operationId ? 'error' : undefined}
                helperText={errors.operationId?.message}
                data-testid="operation-input"
                clearable={false}
              />
            )}
          />
          <Controller
            name="operationPhaseId"
            control={control}
            render={({ field }) => (
              <SingleSelect
                label={operationPhaseLabel}
                items={operationPhaseOptions}
                placeholder="Select one..."
                value={operationPhaseOptions.find(
                  (option) => Number(option.value) === field.value
                )}
                onSelect={(item) => {
                  handleUpdateField(item, 'operationPhaseId');
                }}
                loading={isInitializing}
                meta="Required"
                variant={errors.operationPhaseId ? 'error' : undefined}
                helperText={errors.operationPhaseId?.message}
                data-testid="operationPhase-input"
                locked={
                  (operationId as OperationValues) !==
                    OperationValues.NEW_WELL &&
                  (operationId as OperationValues) !==
                    OperationValues.INTERVENTION &&
                  (operationId as OperationValues) !== OperationValues.TARGET &&
                  !isInitializing
                }
                lockedValue={lockedValueText}
                clearable={false}
              />
            )}
          />
        </FormRow>
      </div>
      <FormRow>
        <ComboboxContainer>
          <Controller
            name="wellboreIdentifier"
            control={control}
            render={({ field }) => (
              <SingleSelect
                label={'Wellbore'}
                items={wellboreOptions}
                placeholder="Select one..."
                value={wellboreOptions.find(
                  (option) => option.label === field.value
                )}
                onSelect={(item) => {
                  handleUpdateField(item, 'wellboreIdentifier');
                }}
                loading={isInitializing}
                meta="Required"
                variant={errors.wellboreIdentifier ? 'error' : undefined}
                helperText={errors.wellboreIdentifier?.message}
                clearable={false}
                disabled={operationId === undefined}
                locked={
                  (operationId as OperationValues) === OperationValues.TARGET &&
                  !isInitializing
                }
                lockedValue="Wellbore locked on Target operation"
              />
            )}
          />
        </ComboboxContainer>
        {showTargetFeature && (
          <ComboboxContainer>
            <Controller
              name="ciTargetUuid"
              control={control}
              render={({ field }) => (
                <SingleSelect
                  label="Target"
                  items={ciWellTargetsOptions}
                  placeholder="Select one..."
                  value={ciWellTargetsOptions.find((option) => {
                    return option.value === field.value;
                  })}
                  onSelect={(item) => {
                    handleUpdateField(item, 'ciTargetUuid');
                  }}
                  loading={isInitializing}
                  lightBackground
                  meta="Required"
                  variant={errors.ciTargetUuid ? 'error' : undefined}
                  helperText={errors.ciTargetUuid?.message}
                  data-testid="target-input"
                  clearable={false}
                  locked={
                    (operationId as OperationValues) !==
                      OperationValues.TARGET && !isInitializing
                  }
                  lockedValue="Select target operation to unlock"
                />
              )}
            />
          </ComboboxContainer>
        )}
      </FormRow>

      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        detailsToDelete={prognosisDetails ?? []}
        handleFormChange={handleFormChange}
      />
    </>
  );
};
