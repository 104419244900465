import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { spacings, Template } from '@equinor/amplify-component-lib';

import { Actions } from 'src/pages/PlotView/components/Actions/Actions';
import { Plot } from 'src/pages/PlotView/components/Plot/Plot';
import { PressureDataProvider } from 'src/pages/PlotView/providers/PressureDataProvider';

import { LinePlotHeader } from 'components/LinePlotHeader/LinePlotHeader';
import { useApp } from 'hooks/useApp';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${spacings.xxx_large} * 2);
  margin: ${spacings.xxx_large};
`;

export const PlotView = () => {
  const { formattedFieldNameForUrl } = useApp();
  const navigate = useNavigate();
  const copyToClipboardRef = useRef<HTMLDivElement>(null);

  const handleOnBackClick = () => {
    navigate(`${formattedFieldNameForUrl}/measured-data/map`);
  };

  return (
    <PressureDataProvider>
      <Container className={Template.FullWidth}>
        <LinePlotHeader
          backText="Go to map"
          title="Daily average pressure"
          handleOnBackClick={handleOnBackClick}
        >
          <Actions copyToClipboardRef={copyToClipboardRef} />
        </LinePlotHeader>
        <Plot />
      </Container>
    </PressureDataProvider>
  );
};
