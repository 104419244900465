import { InfoElement, spacings } from '@equinor/amplify-component-lib';

import { PressureDataSimple, PressureType } from 'src/api';
import { useMeasuredData } from 'src/hooks';
import { Actions } from 'src/pages/MapView/components/MapElements/components/Actions';
import {
  CommonContextMenuProps,
  ContextMenu,
} from 'src/pages/MapView/components/MapElements/components/ContextMenu';
import { Header } from 'src/pages/MapView/components/MapElements/components/Header';
import { Type } from 'src/pages/MapView/components/MapElements/components/Type';
import { WellborePurpose } from 'src/types';
import { roundTo } from 'src/utils';

import styled from 'styled-components';

const InfoElementsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${spacings.medium};
`;

interface WellboreContextMenuProps extends CommonContextMenuProps {
  pressureData: PressureDataSimple;
  name: string;
}

export const ContextMenuWellbore = ({
  pressureData,
  name,
  style,
  onClose,
  open,
}: WellboreContextMenuProps) => {
  const { wellbores } = useMeasuredData();

  const well = wellbores.find(
    (w) => w.wellboreUuid === pressureData.wellboreUuid
  );

  if (well === undefined) return null;

  const block = well.block ?? '-';
  const state =
    (pressureData?.onStreamHours ?? 0) > 0 ? 'Flowing' : 'Not flowing';
  const pressureRaw = roundTo(pressureData.pressureRaw, 2) ?? '-';
  const pressureDC = roundTo(pressureData.pressureDepthCorrected, 2) ?? '-';
  const pressurePI = roundTo(pressureData.pressurePICorrected, 2) ?? '-';
  const pressureFinal = roundTo(pressureData.pressureFinal, 2) ?? '-';
  const formation = pressureData.stratigraphy.formation ?? '-';
  const type =
    well.purpose === (WellborePurpose.PRODUCTION as string)
      ? 'Producer'
      : well.purpose === (WellborePurpose.INJECTION as string)
        ? 'Injector'
        : '-';
  const isMissing = pressureData.pressureType === PressureType.MISSING;
  const rawIsNegative = Math.sign(pressureData.pressureRaw) === -1;

  return (
    <ContextMenu open={open} style={style} onClose={onClose}>
      <Header label={block} title={name} />
      <InfoElementsWrapper>
        <InfoElement title="well type" content={type} />
        <InfoElement title="formation" content={formation} />
        <InfoElement
          title="raw pressure value"
          content={pressureRaw.toString()}
        />
        <InfoElement
          title="datum pressure value"
          content={isMissing || rawIsNegative ? '-' : pressureDC.toString()}
        />
        <InfoElement
          title="PI corrected value"
          content={isMissing || rawIsNegative ? '-' : pressurePI.toString()}
        />
        <InfoElement
          title="QC pressure value"
          content={isMissing ? '-' : pressureFinal.toString()}
        />
        <InfoElement title="state" content={state} />
        <Type measurement={pressureData} />
      </InfoElementsWrapper>
      <Actions {...pressureData} />
    </ContextMenu>
  );
};
