import { StatusIdEnum } from 'src/types';

export const getShowEditActions = ({
  createUser,
  statusId,
  userShortName,
}: {
  createUser: string;
  statusId: number;
  userShortName: string;
}) => {
  const isSubmitted = statusId === StatusIdEnum.SUBMITTED;
  const isRevisionRequired = statusId === StatusIdEnum.REVISION_REQUIRED;
  const isCreator = createUser === userShortName;

  return isCreator && (isSubmitted || isRevisionRequired);
};

export const getShowApproveAction = ({
  mainApprover,
  backupApprover,
  statusId,
  userShortName,
}: {
  mainApprover: string;
  backupApprover: string;
  statusId: number;
  userShortName: string;
}) => {
  const isAssignedApprover =
    userShortName === mainApprover || userShortName === backupApprover;
  const isSubmitted = statusId === StatusIdEnum.SUBMITTED;

  return isAssignedApprover && isSubmitted;
};

export const getShowRejectAction = ({
  mainApprover,
  backupApprover,
  statusId,
  userShortName,
}: {
  mainApprover: string;
  backupApprover: string;
  statusId: number;
  userShortName: string;
}) => {
  const isSubmitted = statusId === StatusIdEnum.SUBMITTED;
  const isApproved = statusId === StatusIdEnum.APPROVED;
  const isAssignedApprover =
    userShortName === mainApprover || userShortName === backupApprover;

  return isAssignedApprover && (isSubmitted || isApproved);
};
