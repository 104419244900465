import { FC } from 'react';

import {
  colors,
  Icon,
  SkeletonBase,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { info_circle, lock } from '@equinor/eds-icons';

import { FieldValues } from 'src/api';
import { calculationExcludeKeys } from 'src/constants';
import { WarningBanner } from 'src/pages/FieldValues/PrognosisValues/PrognosisValues.styles';

import { ViewFieldValues } from 'components/ViewFieldValues/ViewFieldValues';
import { Box } from 'components/ViewFieldValues/ViewFieldValues.styles';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

interface CalculationValuesProps {
  label: string;
  fieldValues: FieldValues;
  originalFieldValue?: FieldValues;
  generalFieldValue: FieldValues;
  missingVitalKeys: boolean;
  isLoading: boolean;
}

const LockPadding = styled.div`
  padding: ${spacings.small} ${spacings.medium};
`;

const Skeleton = styled(SkeletonBase)`
  height: 89px;
`;

export const CalculationValues: FC<CalculationValuesProps> = ({
  label,
  originalFieldValue,
  fieldValues,
  generalFieldValue,
  missingVitalKeys,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <Container>
        <Typography variant="h6">{label}</Typography>
        <Skeleton />
      </Container>
    );
  }

  const generalKeysWithValue = Object.keys(generalFieldValue ?? {}).filter(
    (key) =>
      generalFieldValue &&
      !calculationExcludeKeys.includes(key) &&
      generalFieldValue[key as keyof FieldValues]
  ) as (keyof FieldValues)[];

  const nullKeysinValues = Object.keys(originalFieldValue || {}).filter(
    (key) =>
      originalFieldValue &&
      originalFieldValue[key as keyof FieldValues] === null
  );

  const keysWithValuesInGeneral = nullKeysinValues.filter(
    (key) => generalFieldValue && generalFieldValue[key as keyof FieldValues]
  );

  return (
    <Container>
      <Typography variant="h6">{label}</Typography>
      <ViewFieldValues
        values={fieldValues || generalFieldValue}
        generalKeys={generalKeysWithValue}
        backgroundColor={colors.ui.background__light.hex}
        generalFieldValue={generalFieldValue}
        keysWithValuesInGeneral={keysWithValuesInGeneral}
        showMeasuredDataValues={false}
      >
        <Box>
          <LockPadding>
            <Icon
              data={lock}
              size={24}
              color={colors.text.static_icons__tertiary.hex}
            />
          </LockPadding>
        </Box>
      </ViewFieldValues>
      {fieldValues && !fieldValues.isGeneral && (
        <WarningBanner data-spacing-mode="extra-compact">
          <Icon
            data={info_circle}
            size={24}
            color={colors.text.static_icons__tertiary.hex}
          />
          {!missingVitalKeys ? (
            <Typography group="navigation" variant="label">
              Replaces broader general values in another data set
            </Typography>
          ) : (
            <Typography group="navigation" variant="label">
              Missing values, unable to use calculation. Notify field admin to
              update field value
            </Typography>
          )}
        </WarningBanner>
      )}
    </Container>
  );
};
