import { useQuery } from '@tanstack/react-query';

import { useApp } from './useApp';
import { CiService, WellTarget } from 'src/api';
import { CI_WELL_TARGETS_BY_FIELD_KEY } from 'src/constants';

export const useGetCiWellTargetsByField = () => {
  const { field } = useApp();

  const fieldIdentifier = field?.name;

  return useQuery<WellTarget[]>({
    queryKey: [CI_WELL_TARGETS_BY_FIELD_KEY, field?.name],
    queryFn: () => CiService.getApiV1CiWellTargetsField(fieldIdentifier!),
    enabled: !!field,
  });
};
