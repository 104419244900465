import { FC, useEffect, useMemo } from 'react';

import { BaseTableCell } from '../BaseTableCell/BaseTableCell';
import { useViewPrognosis } from 'src/pages/ViewPrognosis/hooks/useViewPrognosis';
import { findPreviousDetail } from 'src/utils';
import { determineCellColors } from 'src/utils/color';

import { useGetPreviousPrognosisDetails } from 'hooks/useGetPreviousPrognosisDetails';

interface DetailTableCellProps {
  detailValue?: string;
  detailKey: string;
  prognosisId: number;
  isStatusSubmitted: boolean;
}

export const DetailTableCell: FC<DetailTableCellProps> = ({
  detailValue,
  detailKey,
  prognosisId,
  isStatusSubmitted,
}) => {
  const showPlaceholder =
    detailValue === undefined || detailValue === null || detailValue === '';
  const { data: previousData } = useGetPreviousPrognosisDetails();
  const { setHasUpdatedCell } = useViewPrognosis();

  const previousDetailData = useMemo(() => {
    return findPreviousDetail(detailKey, prognosisId, previousData ?? []);
  }, [detailKey, previousData, prognosisId]);

  const isDetailUpdated = Boolean(
    previousDetailData &&
      previousDetailData.toString() !== detailValue?.toString() &&
      isStatusSubmitted
  );

  useEffect(() => {
    setHasUpdatedCell(isDetailUpdated);
  }, [isDetailUpdated, setHasUpdatedCell]);

  const { textColor, backgroundColor } = determineCellColors({
    isUpdated: isDetailUpdated,
    showPlaceholder,
    hasError: false,
  });

  return (
    <BaseTableCell
      backgroundColor={backgroundColor}
      textColor={textColor}
      detailValue={detailValue ?? '0.0'}
    />
  );
};
