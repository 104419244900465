import { spacings, Typography } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const AddButtonWrapper = styled.div`
  display: flex;
  gap: ${spacings.medium};
  width: 100%;
  overflow: scroll;
  padding-bottom: ${spacings.medium};
`;

export const TypographyWithMargin = styled(Typography)`
  margin-bottom: ${spacings.small};
`;

export const WellboreText = styled.b`
  white-space: nowrap;
`;
