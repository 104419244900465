import { FC } from 'react';

import {
  Button,
  colors,
  elevation,
  Icon,
  spacings,
  Typography,
  useLocalStorage,
} from '@equinor/amplify-component-lib';
import { info_circle } from '@equinor/eds-icons';

import { InterpolatedMapLegend } from './InterpolatedMapLegend/InterpolatedMapLegend';
import {
  MAP_FOOTER_HEIGHT,
  MEASURED_DATA_SHOW_MAP_LEGENDS,
  PRESSURE_TYPES_IN_ORDER,
} from 'src/constants';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground } from 'src/pages/MapView/MapView.types';
import { WellborePurpose } from 'src/types';

import { PressureTypeChip } from 'components/PressureTypeChip/PressureTypeChip';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

interface WrapperProps {
  $showInterpolatedLegend: boolean;
}

const Wrapper = styled(motion.div)<WrapperProps>`
  position: absolute;
  right: ${spacings.medium};
  bottom: calc(${MAP_FOOTER_HEIGHT} + ${spacings.medium});
  background: ${colors.ui.background__default.rgba};
  padding: ${spacings.x_small} ${spacings.x_small} ${spacings.x_small}
    ${({ $showInterpolatedLegend }) =>
      $showInterpolatedLegend ? spacings.x_small : spacings.medium_small};
  border-radius: 24px;
  display: flex;
  align-items: center;
  box-shadow: ${elevation.raised};
  justify-content: center;
  gap: ${spacings.small};
`;

const LegendsDivider = styled.div`
  width: 1px;
  height: 24px;
  background: ${colors.ui.background__medium.rgba};
`;

const ToggleButton = styled(Button)`
  justify-self: end;
`;

export const MapLegends: FC = () => {
  const [showLegends, setShowLegends] = useLocalStorage(
    MEASURED_DATA_SHOW_MAP_LEGENDS,
    true
  );
  const { backgroundLayer } = useMapOptions();

  const handleOnClick = () => {
    setShowLegends((prev) => !prev);
  };

  return (
    <>
      <ToggleButton variant="ghost_icon" onClick={handleOnClick}>
        <Icon data={info_circle} />
      </ToggleButton>
      <AnimatePresence>
        {showLegends && (
          <Wrapper
            $showInterpolatedLegend={
              backgroundLayer === MapBackground.INTERPOLATED
            }
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
          >
            {backgroundLayer === MapBackground.INTERPOLATED && (
              <>
                <InterpolatedMapLegend />
                <LegendsDivider />
              </>
            )}
            <Typography group="input" variant="label">
              AVG = Average
            </Typography>
            <LegendsDivider />
            {PRESSURE_TYPES_IN_ORDER.map((type) => {
              return <PressureTypeChip key={type} type={type} />;
            })}
            <PressureTypeChip type={WellborePurpose.PRODUCTION} />
            <PressureTypeChip type={WellborePurpose.INJECTION} />
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
};
