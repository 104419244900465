import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@equinor/amplify-component-lib';
import { chevron_right } from '@equinor/eds-icons';

import { StyledBreadcrumbs } from './Breadcrumbs.styles';

import { useApp } from 'hooks/useApp';

interface BreadcrumbsProps {
  pageTitle: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ pageTitle }) => {
  const { formattedFieldNameForUrl } = useApp();

  return (
    <StyledBreadcrumbs separator={<Icon data={chevron_right} />}>
      <StyledBreadcrumbs.Breadcrumb
        as={Link}
        to={`${formattedFieldNameForUrl}/prognosis`}
      >
        Prognosis
      </StyledBreadcrumbs.Breadcrumb>
      <StyledBreadcrumbs.Breadcrumb disabled>
        {pageTitle}
      </StyledBreadcrumbs.Breadcrumb>
    </StyledBreadcrumbs>
  );
};
