/**
 * Generates a random integer between the specified minimum and maximum values, inclusive.
 *
 * @param min - The minimum value (inclusive).
 * @param max - The maximum value (inclusive).
 * @returns A random integer between `min` and `max`.
 */
export const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Generates a random ID using the Web Crypto API.
 *
 * @returns {number} A randomly generated 32-bit unsigned integer.
 */
export const generateRandomId = (): number => {
  return crypto.getRandomValues(new Uint32Array(1))[0] * -1;
};

/**
 * Generates a random ID using the Web Crypto API.
 *
 * @returns {number} A randomly generated 32-bit unsigned integer.
 */
export const generateRandomInt = (): number => {
  return crypto.getRandomValues(new Uint32Array(1))[0];
};
