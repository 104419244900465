import { FC, useMemo } from 'react';

import { spacings } from '@equinor/amplify-component-lib';

import { Separator } from '../../Steps/Steps.styles';
import { LastUsedZones } from './LastUsedZones/LastUsedZones';
import { SelectColumn } from './SelectColumn/SelectColumn';
import { NavigatorWrapper } from './ZoneNavigator/NavigatorWrapper/NavigatorWrapper';
import { MappedStratigraphicUnit } from './ZoneNavigator/ZoneNavigator';
import { TypographyWithMargin } from './ZoneSelector.styles';

import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
  width: 100%;
  height: 100%;
`;

const SelectContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: auto auto auto 1fr;
  gap: ${spacings.medium};
`;

interface ZoneSelectorProps {
  isInitializing: boolean;
}
export const ZoneSelector: FC<ZoneSelectorProps> = ({ isInitializing }) => {
  const { data: apiPrognosisDetails } = useGetPrognosisDetails();

  const savedSelectedItems: MappedStratigraphicUnit[] = useMemo(() => {
    const fileteredDetails = apiPrognosisDetails?.filter(
      (detail) => detail.stratColumnIdentifier !== 'Total depth'
    );

    const mappedDetails =
      fileteredDetails?.map((detail) => ({
        id: detail?.lithostratSubzone || '',
        label: detail?.lithostratSubzone || '',
        value: detail?.lithostratSubzone || '',
        stratigraphicUnits: [],
      })) ?? [];

    return mappedDetails;
  }, [apiPrognosisDetails]);

  return (
    <Wrapper>
      <TypographyWithMargin variant="h4">Zone selector</TypographyWithMargin>
      <SelectContainer>
        <SelectColumn isInitializing={isInitializing} />
        <Separator />
        <LastUsedZones
          isInitializing={isInitializing}
          savedSelectedItems={savedSelectedItems}
        />
      </SelectContainer>
      <NavigatorWrapper
        isInitializing={isInitializing}
        savedSelectedItems={savedSelectedItems}
      />
    </Wrapper>
  );
};
