import { FC, useMemo } from 'react';

import { ApplicationTabsView } from './ApplicationTabsView/ApplicationTabsView';
import { TabPanel } from './TabPanel/TabPanel';
import { UserAccess } from 'src/api';
import { TabType } from 'src/constants';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';
import { ViewPrognosesOptions } from 'src/utils/prognosisDashboard';

import { useApp } from 'hooks/useApp';

const generateTabPanels = (tabTypes: TabType[]) => {
  return tabTypes.map((tabType, index) => (
    <TabPanel key={tabType} currentTab={tabType} tabIndex={index} />
  ));
};

interface ApplicationTabsProps {
  handleShowFilterSidebar: () => void;
  showFilterSidebar: boolean;
}

export const ApplicationTabs: FC<ApplicationTabsProps> = ({
  handleShowFilterSidebar,
  showFilterSidebar,
}) => {
  const { userAccessForField = {} as UserAccess } = useGetUserAccess();
  const { draftGroup } = useApp();

  const showAll = draftGroup === ViewPrognosesOptions.ALL_PROGNOSES;
  const showAssignedToMe = draftGroup === ViewPrognosesOptions.ASSIGNED_TO_ME;

  const usingTabList: TabType[] = useMemo(() => {
    const tabs = [TabType.APPROVED, TabType.HISTORICAL];

    if (showAssignedToMe) {
      tabs.unshift(TabType.OTHER_ITEMS_ON_THE_FIELD);
    }

    if (showAll) {
      tabs.unshift(TabType.ALL_ITEMS_ON_THE_FIELD);
    }

    if (
      userAccessForField?.userRoles?.submitter ||
      userAccessForField?.userRoles?.approver
    ) {
      tabs.unshift(TabType.AWAITING_APPROVAL);
    }

    if (userAccessForField?.userRoles?.submitter) {
      tabs.unshift(TabType.MY_ITEMS);
    }

    return tabs;
  }, [showAll, showAssignedToMe, userAccessForField]);

  const usingTabPanels = useMemo(
    () => generateTabPanels(usingTabList),
    [usingTabList]
  );

  return (
    <ApplicationTabsView
      usingTabList={usingTabList}
      usingTabPanels={usingTabPanels}
      handleShowFilterSidebar={handleShowFilterSidebar}
      showFilterSidebar={showFilterSidebar}
    />
  );
};
