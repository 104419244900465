import { precisionScale } from 'src/utils';

import { z } from 'zod';

export const fieldValuesSchema = z.object({
  isGeneral: z.boolean({ message: 'Please select a value' }),
  stratColumn: z
    .object({
      stratColumnUuid: z.string().nonempty().optional(),
      stratColumnIdentifier: z.string().nonempty().optional(),
    })
    .optional(),
  stratUnit: z
    .object({
      stratUnitUuid: z.string().nonempty().optional(),
      stratUnitIdentifier: z.string().nonempty().optional(),
    })
    .optional(),
  datumDepth: z
    .number()
    .positive()
    .min(50, { message: 'Depth must be at least 50 meters' })
    .max(9999, { message: 'Depth must be less than 9999 meters' })
    .optional(),
  datumPressure: z
    .union([
      z
        .number()
        .int()
        .positive()
        .min(10, { message: 'Pressure must be at least 10 bar' })
        .max(1000, { message: 'Pressure must be less than 1000 bar' }),
      z.nan(),
    ])
    .optional(),
  initialPressure: z
    .union([
      z
        .number()
        .int()
        .positive()
        .min(10, { message: 'Pressure must be at least 10 bar' })
        .max(1000, { message: 'Pressure must be less than 1000 bar' }),
      z.nan(),
    ])
    .optional(),
  oilGradient: z
    .union([
      z
        .number()
        .positive()
        .min(0)
        .max(1)
        .refine(
          precisionScale(6, 4),
          'Gradient must have up to 4 decimal places'
        ),

      z.nan(),
    ])
    .optional(),
  gasGradient: z
    .union([
      z
        .number()
        .positive()
        .min(0)
        .max(1)
        .refine(
          precisionScale(6, 4),
          'Gradient must have up to 4 decimal places'
        ),
      z.nan(),
    ])
    .optional(),
  waterGradient: z
    .union([
      z
        .number()
        .positive()
        .min(0)
        .max(1)
        .refine(
          precisionScale(6, 4),
          'Gradient must have up to 4 decimal places'
        ),
      z.nan(),
    ])
    .optional(),
  oilWaterContactDepth: z
    .union([
      z
        .number()
        .int()
        .positive()
        .min(50, { message: 'Depth must be at least 50 meters' })
        .max(9999, { message: 'Depth must be less than 9999 meters' }),
      z.nan(),
    ])
    .optional(),
  gasOilContactDepth: z
    .union([
      z
        .number()
        .int()
        .positive()
        .min(50, { message: 'Depth must be at least 50 meters' })
        .max(9999, { message: 'Depth must be less than 9999 meters' }),
      z.nan(),
    ])
    .optional(),
  gasWaterContactDepth: z
    .union([
      z
        .number()
        .int()
        .positive()
        .min(50, { message: 'Depth must be at least 50 meters' })
        .max(9999, { message: 'Depth must be less than 9999 meters' }),
      z.nan(),
    ])
    .optional(),
  gasFormationVolFactor: z
    .union([z.number().positive().min(0), z.nan()])
    .optional(),
  gasSolution: z
    .union([
      z
        .number()
        .positive()
        .min(0)
        .refine(
          precisionScale(9, 4),
          'Factor must have up to 4 decimal places'
        ),
      z.nan(),
    ])
    .optional(),
  oilFormationVolFactor: z
    .union([z.number().positive().min(0), z.nan()])
    .optional(),
  waterFormationVolFactor: z
    .union([z.number().positive().min(0), z.nan()])
    .optional(),
});

export type FieldValues = z.infer<typeof fieldValuesSchema>;

export interface FormElementProps {
  id: keyof FieldValues;
  type: string;
  label: string;
  helperText: string;
  values?: string[];
}

export const formElements: FormElementProps[] = [
  {
    id: 'datumDepth',
    label: 'Datum depth',
    type: 'number',
    helperText: 'm MSL',
  },
  {
    id: 'datumPressure',
    label: 'Datum pressure',
    type: 'number',
    helperText: 'Pressure in bar',
  },
  {
    id: 'initialPressure',
    label: 'Initial pressure',
    type: 'number',
    helperText: 'Pressure in bar',
  },
  {
    id: 'oilGradient',
    label: 'Oil gradient',
    type: 'number',
    helperText: 'bar/m',
  },
  {
    id: 'gasGradient',
    label: 'Gas gradient',
    type: 'number',
    helperText: 'bar/m',
  },
  {
    id: 'waterGradient',
    label: 'Water gradient',
    type: 'number',
    helperText: 'bar/m',
  },
  {
    id: 'oilWaterContactDepth',
    label: 'Oil-water contact depth',
    type: 'number',
    helperText: 'm MSL',
  },
  {
    id: 'gasOilContactDepth',
    label: 'Gas-oil contact depth',
    type: 'number',
    helperText: 'm MSL',
  },
  {
    id: 'gasWaterContactDepth',
    label: 'Gas-water contact depth',
    type: 'number',
    helperText: 'm MSL',
  },
  {
    id: 'oilFormationVolFactor',
    label: 'Oil formation volume',
    type: 'number',
    helperText: 'Rm³/Sm³',
  },
  {
    id: 'gasFormationVolFactor',
    label: 'Gas formation volume',
    type: 'number',
    helperText: 'Rm³/Sm³',
  },
  {
    id: 'waterFormationVolFactor',
    label: 'Water formation volume ',
    type: 'number',
    helperText: 'Rm³/Sm³',
  },
  {
    id: 'gasSolution',
    label: 'Gas solution',
    type: 'number',
    helperText: 'Sm³/Sm³',
  },
];
