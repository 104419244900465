import { FC, useEffect, useState } from 'react';

import { Template } from '@equinor/amplify-component-lib';
import { useQueryClient } from '@tanstack/react-query';

import { FilterSidebar } from './components/FilterSidebar/FilterSidebar';
import { Header } from './components/Header/Header';
import { PrognosisDashboardProvider } from './providers/PrognosisDashboardProvider';
import { Container, MainContent } from './PrognosisDashboard.styles';
import {
  ConfigService,
  OperationPhaseService,
  PrognosisOperationService,
  SmdaService,
} from 'src/api';
import {
  INTERVENTION_PHASE,
  MEASURED_DATA_ACCESS_CHECK_KEY,
  NEW_WELL_PHASE,
  PROGNOSIS_TYPES_KEY,
  WELLBORE_ALL_KEY,
} from 'src/constants';
import { ONE_MINUTE } from 'src/constants/staleTime';
import { ApplicationTabs } from 'src/pages/PrognosisDashboard/components/ApplicationTabs/ApplicationTabs';

import { useApp } from 'hooks/useApp';

export const PrognosisDashboard: FC = () => {
  const queryClient = useQueryClient();
  const { field } = useApp();

  const [showFilterSidebar, setShowFilterSidebar] = useState(false);

  // prefetch data that can be used in any prognosis
  useEffect(() => {
    // prefetch wellbores
    queryClient.prefetchQuery({
      queryKey: [WELLBORE_ALL_KEY, field?.uuid],
      queryFn: () =>
        SmdaService.getApiV1SmdaFieldsWellboresAll(field?.uuid ?? ''),
      staleTime: ONE_MINUTE,
    });

    // prefetch operations
    queryClient.prefetchQuery({
      queryKey: [PROGNOSIS_TYPES_KEY],
      queryFn: () => PrognosisOperationService.getApiV1PrognosesOperations(),
      staleTime: ONE_MINUTE,
    });

    // prefetch new well operation subtypes
    queryClient.prefetchQuery({
      queryKey: [NEW_WELL_PHASE],
      queryFn: () =>
        OperationPhaseService.getApiV1PrognosesOperationPhasesNewWell(),
      staleTime: ONE_MINUTE,
    });

    // prefetch intervention operation subtypes
    queryClient.prefetchQuery({
      queryKey: [INTERVENTION_PHASE],
      queryFn: () =>
        OperationPhaseService.getApiV1PrognosesOperationPhasesIntervention(),
      staleTime: ONE_MINUTE,
    });

    // TODO: Look into a more generalized place we can prefetch something more general like this. For now PrognosisDashboard is a decent place, since it covers most of the cases.
    // prefetch access check for measured data pages, for more seamless navigation without extra FullPageSpinner
    queryClient.prefetchQuery({
      queryKey: [MEASURED_DATA_ACCESS_CHECK_KEY, field?.uuid],
      queryFn: () => ConfigService.accessCheck(field?.uuid ?? ''),
      staleTime: ONE_MINUTE,
    });
  }, [field?.name, field?.uuid, queryClient]);

  const handleOnToggleFilterSidebar = () =>
    setShowFilterSidebar((prev) => !prev);

  return (
    <PrognosisDashboardProvider>
      <Container className={Template.FullWidth}>
        <div>
          <FilterSidebar open={showFilterSidebar} />
        </div>
        <MainContent>
          <Header />
          <ApplicationTabs
            handleShowFilterSidebar={handleOnToggleFilterSidebar}
            showFilterSidebar={showFilterSidebar}
          />
        </MainContent>
      </Container>
    </PrognosisDashboardProvider>
  );
};
