import { FC } from 'react';

import { datumKeys, referenceKeys } from '../DataInputDialog.utils';
import { Gradients } from '../Gradients/Gradients';
import { TextFields } from '../TextFields/TextFields';
import { PrognosisDetailDto } from 'src/api';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';

interface FallbackFieldProps {
  index: number;
  isLoading: boolean;
  formDetail: PrognosisDetailDto | null;
}

export const FallbackFields: FC<FallbackFieldProps> = ({
  index,
  isLoading,
  formDetail,
}) => {
  const { data: optionalAttributes } = useGetOptionalAttributes();

  return (
    <>
      <TextFields
        label="Reference"
        index={index}
        keys={referenceKeys}
        isLoading={isLoading}
      />
      <Gradients
        label="Gradients"
        index={index}
        missingVitalKeys={true}
        pressureGradientDepthUnit={formDetail?.pressureGradientDepthUnit ?? ''}
        isLoading={isLoading}
      />
      {optionalAttributes?.datumDepth && (
        <TextFields
          label="Datum"
          index={index}
          keys={datumKeys}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
