import { FC, useMemo, useState } from 'react';

import { Dialog, spacings, Typography } from '@equinor/amplify-component-lib';

import { usePutBulkDetails } from '../../hooks/api/usePutBulkDetails';
import { CalculationValues } from './CalculationValues/CalculationValues';
import { FallbackFields } from './FallbackFields/FallbackFields';
import { FieldsWithCalculate } from './FieldsWithCalculate/FieldsWithCalculate';
import { General } from './General/General';
import { FieldValues, PrognosisDetailDto } from 'src/api';
import { EMPTY_DETAIL } from 'src/constants';
import { vitalCalculationKeys } from 'src/constants';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { usePutDetail } from 'src/pages/EditPrognosis/hooks/api/usePutDetail';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';
import {
  useGetFieldValues,
  useGetFieldValuesByStratUnit,
} from 'src/pages/FieldValues/hooks/useFieldValues';

import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.x_large};
  padding: ${spacings.medium};
  overflow-y: auto;
`;

interface DataInputDialogProps {
  open: boolean;
  close: () => void;
  detailRow: PrognosisDetailDto;
}

export const DataInputDialog: FC<DataInputDialogProps> = ({
  open,
  close,
  detailRow,
}) => {
  const {
    getValues,
    formState: { errors, isDirty },
    resetField,
    clearErrors,
    setValue,
  } = useEditDetailsStepForm();
  const { mutate: updateDetail } = usePutDetail();
  const { mutate: updateBulkDetails } = usePutBulkDetails();
  const { data: details } = useGetPrognosisDetails();
  const formPrognosisDetails = getValues('prognosisDetails');
  const { data: optionalAttributes } = useGetOptionalAttributes();
  const [formDetail, setFormDetail] = useState<PrognosisDetailDto | null>(
    detailRow
  );

  const enabled = optionalAttributes?.useExistingFieldValues;
  const isTotalDepthRow = detailRow?.reservoirZone === 'Total depth';

  const { data: allValues, isFetching: fetchingAllValues } =
    useGetFieldValues(enabled);
  const { data: fieldValues, isFetching: fetchingValue } =
    useGetFieldValuesByStratUnit(detailRow, enabled);

  const index = useMemo(() => {
    const foundIndex = formPrognosisDetails.findIndex(
      (d) => d.id === detailRow.id
    );
    const foundDetail = formPrognosisDetails[foundIndex];
    setFormDetail(foundDetail);
    return foundIndex;
  }, [detailRow.id, formPrognosisDetails]);

  const isLoading = fetchingAllValues || fetchingValue;
  const generalFieldValue = allValues?.find((value) => value.isGeneral);

  const valuesCopy: FieldValues | null = fieldValues
    ? { ...fieldValues }
    : null;

  if (generalFieldValue && valuesCopy) {
    (Object.keys(valuesCopy) as (keyof FieldValues)[]).forEach(
      <T extends keyof FieldValues>(key: T) => {
        if (valuesCopy[key] === null && generalFieldValue[key] !== null) {
          valuesCopy[key] = generalFieldValue[key];
        }
      }
    );
  }

  const keysWithValue = Object.keys(valuesCopy || {}).filter((key) => {
    const checkKey = key as keyof typeof valuesCopy;
    return valuesCopy?.[checkKey];
  });

  const missingVitalKeys: boolean = vitalCalculationKeys.some(
    (key) => !keysWithValue.includes(key)
  );

  const handleAddData = () => {
    const detailErrors = errors.prognosisDetails?.[index];
    const hasDetailErrors =
      detailErrors && Object.keys(detailErrors).length > 0;

    if (isDirty && !hasDetailErrors && formDetail && details) {
      if (optionalAttributes?.commingledZones) {
        delete formDetail.colorHtml;
        const updatedDetails = details.map((data) => {
          return {
            ...data,
            ...formDetail,
            id: data.id,
            lithostratSubzone: data.lithostratSubzone,
            reservoirZone: data.reservoirZone,
          };
        });
        updateBulkDetails(updatedDetails);
      } else {
        updateDetail(formDetail);
      }

      close();
    } else if (!hasDetailErrors) {
      close();
    }
  };

  const handleOnCancel = () => {
    resetField(`prognosisDetails.${index}`, {
      defaultValue: detailRow,
    });
    close();
  };

  const handleOnClear = () => {
    const clearedDetail = {
      ...detailRow,
      ...EMPTY_DETAIL,
    } as PrognosisDetailDto;
    setFormDetail(clearedDetail);
    setValue(`prognosisDetails.${index}`, clearedDetail, {
      shouldDirty: true,
    });
    clearErrors(`prognosisDetails.${index}`);
  };

  return (
    <Dialog
      actions={[
        {
          color: 'danger',
          onClick: handleOnClear,
          text: 'Clear values',
          variant: 'ghost',
          position: 'left',
        },
        {
          color: 'primary',
          onClick: handleOnCancel,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          color: 'primary',
          onClick: handleAddData,
          text: 'Save data',
          variant: 'contained',
        },
      ]}
      title={
        <>
          <Typography variant="h6">
            Fill data for {detailRow?.lithostratSubzone}
          </Typography>
        </>
      }
      width={1150}
      withContentPadding={{ vertical: true, horizontal: true }}
      onClose={handleOnCancel}
      open={open}
      withBorders
    >
      <Container>
        {valuesCopy && optionalAttributes?.useExistingFieldValues && (
          <CalculationValues
            label="Field and zone values"
            originalFieldValue={fieldValues}
            fieldValues={valuesCopy}
            generalFieldValue={generalFieldValue!}
            missingVitalKeys={missingVitalKeys!}
            isLoading={isLoading}
          />
        )}
        {optionalAttributes?.useExistingFieldValues &&
        !missingVitalKeys &&
        valuesCopy ? (
          <FieldsWithCalculate
            index={index}
            isLoading={isLoading}
            formDetail={formDetail}
            formPrognosisDetails={formPrognosisDetails}
            fieldValues={valuesCopy}
            missingVitalKeys={missingVitalKeys!}
          />
        ) : (
          <FallbackFields
            index={index}
            isLoading={isLoading}
            formDetail={formDetail}
          />
        )}
        <General
          label="General"
          index={index}
          isLoading={isLoading}
          isTotalDepthRow={isTotalDepthRow}
        />
      </Container>
    </Dialog>
  );
};
