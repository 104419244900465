import { FC } from 'react';

import { useStepper } from '@equinor/amplify-component-lib';
import { useIsMutating } from '@tanstack/react-query';

import { StepFooter } from '../../StepFooter/StepFooter';
import { usePutValidityForm } from 'src/pages/EditPrognosis/hooks/api/usePutValidityForm';
import { useCommentStepForm } from 'src/pages/EditPrognosis/hooks/form/useCommentStepForm';
import { useArtificialLoading } from 'src/pages/EditPrognosis/hooks/util/useArtificialLoading';

import { useSavingState } from 'hooks/useSavingState';

interface CommentFooterProps {
  isInitializing: boolean;
}
export const CommentFooter: FC<CommentFooterProps> = ({ isInitializing }) => {
  const {
    trigger,
    formState: { isDirty },
  } = useCommentStepForm();
  const { mutate: putPrognosis } = usePutValidityForm();
  const { goToNextStep } = useStepper();
  const [isLoading, startLoading] = useArtificialLoading(500);
  const isMutating = useIsMutating();
  const isSaving = useSavingState(isMutating);

  const handleSubmit = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    if (isDirty) {
      putPrognosis();
      await startLoading();
    }

    goToNextStep();
  };

  return (
    <StepFooter
      handleSubmit={handleSubmit}
      isMutating={isLoading}
      isInitializing={isInitializing}
      showSavingChip
      isAutoSaving={isSaving}
    />
  );
};
