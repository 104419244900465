import { colors, spacings, Typography } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface ColorCircleProps {
  $isSelected: boolean;
  color: string;
}

export const ColorCircle = styled.div<ColorCircleProps>`
  width: 16px;
  height: 16px;
  border-radius: 12px;
  background: ${({ color }) => color};
  margin-right: ${spacings.x_small};
  border: ${({ $isSelected }) =>
    $isSelected
      ? `2px solid ${colors.interactive.primary__pressed.rgba} `
      : 'none'};
`;

export const TextContainer = styled.div`
  display: flex;
  width: 125px;
  flex-grow: 1;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.x_small};
  padding: 0 ${spacings.small};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
