import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { colors, usePrevious } from '@equinor/amplify-component-lib';
import { Brush } from '@visx/brush';
import BaseBrush from '@visx/brush/lib/BaseBrush';
import { Bounds } from '@visx/brush/lib/types';
import { Group } from '@visx/group';
import { scaleLinear, scaleTime } from '@visx/scale';

import { LineChartLine, LinePlotMargin } from '../../LineChart.types';
import { renderLeftAxisLines } from '../../LineChart.utils';
import { HorizontalBrushHandle } from './HorizontalBrushHandle';
import { useMeasuredData } from 'src/hooks';

import { BRUSH_STYLE } from 'components/Charts/LineChart/LineChart.constants';

interface LineChartBrushProps {
  wellbores: LineChartLine[];
  width: number;
  height: number;
  margin: LinePlotMargin;
  onBrushChange: (domain: Bounds | null) => void;
  setMainChartData: Dispatch<SetStateAction<[Date, Date]>>;
  showFullChart: () => void;
}

export const HorizontalBrush: FC<LineChartBrushProps> = ({
  width,
  height,
  margin,
  wellbores,
  onBrushChange,
  showFullChart,
}) => {
  const {
    currentBrushRange,
    setCurrentBrushRange,
    plotDateRangeOptions,
    daysBetweenFromToPlotRange,
  } = useMeasuredData();
  const [timeBoxStamp, setTimeBoxStamp] = useState(new Date().getTime());
  const prevFromDate = usePrevious(plotDateRangeOptions.fromDate);
  const prevToDate = usePrevious(plotDateRangeOptions.toDate);

  const brushRef = useRef<BaseBrush | null>(null);
  const xMax = useMemo(
    () => width - margin.left - margin.right,
    [margin.left, margin.right, width]
  );
  const yMax = useMemo(() => height, [height]);

  const minValue = Math.min(
    ...wellbores
      .flatMap((data) => data.data)
      .map((d) => d.value)
      .filter((value) => value > 0)
  );
  const maxValue = Math.max(
    ...wellbores
      .flatMap((data) => data.data)
      .map((d) => d.value)
      .filter((value) => value > 0)
  );

  const brushValueScalePadding = (maxValue - minValue) * 0.2;

  const brushValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [
          minValue - brushValueScalePadding,
          maxValue + brushValueScalePadding,
        ],
      }),
    [yMax, minValue, brushValueScalePadding, maxValue]
  );

  const brushDateScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: [
          plotDateRangeOptions.fromDate,
          plotDateRangeOptions.toDate,
        ] as [Date, Date],
      }),
    [xMax, plotDateRangeOptions]
  );

  const initialBrushPosition = useMemo(() => {
    if (brushDateScale.range().some((x) => x < 0)) return undefined;

    const [start, end] = currentBrushRange;
    return {
      start: {
        x: brushDateScale(start),
      },
      end: {
        x: brushDateScale(end),
      },
    };
  }, [currentBrushRange, brushDateScale]);

  useEffect(() => {
    if (
      plotDateRangeOptions.fromDate !== prevFromDate ||
      plotDateRangeOptions.toDate !== prevToDate
    ) {
      setCurrentBrushRange([
        plotDateRangeOptions.fromDate,
        plotDateRangeOptions.toDate,
      ]);
      setTimeBoxStamp(new Date().getTime());
    }
  }, [
    plotDateRangeOptions,
    prevFromDate,
    prevToDate,
    currentBrushRange,
    daysBetweenFromToPlotRange,
    setCurrentBrushRange,
  ]);

  if (!initialBrushPosition) return null;
  return (
    <div>
      <svg width={width} height={height}>
        <Group left={margin.left}>
          <rect
            y={0}
            x={0}
            width={xMax}
            height={height}
            fill={colors.ui.background__default.rgba}
            stroke={colors.ui.background__medium.rgba}
          />
          {renderLeftAxisLines(
            wellbores,
            brushDateScale,
            brushValueScale,
            true
          )}

          <Brush
            key={timeBoxStamp}
            xScale={brushDateScale}
            yScale={brushValueScale}
            width={xMax}
            height={yMax}
            margin={margin}
            handleSize={8}
            innerRef={brushRef}
            resizeTriggerAreas={['left', 'right']}
            brushDirection="horizontal"
            initialBrushPosition={initialBrushPosition}
            onChange={onBrushChange}
            onClick={showFullChart}
            selectedBoxStyle={BRUSH_STYLE}
            useWindowMoveEvents
            renderBrushHandle={(props) => <HorizontalBrushHandle {...props} />}
          />
        </Group>
      </svg>
    </div>
  );
};
