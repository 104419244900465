import { FC } from 'react';

import { ATTRIBUTE_VARIANTS, VariantOptions } from './AttributeChip.types';

import { BaseChip } from 'components/BaseChip/BaseChip';

interface AttributeChipProps {
  label: string;
  variant?: VariantOptions;
  isSelected?: boolean;

  disabled?: boolean;
  onClick?: () => void;
}

export const AttributeChip: FC<AttributeChipProps> = ({
  label,
  variant,
  isSelected,
  onClick,
  disabled,
}) => {
  const finalVariant = disabled
    ? 'disabled'
    : isSelected
      ? 'selected'
      : variant || 'default';
  const { backgroundColor, icon, textColor, asButton, borderColor, hover } =
    ATTRIBUTE_VARIANTS[finalVariant];

  return (
    <BaseChip
      label={label}
      backgroundColor={backgroundColor}
      icon={icon}
      textColor={textColor}
      disabled={disabled}
      asButton={asButton}
      borderColor={borderColor}
      onClick={onClick}
      hover={hover}
    />
  );
};
