import { PrognosisDetailDto } from 'src/api';

const getConicGradientBasedOnLength = (
  existingPrognosisDetails: PrognosisDetailDto[]
) => {
  const length = existingPrognosisDetails.length;
  const degree = 360 / length;
  let conicGradient = `conic-gradient(`;
  for (let i = 0; i < length; i++) {
    const startDegree = degree * i;
    const endDegree = degree * (i + 1);
    conicGradient += `${existingPrognosisDetails[i].colorHtml} ${startDegree}deg, ${existingPrognosisDetails[i].colorHtml} ${endDegree}deg, `;
  }
  conicGradient = conicGradient.slice(0, -2) + `)`;
  return conicGradient;
};

export const getCoMingledAttributes = (
  existingPrognosisDetails: PrognosisDetailDto[]
) => {
  return {
    id: 1,
    lithostratSubzone: existingPrognosisDetails?.length + ' Co-mingled',
    colorHtml: getConicGradientBasedOnLength(existingPrognosisDetails),
  };
};
