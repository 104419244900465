import { FC, RefObject, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dialog } from '@equinor/amplify-component-lib';
import { Autocomplete, Button, Icon } from '@equinor/eds-core-react';
import { edit, timeline } from '@equinor/eds-icons';

import { useApp, useGetUserAccess, useMeasuredData } from 'src/hooks';
import { Export } from 'src/pages/PlotView/components/Actions/Export';
import { Measurement } from 'src/types';
import { getNameFromMeasurement } from 'src/utils';

import { LinePlotDateRange } from 'components/LinePlotDateRange/LinePlotDateRange';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: start;
`;

const ActionsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

const EditIconsWrapper = styled.div`
  position: relative;
  > svg:last-child {
    position: absolute;
    top: -3px;
    left: -2px;
    transform: scale(0.65);
  }
`;

interface ActionsProps {
  copyToClipboardRef: RefObject<HTMLElement>;
}

export const Actions: FC<ActionsProps> = ({ copyToClipboardRef }) => {
  const { formattedFieldNameForUrl } = useApp();
  const { userAccessForField } = useGetUserAccess();
  const navigate = useNavigate();
  const [showEditMode, setShowEditMode] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] =
    useState<Measurement | null>(null);
  const { selectedMeasurements } = useMeasuredData();

  const handleEditMode = () => {
    if (selectedMeasurements.length === 1) {
      navigate(
        `${formattedFieldNameForUrl}/measured-data/edit/${selectedMeasurements[0]?.wellboreUuid}/${selectedMeasurements[0]?.dhpg}`
      );
    } else {
      setShowEditMode(true);
    }
  };

  const handleDialogOnClose = () => {
    setShowEditMode(false);
  };

  const handleDialogOnContinue = () => {
    navigate(
      `${formattedFieldNameForUrl}/measured-data/edit/${selectedMeasurement?.wellboreUuid}/${selectedMeasurement?.dhpg}`
    );
  };

  return (
    <Wrapper>
      <ActionsWrapper>
        <LinePlotDateRange />
        <Export copyToClipboardRef={copyToClipboardRef} />

        {(userAccessForField?.userRoles.submitter ||
          userAccessForField?.userRoles.approver) && (
          <Button
            variant="contained"
            onClick={handleEditMode}
            disabled={selectedMeasurements.length < 1}
          >
            <EditIconsWrapper>
              <Icon data={edit} />
              <Icon data={timeline} size={16} />
            </EditIconsWrapper>
            Edit
          </Button>
        )}
      </ActionsWrapper>
      <Dialog
        open={showEditMode}
        title="Edit data"
        onClose={handleDialogOnClose}
        actions={[
          { onClick: handleDialogOnClose, text: 'Cancel', variant: 'outlined' },
          {
            onClick: handleDialogOnContinue,
            text: 'Continue',
            disabled: selectedMeasurement === null,
          },
        ]}
      >
        <Autocomplete
          label=""
          autoWidth
          placeholder="Type to find wellbore..."
          options={selectedMeasurements}
          selectedOptions={selectedMeasurement ? [selectedMeasurement] : []}
          onOptionsChange={(options) => {
            setSelectedMeasurement(
              options.selectedItems.length ? options.selectedItems[0] : null
            );
          }}
          optionLabel={(item) => getNameFromMeasurement(item)}
        />
      </Dialog>
    </Wrapper>
  );
};
