import { FC, useState } from 'react';

import { TextField } from '@equinor/amplify-component-lib';
import { Dialog } from '@equinor/amplify-component-lib';

import { useCreateFilterView } from 'src/pages/PrognosisDashboard/hooks/useCreateFilterView';

interface FilterSaveDialogProps {
  open: boolean;
  toggleFilterSaveDialog: () => void;
}

export const FilterSaveDialog: FC<FilterSaveDialogProps> = ({
  open,
  toggleFilterSaveDialog,
}) => {
  const [value, setValue] = useState<string>('');
  const { mutateAsync: postFilter, isPending: isSaving } =
    useCreateFilterView(value);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSaveFilter = async () => {
    if (isSaving) return;
    await postFilter();
    setValue('');
    toggleFilterSaveDialog();
  };

  const handleCloseDialog = () => {
    setValue('');
    toggleFilterSaveDialog();
  };

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      title="Save a new filtered view"
      width={400}
      actions={[
        {
          text: 'Cancel',
          onClick: handleCloseDialog,
        },
        {
          text: 'Save',
          onClick: handleSaveFilter,
          disabled: value === '',
        },
      ]}
    >
      <TextField
        id={value}
        value={value}
        placeholder="New filtered view"
        onChange={handleOnChange}
      />
    </Dialog>
  );
};
