import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useStepper } from '@equinor/amplify-component-lib';
import { useQueryClient } from '@tanstack/react-query';

import { StepFooter } from '../../StepFooter/StepFooter';
import {
  OptionalPrognosisAttributesService,
  PrognosisDetailService,
} from 'src/api';
import { GET_OPTIONAL_ATTRIBUTES_KEY, PROGNOSIS_DETAIL } from 'src/constants';
import { usePostCreatePrognosis } from 'src/pages/EditPrognosis/hooks/api/usePostCreatePrognosis';
import { usePutCreatePrognosis } from 'src/pages/EditPrognosis/hooks/api/usePutCreatePrognosis';
import { useCreationStepForm } from 'src/pages/EditPrognosis/hooks/form/useCreationStepForm';
import { useArtificialLoading } from 'src/pages/EditPrognosis/hooks/util/useArtificialLoading';

import { useApp } from 'hooks/useApp';

interface CreateFooterProps {
  isInitializing: boolean;
}
export const CreateFooter: FC<CreateFooterProps> = ({ isInitializing }) => {
  const {
    trigger,
    formState: { isDirty },
  } = useCreationStepForm();
  const queryClient = useQueryClient();
  const { prognosisId } = useParams();
  const { formattedFieldNameForUrl } = useApp();
  const { mutateAsync: postDraft, isPending: isCreating } =
    usePostCreatePrognosis();
  const { mutateAsync: putDraft } = usePutCreatePrognosis();
  const { goToNextStep } = useStepper();
  const navigate = useNavigate();
  const [isLoading, startLoading] = useArtificialLoading(500);

  const usingId = Number(prognosisId);

  const onCreatePage = prognosisId === undefined;

  const handleSubmit = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    if (onCreatePage) {
      const newID = await postDraft();
      navigate(`${formattedFieldNameForUrl}/prognosis/edit/${newID}/1`);
    } else {
      if (isDirty) {
        putDraft();
        await startLoading();
      }
      goToNextStep();
    }
  };

  const prefetchPrognosisDetails = () => {
    queryClient.prefetchQuery({
      queryKey: [PROGNOSIS_DETAIL, usingId],
      queryFn: () => PrognosisDetailService.getApiV1PrognosesDetails1(usingId),
    });
  };

  const prefetchOptionalAttributes = () => {
    queryClient.prefetchQuery({
      queryKey: [GET_OPTIONAL_ATTRIBUTES_KEY, usingId],
      queryFn: () =>
        OptionalPrognosisAttributesService.getApiV1OptionalAttributes(usingId),
    });
  };

  useEffect(() => {
    if (prognosisId !== undefined) {
      prefetchPrognosisDetails();
      prefetchOptionalAttributes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prognosisId]);

  return (
    <StepFooter
      onCreateStep={true}
      handleSubmit={handleSubmit}
      isMutating={isLoading || isCreating}
      isInitializing={isInitializing}
    />
  );
};
