import { FC, ReactNode, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes as ReactRoutes,
  useParams,
} from 'react-router-dom';

import {
  FullPageSpinner,
  PageNotFound,
  Status,
  useAuth,
  usePrevious,
} from '@equinor/amplify-component-lib';
import {
  FeatureRoute,
  useFeatureToggling,
} from '@equinor/subsurface-app-management';

import { EditMeasuredData } from './EditMeasuredData/EditMeasuredData';
import { EditPrognosis } from './EditPrognosis/EditPrognosis';
import { FieldValues } from './FieldValues/FieldValues';
import { MapView } from './MapView/MapView';
import { PlotView } from './PlotView/PlotView';
import { PrognosisDashboard } from './PrognosisDashboard/PrognosisDashboard';
import { ViewPrognosis } from './ViewPrognosis/ViewPrognosis';
import { SelectField } from './SelectField';
import {
  FIELD_VALUES_GENERAL_FEATURE_KEY,
  FIELDS_WITH_MEASURED_DATA,
  SHOW_MEASURED_DATA_ALL_FIELDS,
} from 'src/constants';
import {
  useApp,
  useGetPrognosis,
  useGetUserAccess,
  useMeasuredDataAccessCheck,
} from 'src/hooks';
import { MeasuredDataPage } from 'src/types';
import { getFormattedFieldNameForUrl, getUsernameFromEmail } from 'src/utils';

const PrivateRoutes = () => {
  const { field, formattedFieldNameForUrl, setField } = useApp();
  const { fields, isLoadingUseAccess } = useGetUserAccess();
  const { fieldName } = useParams();

  const previousField = usePrevious(field);

  const selectedField = fields?.find((f) => {
    if (!fieldName) return false;
    return getFormattedFieldNameForUrl(f).includes(fieldName);
  });

  useEffect(() => {
    if (
      fieldName &&
      !formattedFieldNameForUrl.includes(fieldName) &&
      previousField?.uuid === field?.uuid &&
      selectedField
    ) {
      setField(selectedField);
    }
  }, [
    field,
    fieldName,
    fields,
    formattedFieldNameForUrl,
    previousField?.uuid,
    selectedField,
    setField,
  ]);

  if (field?.uuid === null || field?.uuid === '' || field?.uuid === undefined) {
    return <Navigate replace to="/select-field" />;
  }

  if (isLoadingUseAccess) {
    return <FullPageSpinner />;
  }

  return <Outlet />;
};

const MeasuredDataRoutes = () => {
  const { field } = useApp();
  const { showContent } = useFeatureToggling(SHOW_MEASURED_DATA_ALL_FIELDS);
  const { data: missingAccesses, isLoading: isLoadingAccessCheck } =
    useMeasuredDataAccessCheck();

  // TODO: remove the 'FIELDS_WITH_MEASURED_DATA' check when all fields have measured data
  if (!showContent && !FIELDS_WITH_MEASURED_DATA.includes(field?.name ?? '')) {
    return (
      <Navigate
        replace
        to={`${getFormattedFieldNameForUrl(field)}/prognosis`}
      />
    );
  }

  if (isLoadingAccessCheck) {
    return <FullPageSpinner />;
  }

  if (missingAccesses && missingAccesses.length > 0) {
    return (
      <Status>
        <Status.Title title="Missing data accesses for this page" />
        <Status.MissingAccesses
          text="In order to view measured data in Premo, you need the following accesses, with their respective links to apply for:"
          accesses={missingAccesses.map((access) => {
            return { title: access.dataBaseAccess, url: access.accessItLink };
          })}
        />
      </Status>
    );
  }

  return <Outlet />;
};

const EditRoute = () => {
  const { formattedFieldNameForUrl } = useApp();
  const { data: prognosisData } = useGetPrognosis();
  const { account } = useAuth();
  const currentUser = getUsernameFromEmail(account?.username ?? '');

  if (
    prognosisData &&
    currentUser &&
    prognosisData.createUser !== currentUser
  ) {
    return <Navigate replace to={`${formattedFieldNameForUrl}/prognosis`} />;
  }

  return <Outlet />;
};

interface AppRoutesProps {
  children?: ReactNode;
}

export const AppRoutes: FC<AppRoutesProps> = ({ children }) => {
  const { formattedFieldNameForUrl } = useApp();

  return (
    <ReactRoutes>
      <Route element={<PrivateRoutes />}>
        <Route
          path="/"
          element={
            <Navigate replace to={`${formattedFieldNameForUrl}/prognosis`} />
          }
        />
        <Route
          element={<MeasuredDataRoutes />}
          path="/:fieldName/measured-data"
        >
          <Route path={`${MeasuredDataPage.MAP}`} element={<MapView />} />
          <Route path={`${MeasuredDataPage.PLOT}`} element={<PlotView />} />
          <Route
            path={`${MeasuredDataPage.EDIT}/:wellId/:dhpg`}
            element={<EditMeasuredData />}
          />
        </Route>
        <Route path="/:fieldName/prognosis">
          <Route
            path="field-values"
            element={
              <FeatureRoute
                element={<FieldValues />}
                featureUuid={FIELD_VALUES_GENERAL_FEATURE_KEY}
              />
            }
          />
          <Route index element={<PrognosisDashboard />} />
          <Route path="create/:step" element={<EditPrognosis />} />
          <Route element={<EditRoute />}>
            <Route path="edit/:prognosisId/:step" element={<EditPrognosis />} />
          </Route>
          <Route
            path="*"
            element={
              <Navigate replace to={`${formattedFieldNameForUrl}/prognosis`} />
            }
          />
          <Route path="view/:prognosisId" element={<ViewPrognosis />} />
        </Route>
      </Route>
      <Route path="/select-field" element={<SelectField />} />
      <Route path="*" element={<PageNotFound />} />
      {children}
    </ReactRoutes>
  );
};
