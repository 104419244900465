import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import {
  Chip,
  colors,
  OptionDrawer,
  Search,
  shape,
  spacings,
} from '@equinor/amplify-component-lib';
import { ToggleEventProps } from '@equinor/amplify-component-lib/dist/molecules/OptionDrawer/OptionDrawer';

import { StratigraphicUnit, Stratigraphy } from 'src/api';
import { useZoneSelector } from 'src/pages/EditPrognosis/hooks/util/useZoneSelector';
import { flattenOptions } from 'src/utils';

import styled from 'styled-components';

const ChipContainer = styled.div`
  display: flex;
  gap: ${spacings.small};
  padding-bottom: ${spacings.medium};
  flex-wrap: wrap;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.ui.background__medium.rgba};
  border-radius: ${shape.corners.borderRadius};
  height: 100%;
`;

const OptionDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${spacings.small};
  overflow-y: auto;
`;

interface ZoneNavigatorProps {
  column: string;
  data: Stratigraphy[];
  savedSelectedItems: MappedStratigraphicUnit[];
}

export interface MappedStratigraphicUnit extends StratigraphicUnit {
  id: string;
  label: string;
  value: string;
  children?: MappedStratigraphicUnit[];
  disabled?: boolean;
}

export const ZoneNavigator: FC<ZoneNavigatorProps> = ({
  column,
  data,
  savedSelectedItems,
}) => {
  const { selectedStratUnits, setSelectedStratUnits } = useZoneSelector();

  const { stratUnits } =
    data?.find(
      ({ stratColumnIdentifier }) => stratColumnIdentifier === column
    ) ?? {};
  const [searchValue, setSearchValue] = useState('');

  const mapStratigraphicUnit = useCallback(
    (unit: StratigraphicUnit): MappedStratigraphicUnit => {
      const children = unit.stratigraphicUnits?.map(mapStratigraphicUnit) || [];
      return {
        id: unit.stratUnitIdentifier || '',
        label: unit.stratUnitIdentifier || '',
        value: unit.stratUnitIdentifier || '',
        disabled: savedSelectedItems?.some(
          (item) => item.value === unit.stratUnitIdentifier
        ),
        ...unit,
        ...(children.length > 0 && { children }),
      };
    },
    [savedSelectedItems]
  );

  const processIntermediateUnits = useCallback(() => {
    return stratUnits?.map(mapStratigraphicUnit) ?? [];
  }, [stratUnits, mapStratigraphicUnit]);

  const handleOnToggle = (event: ToggleEventProps<MappedStratigraphicUnit>) => {
    if (event.items.length === 0) return;
    const item = event.items[0];

    const isSelected = selectedStratUnits.some(
      (selectedItem) => selectedItem.value === item.value
    );

    const newSelectedItems = isSelected
      ? selectedStratUnits.filter(
          (selectedItem) => selectedItem.value !== item.value
        )
      : [...selectedStratUnits, item];

    setSelectedStratUnits(newSelectedItems);
  };

  const filterUnits = useMemo(() => {
    if (searchValue === '') {
      return processIntermediateUnits();
    }

    const flatOptions = flattenOptions(processIntermediateUnits());
    return flatOptions.filter((option) => {
      const searchTerms = searchValue.toLowerCase().split(' ');
      return searchTerms.every((term) =>
        option.label.toLowerCase().includes(term)
      );
    });
  }, [searchValue, processIntermediateUnits]);

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const optionsDrawerCombinedSelectedItems = useMemo(() => {
    return [...selectedStratUnits, ...savedSelectedItems];
  }, [savedSelectedItems, selectedStratUnits]);

  return (
    <>
      <ChipContainer>
        {savedSelectedItems.map((item, index) => {
          return <Chip key={`${item.value}-${index}`}>{item.value}</Chip>;
        })}
        {selectedStratUnits.map((item, index) => {
          return (
            <Chip
              key={`${item.value}-${index}`}
              onDelete={() => {
                const newSelectedItems = selectedStratUnits.filter(
                  (selectedItem) => selectedItem.value !== item.value
                );

                setSelectedStratUnits(newSelectedItems);
              }}
            >
              {item.value}
            </Chip>
          );
        })}
      </ChipContainer>
      <SearchContainer>
        <Search
          id="subzone-search"
          onChange={handleOnSearch}
          placeholder="Search for subzones"
        />
        <OptionDrawerWrapper data-testid="zone-navigator-options">
          {filterUnits.map((unit) => {
            return (
              <OptionDrawer
                key={unit.stratUnitIdentifier}
                item={unit}
                onToggle={handleOnToggle}
                parentHasNestedItems
                singleSelect
                selectedItems={optionsDrawerCombinedSelectedItems}
                showIntermediateParent
              />
            );
          })}
        </OptionDrawerWrapper>
      </SearchContainer>
    </>
  );
};
