import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ConfigService, FieldConfigDto } from '../api';
import { useApp } from './useApp';
import { GET_FIELD_CONFIG_KEY } from 'src/constants';
import { TWELVE_HOURS } from 'src/constants/staleTime';

export function useGetFieldConfig() {
  const { field } = useApp();
  return useQuery({
    queryKey: [GET_FIELD_CONFIG_KEY, field?.uuid],
    queryFn: () => ConfigService.getFieldConfig(field?.uuid ?? ''),
    gcTime: TWELVE_HOURS,
    staleTime: TWELVE_HOURS,
    enabled: !!field?.uuid,
  });
}

export function usePatchFieldConfig() {
  const { field } = useApp();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FieldConfigDto) =>
      ConfigService.updateFieldConfig(field?.uuid ?? '', data),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [GET_FIELD_CONFIG_KEY, field?.uuid],
      }),
  });
}
