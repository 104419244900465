import { useNavigate } from 'react-router-dom';

import { environment, Field, TopBar } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';
import { useIsFetching } from '@tanstack/react-query';

import { Account } from './Content/Account';
import { Guidelines } from './Content/Guidelines';
import { Settings } from './Content/Settings';
import { TOPBAR_GUIDELINES } from 'src/constants';
import { useApp } from 'src/hooks';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';
import { getFormattedFieldNameForUrl } from 'src/utils';

const { getAppName } = environment;

export const ApplicationTopBar = () => {
  const { field } = useApp();
  const navigate = useNavigate();
  const isFetching = useIsFetching() > 0;
  const { fields } = useGetUserAccess();
  const { showContent } = useFeatureToggling(TOPBAR_GUIDELINES);

  const handleSetField = (newField: Field) => {
    navigate(`${getFormattedFieldNameForUrl(newField)}/prognosis`); // TODO: maybe improve logic to navigate to home of whatever "part" you are on
  };

  return (
    <TopBar
      applicationIcon="premo"
      applicationName={getAppName(import.meta.env.VITE_NAME)}
      isFetching={isFetching}
      environment={environment.getEnvironmentName(
        import.meta.env.VITE_ENVIRONMENT_NAME
      )}
      currentField={field}
      onSelectField={handleSetField}
      availableFields={fields}
    >
      <TopBar.Actions>
        <Account key="topbar-account" />
        <Settings key="topbar-settings" />
        {showContent && <Guidelines />}
        <TopBar.Resources hideLearnMore />
      </TopBar.Actions>
    </TopBar>
  );
};
