import { FC } from 'react';

import { Dialog, Typography } from '@equinor/amplify-component-lib';
import { ColumnMeta, flexRender, Table } from '@tanstack/react-table';

import { InfoWrapper } from '../InfoWrapper/InfoWrapper';
import { PinnedRow } from '../PinnedRow/PinnedRow';
import {
  Footer,
  ScrollableDiv,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from './BaseTable.styles';
import { PrognosisDetailDto } from 'src/api';

export interface CustomColumnMeta
  extends ColumnMeta<PrognosisDetailDto, unknown> {
  headerProps?: React.HTMLAttributes<HTMLTableCellElement>;
  cellProps?: React.HTMLAttributes<HTMLTableCellElement>;
}

interface BaseTableProps {
  table: Table<PrognosisDetailDto>;
  comment: string;
  openCommentModal: boolean;
  setOpenCommentModal: (open: boolean) => void;
  hasErrors?: boolean;
  hasUpdatedCells?: boolean;
}

export const BaseTable: FC<BaseTableProps> = ({
  table,
  comment,
  openCommentModal,
  setOpenCommentModal,
  hasErrors,
  hasUpdatedCells,
}) => {
  const handleOnCloseComment = () => {
    setOpenCommentModal(false);
  };

  return (
    <>
      <ScrollableDiv>
        <TableContainer>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader
                    key={header.id}
                    colSpan={header.colSpan}
                    {...(header.column.columnDef.meta as CustomColumnMeta)
                      ?.headerProps}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHeader>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getCenterRows().map((row, index) => {
              const isLastRow = index === table.getRowModel().rows.length - 1;
              const isSelected = row.getIsSelected();
              return (
                <TableRow key={row.id} onClick={row.getToggleSelectedHandler()}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      $isLastRow={isLastRow}
                      $isSelected={isSelected}
                      {...(cell.column.columnDef.meta as CustomColumnMeta)
                        ?.cellProps}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {table.getBottomRows().map((row) => (
              <PinnedRow key={row.id} row={row} />
            ))}
          </tbody>
        </TableContainer>
      </ScrollableDiv>
      <Footer>
        <InfoWrapper hasUpdatedCell={hasUpdatedCells} hasErrors={hasErrors} />
      </Footer>
      {openCommentModal && (
        <Dialog
          title="Detail comment"
          onClose={handleOnCloseComment}
          open={openCommentModal}
          isDismissable
          width={400}
        >
          <Typography>{comment}</Typography>
        </Dialog>
      )}
    </>
  );
};
