import { useMutation } from '@tanstack/react-query';

import { BlobService } from 'src/api';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePostBlob = () => {
  const usingId = usePrognosisIDFromParams();

  return useMutation({
    mutationFn: async (image: File) => {
      return await BlobService.postApiV1Blob(usingId, {
        file: image,
      });
    },
  });
};
