import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { OptionalAttributes } from '../../FormData/OptionalAttributes/OptionalAttributes';
import { ZoneSelector } from '../../FormData/ZoneSelector/ZoneSelector';
import { MainContentGrid, Separator } from '../Steps.styles';
import { ZoneFooter } from './ZoneFooter/ZoneFooter';
import { useInitializeZoneSelectionStep } from 'src/pages/EditPrognosis/hooks/util/useInitializeZoneSelectionStep';
import { ZoneSelectionProvider } from 'src/pages/EditPrognosis/providers/ZoneSelectionProvider';
import { zoneSelectionSchema, ZoneSelectionStepFormType } from 'src/utils';

export const ZoneSelectionStep: FC = () => {
  const methods = useForm<ZoneSelectionStepFormType>({
    resolver: zodResolver(zoneSelectionSchema),
    defaultValues: {
      optionalAttributes: {
        datumDepth: false,
        pressureInitial: false,
        pressureMax: false,
        pressureMin: false,
        segment: false,
        totalDepthRow: false,
        useExistingFieldValues: false,
        commingledZones: false,
      },
    },
  });

  const { isInitializing } = useInitializeZoneSelectionStep({
    formReset: methods.reset,
  });

  return (
    <FormProvider {...methods}>
      <ZoneSelectionProvider>
        <MainContentGrid>
          <OptionalAttributes isInitializing={isInitializing} />
          <Separator />
          <ZoneSelector isInitializing={isInitializing} />
        </MainContentGrid>
        <ZoneFooter isInitializing={isInitializing} />
      </ZoneSelectionProvider>
    </FormProvider>
  );
};
