/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FieldValues } from '../models/FieldValues';
import type { FieldValuesDto } from '../models/FieldValuesDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FieldValuesService {
  /**
   * Get field values for a field
   * @param fieldUuid
   * @returns FieldValues Success
   * @throws ApiError
   */
  public static getApiV1FieldValuesField(
    fieldUuid: string
  ): CancelablePromise<Array<FieldValues>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/FieldValues/Field/{fieldUuid}',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get field values for a strat unit
   * @param fieldUuid
   * @param stratColumnIdentifier
   * @param stratUnitIdentifier
   * @returns FieldValues Success
   * @throws ApiError
   */
  public static getApiV1FieldValuesFieldStratColumnStratUnit(
    fieldUuid: string,
    stratColumnIdentifier: string,
    stratUnitIdentifier: string
  ): CancelablePromise<FieldValues> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/FieldValues/Field/{fieldUuid}/StratColumn/{stratColumnIdentifier}/StratUnit/{stratUnitIdentifier}',
      path: {
        fieldUuid: fieldUuid,
        stratColumnIdentifier: stratColumnIdentifier,
        stratUnitIdentifier: stratUnitIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Create new field values
   * @param requestBody
   * @returns number Created
   * @throws ApiError
   */
  public static postApiV1FieldValues(
    requestBody?: FieldValuesDto
  ): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/FieldValues',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Update field values
   * @param id
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static putApiV1FieldValues(
    id: number,
    requestBody?: FieldValuesDto
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/FieldValues/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Delete field values
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static deleteApiV1FieldValues(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/FieldValues/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
