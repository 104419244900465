import { spacings } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

export const ChipWrapper = styled.div`
  display: flex;
  gap: ${spacings.small};
  flex-wrap: wrap;
`;

export const SingleSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${spacings.small};
`;

export const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: ${spacings.small};
  align-items: end;
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: ${spacings.small};
  align-items: center;
`;
