import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { usePrevious } from '@equinor/amplify-component-lib';
import { useQueryClient } from '@tanstack/react-query';

import { WellboreDetailed } from '../api';
import { PRES_DATA_FOR_WELLBORES_FLAT_KEY } from '../constants';
import { useApp } from 'src/hooks';
import { useGetDrilledWellsFeatures } from 'src/hooks/useGetDrilledWellsFeatures';
import {
  MapDateRangeOptions,
  Measurement,
  PlotDateRangeOptions,
} from 'src/types';
import { compareMeasurements, getDateForDaysIntoPast } from 'src/utils';

interface MeasuredDataContextType {
  wellbores: WellboreDetailed[];
  mapDateRangeOptions: MapDateRangeOptions;
  handleSetMapDateRangeOptions: (options: Partial<MapDateRangeOptions>) => void;
  plotDateRangeOptions: PlotDateRangeOptions;
  handleSetPlotDateRangeOptions: (
    options: Partial<PlotDateRangeOptions>
  ) => void;
  daysBetweenFromToPlotRange: number;
  currentBrushRange: [Date, Date];
  setCurrentBrushRange: Dispatch<SetStateAction<[Date, Date]>>;
  selectedMeasurements: Measurement[];
  setSelectedMeasurements: Dispatch<SetStateAction<Measurement[]>>;
  addMeasurements: (measurements: Measurement[]) => void;
  removeMeasurement: (measurement: Measurement) => void;
  clear: () => void;
}

export const MeasuredDataContext = createContext<
  MeasuredDataContextType | undefined
>(undefined);

interface MeasuredDataProviderProps {
  children: ReactNode;
}

export const MeasuredDataProvider: FC<MeasuredDataProviderProps> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const { field } = useApp();
  const previousField = usePrevious(field);
  const [selectedMeasurements, setSelectedMeasurements] = useState<
    Measurement[]
  >([]);

  const { data: wellbores } = useGetDrilledWellsFeatures();

  const [mapDateRangeOptions, setMapDateRangeOptions] =
    useState<MapDateRangeOptions>({
      range: 30,
      dateForRange: getDateForDaysIntoPast(1),
      selectedDate: getDateForDaysIntoPast(1),
    });

  const handleSetMapDateRangeOptions = (
    options: Partial<MapDateRangeOptions>
  ) => {
    setMapDateRangeOptions((prev) => ({
      ...prev,
      ...options,
    }));
  };

  const [plotDateRangeOptions, setPlotDateRangeOptions] =
    useState<PlotDateRangeOptions>({
      fromDate: getDateForDaysIntoPast(91),
      toDate: getDateForDaysIntoPast(1),
    });

  const handleSetPlotDateRangeOptions = (
    options: Partial<PlotDateRangeOptions>
  ) => {
    setPlotDateRangeOptions((prev) => ({
      ...prev,
      ...options,
    }));
  };

  useEffect(() => {
    if (field && previousField !== field) {
      setSelectedMeasurements([]);
    }
  }, [field, previousField]);

  const daysBetweenFromToPlotRange = useMemo(() => {
    return Math.round(
      (plotDateRangeOptions.toDate.getTime() -
        plotDateRangeOptions.fromDate.getTime()) /
        (1000 * 3600 * 24) // Unix time between fromDate/toDate divided by unix time for a single day
    );
  }, [plotDateRangeOptions]);

  const [currentBrushRange, setCurrentBrushRange] = useState<[Date, Date]>([
    plotDateRangeOptions.fromDate,
    plotDateRangeOptions.toDate,
  ]);

  const addMeasurements = (measurements: Measurement[]) => {
    setSelectedMeasurements((sm) => [
      ...sm,
      ...measurements.filter(
        (m) => sm.find((s) => compareMeasurements(s, m)) === undefined
      ),
    ]);
  };

  const removeMeasurement = (measurement: Measurement) => {
    const dhpg = measurement.dhpg === 0 ? 1 : measurement.dhpg;
    setSelectedMeasurements((Measurements) => [
      ...Measurements.filter(
        (w) => !(w.value === measurement.value && w.dhpg === dhpg)
      ),
    ]);
    queryClient.invalidateQueries({
      queryKey: [
        PRES_DATA_FOR_WELLBORES_FLAT_KEY,
        measurement.value,
        plotDateRangeOptions.fromDate.toDateString(),
        plotDateRangeOptions.toDate.toDateString(),
        field?.uuid,
      ],
    });
  };

  return (
    <MeasuredDataContext.Provider
      value={{
        mapDateRangeOptions,
        handleSetMapDateRangeOptions,
        plotDateRangeOptions,
        handleSetPlotDateRangeOptions,
        wellbores: wellbores ?? [],
        daysBetweenFromToPlotRange,
        currentBrushRange,
        setCurrentBrushRange,
        selectedMeasurements,
        setSelectedMeasurements,
        removeMeasurement,
        addMeasurements,
        clear: () => setSelectedMeasurements([]),
      }}
    >
      {children}
    </MeasuredDataContext.Provider>
  );
};
