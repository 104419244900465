import { useMemo } from 'react';

import { SelectOptionRequired } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import {
  Operation,
  OperationPhase,
  PrognosisDto,
  Wellbore,
  WellTarget,
} from 'src/api';
import { TARGET_OPERATION } from 'src/constants';
import { lookupValueToComboBoxRequired, OperationValues } from 'src/utils';

interface GetInitialCreateOptionsProps {
  selectedOperation: Operation | undefined;
  existingPrognosis: PrognosisDto | undefined;
  newWellPhases: OperationPhase[] | undefined;
  interventionPhases: OperationPhase[] | undefined;
  targetPhases: OperationPhase[] | undefined;
  ciWellTargets: WellTarget[] | undefined;
  allWellbores: Wellbore[] | undefined;
  plannedWellbores: Wellbore[] | undefined;
  drilledWellbores: Wellbore[] | undefined;
}

/**
 * Retrieves the initial options for creating a form based on the selected operation and existing prognosis.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Operation} params.selectedOperation - The selected operation.
 * @param {Prognosis} params.existingPrognosis - The existing prognosis.
 * @param {OperationPhase[]} params.newWellPhases - The phases for a new well operation.
 * @param {OperationPhase[]} params.interventionPhases - The phases for an intervention operation.
 * @param {OperationPhase[]} params.targetPhases - The phases for a target
 * @param {WellTarget[]} params.ciWellTargets - The CI well targets.
 * @param {Wellbore[]} params.allWellbores - All available wellbores.
 * @param {Wellbore[]} params.plannedWellbores - The planned wellbores.
 * @param {Wellbore[]} params.drilledWellbores - The drilled wellbores.
 * @returns {Object} The initial options for the form.
 * @returns {Wellbore | undefined} return.selectedWellbore - The selected wellbore.
 * @returns {OperationPhase | undefined} return.selectedOperationPhase - The selected operation phase.
 */
export const getInitialCreateOptions = ({
  selectedOperation,
  existingPrognosis,
  newWellPhases,
  interventionPhases,
  targetPhases,
  ciWellTargets,
  allWellbores,
  plannedWellbores,
  drilledWellbores,
}: GetInitialCreateOptionsProps) => {
  if (!selectedOperation || !existingPrognosis) {
    return {
      selectedWellbore: undefined,
      selectedOperationPhase: undefined,
    };
  }

  const operationValue = selectedOperation.id as OperationValues;
  const { wellboreIdentifier, operationPhaseId } = existingPrognosis;

  let selectedWellbore: Wellbore | undefined;
  let selectedOperationPhase: OperationPhase | undefined;
  let selectedCiWellTarget: WellTarget | undefined;

  switch (operationValue) {
    case OperationValues.NEW_WELL:
      selectedWellbore = plannedWellbores?.find(
        (well) => well.uniqueWellboreIdentifier === wellboreIdentifier
      );
      selectedOperationPhase = newWellPhases?.find(
        (phase) => phase.id === operationPhaseId
      );
      break;

    case OperationValues.INTERVENTION:
      selectedWellbore = drilledWellbores?.find(
        (well) => well.uniqueWellboreIdentifier === wellboreIdentifier
      );
      selectedOperationPhase = interventionPhases?.find(
        (phase) => phase.id === operationPhaseId
      );
      break;

    case OperationValues.P_N_A:
      selectedWellbore = drilledWellbores?.find(
        (well) => well.uniqueWellboreIdentifier === wellboreIdentifier
      );
      break;

    case OperationValues.TARGET:
      selectedWellbore = allWellbores?.find(
        (well) => well.uniqueWellboreIdentifier === wellboreIdentifier
      );
      selectedOperationPhase = targetPhases?.find(
        (phase) => phase.id === operationPhaseId
      );
      selectedCiWellTarget = ciWellTargets?.find(
        (target) => target.targetUuid === existingPrognosis.ciTargetUuid
      );
      break;

    default:
      selectedWellbore = allWellbores?.find(
        (well) => well.uniqueWellboreIdentifier === wellboreIdentifier
      );
  }

  return { selectedWellbore, selectedOperationPhase, selectedCiWellTarget };
};

interface UseMemoizedOptionsProps {
  operations: Operation[] | undefined;
  newWellPhases: OperationPhase[] | undefined;
  interventionPhases: OperationPhase[] | undefined;
  targetPhases: OperationPhase[] | undefined;
  ciWellTargets: WellTarget[] | undefined;
  allWellbores: Wellbore[] | undefined;
  plannedWellbores: Wellbore[] | undefined;
  drilledWellbores: Wellbore[] | undefined;
  operationId: number | undefined;
}

export const getOperationPhaseLabel = (operationId: number) => {
  switch (operationId) {
    case OperationValues.NEW_WELL:
    case OperationValues.TARGET:
      return 'Planning phase';
    case OperationValues.INTERVENTION:
      return 'Suboperation';
    default:
      return 'Locked';
  }
};

/**
 * Custom hook to create form options for various dropdowns used in the Edit Prognosis page.
 *
 * @param {Object} params - The parameters object.
 * @param {Array} params.operations - List of operations.
 * @param {Array} params.newWellPhases - List of new well phases.
 * @param {Array} params.interventionPhases - List of intervention phases.
 * @param {Array} params.targetPhases - List of target phases.
 * @param {Array} params.ciWellTargets - List of well targets.
 * @param {Array} params.allWellbores - List of all wellbores.
 * @param {Array} params.plannedWellbores - List of planned wellbores.
 * @param {Array} params.drilledWellbores - List of drilled wellbores.
 * @param {string} params.operationId - The ID of the selected operation.
 *
 * @returns {Object} An object containing the following properties:
 * - `operationOptions`: Array of options for the operations dropdown.
 * - `newWellPhasesOptions`: Array of options for the new well phases dropdown.
 * - `interventionPhasesOptions`: Array of options for the intervention phases dropdown.
 * - `allWellboresOptions`: Array of options for the all wellbores dropdown.
 * - `plannedWellboresOptions`: Array of options for the planned wellbores dropdown.
 * - `drilledWellboresOptions`: Array of options for the drilled wellbores dropdown.
 * - `lockedValueText`: Text to display when the operation is locked.
 */
export const useCreateFormOptions = ({
  operations = [],
  newWellPhases = [],
  interventionPhases = [],
  targetPhases = [],
  ciWellTargets = [],
  allWellbores = [],
  plannedWellbores = [],
  drilledWellbores = [],
  operationId,
}: UseMemoizedOptionsProps) => {
  const { showContent: showTargetFeature } =
    useFeatureToggling(TARGET_OPERATION);

  const operationOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      // Filter out the target operation if its featured toggled off
      operations
        ?.filter((item) => {
          if (item.id === OperationValues.TARGET && !showTargetFeature) {
            return false;
          }
          return item;
        })
        ?.map((item) => ({
          value: item.id.toString() ?? '',
          label: lookupValueToComboBoxRequired(item.name, true).label,
        })) ?? []
    );
  }, [operations, showTargetFeature]);

  const newWellPhasesOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      newWellPhases?.map((item) => ({
        value: item.id.toString(),
        label: lookupValueToComboBoxRequired(item.name, true).label,
      })) ?? []
    );
  }, [newWellPhases]);

  const interventionPhasesOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      interventionPhases?.map((item) => ({
        value: item.id?.toString() ?? '',
        label: lookupValueToComboBoxRequired(item.name, true).label,
      })) ?? []
    );
  }, [interventionPhases]);

  const targetPhasesOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      targetPhases?.map((item) => ({
        value: item.id?.toString() ?? '',
        label: lookupValueToComboBoxRequired(item.name, true).label,
      })) ?? []
    );
  }, [targetPhases]);

  const ciWellTargetsOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      ciWellTargets?.map((item) => ({
        value: item.targetUuid ?? '',
        label: item.targetName ?? 'N/A',
      })) ?? []
    );
  }, [ciWellTargets]);

  const allWellboresOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      allWellbores?.map((item) => ({
        value: item.wellboreUuid ?? '',
        label: item.uniqueWellboreIdentifier ?? 'N/A',
      })) ?? []
    );
  }, [allWellbores]);

  const plannedWellboresOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      plannedWellbores?.map((item) => ({
        value: item.wellboreUuid ?? '',
        label: item.uniqueWellboreIdentifier ?? 'N/A',
      })) ?? []
    );
  }, [plannedWellbores]);

  const drilledWellboresOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      drilledWellbores?.map((item) => ({
        value: item.wellboreUuid ?? '',
        label: item.uniqueWellboreIdentifier ?? 'N/A',
      })) ?? []
    );
  }, [drilledWellbores]);

  const lockedValueText = useMemo(() => {
    if (operationId === OperationValues.OTHER) {
      return 'Other';
    } else if (operationId === OperationValues.P_N_A) {
      return 'P&a';
    } else {
      return 'Select an operation first';
    }
  }, [operationId]);

  const operationPhaseLabel = useMemo(() => {
    return getOperationPhaseLabel(operationId as number);
  }, [operationId]);

  return {
    operationOptions,
    newWellPhasesOptions,
    interventionPhasesOptions,
    targetPhasesOptions,
    ciWellTargetsOptions,
    allWellboresOptions,
    plannedWellboresOptions,
    drilledWellboresOptions,
    lockedValueText,
    operationPhaseLabel,
    showTargetFeature,
  };
};
