import { FC } from 'react';

import { CircularProgress } from '@equinor/amplify-component-lib';
import { useIsFetching } from '@tanstack/react-query';

import { MEASURED_DATA_MAP_KEY } from 'src/constants';
import { useMapData, useMapOptions } from 'src/pages/MapView/hooks';

import styled, { keyframes } from 'styled-components';

const spawn = keyframes`
 from {
   opacity: 0;
 }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 200;
  justify-content: center;
  align-items: center;
  user-select: none;
  animation: ${spawn} 800ms;
`;

export const LoadingScrim: FC = () => {
  const { isLoading: isLoadingHeatmap } = useMapOptions();
  const { pressurePoints } = useMapData();
  const isLoading =
    useIsFetching({
      predicate: (query) => {
        return query.queryKey.includes(MEASURED_DATA_MAP_KEY);
      },
    }) > 0 || pressurePoints === undefined;

  if (isLoading || isLoadingHeatmap)
    return (
      <Container>
        <CircularProgress color="neutral" />
      </Container>
    );
  return null;
};
