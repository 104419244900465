import { useQuery } from '@tanstack/react-query';

import { usePrognosisIDFromParams } from './usePrognosisIDFromParams';
import { OverlappingPrognosisService } from 'src/api';
import { OVERLAPPING_PROGNOSES } from 'src/constants';

export const useOverlappingPrognoses = () => {
  const usingId = usePrognosisIDFromParams();

  return useQuery({
    queryKey: [OVERLAPPING_PROGNOSES, usingId],
    queryFn: () =>
      OverlappingPrognosisService.getApiV1PrognosesOverlappingPrognoses(
        usingId
      ),
  });
};
