import { useSnackbar, useStepper } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisDto, PrognosisPatchDto, PrognosisService } from 'src/api';
import { PROGNOSIS_KEY } from 'src/constants';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePutStep = () => {
  const queryClient = useQueryClient();
  const usingId = usePrognosisIDFromParams();
  const { currentStep } = useStepper();
  const { showSnackbar } = useSnackbar();

  const body: PrognosisPatchDto = {
    currentStep: currentStep,
  };

  return useMutation({
    mutationFn: async () => {
      return await PrognosisService.patchApiV1Prognoses(usingId, body);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_KEY, usingId],
      });

      const previousPrognosis: PrognosisDto | undefined =
        queryClient.getQueryData([PROGNOSIS_KEY, usingId]);

      queryClient.setQueryData([PROGNOSIS_KEY, usingId], {
        ...previousPrognosis,
        ...body,
      } as PrognosisDto);

      return { previousPrognosis };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_KEY, usingId],
        context?.previousPrognosis ?? []
      );
      showSnackbar('Failed to update prognosis.');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_KEY, usingId],
      });
    },
  });
};
