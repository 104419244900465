import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import { EquipmentCollectionDto, EquipmentMappingService } from 'src/api';
import { ADD_EQUIPMENT_MAPPINGS, GET_EQUIPMENT_MAPPINGS } from 'src/constants';
import { EquipmentMapping, EquipmentType } from 'src/types';

import { useApp } from 'hooks/useApp';
import { useMeasuredData } from 'hooks/useMeasuredData';

export const useEquipmentMapping = (wellId?: string, dhpg?: string) => {
  const { field } = useApp();
  const { wellId: wellIdFromParams, dhpg: dhpgFromParams } = useParams();
  const { wellbores } = useMeasuredData();

  const currentWellbore = useMemo(
    () =>
      wellbores.find((wellbore) => wellbore.wellboreUuid === wellIdFromParams),
    [wellIdFromParams, wellbores]
  );

  const wellIdToUse = wellIdFromParams ?? wellId;
  const dhpgToUse = Number(dhpgFromParams ?? dhpg);

  if (!wellIdToUse) {
    throw new Error('Missing wellId from either params or argument');
  }

  const { data: equipmentMappingOptionsForWell } = useQuery({
    queryKey: [GET_EQUIPMENT_MAPPINGS, wellIdToUse],
    queryFn: () => EquipmentMappingService.getEquipment(wellIdToUse),
  });

  const { mutateAsync: addEquipment } = useMutation({
    mutationKey: [ADD_EQUIPMENT_MAPPINGS],
    mutationFn: (equipmentMappings: EquipmentCollectionDto) =>
      EquipmentMappingService.addEquipment(
        wellIdToUse,
        field?.uuid ?? '',
        equipmentMappings
      ),
  });

  const equipmentCollectionForCurrentPressurePoint: EquipmentCollectionDto =
    useMemo(() => {
      return {
        gauge: {
          gaugeMdCorrected: currentWellbore?.gaugeMdFinal,
          gaugeTvdCorrected: currentWellbore?.gaugeTvdFinal,
        },
        screenOrPerforation:
          dhpgToUse === 1
            ? {
                gaugeNo: 1,
                mdCorrected: currentWellbore?.md1Final,
                tvdCorrected: currentWellbore?.tvd1Final,
                pointTypeCorrected: currentWellbore?.dhpg1PointTypeFinal,
              }
            : {
                gaugeNo: 2,
                mdCorrected: currentWellbore?.md2Final,
                tvdCorrected: currentWellbore?.tvd2Final,
                pointTypeCorrected: currentWellbore?.dhpg2PointTypeFinal,
              },
      };
    }, [currentWellbore, dhpgToUse]);

  const equipmentForPressurePoint: EquipmentMapping[] = useMemo(() => {
    if (!currentWellbore) return [];
    const mappingsArray = [];
    if (currentWellbore.gaugeMd !== currentWellbore.gaugeMdFinal) {
      mappingsArray.push({
        fromMd: currentWellbore.gaugeMd,
        toMd: currentWellbore.gaugeMdFinal,
        type: EquipmentType.GAUGE,
      });
    }
    if (currentWellbore.md1 !== currentWellbore.md1Final && dhpgToUse === 1) {
      mappingsArray.push({
        fromMd: currentWellbore.md1,
        toMd: currentWellbore.md1Final,
        type: EquipmentType.DHPG,
      });
    }
    if (currentWellbore.md2 !== currentWellbore.md2Final && dhpgToUse === 2) {
      mappingsArray.push({
        fromMd: currentWellbore.md2,
        toMd: currentWellbore.md2Final,
        type: EquipmentType.DHPG,
      });
    }

    return mappingsArray;
  }, [currentWellbore, dhpgToUse]);

  const originalValuesForPressurePoint = useMemo(() => {
    if (!currentWellbore) return;
    return {
      gauge: {
        md: currentWellbore.gaugeMd,
        tvd: currentWellbore.gaugeTvd,
      },
      screenOrPerf:
        dhpgToUse === 1
          ? {
              md: currentWellbore.md1,
              tvd: currentWellbore.tvd1,
              pointType: currentWellbore.dhpg1PointType,
            }
          : {
              md: currentWellbore.md2,
              tvd: currentWellbore.tvd2,
              pointType: currentWellbore.dhpg2PointType,
            },
    };
  }, [currentWellbore, dhpgToUse]);

  return {
    originalValuesForPressurePoint,
    equipmentCollectionForCurrentPressurePoint,
    equipmentMappingOptionsForWell,
    addEquipment,
    equipmentForPressurePoint,
  };
};
