import { CSSProperties, forwardRef } from 'react';

import {
  getHoverFillColor,
  getMarkerBorderColor,
  getMarkerFillColor,
} from 'src/pages/MapView/MapView.utils';
import { WellborePurpose } from 'src/types';

import styled from 'styled-components';

interface CircleProps {
  $selected: boolean;
  $purpose: WellborePurpose;
}

const Circle = styled.div<CircleProps>`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ $purpose }) => getMarkerFillColor($purpose)};
  border: ${({ $purpose, $selected }) =>
    `2px solid ${getMarkerBorderColor($purpose, $selected)}`};
  position: absolute;
  z-index: 10;
  :hover {
    background-color: ${({ $selected }) => getHoverFillColor($selected)};
  }
`;

interface MarkerProps {
  style: CSSProperties;
  onClick: () => void;
  purpose: WellborePurpose;
  selected?: boolean;
}

export const Marker = forwardRef<HTMLDivElement, MarkerProps>(
  ({ style, onClick, selected = false, purpose }, ref) => {
    return (
      <Circle
        onClick={onClick}
        ref={ref}
        style={style}
        $selected={selected}
        $purpose={purpose}
      />
    );
  }
);

Marker.displayName = 'Marker';
