import { useQuery } from '@tanstack/react-query';

import { useApp } from './useApp';
import { useGetPrognosis } from './useGetPrognosis';
import { SmdaService, Wellbore } from 'src/api';
import {
  WELLBORE_ALL_KEY,
  WELLBORE_BY_IDENTIFIER_KEY,
  WELLBORE_DRILLED_KEY,
  WELLBORE_PLANNED_KEY,
} from 'src/constants/queryKeys';

export const useGetWellboreByIdentifier = () => {
  const { data: prognosisData } = useGetPrognosis();
  const wellbore = prognosisData?.wellboreIdentifier ?? '';

  const identifier = encodeURIComponent(wellbore);

  return useQuery({
    queryKey: [WELLBORE_BY_IDENTIFIER_KEY, identifier],
    queryFn: () => SmdaService.getApiV1SmdaWellbores(identifier),
    enabled: !!wellbore,
  });
};

export const useGetAllWellbores = () => {
  const { field } = useApp();
  return useQuery<Wellbore[]>({
    queryKey: [WELLBORE_ALL_KEY, field?.uuid],
    queryFn: () =>
      SmdaService.getApiV1SmdaFieldsWellboresAll(field?.uuid ?? ''),
  });
};

export const useGetDrilledWellbores = () => {
  const { field } = useApp();
  return useQuery<Wellbore[]>({
    queryKey: [WELLBORE_DRILLED_KEY, field?.uuid],
    queryFn: () =>
      SmdaService.getApiV1SmdaFieldsWellboresDrilled(field?.uuid ?? ''),
  });
};

export const useGetPlannedWellbores = () => {
  const { field } = useApp();
  return useQuery<Wellbore[]>({
    queryKey: [WELLBORE_PLANNED_KEY, field?.uuid],
    queryFn: () =>
      SmdaService.getApiV1SmdaFieldsWellboresPlanned(field?.uuid ?? ''),
  });
};
