import {
  colors,
  elevation,
  shape,
  spacings,
} from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const MainContent = styled.div`
  display: flex;
  max-width: calc(1600px - ${spacings.large} * 2);
  min-height: calc(100vh - 64px);
  width: 100%;
  flex-direction: column;
  gap: ${spacings.large};
  padding: ${spacings.large};
`;

export const Wrapper = styled.div`
  display: flex;
  padding: ${spacings.large};
  flex-direction: column;
  align-items: center;
  gap: ${spacings.large};
  align-self: stretch;
  border-radius: ${shape.corners.borderRadius};
  background-color: ${colors.ui.background__default.rgba};
  box-shadow: ${elevation.raised};
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
`;

export const Spacings = styled.div`
  margin: ${spacings.large} 0;
`;
