import { FieldError, FieldErrors } from 'react-hook-form';

import { EditDetailsStepFormType } from './zodSchema';
import { PrognosisDetailDto } from 'src/api';

/**
 * Retrieves a specific error from the prognosis details array within the form errors.
 *
 * @param errors - The object containing all field errors for the form.
 * @param index - The index of the prognosis detail in the array.
 * @param detailKey - The key of the specific detail to retrieve the error for.
 * @returns The error associated with the specified prognosis detail, or undefined if no error exists.
 */
export const getPrognosisDetailError = (
  errors: FieldErrors<EditDetailsStepFormType>,
  index: number,
  detailKey: keyof PrognosisDetailDto
): FieldError | undefined => {
  return errors.prognosisDetails?.[index]?.[detailKey];
};
