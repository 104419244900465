import { FC, useMemo, useState } from 'react';

import { spacings, Typography } from '@equinor/amplify-component-lib';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { BaseTable } from './BaseTable/BaseTable';
import { Header, Wrapper } from './BaseTable/BaseTable.styles';
import { EditGroup } from './EditGroup/EditGroup';
import { PrognosisDetailDto } from 'src/api';
import { defaultColumns } from 'src/constants/table';
import { AttributeChip } from 'src/pages/EditPrognosis/components/AttributeChip/AttributeChip';
import { DataInputDialog } from 'src/pages/EditPrognosis/components/DataInputDialog/DataInputDialog';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';
import { getCoMingledAttributes } from 'src/utils';

import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import styled from 'styled-components';

const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacings.small};
`;

export const EditDetailsTable: FC = () => {
  const {
    formState: { errors },
  } = useEditDetailsStepForm();
  const { data: optionalAttributes } = useGetOptionalAttributes();
  const { data: prognosisDetails } = useGetPrognosisDetails();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [prognosisDetailSnapshot, setPrognosisDetailSnapshot] =
    useState<PrognosisDetailDto>();
  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [detailComment, setDetailComment] = useState<
    string | null | undefined
  >();

  const handleOnCloseEditDialog = () => {
    setOpenEditModal(false);
  };

  const coMingledRow: PrognosisDetailDto[] = useMemo(() => {
    if (!prognosisDetails) {
      return [];
    }

    return [
      {
        ...prognosisDetails[0],
        ...getCoMingledAttributes(prognosisDetails),
      },
    ] as PrognosisDetailDto[];
  }, [prognosisDetails]);

  const totalDepthRowId = useMemo(() => {
    return (
      prognosisDetails
        ?.find((detail) => detail.reservoirZone === 'Total depth')
        ?.id.toString() ?? ''
    );
  }, [prognosisDetails]);

  const hasErrors = errors && Object.keys(errors).length > 0;

  const table = useReactTable({
    data:
      (optionalAttributes?.commingledZones ? coMingledRow : prognosisDetails) ??
      [],
    columns,
    initialState: {
      sorting: [
        {
          id: 'referenceDepth',
          desc: false,
        },
      ],
    },
    state: {
      rowSelection,
      columnVisibility: {
        ...columnVisibility,
        actions: !!prognosisDetails?.length,
        pressureInitial: optionalAttributes?.pressureInitial ?? false,
        pressureMin: optionalAttributes?.pressureMin ?? false,
        pressureMax: optionalAttributes?.pressureMax ?? false,
        datumDepth: optionalAttributes?.datumDepth ?? false,
        pressureDatumExpected: optionalAttributes?.datumDepth ?? false,
        pressureDatumLow: optionalAttributes?.datumDepth ?? false,
        pressureDatumHigh: optionalAttributes?.datumDepth ?? false,
      },
      rowPinning: {
        bottom: totalDepthRowId ? [totalDepthRowId] : [],
      },
    },
    meta: {
      handleOnOpenComment: (detailComment: string | null | undefined) => {
        setOpenCommentModal(true);
        setDetailComment(detailComment);
      },
      handleOnOpenEditDialog: (detailRow: PrognosisDetailDto) => {
        setPrognosisDetailSnapshot(detailRow);
        setOpenEditModal(true);
      },
      isStatusSubmitted: false,
      isEditing: true,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id.toString(),
    enableRowPinning: true,
  });

  return (
    <Wrapper>
      {optionalAttributes?.commingledZones && prognosisDetails && (
        <>
          <Typography variant="h4">Selected commingled zones</Typography>
          <ChipWrapper>
            {prognosisDetails.map((detail) => (
              <AttributeChip
                key={detail.id}
                label={detail.reservoirZone}
                variant="locked"
              />
            ))}
          </ChipWrapper>
        </>
      )}
      <Header>
        <Typography variant="h4">Fill in the prognosis data</Typography>
        <EditGroup
          selectedRows={rowSelection}
          resetRowSelection={table.resetRowSelection}
        />
      </Header>
      <BaseTable
        table={table}
        comment={detailComment ?? ''}
        openCommentModal={openCommentModal}
        setOpenCommentModal={setOpenCommentModal}
        hasErrors={hasErrors}
      />
      {openEditModal && prognosisDetailSnapshot && (
        <DataInputDialog
          open={openEditModal}
          close={handleOnCloseEditDialog}
          detailRow={prognosisDetailSnapshot}
        />
      )}
    </Wrapper>
  );
};
