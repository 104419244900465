import { useQuery } from '@tanstack/react-query';

import { PrognosisDetailService } from 'src/api';
import { RESERVOIR_ZONE_DETAILS_KEY } from 'src/constants/queryKeys';
import { FIVE_MINUTES } from 'src/constants/staleTime';

interface ReservoirZoneDetails {
  wellboreIdentifier: string;
  targetUuid?: string;
  stratColumn: string;
  reservoirZone: string;
}

export const useGetReservoirZoneDetails = ({
  wellboreIdentifier,
  targetUuid,
  stratColumn,
  reservoirZone,
}: ReservoirZoneDetails) => {
  return useQuery({
    queryKey: [
      RESERVOIR_ZONE_DETAILS_KEY,
      wellboreIdentifier,
      stratColumn,
      reservoirZone,
    ],
    queryFn: () => {
      const wellboreId = targetUuid ? '' : wellboreIdentifier;
      const targetId = targetUuid ? targetUuid : '';

      return PrognosisDetailService.getApiV1PrognosesDetailsHistoric(
        wellboreId,
        targetId,
        stratColumn,
        reservoirZone
      );
    },
    staleTime: FIVE_MINUTES,
    enabled:
      !!reservoirZone &&
      (!!wellboreIdentifier || !!targetUuid) &&
      !!stratColumn,
  });
};
