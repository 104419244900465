import { FC } from 'react';

import { Button, Icon, Tabs } from '@equinor/amplify-component-lib';
import { close, filter_list } from '@equinor/eds-icons';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { PrognosisFilter } from '../../PrognosisFilter/PrognosisFilter';
import { TabRowContainer } from '../CommonTabStyles.styles';
import { TabWithCount } from '../TabWithCount/TabWithCount';
import { PROGNOSIS_FILTER_FEATURE_KEY, TabType } from 'src/constants';
import { usePrognosisDashboard } from 'src/pages/PrognosisDashboard/hooks/useDashboard';

interface ApplicationTabsViewProps {
  usingTabList: TabType[];
  usingTabPanels: JSX.Element[];
  handleShowFilterSidebar: () => void;
  showFilterSidebar: boolean;
}

export const ApplicationTabsView: FC<ApplicationTabsViewProps> = ({
  usingTabList,
  usingTabPanels,
  handleShowFilterSidebar,
  showFilterSidebar,
}) => {
  const { activeTab, handleTabChange } = usePrognosisDashboard();
  const { showContent: showFilter } = useFeatureToggling(
    PROGNOSIS_FILTER_FEATURE_KEY
  );
  return (
    <Tabs activeTab={activeTab} onChange={handleTabChange}>
      <TabRowContainer>
        <Tabs.List>
          {usingTabList.map((item) => (
            <Tabs.Tab key={`tab-${item}`}>
              <TabWithCount tabName={item} />
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {!showFilter && (
          <Button onClick={handleShowFilterSidebar} variant="ghost">
            <Icon data={showFilterSidebar ? close : filter_list} />
            {showFilterSidebar ? 'Hide filters' : 'Show filters'}
          </Button>
        )}
      </TabRowContainer>
      {showFilter && <PrognosisFilter />}
      <Tabs.Panels>
        {usingTabPanels.map((element, index) => (
          <Tabs.Panel key={`panel-${index}`}>{element}</Tabs.Panel>
        ))}
      </Tabs.Panels>
    </Tabs>
  );
};
