import { FC, useEffect } from 'react';

import { useSnackbar, useStepper } from '@equinor/amplify-component-lib';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';

import { StepFooter } from '../../StepFooter/StepFooter';
import {
  LastSubmittedPrognosisService,
  OverlappingPrognosisService,
  UserService,
} from 'src/api';
import {
  BACKUP_APPROVER,
  MAIN_APPROVER,
  OVERLAPPING_PROGNOSES,
  PREVIOUS_PROGNOSIS_DETAILS_KEY,
} from 'src/constants';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';

import { useApp } from 'hooks/useApp';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';
import { useSavingState } from 'hooks/useSavingState';

interface EditDetailsFooterProps {
  isInitializing: boolean;
}

export const EditDetailsFooter: FC<EditDetailsFooterProps> = ({
  isInitializing,
}) => {
  const { trigger } = useEditDetailsStepForm();
  const { showSnackbar } = useSnackbar();
  const usingId = usePrognosisIDFromParams();
  const isMutating = useIsMutating();
  const isSaving = useSavingState(isMutating);
  const { data: prognosisDetails } = useGetPrognosisDetails();
  const queryClient = useQueryClient();
  const { goToNextStep } = useStepper();
  const { field } = useApp();

  const handleSubmit = async () => {
    if (prognosisDetails?.length === 0) {
      showSnackbar('Please add at least one detail');
      return;
    }

    const isValid = await trigger();
    if (!isValid) {
      showSnackbar('Please fill in the required fields');
      return;
    }

    goToNextStep();
  };

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: [PREVIOUS_PROGNOSIS_DETAILS_KEY, usingId],
      queryFn: () =>
        LastSubmittedPrognosisService.getApiV1PrognosesDetailsLastSubmitted(
          usingId
        ),
    });

    queryClient.prefetchQuery({
      queryKey: [MAIN_APPROVER, field?.name],
      queryFn: () => UserService.getApiV1UserApprovers(field?.name ?? ''),
    });

    queryClient.prefetchQuery({
      queryKey: [BACKUP_APPROVER, field?.name],
      queryFn: () => UserService.getApiV1UserApproversBackup(field?.name ?? ''),
    });

    queryClient.prefetchQuery({
      queryKey: [OVERLAPPING_PROGNOSES, usingId],
      queryFn: () =>
        OverlappingPrognosisService.getApiV1PrognosesOverlappingPrognoses(
          usingId
        ),
    });
  }, [field?.name, queryClient, usingId]);

  return (
    <StepFooter
      handleSubmit={handleSubmit}
      isMutating={false}
      showSavingChip={true}
      isAutoSaving={isSaving}
      isInitializing={isInitializing}
    />
  );
};
