import { useNavigate } from 'react-router-dom';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisService } from 'src/api';
import { PROGNOSIS_KEY } from 'src/constants/queryKeys';
import { useApp, useGetPrognosis } from 'src/hooks';
import { StatusIdEnum } from 'src/types/status';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePatchStatus = () => {
  const { formattedFieldNameForUrl } = useApp();
  const navigate = useNavigate();
  const usingId = usePrognosisIDFromParams();
  const { data } = useGetPrognosis();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const approvePrognosis = useMutation({
    mutationFn: async (optionalComment: string) => {
      return await PrognosisService.patchApiV1PrognosesApprove(
        usingId,
        optionalComment
      );
    },
    onSuccess: () => {
      showSnackbar('Review submitted');
      queryClient.setQueryData([PROGNOSIS_KEY, usingId], {
        ...data,
        statusId: StatusIdEnum.APPROVED,
      });
    },
  });

  const revisePrognosis = useMutation({
    mutationFn: async (optionalComment: string) => {
      return await PrognosisService.patchApiV1PrognosesReject(
        usingId,
        optionalComment
      );
    },
    onSuccess: () => {
      showSnackbar('Review submitted');
      navigate(`${formattedFieldNameForUrl}/prognosis`);
    },
  });

  const submitPrognosis = useMutation({
    mutationFn: async () => {
      return await PrognosisService.patchApiV1PrognosesSubmit(usingId);
    },
  });

  return { approvePrognosis, revisePrognosis, submitPrognosis };
};
