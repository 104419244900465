import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextField } from '@equinor/amplify-component-lib';

import { FormGrid } from './PrognosisValues.styles';
import {
  FieldValues,
  FormElementProps,
  formElements,
} from './PrognosisValues.utils';

export const InputElements: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FieldValues>();

  return (
    <FormGrid>
      {formElements.map((element: FormElementProps) => {
        return (
          <TextField
            key={element.id}
            id={element.id}
            {...register(element.id, {
              valueAsNumber: element.type === 'number',
            })}
            label={element.label}
            name={element.id}
            type={element.type}
            variant={errors[element.id] ? 'error' : undefined}
            helperText={
              errors[element.id]
                ? errors[element.id]?.message
                : element.helperText
            }
          />
        );
      })}
    </FormGrid>
  );
};
