import { FC } from 'react';

import { Chip } from '@equinor/amplify-component-lib';

import { PLOT_DATE_RANGE_VALUES } from 'src/constants';
import { useMeasuredData } from 'src/hooks';
import { getDateForDaysIntoPast } from 'src/utils';

import styled from 'styled-components';

export const DateOptionsWrapper = styled.div`
  justify-self: start;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 48px;
`;

export const DateRangeOptions: FC = () => {
  const { daysBetweenFromToPlotRange, handleSetPlotDateRangeOptions } =
    useMeasuredData();

  const handleOnClick = (range: number) => {
    const toDate = getDateForDaysIntoPast(1);

    const newFromDate = new Date(toDate);
    newFromDate.setDate(toDate.getDate() - range);

    handleSetPlotDateRangeOptions({
      fromDate: newFromDate,
      toDate: toDate,
    });
  };

  return (
    <DateOptionsWrapper>
      {PLOT_DATE_RANGE_VALUES.map((item) => (
        <Chip
          key={item.label}
          selected={daysBetweenFromToPlotRange === item.range}
          onClick={() => handleOnClick(item.range)}
        >
          {item.label}
        </Chip>
      ))}
    </DateOptionsWrapper>
  );
};
