import { SelectOptionRequired } from '@equinor/amplify-component-lib';
import { useQuery } from '@tanstack/react-query';

import { useApp } from './useApp';
import { User, UserService } from 'src/api';
import { BACKUP_APPROVER } from 'src/constants/queryKeys';

export const useGetBackupApprovers = () => {
  const { field } = useApp();
  return useQuery<User[], Error, SelectOptionRequired[]>({
    queryKey: [BACKUP_APPROVER, field?.name],
    queryFn: () => UserService.getApiV1UserApproversBackup(field?.name ?? ''),
    select: (data) =>
      data.map(
        (approver) =>
          ({
            label: approver.displayName,
            value: approver.shortName,
          }) as SelectOptionRequired
      ),
  });
};
