import { UseFormSetValue, UseFormUnregister } from 'react-hook-form';

import { SelectOptionRequired } from '@equinor/amplify-component-lib';

import { PrognosisDetailKeys } from '../components/DataInputDialog/DataInputDialog.utils';
import { MappedStratigraphicUnit } from '../components/FormData/ZoneSelector/ZoneNavigator/ZoneNavigator';
import { PrognosisDetailDto, Stratigraphy } from 'src/api';
import {
  cleanPrognosisDetail,
  CreationStepFormType,
  OperationValues,
  OptionalAttributes,
  roundTo,
} from 'src/utils';
import { generateRandomId } from 'src/utils/randomInt';

/**
 * Filters the keys of a PrognosisDetailKeys object based on optional attributes and conditions.
 *
 * @param keys - The object containing the keys to be filtered.
 * @param optionalAttributes - An object specifying which optional attributes are available.
 * @param isTotalDepthRow - A boolean indicating if the current row is a total depth row.
 * @returns A new PrognosisDetailKeys object with the filtered keys.
 */
export const filterFieldKeys = ({
  keys,
  optionalAttributes,
  hideDatumDepth,
}: {
  keys: PrognosisDetailKeys;
  optionalAttributes: OptionalAttributes | undefined;
  hideDatumDepth: boolean | undefined;
}): PrognosisDetailKeys => {
  return Object.fromEntries(
    Object.entries(keys).filter(([key]) => {
      if (key === 'pressureInitial' && !optionalAttributes?.pressureInitial) {
        return false;
      }
      if (key === 'pressureMin' && !optionalAttributes?.pressureMin) {
        return false;
      }
      if (key === 'pressureMax' && !optionalAttributes?.pressureMax) {
        return false;
      }
      if (key === 'datumDepth' && hideDatumDepth) {
        return false;
      }
      return true;
    })
  ) as PrognosisDetailKeys;
};

/**
 * Returns the appropriate phase options based on the provided operation ID.
 *
 * @param {Object} params - The parameters for the function.
 * @param {OperationValues} params.operationId - The ID of the operation.
 * @param {SelectOptionRequired[]} params.newWellPhasesOptions - The options for new well phases.
 * @param {SelectOptionRequired[]} params.interventionPhasesOptions - The options for intervention phases.
 * @returns {SelectOptionRequired[]} The phase options corresponding to the operation ID.
 */
export const getOperationPhaseOptions = ({
  operationId,
  newWellPhasesOptions,
  interventionPhasesOptions,
  targetPhasesOptions,
}: {
  operationId: OperationValues;
  newWellPhasesOptions: SelectOptionRequired[];
  interventionPhasesOptions: SelectOptionRequired[];
  targetPhasesOptions: SelectOptionRequired[];
}) => {
  switch (operationId) {
    case OperationValues.NEW_WELL:
      return newWellPhasesOptions;
    case OperationValues.INTERVENTION:
      return interventionPhasesOptions;
    case OperationValues.TARGET:
      return targetPhasesOptions;
    default:
      return [];
  }
};

/**
 * Returns the appropriate wellbore options based on the operation type.
 *
 * @param {Object} params - The parameters for the function.
 * @param {OperationValues} params.operationId - The type of operation being performed.
 * @param {SelectOptionRequired[]} params.plannedWellboresOptions - The options for planned wellbores.
 * @param {SelectOptionRequired[]} params.drilledWellboresOptions - The options for drilled wellbores.
 * @param {SelectOptionRequired[]} params.allWellboresOptions - The options for all wellbores.
 * @returns {SelectOptionRequired[]} The wellbore options corresponding to the operation type.
 */
export const getWellboreOptions = ({
  operationId,
  plannedWellboresOptions,
  drilledWellboresOptions,
  allWellboresOptions,
}: {
  operationId: OperationValues;
  plannedWellboresOptions: SelectOptionRequired[];
  drilledWellboresOptions: SelectOptionRequired[];
  allWellboresOptions: SelectOptionRequired[];
}) => {
  switch (operationId) {
    case OperationValues.NEW_WELL:
      return plannedWellboresOptions;
    case OperationValues.INTERVENTION:
    case OperationValues.P_N_A:
      return drilledWellboresOptions;
    default:
      return allWellboresOptions;
  }
};

/**
 * Retrieves stratigraphic data based on the operation type.
 *
 * @param operationId - The ID of the operation, which determines the type of stratigraphic data to return.
 * @param all - An array of all stratigraphy data.
 * @param planned - An array of planned stratigraphy data.
 * @param drilled - An array of drilled stratigraphy data.
 * @returns An array of stratigraphy data corresponding to the operation type.
 *
 * The function returns:
 * - `planned` data if the operation is a new well.
 * - `drilled` data if the operation is an intervention or P&A.
 * - `all` data if the operation is of other types.
 * - An empty array if the operation type does not match any case.
 */
export const getStratigraphicData = ({
  operationId,
  all,
  planned,
  drilled,
}: {
  operationId: number | undefined;
  all: Stratigraphy[] | undefined;
  planned: Stratigraphy[] | undefined;
  drilled: Stratigraphy[] | undefined;
}) => {
  switch (operationId as OperationValues) {
    case OperationValues.NEW_WELL:
      return planned ?? [];
    case OperationValues.INTERVENTION:
    case OperationValues.P_N_A:
      return drilled ?? [];
    case OperationValues.OTHER:
    case OperationValues.TARGET:
      return all ?? [];
    default:
      return [];
  }
};

/**
 * Helper function to update operation values and manage form state.
 *
 * @param {Object} params - The parameters for the helper function.
 * @param {OperationValues} params.previousValue - The previous operation value.
 * @param {OperationValues} params.newValue - The new operation value.
 * @param {UseFormSetValue<CreationStepFormType>} params.setValue - Function to set form values.
 * @param {UseFormUnregister<CreationStepFormType>} params.unregister - Function to unregister form fields.
 *
 * This function performs the following actions based on the new operation value:
 * - If the new value is `OperationValues.NEW_WELL` or `OperationValues.OTHER`, it unregisters the `wellboreIdentifier` field and sets `operationPhaseId` to null.
 * - If the previous value was `OperationValues.P_N_A` and the new value is `OperationValues.INTERVENTION`, or vice versa, it sets `operationPhaseId` to null.
 */
export const updateOperationHelper = ({
  previousValue,
  newValue,
  setValue,
}: {
  previousValue: OperationValues;
  newValue: OperationValues;
  setValue: UseFormSetValue<CreationStepFormType>;
  unregister: UseFormUnregister<CreationStepFormType>;
}) => {
  if (
    (previousValue === OperationValues.P_N_A &&
      newValue === OperationValues.INTERVENTION) ||
    (previousValue === OperationValues.INTERVENTION &&
      newValue === OperationValues.P_N_A)
  ) {
    setValue('operationPhaseId', null, { shouldDirty: true });
  } else if (newValue === OperationValues.TARGET) {
    setValue('wellboreIdentifier', null);
  } else if (previousValue === OperationValues.TARGET) {
    setValue('ciTargetUuid', null);
  } else {
    setValue('operationPhaseId', null, { shouldDirty: true });
  }
};

/**
 * Converts an array of MappedStratigraphicUnit items to an array of detail objects.
 *
 * @param items - An array of MappedStratigraphicUnit objects to be converted.
 * @returns An array of detail objects
 */
export const convertToDetails = (items: MappedStratigraphicUnit[]) => {
  const newDetails = items.map((subzone) => {
    const newDetail = {
      lithostratSubzone: subzone.value,
      stratColumnIdentifier: subzone.stratColumnIdentifier ?? '',
      topAge: subzone.topAge ?? undefined,
      colorHtml: subzone.colorHtml ?? undefined,
    };

    return newDetail;
  });

  return newDetails;
};

export const createNewDetails = ({
  details,
  newPrognosisId,
  optionalAttributes,
}: {
  details: Partial<PrognosisDetailDto>[];
  newPrognosisId: number;
  optionalAttributes: OptionalAttributes;
}) => {
  const modifiedDetails: PrognosisDetailDto[] = [];

  for (const detail of details) {
    const baseDetail: PrognosisDetailDto = {
      colorHtml: detail?.colorHtml,
      fluidType: 'Oil',
      depthUnit: 'm',
      pressureUnit: 'bar',
      temperatureUnit: 'degC',
      pressureGradientDepthUnit: 'bar/m',
      reservoirZone: detail.lithostratSubzone ?? '',
      lithostratSubzone: detail.lithostratSubzone ?? '',
      stratColumnIdentifier: detail.stratColumnIdentifier ?? '',
      ...detail,
      id: generateRandomId(),
      prognosisId: newPrognosisId,
      commingledId: optionalAttributes.commingledZones ? 1 : null,
    };

    const cleanedDetail = cleanPrognosisDetail(baseDetail, optionalAttributes);

    modifiedDetails.push(cleanedDetail);
  }

  return modifiedDetails;
};

/**
 * Retrieves the main approvers for a given user, falling back to backup approvers
 * if the user is an approver and no main approvers match the user's short name.
 *
 * @param params - The parameters for determining the approvers.
 * @param params.mainApprovers - The list of main approvers.
 * @param params.backupApprovers - The list of backup approvers.
 * @param params.isApprover - A flag indicating if the user is an approver.
 * @param params.userShortName - The short name of the user.
 * @returns The list of approvers, either the filtered main approvers or the backup approvers.
 */
export const getMainApprovers = ({
  mainApprovers,
  backupApprovers,
  isApprover,
  userShortName,
}: {
  mainApprovers: SelectOptionRequired[];
  backupApprovers: SelectOptionRequired[];
  isApprover: boolean;
  userShortName: string;
}) => {
  const filteredMainApprovers = mainApprovers.filter(
    (approver) => approver.value !== userShortName
  );

  // If the user is an approver and there are no main approvers, use the backup approvers
  if (isApprover && filteredMainApprovers.length === 0) {
    const filteredBackupApprovers = backupApprovers.filter(
      (approver) => approver.value !== userShortName
    );
    return filteredBackupApprovers;
  } else {
    return filteredMainApprovers;
  }
};

/**
 * Retrieves a list of backup approvers based on the provided main approvers, backup approvers,
 * and the user's short name. If there are more than one main approvers, they are added to the
 * backup approvers list.
 *
 * @param params - An object containing the following properties:
 * @param params.mainApprovers - An array of main approvers, each represented as a `SelectOptionRequired` object.
 * @param params.backupApprovers - An array of backup approvers, each represented as a `SelectOptionRequired` object.
 * @param params.userShortName - The short name of the user to filter backup approvers.
 *
 * @returns An array of `SelectOptionRequired` objects representing the filtered backup approvers,
 *          with main approvers included if there are more than one.
 */
export const getBackupApprovers = ({
  mainApprovers,
  backupApprovers,
  userShortName,
}: {
  mainApprovers: SelectOptionRequired[];
  backupApprovers: SelectOptionRequired[];
  userShortName: string;
}) => {
  const filteredBackupApprovers = backupApprovers.filter(
    (approver) => approver.value !== userShortName
  );

  // If there are more than one main approvers, add them to the backup approvers list if they are not all ready there
  if (mainApprovers.length > 1) {
    return [
      ...filteredBackupApprovers,
      ...mainApprovers.filter(
        (mainApprover) =>
          !filteredBackupApprovers.some(
            (backupApprover) => backupApprover.value === mainApprover.value
          )
      ),
    ];
  } else {
    return filteredBackupApprovers;
  }
};

/**
 * Calculates the reference depth based on the total depth, elevation, and whether the well is new.
 *
 * @param params - The parameters for the calculation.
 * @param params.isNewWell - A boolean indicating if the well is new. If true, the function returns null.
 * @param params.totalDepth - The total depth of the well. Optional.
 * @param params.elevation - The elevation of the well. Optional.
 * @returns The calculated reference depth rounded to 3 decimal places, or null if the calculation cannot be performed.
 */
export const calculateReferenceDepth = ({
  isNewWell,
  totalDepth,
  elevation,
}: {
  isNewWell: boolean;
  totalDepth?: number;
  elevation?: number;
}): number | null => {
  if (totalDepth && elevation && !isNewWell) {
    return roundTo(totalDepth - elevation, 3) ?? null;
  }

  return null;
};
