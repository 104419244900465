import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { DotProgress, useAuth } from '@equinor/amplify-component-lib';
import { zodResolver } from '@hookform/resolvers/zod';

import { MainContentGrid, Separator } from '../Steps.styles';
import { CommentFooter } from './CommentFooter/CommentFooter';
import { Comments } from './Comments/Comments';
import { ValidityForm } from './ValidityForm/ValidityForm';
import { useInitializeCommentsStep } from 'src/pages/EditPrognosis/hooks/util/useInitializeCommentsStep';
import {
  getBackupApprovers,
  getMainApprovers,
} from 'src/pages/EditPrognosis/utils/formUtils';
import {
  commentSchema,
  CommentsStepFormType,
  getUsernameFromEmail,
} from 'src/utils';

import { useGetBackupApprovers } from 'hooks/useGetBackupApprovers';
import { useGetMainApprovers } from 'hooks/useGetMainApprovers';
import { useGetUserAccess } from 'hooks/useGetUserAccess';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const CommentsStep: FC = () => {
  const methods = useForm<CommentsStepFormType>({
    resolver: zodResolver(commentSchema),
  });
  const { isInitializing } = useInitializeCommentsStep({
    formReset: methods.reset,
  });
  const { data: mainApprovers } = useGetMainApprovers();
  const { data: backupApprovers } = useGetBackupApprovers();
  const { userAccessForField } = useGetUserAccess();
  const { account } = useAuth();

  const userShortName = getUsernameFromEmail(account?.username);
  const isApprover = userAccessForField?.userRoles.approver;

  const mainApproverOptions = getMainApprovers({
    mainApprovers: mainApprovers ?? [],
    backupApprovers: backupApprovers ?? [],
    isApprover: isApprover ?? false,
    userShortName,
  });

  const backupApproverOptions = getBackupApprovers({
    mainApprovers: mainApprovers ?? [],
    backupApprovers: backupApprovers ?? [],
    userShortName,
  });

  return (
    <FormProvider {...methods}>
      <MainContentGrid>
        {isInitializing ? (
          <Wrapper>
            <DotProgress color="primary" size={48} />
          </Wrapper>
        ) : (
          <ValidityForm
            isInitializing={isInitializing}
            mainApproverOptions={mainApproverOptions}
            backupApproverOptions={backupApproverOptions}
          />
        )}
        <Separator />
        {isInitializing ? (
          <Wrapper>
            <DotProgress color="primary" size={48} />
          </Wrapper>
        ) : (
          <Comments />
        )}
      </MainContentGrid>
      <CommentFooter isInitializing={isInitializing} />
    </FormProvider>
  );
};
