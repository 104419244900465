import { Map } from './components/Map/Map';
import { MapOptionsProvider } from './providers/MapOptionsProvider';
import { MapDataProvider } from 'src/pages/MapView/providers/MapDataProvider';

export const MapView = () => {
  return (
    <MapDataProvider>
      <MapOptionsProvider>
        <Map />
      </MapOptionsProvider>
    </MapDataProvider>
  );
};
