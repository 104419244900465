import { FC } from 'react';

import { colors, Typography } from '@equinor/amplify-component-lib';

import { Box, Item } from './ViewFieldValues.styles';
import { FieldValues } from 'src/api';
import {
  factorUnitKeys,
  filterKeys,
  keysForDepthUnit,
  keysForPressureGradientDepthUnit,
  keysForPressureUnit,
  measuredDataKeys,
  titleKeys,
  unitKeys,
} from 'src/constants';
import { capitalizeAndFormatString } from 'src/utils';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
`;

interface ViewFieldValuesProps {
  values: FieldValues | undefined;
  generalKeys?: (keyof FieldValues)[];
  children?: React.ReactNode;
  backgroundColor?: string;
  generalFieldValue?: FieldValues;
  keysWithValuesInGeneral?: string[];
  showMeasuredDataValues?: boolean;
}

export const ViewFieldValues: FC<ViewFieldValuesProps> = ({
  values,
  generalKeys,
  children,
  backgroundColor,
  keysWithValuesInGeneral,
  showMeasuredDataValues = true,
}) => {
  if (!values) return;

  const getValueWithUnit = (key: keyof FieldValues, values: FieldValues) => {
    if (keysForDepthUnit.includes(key)) {
      return values.depthUnit;
    } else if (keysForPressureUnit.includes(key)) {
      return values.pressureUnit;
    } else if (keysForPressureGradientDepthUnit.includes(key)) {
      return values.pressureGradientDepthUnit;
    } else if (factorUnitKeys.includes(key)) {
      return 'Rm³/Sm³';
    } else if (key === 'gasSolution') {
      return 'Sm³/Sm³';
    } else {
      return '';
    }
  };

  let mapKeys: (keyof FieldValues)[] = Object.keys(
    values
  ) as (keyof FieldValues)[];

  if (!showMeasuredDataValues) {
    mapKeys = Object.keys(values).filter(
      (key): key is keyof FieldValues =>
        !measuredDataKeys.includes(key as keyof FieldValues)
    ) as (keyof FieldValues)[];
  }

  return (
    <Container>
      <Item $background={backgroundColor}>
        {mapKeys
          .filter((key) => !filterKeys.includes(key as keyof FieldValues))
          .map((key) => {
            const fieldValueKey = key as keyof FieldValues;
            if (titleKeys.includes(fieldValueKey) && values[fieldValueKey]) {
              return (
                <Box key={key}>
                  <Typography
                    group="table"
                    variant="cell_text_bold"
                    color={colors.text.static_icons__default.hex}
                  >
                    {values[fieldValueKey]}
                  </Typography>
                </Box>
              );
            } else if (
              !unitKeys.includes(fieldValueKey) &&
              !titleKeys.includes(fieldValueKey)
            ) {
              return (
                <Box
                  key={key}
                  $keyingeneral={
                    generalKeys?.includes(fieldValueKey) &&
                    !values.isGeneral &&
                    values[fieldValueKey] !== null &&
                    !keysWithValuesInGeneral?.includes(fieldValueKey)
                  }
                >
                  <Typography
                    group="navigation"
                    variant="label"
                    color={colors.text.static_icons__default.hex}
                  >
                    {capitalizeAndFormatString(key)}
                  </Typography>
                  <Typography
                    group="table"
                    variant="cell_text_bold"
                    color={colors.text.static_icons__default.hex}
                  >
                    {values[fieldValueKey]?.toString() || 'N/A'}
                  </Typography>
                  <Typography
                    group="navigation"
                    variant="label"
                    color={colors.text.static_icons__default.hex}
                  >
                    {getValueWithUnit(fieldValueKey, values)}
                  </Typography>
                </Box>
              );
            }
          })}
        {children}
      </Item>
    </Container>
  );
};
