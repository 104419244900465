import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FullPageSpinner } from '@equinor/amplify-component-lib';
import { zodResolver } from '@hookform/resolvers/zod';

import { MainContent } from '../Steps.styles';
import { EditDetailsFooter } from './EditDetailsFooter/EditDetailsFooter';
import { useInitializeEditDetailsStep } from 'src/pages/EditPrognosis/hooks/util/useInitializeEditDetailsStep';
import { EditTableProvider } from 'src/pages/EditPrognosis/providers/EditTableProvider';
import { EditDetailsStepFormType, usePrognosisDetailsSchema } from 'src/utils';

import { EditDetailsTable } from 'components/Table/EditDetailsTable';

export const EditDetailsStep: FC = () => {
  const schema = usePrognosisDetailsSchema();
  const methods = useForm<EditDetailsStepFormType>({
    resolver: zodResolver(schema),
  });

  const { isInitializing } = useInitializeEditDetailsStep({
    formReset: methods.reset,
  });

  return (
    <FormProvider {...methods}>
      <EditTableProvider>
        <MainContent>
          {isInitializing ? <FullPageSpinner /> : <EditDetailsTable />}
        </MainContent>
        <EditDetailsFooter isInitializing={isInitializing} />
      </EditTableProvider>
    </FormProvider>
  );
};
