import { FC, useCallback } from 'react';
import { Controller } from 'react-hook-form';

import {
  RichTextEditor,
  RichTextEditorFeatures,
  spacings,
  Theme,
  Typography,
} from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { BlobService } from 'src/api';
import { IMAGE_UPLOAD } from 'src/constants';
import { usePostBlob } from 'src/pages/EditPrognosis/hooks/api/usePostBlob';
import { usePutValidityForm } from 'src/pages/EditPrognosis/hooks/api/usePutValidityForm';
import { useCommentStepForm } from 'src/pages/EditPrognosis/hooks/form/useCommentStepForm';

import { debounce } from 'lodash';
import styled from 'styled-components';

// 64px - 182px - (3*24)px - 32px - 8px - 50px - 85px - )`} // 494px
const COMMENT_EDITOR_HEIGHT = `calc(100vh - 495px)`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  width: 100%;
  .tiptap {
    background: none;
  }
`;

export const Comments: FC = () => {
  const { control } = useCommentStepForm();
  const { mutate: putPrognosis } = usePutValidityForm();
  const { mutateAsync: postBlob } = usePostBlob();
  const { showContent } = useFeatureToggling(IMAGE_UPLOAD);

  const handleImageRead = async (blobId: string) => {
    const blob = await BlobService.getApiV1BlobByBlobId(blobId);
    return blob.content ?? '';
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateComments = useCallback(
    debounce(() => {
      putPrognosis();
    }, 500),
    []
  );

  return (
    <Section>
      <Typography variant="h4">Prognosis assumptions</Typography>
      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <div data-theme={Theme.LIGHT}>
            <RichTextEditor
              minHeight={COMMENT_EDITOR_HEIGHT}
              maxHeight={COMMENT_EDITOR_HEIGHT}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                debouncedUpdateComments();
              }}
              onImageRead={(blobUrl) => handleImageRead(blobUrl)}
              onImageRemove={async (blogId) => {
                await BlobService.deleteApiV1Blob(blogId);
              }}
              onImageUpload={async (file) => {
                const blobId = await postBlob(file);
                return {
                  src: blobId,
                  alt: file.name,
                };
              }}
              removeFeatures={
                !showContent ? [RichTextEditorFeatures.IMAGES] : []
              }
            />
          </div>
        )}
      />
    </Section>
  );
};
