import { FC, useMemo } from 'react';

import {
  colors,
  elevation,
  formatDate,
  spacings,
} from '@equinor/amplify-component-lib';

import { ONE_DAY_IN_MILLISECONDS } from 'src/constants';
import { SLIDER_SELECTED_THUMB_WIDTH } from 'src/pages/MapView/components/Footer/NewSlider/Slider.constants';
import { useMapData } from 'src/pages/MapView/hooks';

import { useMeasuredData } from 'hooks/useMeasuredData';
import { styled } from 'styled-components';

interface SelectedCircleProps {
  $left: number;
}

const Wrapper = styled.div<SelectedCircleProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacings.xx_small};
  top: 8px;
  left: ${({ $left }) => `${$left}px`};
  border: 1px solid ${colors.interactive.primary__resting.rgba};
  height: 24px;
  transform: translateX(-50%);
  width: ${SLIDER_SELECTED_THUMB_WIDTH}px;
  background: ${colors.ui.background__default.rgba};
  box-shadow: ${elevation.raised};
  box-sizing: border-box;
  pointer-events: none;
  border-radius: 12px;
  z-index: 1000;
`;

interface SelectedDateThumbProps {
  daysBuckets: number[];
}

export const SelectedDateThumb: FC<SelectedDateThumbProps> = ({
  daysBuckets,
}) => {
  const { fromRangeDate } = useMapData();
  const { mapDateRangeOptions } = useMeasuredData();

  const selectedDateValue = useMemo(() => {
    if (mapDateRangeOptions.selectedDate) {
      return Math.floor(
        (new Date(
          mapDateRangeOptions.selectedDate.setHours(0, 0, 0, 0)
        ).getTime() -
          new Date(fromRangeDate.setHours(0, 0, 0, 0)).getTime()) /
          ONE_DAY_IN_MILLISECONDS
      );
    }
    return 0;
  }, [fromRangeDate, mapDateRangeOptions.selectedDate]);

  const selectedDatePxValue = useMemo(() => {
    if (daysBuckets.length === 0) return;
    return daysBuckets.at(selectedDateValue + 1);
  }, [selectedDateValue, daysBuckets]);

  if (!selectedDatePxValue) return null;

  return (
    <Wrapper $left={selectedDatePxValue}>
      {formatDate(mapDateRangeOptions.selectedDate, {
        format: 'DD.MM.YYYY',
      })}
    </Wrapper>
  );
};
