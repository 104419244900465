import { FC } from 'react';

import { FooterActions, Wrapper } from './Footer.styles';
import { MapLegends } from 'src/pages/MapView/components/Footer/components/MapLegends/MapLegends';
import { MapRangeChips } from 'src/pages/MapView/components/Footer/components/MapRangeChips';
import { RangeDatePicker } from 'src/pages/MapView/components/Footer/components/RangeDatePicker';
import { Slider } from 'src/pages/MapView/components/Footer/NewSlider/Slider';

export const Footer: FC = () => {
  return (
    <Wrapper>
      <FooterActions>
        <MapRangeChips />
        <RangeDatePicker />
        <MapLegends />
      </FooterActions>
      <Slider />
    </Wrapper>
  );
};
