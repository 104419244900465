import { FC, RefObject, useRef, useState } from 'react';

import { Button, formatDate, Icon, Menu } from '@equinor/amplify-component-lib';
import { arrow_drop_down, arrow_drop_up, download } from '@equinor/eds-icons';

import { useMeasuredData } from 'src/hooks';
import { downloadFile } from 'src/pages/PlotView/components/Actions/Export.utils';
import { usePressureData } from 'src/pages/PlotView/hooks/usePressureData';

interface ExportProps {
  copyToClipboardRef: RefObject<HTMLElement>;
}

export const Export: FC<ExportProps> = () => {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { pressureData } = usePressureData();
  const { plotDateRangeOptions } = useMeasuredData();

  // TODO: fix screenshot logic
  // const handleCopyToClipboard = async () => {
  //   if (copyToClipboardRef.current === null) {
  //     showSnackbar(`Error: 'Ref cannot be null when copying to clipboard!'`);
  //     return;
  //   }
  //
  //   // Remove box-shadow just when copying to clipboard
  //   for (const child of copyToClipboardRef.current.children[1].children) {
  //     child.setAttribute('style', 'box-shadow: none !important');
  //   }
  //   const blob = await exportComponent(copyToClipboardRef);
  //   for (const child of copyToClipboardRef.current.children[1].children) {
  //     child.removeAttribute('style');
  //   }
  //   const data = [new ClipboardItem({ [blob.type]: blob })];
  //   await navigator.clipboard.write(data);
  //   showSnackbar('Copied to clipboard');
  // };

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  const handleCSVExport = () => {
    downloadFile(
      pressureData,
      `dailyAveragePressure_${formatDate(plotDateRangeOptions.fromDate)}-${formatDate(plotDateRangeOptions.toDate)}.csv`
    );
  };

  return (
    <>
      <Button variant="outlined" ref={menuRef} onClick={handleToggle}>
        Export <Icon data={open ? arrow_drop_up : arrow_drop_down} />
      </Button>
      <Menu
        anchorEl={menuRef.current}
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom-end"
      >
        <Menu.Section index={0} title="Export options">
          {/* TODO: fix screenshot logic */}
          {/*<Menu.Item onClick={handleCopyToClipboard} disabled>*/}
          {/*  Copy screenshot <Icon data={copy} />*/}
          {/*</Menu.Item>*/}
          <Menu.Item onClick={handleCSVExport}>
            Export as .csv <Icon data={download} />
          </Menu.Item>
        </Menu.Section>
      </Menu>
    </>
  );
};
