import { useContext } from 'react';

import {
  MapDataContext,
  MapDataContextProps,
} from 'src/pages/MapView/providers/MapDataProvider';

export const useMapData = (): MapDataContextProps => {
  const context = useContext(MapDataContext);
  if (context === undefined) {
    throw new Error('useMapData must be used within a MapDataProvider');
  }
  return context;
};
