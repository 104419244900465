import { FC } from 'react';

import { colors, Icon, Typography } from '@equinor/amplify-component-lib';
import { lock } from '@equinor/eds-icons';

import { ValuesGrid, ValuesItem } from './LockedInformation.styles';

interface LockedInformationProps {
  label: string;
  value: string | undefined | null | number | boolean;
  meta?: string | null | undefined;
  withlock?: boolean;
}

export const LockedInformation: FC<LockedInformationProps> = ({
  label,
  value,
  meta,
  withlock,
}) => {
  return (
    <ValuesGrid>
      <ValuesItem $withlock={withlock}>
        <div>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.hex}
          >
            {label}
          </Typography>
          <Typography variant="body_short_bold">{value}</Typography>
          <Typography
            group="navigation"
            variant="label"
            color={colors.text.static_icons__tertiary.hex}
          >
            {meta}
          </Typography>
        </div>
        {withlock && (
          <Icon
            data={lock}
            size={24}
            color={colors.text.static_icons__tertiary.hex}
          />
        )}
      </ValuesItem>
    </ValuesGrid>
  );
};
