import { useAuth } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisSubscriptionService } from 'src/api';
import {
  PROGNOSIS_KEY,
  SUBSCRIBE_PROGNOSIS_KEY,
  UNSUBSCRIBE_PROGNOSIS_KEY,
} from 'src/constants';

import { useGetPrognosis } from 'hooks/useGetPrognosis';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const useSubscription = (prognosisId?: number) => {
  const { account } = useAuth();
  const { data } = useGetPrognosis();
  const usingId = usePrognosisIDFromParams();

  const currentId = usingId ?? prognosisId;

  if (!currentId) {
    throw new Error('No prognosis id provided');
  }

  const queryClient = useQueryClient();

  const { mutate: mutateSubscribe, isPending: subscribePending } = useMutation({
    mutationKey: [SUBSCRIBE_PROGNOSIS_KEY, currentId],
    mutationFn: () => {
      return PrognosisSubscriptionService.postApiV1PrognosisSubscription(
        Number(currentId),
        account?.username ?? ''
      );
    },
    onSuccess: () => {
      queryClient.setQueryData([PROGNOSIS_KEY, currentId], {
        ...data,
        isSubscribed: true,
      });
    },
  });

  const { mutate: mutateUnsubscribe, isPending: unsubscribePending } =
    useMutation({
      mutationKey: [UNSUBSCRIBE_PROGNOSIS_KEY, currentId],
      mutationFn: () => {
        return PrognosisSubscriptionService.deleteApiV1PrognosisSubscription(
          Number(currentId),
          account?.username ?? ''
        );
      },
      onSuccess: () => {
        queryClient.setQueryData([PROGNOSIS_KEY, currentId], {
          ...data,
          isSubscribed: false,
        });
      },
    });

  const handleToggleSubscribe = () => {
    if (data?.isSubscribed) {
      mutateUnsubscribe();
    } else {
      mutateSubscribe();
    }
  };

  return {
    handleToggleSubscribe,
    mutateSubscribe,
    mutateUnsubscribe,
    isPending: subscribePending || unsubscribePending,
  };
};
