import { Button, colors, spacings } from '@equinor/amplify-component-lib';

import { MAP_FOOTER_HEIGHT } from 'src/constants';

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  transform: translateX(-50%);
  height: ${MAP_FOOTER_HEIGHT};
  bottom: 0;
  left: 50%;
  z-index: 600;
  width: calc(
    100% + 1px
  ); // 1px here to avoid tiny gap between the footer and the sidebar
  background-color: ${colors.ui.background__default.rgba};
  padding: ${spacings.small} ${spacings.large};
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  border-top: 1px solid ${colors.ui.background__medium.rgba};
`;

export const FooterActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
`;

export const StyledButton = styled(Button)`
  justify-self: center;
  width: 70px;
  height: 48px;
  transition: 0.1s;
  span {
    grid-gap: 0;
  }
`;
