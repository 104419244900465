import { FC } from 'react';

import {
  Chip,
  colors,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { MAP_DATE_RANGE_VALUES } from 'src/constants';
import { useMeasuredData } from 'src/hooks';

import styled from 'styled-components';

export const DateOptionsWrapper = styled.div`
  justify-self: start;
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: ${spacings.small};
  align-items: start;
  height: 48px;
  > p {
    grid-column: span 3;
  }
`;

export const MapRangeChips: FC = () => {
  const { mapDateRangeOptions, handleSetMapDateRangeOptions } =
    useMeasuredData();

  const handleOnClick = (range: number) => {
    const sliderCenterDate = mapDateRangeOptions.dateForRange;
    const newFromDate = new Date(sliderCenterDate);
    const newToDate = new Date(sliderCenterDate);

    newFromDate.setDate(sliderCenterDate.getDate() - range);
    newToDate.setDate(sliderCenterDate.getDate() + range);

    if (newFromDate > mapDateRangeOptions.selectedDate) {
      handleSetMapDateRangeOptions({ range, selectedDate: newFromDate });
    } else if (newToDate < mapDateRangeOptions.selectedDate) {
      handleSetMapDateRangeOptions({ range, selectedDate: newToDate });
    } else {
      handleSetMapDateRangeOptions({ range });
    }
  };

  return (
    <DateOptionsWrapper>
      <Typography
        group="input"
        variant="label"
        color={colors.text.static_icons__tertiary.rgba}
      >
        Range length
      </Typography>
      {MAP_DATE_RANGE_VALUES.map((item) => (
        <Chip
          key={item.label}
          selected={mapDateRangeOptions.range === item.range}
          onClick={() => handleOnClick(item.range)}
        >
          {item.label}
        </Chip>
      ))}
    </DateOptionsWrapper>
  );
};
