import { colors, spacings, Typography } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const FilterItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  overflow: auto;
  min-height: calc(100vh - 65px - 64px - 58px);
`;
export const ListTitle = styled(Typography)`
  padding: ${spacings.medium} ${spacings.medium} ${spacings.x_small}
    ${spacings.medium};
  border-bottom: 1px solid ${colors.ui.background__scrim.rgba};
  width: 100%;
`;
