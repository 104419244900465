import { useFeatureToggling } from '@equinor/subsurface-app-management';
import { useQuery } from '@tanstack/react-query';

import { WellService } from '../api';
import { useApp } from './useApp';
import {
  FIELDS_WITH_MEASURED_DATA,
  SHOW_MEASURED_DATA_ALL_FIELDS,
} from 'src/constants';
import { DRILLED_WELLS_FEATURES_KEY } from 'src/constants/queryKeys';
import { ONE_MINUTE } from 'src/constants/staleTime';

export function useGetDrilledWellsFeatures() {
  const { field } = useApp();
  const { showContent } = useFeatureToggling(SHOW_MEASURED_DATA_ALL_FIELDS);

  return useQuery({
    queryKey: [DRILLED_WELLS_FEATURES_KEY, field?.uuid],
    queryFn: () =>
      WellService.getDrilledWellsFeaturesByField(field?.uuid ?? ''),
    staleTime: ONE_MINUTE,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled:
      FIELDS_WITH_MEASURED_DATA.includes(field?.name ?? '') || showContent, // TODO: remove this when all fields have measured data
  });
}
