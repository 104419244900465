import { FC, useMemo } from 'react';

import { colors, spacings } from '@equinor/amplify-component-lib';

import { SLIDER_TICK_SIZE } from 'src/pages/MapView/components/Footer/NewSlider/Slider.constants';

import { styled } from 'styled-components';

export const SliderTicksWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  padding: 0 calc(${spacings.medium_small} + 1px); // 1px is the border width
  justify-content: space-between;
`;

interface SliderTickProps {
  $columnIndex: number;
}

export const SliderTick = styled.div<SliderTickProps>`
  width: 8px;
  height: 8px;
  grid-row: 1;
  border-radius: 50%;
  pointer-events: none;
  background-color: ${colors.ui.background__medium.rgba};
`;

interface SliderTicksProps {
  sliderWidth: number;
}

export const SliderTicks: FC<SliderTicksProps> = ({ sliderWidth }) => {
  const tickCount = useMemo(() => {
    if (!sliderWidth) return 0;
    return Math.round(sliderWidth / SLIDER_TICK_SIZE);
  }, [sliderWidth]);

  const ticks = useMemo(() => {
    return new Array(tickCount).fill(0).map((_, index) => {
      return <SliderTick key={index} $columnIndex={index} />;
    });
  }, [tickCount]);

  return <SliderTicksWrapper>{ticks}</SliderTicksWrapper>;
};
