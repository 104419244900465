import { useNavigate } from 'react-router-dom';

import { Button, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { AdvancedFilterButton } from './AdvancedFilterButton/AdvancedFilterButton';
import { DragToggleButton } from './DragToggleButton';
import { ManualDataButton } from './ManualDataButton';
import { FILTER_PANEL_WIDTH, TOOLBAR_FOOTER_HEIGHT } from 'src/constants';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';

import { useApp } from 'hooks/useApp';
import styled from 'styled-components';

const { colors } = tokens;

interface WrapperProps {
  $filterPanelOpen: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  height: ${TOOLBAR_FOOTER_HEIGHT};
  left: 0;
  bottom: 0;
  width: ${({ $filterPanelOpen }) =>
    $filterPanelOpen ? `calc(100% - ${FILTER_PANEL_WIDTH})` : '100%'};
  background-color: ${colors.ui.background__default.rgba};
  padding: 0 ${spacings.large};
  border-top: 1px solid ${colors.ui.background__medium.rgba};
`;

const ToolsWrapper = styled.div`
  display: flex;
  gap: ${spacings.medium};
`;

export const ToolBar = () => {
  const { formattedFieldNameForUrl } = useApp();
  const { filterPanelOpen } = useEditMeasuredData();
  const navigate = useNavigate();

  const handleOnBackClick = () => {
    navigate(`${formattedFieldNameForUrl}/measured-data/plot`);
  };

  return (
    <Wrapper $filterPanelOpen={filterPanelOpen}>
      <Button variant="ghost" onClick={handleOnBackClick}>
        Return to line plot
      </Button>
      <ToolsWrapper>
        <AdvancedFilterButton />
        <ManualDataButton />
        <DragToggleButton />
      </ToolsWrapper>
    </Wrapper>
  );
};
