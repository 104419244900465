import { useMemo } from 'react';

import { PathLayer } from '@deck.gl/layers';
import { Theme, useThemeProvider } from '@equinor/amplify-component-lib';
import { useQuery } from '@tanstack/react-query';

import { FieldService } from 'src/api';
import { MEASURED_DATA_MAP_KEY, WELLBORE_PATHS_QUERY_KEY } from 'src/constants';
import { useApp, useMeasuredData } from 'src/hooks';
import { useMapData, useMapOptions } from 'src/pages/MapView/hooks';
import { MapLayer } from 'src/pages/MapView/MapView.types';
import { filterPressurePoints } from 'src/utils';

export function useWellborePaths() {
  const { field } = useApp();
  const { theme } = useThemeProvider();
  const { layers } = useMapOptions();

  const { wellbores } = useMeasuredData();
  const { pressurePoints } = useMapData();
  const { data: drilledWelllines } = useQuery({
    queryKey: [MEASURED_DATA_MAP_KEY, WELLBORE_PATHS_QUERY_KEY, field?.uuid],
    queryFn: () => FieldService.getWellpositions(field?.uuid ?? ''),
    staleTime: Infinity,
  });

  const filteredPressurePoints = useMemo(
    () => filterPressurePoints(pressurePoints, wellbores, layers),
    [layers, pressurePoints, wellbores]
  );

  const welllines = useMemo(() => {
    return drilledWelllines
      ?.filter((wellline) =>
        filteredPressurePoints.some(
          (pressurePoint) =>
            pressurePoint.wellboreUuid === wellline.wellboreUuid
        )
      )
      .map((wellline) => {
        const coordinates = wellline.wellPositions?.coordinates?.map(
          (coordinate) => {
            const [lat, lng] = coordinate;
            return [lng, lat];
          }
        );
        return {
          ...wellline,
          wellPositions: {
            ...wellline.wellPositions,
            coordinates: coordinates,
          },
        };
      });
  }, [drilledWelllines, filteredPressurePoints]);

  const lineColor: [number, number, number] = useMemo(() => {
    return theme === Theme.LIGHT ? [36, 55, 70] : [222, 229, 231];
  }, [theme]);

  return useMemo(
    () =>
      new PathLayer({
        id: MapLayer.WELLBORE_PATHS,
        filled: false,
        data: welllines?.map((wellline) => wellline.wellPositions.coordinates),
        getPath: (d: number[]) => d,
        getColor: lineColor, // Equinor interactive secondary resting
        widthUnits: 'meters',
        getWidth: 2,
        widthMinPixels: 1,
        updateTriggers: {
          getColor: lineColor,
        },
      }),
    [lineColor, welllines]
  );
}
