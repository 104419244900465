import { FC } from 'react';

import { Icon, spacings, Typography } from '@equinor/amplify-component-lib';
import { arrow_drop_right, measure } from '@equinor/eds-icons';

import { EquipmentMapping, EquipmentType } from 'src/types';
import { roundTo } from 'src/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: ${spacings.medium_small} ${spacings.medium};
  display: grid;
  grid-template-columns: auto 2fr 1fr auto 1fr;
  align-items: center;
  justify-items: center;
`;

const CapitalizedTypography = styled(Typography)`
  text-transform: capitalize;
`;

export const CorrectionItem: FC<EquipmentMapping> = ({
  type,
  fromMd,
  toMd,
}) => {
  return (
    <Wrapper>
      <Icon data={measure} />
      <CapitalizedTypography group="table" variant="cell_text_bold">
        {type === EquipmentType.GAUGE ? 'Gauge' : 'Scre/perf'} MD
      </CapitalizedTypography>
      <Typography group="input" variant="label">
        {roundTo(fromMd, 2)}
      </Typography>
      <Icon data={arrow_drop_right} />
      <Typography group="input" variant="label">
        {roundTo(toMd, 2)}
      </Typography>
      <div></div>
    </Wrapper>
  );
};
