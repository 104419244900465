import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { colors, formatDate, Typography } from '@equinor/amplify-component-lib';
import { Dialog } from '@equinor/amplify-component-lib';

import { useLinePlot } from '../../hooks/useLinePlot';
import { MeasuredDataFilter } from 'src/api';
import { FilterChange } from 'src/api';
import { FilterType } from 'src/api';
import { useApp } from 'src/hooks/useApp';
import { useMeasuredDataWellbore } from 'src/hooks/useMeasuredDataWellbore';
import {
  ManualDataTable,
  ManualDataTableDataObject,
} from 'src/pages/EditMeasuredData/components/ManualDataEntry/ManualDataTable/ManualDataTable';
import { getNameFromDetailed, roundTo } from 'src/utils';

import { useMeasuredData } from 'hooks/useMeasuredData';
import styled from 'styled-components';

interface ManualDataEntryProps {
  onClose: () => void;
  initialFilters?: MeasuredDataFilter[];
}

export const StyledDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 15px;
  Button {
    border: 1px solid ${colors.interactive.disabled__border.rgba};
  }
`;

export const StyledTableWrapper = styled.div`
  display: grid;
  padding: 0 15px;
  tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    > td,
    > th {
      display: flex;
      align-items: center;
    }
  }
  th > div > p > span {
    font-size: 12px;
    font-weight: 500;
  }
  thead {
    position: sticky;
    top: -16px;
    z-index: 1000;
  }
`;

export const ManualDataEntry: FC<ManualDataEntryProps> = ({
  onClose,
  initialFilters,
}) => {
  const { dhpg, wellId } = useParams();
  const { field } = useApp();
  const { plotDateRangeOptions } = useMeasuredData();
  const { wellbore } = useMeasuredDataWellbore(wellId);
  const { filteredData, handleChange } = useLinePlot();
  const [manualFilters, setManualFilters] = useState<MeasuredDataFilter[]>(
    initialFilters ?? []
  );
  const [isSubmitActive, setIsSubmitActive] = useState(true);

  const createNewFilter = useCallback(
    (value: string, dataObject: ManualDataTableDataObject) => {
      const newChange: FilterChange = {
        date: dataObject.date,
        from: dataObject.pressureCorrected,
        to: parseFloat(value),
        filterChangeOrder: 1,
      };

      const newFilter: MeasuredDataFilter = {
        wellId: wellId,
        fieldGuid: field?.uuid ?? '',
        dhpg: parseInt(dhpg ?? ''),
        filterOrder: 0,
        disabled: false,
        type: FilterType.MANUAL,
        changes: [newChange],
      };

      return newFilter;
    },
    [dhpg, field, wellId]
  );

  const updateManualFilters = useCallback(
    (value: string, dataObject: ManualDataTableDataObject) => {
      const filterIdx = manualFilters?.findIndex(
        (filter) => filter.changes && filter.changes[0].date === dataObject.date
      );

      if (filterIdx === -1) {
        setManualFilters([
          ...manualFilters,
          createNewFilter(value, dataObject),
        ]);
      } else if (
        manualFilters[filterIdx].changes?.some(
          (change) => roundTo(change.from, 2) === parseFloat(value)
        )
      ) {
        setManualFilters([
          ...manualFilters.slice(0, filterIdx),
          ...manualFilters.slice(filterIdx + 1, manualFilters.length),
        ]);
      } else if (filterIdx !== undefined && filterIdx >= 0) {
        const oldChanges = manualFilters[filterIdx].changes ?? [];
        const newChange = [
          {
            ...oldChanges[0],
            to: parseFloat(value),
          },
        ];
        setManualFilters([
          ...manualFilters.slice(0, filterIdx),
          {
            ...manualFilters[filterIdx],
            changes: newChange,
          },
          ...manualFilters.slice(filterIdx + 1, manualFilters.length),
        ]);
      }
    },
    [createNewFilter, manualFilters]
  );

  const handleSubmit = useCallback(() => {
    handleChange(manualFilters);
    onClose();
  }, [onClose, handleChange, manualFilters]);

  return (
    <Dialog
      title={`Manual data entry: ${formatDate(plotDateRangeOptions.fromDate)} - ${formatDate(plotDateRangeOptions.toDate)}`}
      open
      onClose={onClose}
      width={1100}
      withContentPadding={{ vertical: true, horizontal: true }}
      withBorders
      actions={[
        {
          position: 'left',
          onClick: onClose,
          text: 'Cancel',
          variant: 'ghost',
        },
        {
          position: 'right',
          onClick: handleSubmit,
          text: 'Save',
          color: 'primary',
          disabled: !isSubmitActive,
        },
      ]}
    >
      <StyledDescription>
        <Typography group="input" variant="text">
          To edit the data for{' '}
          {wellbore ? getNameFromDetailed(wellbore) : 'the current edit'},
          please update the current version column in the table below.
        </Typography>
      </StyledDescription>
      <StyledTableWrapper>
        <ManualDataTable
          setIsSubmitActive={setIsSubmitActive}
          filteredData={filteredData ?? []}
          updateManualFilters={updateManualFilters}
        />
      </StyledTableWrapper>
    </Dialog>
  );
};
