import { colors } from '@equinor/amplify-component-lib';
import { createColumnHelper, Row, RowData } from '@tanstack/react-table';

import { PrognosisDetailDto } from 'src/api';
import { Unit } from 'src/types';

import { ActionsCell } from 'components/Table/ActionsCell/ActionsCell';
import { CommentCell } from 'components/Table/CommentCell/CommentCell';
import { DetailTableCell } from 'components/Table/DetailTableCell/DetailTableCell';
import { EditTableCell } from 'components/Table/EditTableCell/EditTableCell';
import { SegmentCell } from 'components/Table/SegmentCell/SegmentCell';
import { TableHeaderKey } from 'components/Table/TableHeaderKey/TableHeaderKey';
import { TableHeaderTitle } from 'components/Table/TableHeaderTitle/TableHeaderTitle';
import { TableSubHeader } from 'components/Table/TableSubHeader/TableSubHeader';
import { ZoneCell } from 'components/Table/ZoneCell/ZoneCell';

export const fallbackData: PrognosisDetailDto[] = [];

const columnHelper = createColumnHelper<PrognosisDetailDto>();

declare module '@tanstack/react-table' {
  // All declarations of 'TableMeta' must have identical type parameters
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    handleOnOpenComment: (detailComment: string | null | undefined) => void;
    handleOnOpenEditDialog?: (detailRow: PrognosisDetailDto) => void;
    isStatusSubmitted: boolean;
    isEditing: boolean;
    unit?: Unit;
  }
}

/**
 * Custom sorting function for sorting rows of PrognosisDetailDto.
 *
 * This function sorts rows based on the following criteria:
 * 1. If `referenceDepth` is defined for one row and not the other, the row with the defined `referenceDepth` is moved to the bottom.
 * 2. If `referenceDepth` is defined for both rows, they are sorted in ascending order by `referenceDepth`.
 * 3. If `referenceDepth` is undefined for both rows, they are sorted in ascending order by `topAge`.
 *
 * @param rowA - The first row to compare.
 * @param rowB - The second row to compare.
 * @returns A negative number if `rowA` should come before `rowB`, a positive number if `rowA` should come after `rowB`, or zero if they are considered equal.
 */
const customSortingFn = (
  rowA: Row<PrognosisDetailDto>,
  rowB: Row<PrognosisDetailDto>
) => {
  const referenceDepthA = rowA.original.referenceDepth;
  const referenceDepthB = rowB.original.referenceDepth;

  if (referenceDepthA != undefined && referenceDepthB == undefined) {
    return 1; // Move rowA to the bottom
  }

  if (referenceDepthA == undefined && referenceDepthB != undefined) {
    return -1; // Move rowB to the bottom
  }

  if (referenceDepthA != undefined && referenceDepthB != undefined) {
    return referenceDepthA - referenceDepthB; // Ascending order by referenceDepth
  }

  const topAgeA = rowA.original.topAge || 0;
  const topAgeB = rowB.original.topAge || 0;
  return topAgeA - topAgeB; // Ascending order by topAge
};

export const defaultColumns = [
  columnHelper.group({
    id: 'zone',
    meta: {
      headerProps: {
        style: {
          borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
          position: 'sticky',
          left: '0',
          zIndex: '2',
        },
      },
    },
    columns: [
      columnHelper.group({
        id: 'subHeaderZone',
        header: () => <TableSubHeader label="Name / Segment" />,
        meta: {
          headerProps: {
            style: {
              borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
              position: 'sticky',
              left: '0',
              zIndex: '2',
            },
          },
        },
        columns: [
          columnHelper.accessor('lithostratSubzone', {
            id: 'lithostratSubzone',
            header: ({ header }) => (
              <TableHeaderKey
                label="Zones"
                {...{
                  $isFirstColumn: header.index === 0,
                }}
              />
            ),
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                  position: 'sticky',
                  left: '0',
                  zIndex: '2',
                },
              },
            },
            cell: (info) => (
              <ZoneCell
                color={info.row.original.colorHtml}
                zoneName={info.getValue<string>()}
                nickname={info.row.original?.reservoirZoneNickname}
                isSelected={info.row.getIsSelected()}
              />
            ),
          }),
          columnHelper.accessor('segment', {
            id: 'segment',
            header: () => <></>, // so that we can apply styles
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                  position: 'sticky',
                  left: '161px',
                  zIndex: '2',
                  backgroundColor: `${colors.ui.background__default.rgba}`,
                },
              },
            },
            enableHiding: false,
            cell: (info) => (
              <SegmentCell segmentName={info.getValue<string>()} />
            ),
          }),
          columnHelper.accessor('comment', {
            id: 'comment',
            header: () => <></>,
            meta: {
              headerProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                  backgroundColor: `${colors.ui.background__default.rgba}`,
                  position: 'sticky',
                  left: `calc(161px + 50px)`,
                  zIndex: '2',
                },
              },
              cellProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            enableHiding: false,
            cell: (info) => {
              const detailComment = info.getValue<string>();
              const handleOnOpenComment =
                info.table.options.meta?.handleOnOpenComment ??
                (() => {
                  console.warn('No handleOnOpenComment function provided');
                });

              return (
                <CommentCell
                  detailComment={detailComment}
                  handleOnOpenComment={handleOnOpenComment}
                />
              );
            },
          }),
        ],
      }),
    ],
  }),
  columnHelper.group({
    id: 'reference',
    header: () => <TableHeaderTitle label="Reference" />,
    meta: {
      headerProps: {
        style: {
          borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
        },
      },
    },
    columns: [
      columnHelper.group({
        id: 'subHeaderReference',
        header: (info) => (
          <TableSubHeader
            label={
              info.table.options.meta?.unit === Unit.SG
                ? 'm TVD RKB'
                : 'm TVD MSL'
            }
          />
        ),
        columns: [
          columnHelper.accessor('referenceDepth', {
            id: 'referenceDepth',
            header: () => <TableHeaderKey label="Depth" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            sortingFn: customSortingFn,
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
        ],
      }),
      columnHelper.group({
        id: 'subHeaderPressure',
        header: () => (
          <TableSubHeader label="Pressure at reference depth in bar" />
        ),
        meta: {
          headerProps: {
            style: {
              borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
            },
          },
        },
        columns: [
          columnHelper.accessor('pressureInitial', {
            id: 'pressureInitial',
            header: () => <TableHeaderKey label="Initial" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
          columnHelper.accessor('pressureMin', {
            id: 'pressureMin',
            header: () => <TableHeaderKey label="Min" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
          columnHelper.accessor('pressureMax', {
            id: 'pressureMax',
            header: () => <TableHeaderKey label="Max" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
          columnHelper.accessor('pressureExpected', {
            id: 'pressureExpected',
            header: () => <TableHeaderKey label="Expected" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
          columnHelper.accessor('pressureLow', {
            id: 'pressureLow',
            header: () => <TableHeaderKey label="Low" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
          columnHelper.accessor('pressureHigh', {
            id: 'pressureHigh',
            header: () => <TableHeaderKey label="High" />,
            meta: {
              headerProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
              cellProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
        ],
      }),
    ],
  }),
  columnHelper.group({
    id: 'gradients',
    header: () => <TableHeaderTitle label="Gradients" />,
    meta: {
      headerProps: {
        style: {
          borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
        },
      },
    },
    columns: [
      columnHelper.group({
        id: 'subHeaderGradientFluid',
        header: () => <TableSubHeader label="Fluid" />,
        columns: [
          columnHelper.accessor('fluidType', {
            id: 'fluidType',
            header: () => <TableHeaderKey label="Type" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
        ],
      }),
      columnHelper.group({
        id: 'subHeaderGradientUnit',
        header: () => <TableSubHeader label="bar/m" />,
        meta: {
          headerProps: {
            style: {
              borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
            },
          },
        },
        columns: [
          columnHelper.accessor('pressureGradientDepth', {
            id: 'pressureGradientDepth',
            header: () => <TableHeaderKey label="Value" />,
            meta: {
              headerProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
              cellProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
        ],
      }),
    ],
  }),
  columnHelper.group({
    id: 'datum',
    header: () => <TableHeaderTitle label="Datum" />,
    meta: {
      headerProps: {
        style: {
          borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
        },
      },
    },
    columns: [
      columnHelper.group({
        id: 'subHeaderDatum',
        header: () => <TableSubHeader label="m TVD MSL" />,
        columns: [
          columnHelper.accessor('datumDepth', {
            id: 'datumDepth',
            header: () => <TableHeaderKey label="Depth" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
        ],
      }),
      columnHelper.group({
        id: 'subHeaderPressureDatum',
        header: () => <TableSubHeader label="Pressure at datum depth in bar" />,
        meta: {
          headerProps: {
            style: {
              borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
            },
          },
        },
        columns: [
          columnHelper.accessor('pressureDatumExpected', {
            id: 'pressureDatumExpected',
            header: () => <TableHeaderKey label="Expected" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
          columnHelper.accessor('pressureDatumLow', {
            id: 'pressureDatumLow',
            header: () => <TableHeaderKey label="Low" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
          columnHelper.accessor('pressureDatumHigh', {
            id: 'pressureDatumHigh',
            header: () => <TableHeaderKey label="High" />,
            meta: {
              headerProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
              cellProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) =>
              info.table.options?.meta?.isEditing ? (
                <EditTableCell
                  detailValue={info.getValue<string>()}
                  detailKey={info.column.id}
                  index={info.row.index}
                />
              ) : (
                <DetailTableCell
                  detailKey={info.column.id}
                  detailValue={info.getValue<string>()}
                  prognosisId={info.row.original.id}
                  isStatusSubmitted={
                    info.table.options.meta?.isStatusSubmitted ?? false
                  }
                />
              ),
          }),
        ],
      }),
    ],
  }),
  columnHelper.group({
    id: 'actionsTop',
    header: undefined,
    columns: [
      columnHelper.group({
        id: 'actionsMiddle',
        header: undefined,
        columns: [
          columnHelper.display({
            id: 'actions',
            header: undefined,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => {
              const detailRow = info.row.original;
              const handleOnOpenEditDialog =
                info.table.options.meta?.handleOnOpenEditDialog ??
                (() => {
                  console.warn('No handleOnOpenComment function provided');
                });
              return (
                <ActionsCell
                  detailRow={detailRow}
                  handleOnOpenEditModal={handleOnOpenEditDialog}
                />
              );
            },
          }),
        ],
      }),
    ],
  }),
];
