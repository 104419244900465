/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LookupValues } from '../models/LookupValues';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LookupValuesService {
  /**
   * Get lookup values
   * @param fieldIdentifier
   * @returns LookupValues Success
   * @throws ApiError
   */
  public static getApiV1PrognosesLookupValues(
    fieldIdentifier: string
  ): CancelablePromise<LookupValues> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/LookupValues/{fieldIdentifier}',
      path: {
        fieldIdentifier: fieldIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
