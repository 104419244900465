import { PrognosisDetailDto } from 'src/api';
import { generateRandomInt } from 'src/utils/randomInt';

export interface ChartProps {
  width: number;
  height: number;
  data: PrognosisDetailDto[];
  toggleXY?: boolean;
  useDatumData?: boolean;
}

export enum ChartDataPointCategory {
  EXPECTED = 'Expected',
  LOW_HIGH = 'Range',
  INITIAL = 'Initial',
}

export enum ChartDataCategory {
  REFERENCE = 'Reference',
  REFERENCE_ID = generateRandomInt(),
  DATUM = 'Datum',
  DATUM_ID = generateRandomInt(),
}

export interface ChartSelect {
  name: string;
  id: number;
  stratColumn?: string;
}

export interface AddedPrognosisDto extends PrognosisDetailDto {
  high: number;
  low: number;
  expected: number;
  initial?: number;
  depth: number;
}
