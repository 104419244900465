import { useQuery } from '@tanstack/react-query';

import { usePrognosisIDFromParams } from './usePrognosisIDFromParams';
import { LastSubmittedPrognosisService } from 'src/api/services/LastSubmittedPrognosisService';
import { PREVIOUS_PROGNOSIS_DETAILS_KEY } from 'src/constants/queryKeys';

export const useGetPreviousPrognosisDetails = () => {
  const usingId = usePrognosisIDFromParams();

  return useQuery({
    queryKey: [PREVIOUS_PROGNOSIS_DETAILS_KEY, usingId],
    queryFn: () =>
      LastSubmittedPrognosisService.getApiV1PrognosesDetailsLastSubmitted(
        usingId
      ),
    enabled: !Number.isNaN(usingId),
  });
};
