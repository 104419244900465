import { useEffect, useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useGetOptionalAttributes } from '../api/useGetOptionalAttributes';
import { usePutStep } from '../api/usePutStep';
import { EditDetailsStepFormType, getCoMingledAttributes } from 'src/utils';

import { useGetPrognosis } from 'hooks/useGetPrognosis';
import { useGetPrognosisDetails } from 'hooks/useGetPrognosisDetails';

interface UseInitializeEditDetailStep {
  formReset: UseFormReset<EditDetailsStepFormType>;
}

export function useInitializeEditDetailsStep({
  formReset,
}: UseInitializeEditDetailStep) {
  const { data: existingPrognosisDetails } = useGetPrognosisDetails();
  const { data: existingPrognosisData } = useGetPrognosis();
  const { mutate: putStep } = usePutStep();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const initializeForm = () => {
      let formValues: EditDetailsStepFormType = existingPrognosisDetails
        ? {
            prognosisDetails: existingPrognosisDetails,
          }
        : { prognosisDetails: [] };
      if (optionalAttributes?.commingledZones && existingPrognosisDetails) {
        formValues = {
          prognosisDetails: [
            {
              ...existingPrognosisDetails[0],
              ...getCoMingledAttributes(existingPrognosisDetails),
            },
          ],
        };
      }

      formReset(formValues);
      setIsInitializing(false);
    };

    if (
      existingPrognosisData &&
      existingPrognosisDetails &&
      optionalAttributes &&
      isInitializing &&
      !existingPrognosisDetails.some((detail) => detail.id < 0)
    ) {
      initializeForm();
      putStep();
    }
  }, [
    existingPrognosisData,
    existingPrognosisDetails,
    formReset,
    isInitializing,
    putStep,
    optionalAttributes,
  ]);

  return { isInitializing };
}
