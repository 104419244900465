import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Button, TextField } from '@equinor/amplify-component-lib';
import { zodResolver } from '@hookform/resolvers/zod';

import { ButtonWrapper, Form } from './MeasuredValues.styles';
import {
  measuredValuesSchema,
  MeasuredValuesTypes,
} from './MeasuredValues.utils';
import { FieldConfig, FieldConfigDto } from 'src/api';

import { usePatchFieldConfig } from 'hooks/useGetFieldConfig';

interface MeasuredValuesFormProps {
  fieldConfig: FieldConfig | undefined;
}

export const MeasuredValuesForm: FC<MeasuredValuesFormProps> = ({
  fieldConfig,
}) => {
  const { mutate, isPending } = usePatchFieldConfig();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<MeasuredValuesTypes>({
    resolver: zodResolver(measuredValuesSchema),
    defaultValues: {
      minimumPressure: fieldConfig?.lowerPressureBoundary ?? 0,
      maximumPressure: fieldConfig?.upperPressureBoundary ?? 0,
    },
  });

  const { minimumPressure, maximumPressure } = watch();

  const valuesUnChanged =
    fieldConfig?.lowerPressureBoundary === minimumPressure &&
    fieldConfig?.upperPressureBoundary === maximumPressure;

  const onSubmit = () => {
    const data: FieldConfigDto = {
      lowerPressureBoundary: minimumPressure,
      upperPressureBoundary: maximumPressure,
    };

    mutate(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        id="minimumPressure"
        label="Minimum pressure"
        disabled={isPending}
        type="number"
        variant={errors.minimumPressure && 'error'}
        helperText={
          errors.minimumPressure ? errors.minimumPressure.message : 'bar'
        }
        {...register('minimumPressure', {
          valueAsNumber: true,
        })}
      />
      <TextField
        id="maximumPressure"
        label="Maximum pressure (bar)"
        disabled={isPending}
        type="number"
        variant={errors.maximumPressure && 'error'}
        helperText={
          errors.maximumPressure ? errors.maximumPressure.message : 'bar'
        }
        {...register('maximumPressure', {
          valueAsNumber: true,
        })}
      />
      <ButtonWrapper>
        <Button
          as="button"
          type="submit"
          variant="contained"
          disabled={isPending || valuesUnChanged}
        >
          {isPending ? 'Saving...' : 'Save values'}
        </Button>
      </ButtonWrapper>
    </Form>
  );
};
