import { useEffect, useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useGetOptionalAttributes } from '../api/useGetOptionalAttributes';
import { usePutStep } from '../api/usePutStep';
import { useGetPrognosis, useGetPrognosisDetails } from 'src/hooks';
import { ZoneSelectionStepFormType } from 'src/utils/zodSchema';

interface UseInitializeZoneSelectionStep {
  formReset: UseFormReset<ZoneSelectionStepFormType>;
}

export function useInitializeZoneSelectionStep({
  formReset,
}: UseInitializeZoneSelectionStep) {
  const { data: existingPrognosis } = useGetPrognosis();
  const { data: optionalAttributes } = useGetOptionalAttributes();
  const { data: existingPrognosisDetails } = useGetPrognosisDetails();
  const { mutate: putStep } = usePutStep();

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const initializeForm = () => {
      const formValues: ZoneSelectionStepFormType = {
        stratColumnIdentifier: existingPrognosis?.stratColumnIdentifier ?? '',
        optionalAttributes: optionalAttributes ?? {
          segment: false,
          datumDepth: false,
          totalDepthRow: false,
          pressureInitial: false,
          pressureMin: false,
          pressureMax: false,
          useExistingFieldValues: false,
          commingledZones: false,
        },
      };

      formReset(formValues, {
        keepErrors: true,
      });
      setIsInitializing(false);
    };

    if (
      existingPrognosis &&
      isInitializing &&
      optionalAttributes &&
      existingPrognosisDetails
    ) {
      initializeForm();
      putStep();
    }
  }, [
    existingPrognosis,
    existingPrognosisDetails,
    formReset,
    isInitializing,
    optionalAttributes,
    putStep,
  ]);

  return { isInitializing };
}
