import { colors, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const Box = styled.div<{ hidden?: boolean; $keyingeneral?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  padding: ${spacings.small};
  justify-content: center;
  align-items: start;
  align-self: stretch;
  background-color: ${({ $keyingeneral }) =>
    $keyingeneral ? colors.ui.background__warning.rgba : 'transparent'};
  &:not(:first-child) {
    border-left: 1px solid ${colors.ui.background__medium.rgba};
  }
`;

export const Item = styled.div<{ $background?: string }>`
  display: flex;
  align-items: center;
  border-radius: ${spacings.x_small};
  background-color: ${colors.ui.background__default.rgba};
  border: 1px solid ${colors.ui.background__medium.rgba};
  background-color: ${({ $background }) => $background || 'transparent'};
`;
