import { colors, spacings } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacings.small};
`;

export const ValuesGrid = styled.div`
  width: max-content;
  display: flex;
  border-radius: 4px;
  background-color: ${colors.ui.background__light.hex};
  border: 1px solid ${colors.ui.background__medium.hex};
`;

export const ValuesItem = styled.div<{
  $withlock?: boolean;
}>`
  display: flex;
  flex-direction: ${({ $withlock }) => ($withlock ? 'row' : 'column')};
  padding: ${spacings.small} ${spacings.medium};
  align-items: ${({ $withlock }) => ($withlock ? 'center' : 'flex-start')};
  align-self: stretch;
  place-content: center;
`;
