import { FC } from 'react';

import { spacings, Typography } from '@equinor/amplify-component-lib';

import { PrognosisDetailDto } from 'src/api';
import { readonlyKeys } from 'src/constants';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { capitalizeAndFormatString } from 'src/utils';

import { LockedInformation } from 'components/LockedInformation/LockedInfoSingle';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: ${spacings.medium};
  overflow-y: auto;
`;

const Title = styled(Typography)`
  margin-bottom: ${spacings.medium};
`;

interface ReadonlyProps {
  label: string;
  detailRow: PrognosisDetailDto | null;
}

export const Readonly: FC<ReadonlyProps> = ({ label, detailRow }) => {
  const { data: optionalAttributes } = useGetOptionalAttributes();

  if (
    !detailRow ||
    readonlyKeys.every(
      (key) => detailRow[key] === null || detailRow[key] === undefined
    )
  ) {
    return null;
  }

  return (
    <div>
      <Title variant="h6">{label}</Title>
      <Container>
        {detailRow &&
          Object.keys(detailRow)
            .filter((item) => {
              return (
                optionalAttributes?.pressureInitial !== false ||
                item !== 'pressureInitial'
              );
            })
            .map((key) => {
              if (readonlyKeys.includes(key as keyof PrognosisDetailDto)) {
                return (
                  <LockedInformation
                    key={key}
                    label={capitalizeAndFormatString(key)}
                    value={detailRow[key as keyof PrognosisDetailDto]}
                    withlock
                  />
                );
              }
              return null;
            })}
      </Container>
    </div>
  );
};
