import { FC } from 'react';

import { spacings, Status } from '@equinor/amplify-component-lib';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.large};
`;

export const AuthorizationError: FC = () => {
  return (
    <Container>
      <Wrapper>
        <Status>
          <Status.Description
            text=" Whoops! You aren't supposed to be here. Please select a field
              you have access to."
          />
        </Status>
      </Wrapper>
    </Container>
  );
};
