import { FC, useContext } from 'react';

import { DataContext } from '@visx/xychart';

import { AddedPrognosisDto } from '../Utils/Chart.types';

interface RectGlyphProps {
  x?: number;
  y?: number;
  datum: AddedPrognosisDto;
  color: string | undefined;
  vertical?: boolean;
  selectedZone?: number;
}

export const RectGlyph: FC<RectGlyphProps> = ({
  x,
  y,
  datum,
  color,
  vertical,
  selectedZone,
}) => {
  const { yScale, xScale } = useContext(DataContext);
  if (!datum.low || !datum.high || !yScale || !xScale) return;

  const scaleLow: number = vertical
    ? (yScale(datum.low) as number)
    : (xScale(datum.low) as number);
  const scaleHigh: number = vertical
    ? (yScale(datum.high) as number)
    : (xScale(datum.high) as number);

  const barY = scaleLow - scaleHigh;
  const barX = scaleHigh - scaleLow;

  const currentZone = selectedZone === datum.id;

  const barThickness = currentZone ? 12 : 6;
  const radius = currentZone ? 6 : 3;

  return (
    <g>
      <rect
        rx={radius}
        width={vertical ? barThickness : barX}
        height={vertical ? barY : barThickness}
        // stroke={colors.interactive.primary__resting.hex}
        fill={color}
        x={x}
        y={y}
      ></rect>
    </g>
  );
};
