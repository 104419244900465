import { FC, MouseEvent, useMemo, useRef, useState } from 'react';

import { formatDate, Typography } from '@equinor/amplify-component-lib';

import { DateLabels } from 'src/pages/MapView/components/Footer/NewSlider/components/DateLabels';
import { SelectedDateThumb } from 'src/pages/MapView/components/Footer/NewSlider/components/SelectedDateThumb';
import { SliderInputBlocker } from 'src/pages/MapView/components/Footer/NewSlider/components/SliderInputBlocker';
import { SliderTicks } from 'src/pages/MapView/components/Footer/NewSlider/components/SliderTicks';
import { SLIDER_HALF_SELECTED_THUMB_WIDTH } from 'src/pages/MapView/components/Footer/NewSlider/Slider.constants';
import {
  SliderBackground,
  SliderInput,
  SliderTooltip,
  Wrapper,
} from 'src/pages/MapView/components/Footer/NewSlider/Slider.styles';
import { SliderTooltipData } from 'src/pages/MapView/components/Footer/NewSlider/Slider.types';
import { sliderRangeToDate } from 'src/pages/MapView/components/Footer/NewSlider/Slider.utils';
import { useMapData, useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground } from 'src/pages/MapView/MapView.types';

import { useMeasuredData } from 'hooks/useMeasuredData';

// TODO: Look at rerender breaking slider on hot reload
// TODO: Possible improvement to slider is visualising dragging of selected thumb
// TODO: Reset selected date when selected date is outside range when changing from 3mn to 1mn f.ex

export const Slider: FC = () => {
  const { backgroundLayer, toggleBackgroundLayer } = useMapOptions();
  const { fromRangeDate, toRangeDate, daysBetweenFromToRangeDate } =
    useMapData();
  const { handleSetMapDateRangeOptions } = useMeasuredData();
  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderValue, setSliderValue] = useState<number>(-1);
  const [tooltipData, setTooltipData] = useState<SliderTooltipData | undefined>(
    undefined
  );

  const daysBuckets = useMemo(() => {
    if (!sliderWidth || sliderWidth <= 0) return [];

    const sliderSpanWidth = (sliderWidth - 2) / daysBetweenFromToRangeDate;
    let pixelValue = 0;

    return new Array(daysBetweenFromToRangeDate + 3)
      .fill(0)
      .map((_, index, array) => {
        if (index === 0 || index === array.length - 2) {
          pixelValue = pixelValue + SLIDER_HALF_SELECTED_THUMB_WIDTH + 8;
          return pixelValue - SLIDER_HALF_SELECTED_THUMB_WIDTH - 8;
        }
        pixelValue = pixelValue + sliderSpanWidth;
        return pixelValue - sliderSpanWidth;
      });
  }, [daysBetweenFromToRangeDate, sliderWidth]);

  const resizeObserver = useRef<ResizeObserver>(
    new ResizeObserver((entries) => {
      // Since we only ever observe 1 element we can safely access [0]
      const { width } = entries[0].contentRect;
      setSliderWidth(width);
    })
  );

  const handleOnNewRef = (element: HTMLDivElement | null) => {
    if (element) {
      resizeObserver.current.observe(element);
    }
  };

  const handleOnHover = (event: MouseEvent<HTMLInputElement>) => {
    if (!sliderWidth || !(event.target instanceof Element)) return;

    const targetBoundingRect = event.target.getBoundingClientRect();
    const halfBucketSize = (daysBuckets[2] - daysBuckets[1]) / 2;
    const eventValue = event.clientX - targetBoundingRect.x;
    const bucketIndex = daysBuckets?.findIndex((bucket, index, array) => {
      if (eventValue < halfBucketSize) return false;
      if (index === array.length - 2) return true;
      return eventValue - halfBucketSize < bucket;
    });

    const rangeValue = bucketIndex > 1 ? bucketIndex - 1 : 0;

    const rangeDate = sliderRangeToDate(
      Number(rangeValue),
      fromRangeDate,
      toRangeDate
    );

    setSliderValue(bucketIndex - 1);

    setTooltipData({
      label: formatDate(rangeDate, { format: 'DD.MM.YYYY' }),
      xOffset: daysBuckets?.at(rangeValue + 1) ?? 0,
    });
  };

  const handleOnMouseLeave = () => {
    setTooltipData(undefined);
    setSliderValue(-1);
  };

  const handleOnChange = () => {
    if (sliderValue < 0) return;

    if (backgroundLayer === MapBackground.INTERPOLATED) {
      toggleBackgroundLayer(MapBackground.INTERPOLATED);
    }
    const newDate = sliderRangeToDate(sliderValue, fromRangeDate, toRangeDate);

    if (newDate.getTime() > toRangeDate.getTime()) {
      handleSetMapDateRangeOptions({ selectedDate: toRangeDate });
    } else if (newDate.getTime() < fromRangeDate.getTime()) {
      handleSetMapDateRangeOptions({ selectedDate: fromRangeDate });
    } else {
      handleSetMapDateRangeOptions({
        selectedDate: sliderRangeToDate(
          sliderValue,
          fromRangeDate,
          toRangeDate
        ),
      });
    }
  };

  return (
    <Wrapper>
      <SliderInputBlocker daysBuckets={daysBuckets} />
      <SelectedDateThumb daysBuckets={daysBuckets} />
      <SliderBackground
        onMouseMove={handleOnHover}
        onMouseLeave={handleOnMouseLeave}
        onMouseUp={handleOnChange}
      >
        <DateLabels />
        <SliderTicks sliderWidth={sliderWidth} />
      </SliderBackground>
      <SliderInput
        $hideThumb={sliderValue === -1}
        ref={handleOnNewRef}
        type="range"
        min={0}
        max={daysBetweenFromToRangeDate}
        value={sliderValue}
        onChange={handleOnChange}
      />
      {tooltipData && (
        <SliderTooltip
          style={{
            left: `${tooltipData?.xOffset}px`,
          }}
        >
          <Typography group="ui" variant="tooltip">
            {tooltipData?.label}
          </Typography>
        </SliderTooltip>
      )}
    </Wrapper>
  );
};
