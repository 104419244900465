import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useEditDetailsStepForm } from '../form/useEditDetailsStepForm';
import { useGetOptionalAttributes } from './useGetOptionalAttributes';
import { PrognosisDetailDto, PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants';
import { cleanPrognosisDetail } from 'src/utils';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePutDetail = () => {
  const { setValue, resetField } = useEditDetailsStepForm();
  const usingId = usePrognosisIDFromParams();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  return useMutation({
    mutationFn: async (detail: Partial<PrognosisDetailDto>) => {
      const cleanedDetails = cleanPrognosisDetail(detail, optionalAttributes);

      return await PrognosisDetailService.putApiV1PrognosesDetails(
        usingId,
        cleanedDetails
      );
    },
    onMutate: async (detail: Partial<PrognosisDetailDto>) => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId],
      });

      const previousDetails: PrognosisDetailDto[] =
        queryClient.getQueryData([PROGNOSIS_DETAIL, usingId]) ?? [];

      const cleanedDetails = cleanPrognosisDetail(detail, optionalAttributes);

      const index = previousDetails.findIndex(
        (d) => d.id === cleanedDetails.id
      );

      const updatedDetails = [...previousDetails];
      if (index !== -1) {
        updatedDetails[index] = cleanedDetails;
      }

      queryClient.setQueryData([PROGNOSIS_DETAIL, usingId], updatedDetails);

      setValue('prognosisDetails', updatedDetails);

      return {
        previousDetails,
        optimisticDetail: cleanedDetails,
        updatedDetails,
      };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, usingId],
        context?.previousDetails ?? []
      );
      resetField('prognosisDetails', {
        defaultValue: context?.previousDetails,
      });
      showSnackbar(`Failed to update detail: ` + err.message);
    },
    onSuccess: (err, values, context) => {
      resetField('prognosisDetails', {
        defaultValue: context?.updatedDetails,
        keepError: true,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId],
      });
    },
  });
};
