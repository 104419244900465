import { useEffect, useMemo, useState } from 'react';

import { useAuth, useDebounce } from '@equinor/amplify-component-lib';
import { useInfiniteQuery } from '@tanstack/react-query';

import { FilterValue } from '../pages/PrognosisDashboard/providers/PrognosisDashboardProvider';
import { useApp } from './useApp';
import { SortDirection } from './useSortDirection';
import { ApiError, PrognosisService } from 'src/api';
import { PAGE_SIZE } from 'src/constants/queryKeys';
import { TabType } from 'src/constants/tab';
import { StatusNameEnum } from 'src/types/status';
import { getUsernameFromEmail } from 'src/utils';

interface usePrognosesArgs {
  currentTab: TabType;
  filterValue?: FilterValue;
  sortDirection?: SortDirection;
}

export const useGetPrognoses = ({
  currentTab,
  filterValue,
  sortDirection = SortDirection.DESCENDING,
}: usePrognosesArgs) => {
  const { field } = useApp();
  const debouncedFilterValue = useDebounce(filterValue, 500);
  const { account } = useAuth();

  const [isSearching, setIsSearching] = useState(false);

  const userShortName = getUsernameFromEmail(account?.username);

  const tabConfig = useMemo(() => {
    switch (currentTab) {
      case TabType.MY_ITEMS:
        return {
          prognosisStatus: [
            StatusNameEnum.DRAFT,
            StatusNameEnum.SUBMITTED,
            StatusNameEnum.REVISION_REQUIRED,
            StatusNameEnum.OUTDATED,
          ].join(','),
          createUser: userShortName,
          sortColumn: 'updateDate',
        };

      case TabType.AWAITING_APPROVAL:
        return {
          prognosisStatus: StatusNameEnum.SUBMITTED,
          mainApprover: userShortName,
          backupApprover: userShortName,
          sortColumn: 'updateDate',
        };

      case TabType.APPROVED:
        return {
          prognosisStatus: StatusNameEnum.APPROVED,
          sortColumn: 'validToDate',
        };

      case TabType.HISTORICAL:
        return {
          prognosisStatus: StatusNameEnum.HISTORICAL,
          sortColumn: 'validToDate',
        };

      case TabType.OTHER_ITEMS_ON_THE_FIELD:
        return {
          prognosisStatus: [
            StatusNameEnum.DRAFT,
            StatusNameEnum.SUBMITTED,
            StatusNameEnum.REVISION_REQUIRED,
            StatusNameEnum.OUTDATED,
          ].join(','),
          sortColumn: 'updateDate',
          mainApprover: userShortName,
        };

      case TabType.ALL_ITEMS_ON_THE_FIELD:
        return {
          prognosisStatus: [
            StatusNameEnum.DRAFT,
            StatusNameEnum.SUBMITTED,
            StatusNameEnum.REVISION_REQUIRED,
            StatusNameEnum.OUTDATED,
          ].join(','),
          sortColumn: 'updateDate',
        };

      default:
        return {};
    }
  }, [currentTab, userShortName]);

  useEffect(() => {
    setIsSearching(
      JSON.stringify(filterValue) !== JSON.stringify(debouncedFilterValue)
    );
  }, [debouncedFilterValue, filterValue]);

  const queryKey = [
    tabConfig?.prognosisStatus,
    tabConfig.mainApprover,
    tabConfig.backupApprover,
    tabConfig.createUser,
    Object.values(debouncedFilterValue ?? {}),
    sortDirection,
    tabConfig.sortColumn,
    field?.name,
  ];

  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) =>
      PrognosisService.getApiV1Prognoses(
        debouncedFilterValue?.wellbore ?? undefined, //wellboreIdentifier
        undefined, // ciTargetName
        field?.name ?? undefined, // fieldIdentifier
        debouncedFilterValue?.status ?? tabConfig.prognosisStatus, // status
        debouncedFilterValue?.operation ?? undefined, // operation
        undefined, // operationPhase
        undefined, // stratColumnIdentifier
        undefined, // reservoirZone
        debouncedFilterValue?.search ?? undefined, // title
        undefined, // comment
        debouncedFilterValue?.createdBy ?? tabConfig.createUser, // createUser
        debouncedFilterValue?.mainApprover ?? tabConfig.mainApprover, // mainApprover
        debouncedFilterValue?.backupApprover ?? tabConfig.backupApprover, // backupApprover
        debouncedFilterValue?.approver ?? undefined, // approveUser
        undefined, // isSubscribed
        debouncedFilterValue?.lastModifiedFrom ?? undefined, // update_dateFrom
        filterValue?.lastModifiedTo ?? undefined, // update_dateTo
        undefined, // validDateFrom
        undefined, // validDateTo
        tabConfig.sortColumn, // sortColumn
        sortDirection, // sortDirection
        pageParam, // page
        PAGE_SIZE // pageSize
      ),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.prognosisDtos.length === PAGE_SIZE
        ? pages.length + 1
        : undefined,
    initialPageParam: 1,
    retry: (failureCount, error: ApiError) =>
      error.status === 404 ? false : failureCount < 4,
    enabled: sortDirection !== undefined, // prevents requests from being made before the sort direction is initialized
  });

  return { ...query, isSearching, queryKey };
};
