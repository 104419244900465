import { FC } from 'react';
import { Controller, Path } from 'react-hook-form';

import {
  Icon,
  SingleSelect,
  spacings,
  TextField,
  Typography,
} from '@equinor/amplify-component-lib';
import { error_filled } from '@equinor/eds-icons';

import { TextFieldContainer } from '../DataInputDialog.styles';
import { Skeleton } from '../Skeleton/Skeleton';
import { FieldValues } from 'src/api';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { useEditDetailsStepForm } from 'src/pages/EditPrognosis/hooks/form/useEditDetailsStepForm';
import { getPrognosisDetailError } from 'src/utils/error';
import { EditDetailsStepFormType } from 'src/utils/zodSchema';

import { LockedInformation } from 'components/LockedInformation/LockedInfoSingle';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
`;

interface GradientsProps {
  label: string;
  index: number;
  fieldValues?: FieldValues;
  missingVitalKeys: boolean;
  pressureGradientDepthUnit: string;
  isLoading: boolean;
}

const items = [
  {
    label: 'Oil',
    value: 'Oil',
  },
  {
    label: 'Water',
    value: 'Water',
  },
  {
    label: 'Gas',
    value: 'Gas',
  },
];

export const Gradients: FC<GradientsProps> = ({
  label,
  index,
  fieldValues,
  missingVitalKeys,
  pressureGradientDepthUnit,
  isLoading,
}) => {
  const {
    register,
    trigger,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useEditDetailsStepForm();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const fluid: Path<EditDetailsStepFormType> = `prognosisDetails.${index}.fluidType`;
  const pressureGradientDepth: Path<EditDetailsStepFormType> = `prognosisDetails.${index}.pressureGradientDepth`;
  const pressureGradientToDatumDepth: Path<EditDetailsStepFormType> = `prognosisDetails.${index}.pressureGradientToDatumDepth`;

  const fluidError = getPrognosisDetailError(errors, index, 'fluidType');
  const pressureGradientDepthError = getPrognosisDetailError(
    errors,
    index,
    'pressureGradientDepth'
  );

  const watchFluid = getValues(fluid);
  if (optionalAttributes?.useExistingFieldValues && !missingVitalKeys) {
    switch (watchFluid) {
      case 'Oil':
        setValue(pressureGradientDepth, fieldValues?.gradientOil);
        setValue(pressureGradientToDatumDepth, fieldValues?.gradientOil);
        break;
      case 'Water':
        setValue(pressureGradientDepth, fieldValues?.gradientWater);
        setValue(pressureGradientToDatumDepth, fieldValues?.gradientWater);
        break;
      case 'Gas':
        setValue(pressureGradientDepth, fieldValues?.gradientGas);
        setValue(pressureGradientToDatumDepth, fieldValues?.gradientGas);
        break;
      default:
        break;
    }
  }

  const fluidValidationVariant = fluidError && 'error';
  const pressureGradientDepthValidationVariant =
    pressureGradientDepthError && 'error';

  const handleBlur = (fieldPath: Path<EditDetailsStepFormType>) => {
    const value = getValues(fieldPath);

    if (value) {
      trigger(fieldPath);
    } else {
      clearErrors(fieldPath);
    }
  };

  if (isLoading) {
    return <Skeleton label={label} />;
  }

  return (
    <Container>
      <Typography variant="h6">{label}</Typography>
      <TextFieldContainer>
        <Controller
          name={fluid}
          render={({ field }) => {
            return (
              <SingleSelect
                items={items}
                label="Fluid Type"
                helperText={fluidError?.message || 'Select fluid type'}
                value={{
                  label: field.value as string,
                  value: field.value as string,
                }}
                clearable={false}
                data-testid={fluid}
                variant={fluidValidationVariant}
                onSelect={(value) => {
                  field.onChange(value?.value);
                  trigger(fluid);
                }}
              />
            );
          }}
        />

        {optionalAttributes?.useExistingFieldValues && !missingVitalKeys ? (
          <LockedInformation
            label={`${watchFluid} gradient`}
            value={getValues(pressureGradientDepth)}
            meta={pressureGradientDepthUnit}
            withlock
          />
        ) : (
          <TextField
            {...register(pressureGradientDepth)}
            id={'PressureGradientDepth'}
            placeholder="0.0"
            label="Gradient"
            meta="Required"
            helperIcon={
              pressureGradientDepthError && (
                <Icon data={error_filled} size={16} />
              )
            }
            helperText={pressureGradientDepthError?.message || 'bar/m'}
            variant={pressureGradientDepthValidationVariant}
            data-testid={'pressureGradientDepth'}
            onBlur={() => handleBlur(pressureGradientDepth)}
          />
        )}
      </TextFieldContainer>
    </Container>
  );
};
