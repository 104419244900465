import { FC } from 'react';

import { formatDate } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { StyledTypography } from './PrognosisCard.styles';
import { TabType } from 'src/constants/tab';

const { colors } = tokens;

interface PrognosisContainerValuesProps {
  label: string | null | undefined;
  isDate?: boolean;
  isShortName?: boolean;
  currentTab?: TabType | undefined;
}

export const PrognosisValues: FC<PrognosisContainerValuesProps> = ({
  label,
  isDate = false,
  isShortName = false,
  currentTab,
}) => {
  //TODO: disabled tooltip until we update the endpoints to include the full name inside the prognosis object
  // const { data: fullName } = useFullName(isShortName ? label : undefined);

  if (isDate && isShortName) {
    throw new Error("The provided value can't be both a date and a shortname!");
  }

  const getColor = () =>
    isDate && label && currentTab === TabType.APPROVED
      ? colors.interactive.success__text.rgba
      : colors.text.static_icons__default.rgba;

  // const tooltip = () => {
  //   if (isDate && label) {
  //     return date.formatDateTime(label, { month: 'short' });
  //   }
  //   if (isShortName && label) {
  //     return fullName ?? 'N/A';
  //   }
  // };

  const content = () => {
    if (isDate && label) return formatDate(label, { format: 'DD. month YYYY' });
    if (isShortName && label) return label;
    return label ?? 'N/A';
  };

  return (
    // <OptionalTooltip title={tooltip()} placement="top">
    <StyledTypography color={getColor()} group="table" variant="cell_text">
      {content()}
    </StyledTypography>
    // </OptionalTooltip>
  );
};
