/**
 * Capitalizes the first word and formats a camelCase string into a space-separated string.
 *
 * @param str - The input string to be formatted.
 * @returns The formatted string with the first word capitalized and subsequent words in lowercase.
 */
export const capitalizeAndFormatString = (str: string): string => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map((word, index) =>
      index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase()
    )
    .join(' ');
};
