import { FilterValue } from './providers/PrognosisDashboardProvider';
import { LookupValues } from 'src/api';

export function filterValueKeyToLabel(key: keyof FilterValue): string {
  return key
    .split(/(?=[A-Z])/)
    .map((subString) => subString[0].toUpperCase() + subString.slice(1))
    .join(' ');
}

export function readableValue(value: string, key?: keyof FilterValue): string {
  if (key === 'lastModifiedFrom' || key === 'lastModifiedTo') {
    return new Date(value).toLocaleDateString();
  }
  return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function checkKeyAgainstFilterKeys(
  key: keyof LookupValues
): keyof FilterValue | null {
  switch (key) {
    case 'approveUsers':
      return 'approver';
    case 'backupApprovers':
      return null;
    case 'createUsers':
      return null;
    case 'mainApprovers':
      return null;
    case 'operationPhases':
      return null;
    case 'operations':
      return 'operation';
    case 'reservoirZones':
      return 'reservoirZone';
    case 'statuses':
      return 'status';
    case 'wellbores':
      return 'wellbore';
    default:
      return null;
  }
}
