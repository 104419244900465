import { PrognosisDetailDto } from 'src/api';

interface CalculateProps {
  pressure: number;
  height: number;
  airgap: number;
}

interface ConvertPrognosisToSGProps {
  prognosis: PrognosisDetailDto;
  depthReferenceElevation: number;
}

export const calcSg = ({ pressure, height, airgap }: CalculateProps) => {
  if (!pressure) return;

  /**
   * Constant value to calculate SG
   *  */
  const gradient = 0.0981;

  /**
   * Total height from platform floor to the depth of prognosis detail
   * Airgap is set by user in localstorage, until we get it from backend
   */
  const totalHeight = airgap + height;

  const SG = pressure / (gradient * totalHeight);

  return Number(SG.toFixed(4));
};

export const convertPrognosisToSG = ({
  prognosis,
  depthReferenceElevation,
}: ConvertPrognosisToSGProps) => {
  return {
    ...prognosis,
    datumDepth: prognosis.datumDepth
      ? prognosis.datumDepth + depthReferenceElevation
      : 0,
    referenceDepth: prognosis.referenceDepth
      ? prognosis.referenceDepth + depthReferenceElevation
      : 0,
    pressureExpected: calcSg({
      pressure: prognosis.pressureExpected!,
      height: prognosis.referenceDepth!,
      airgap: depthReferenceElevation,
    }),
    pressureHigh: calcSg({
      pressure: prognosis.pressureHigh!,
      height: prognosis.referenceDepth!,
      airgap: depthReferenceElevation,
    }),
    pressureLow: calcSg({
      pressure: prognosis.pressureLow!,
      height: prognosis.referenceDepth!,
      airgap: depthReferenceElevation,
    }),
    pressureInitial: calcSg({
      pressure: prognosis.pressureInitial!,
      height: prognosis.referenceDepth!,
      airgap: depthReferenceElevation,
    }),
    pressureMin: calcSg({
      pressure: prognosis.pressureMin!,
      height: prognosis.referenceDepth!,
      airgap: depthReferenceElevation,
    }),
    pressureMax: calcSg({
      pressure: prognosis.pressureMax!,
      height: prognosis.referenceDepth!,
      airgap: depthReferenceElevation,
    }),
  };
};
