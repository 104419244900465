import { FC } from 'react';
import { useNavigate } from 'react-router';

import { colors, Icon, Typography } from '@equinor/amplify-component-lib';
import { chevron_right, notifications, time } from '@equinor/eds-icons';

import { ApproveValue } from '../ApproveValue';
import {
  SubCardContent,
  TitleContainer,
  TitleRow,
} from '../PrognosisCard.styles';
import { PrognosisValues } from '../PrognosisValues';
import { ValidityDate } from '../ValidityDate/ValidityDate';
import { PrognosisDto } from 'src/api';
import { TabType } from 'src/constants/tab';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';

import { BaseChip } from 'components/BaseChip/BaseChip';

interface RelatedPrognosisProps extends PrognosisDto {
  currentTab: TabType;
}

export const RelatedPrognosis: FC<RelatedPrognosisProps> = ({
  approveUser,
  mainApprover,
  backupApprover,
  createUser,
  id,
  operation,
  title,
  validFromDate,
  validToDate,
  statusId,
  currentTab,
  isSubscribed,
}) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (id) {
      navigate(`view/${id}`);
    }
  };

  const formattedOperation = lookupValueToComboBoxRequired(
    operation,
    true
  ).label;

  return (
    <SubCardContent onClick={handleOnClick}>
      <TitleContainer>
        <TitleRow>
          <BaseChip
            label="Scheduled"
            icon={time}
            backgroundColor={colors.dataviz.lightblue.darker}
            textColor={colors.text.static_icons__primary_white.rgba}
            iconColor={colors.dataviz.lightblue.lighter}
            expandible
          />
          <Typography>{title || 'N/A'}</Typography>
        </TitleRow>
        {isSubscribed && (
          <Icon
            size={16}
            data={notifications}
            color={colors.interactive.primary__resting.hex}
          />
        )}
      </TitleContainer>
      <ValidityDate
        statusId={statusId}
        validFromDate={validFromDate}
        validToDate={validToDate}
      />
      <PrognosisValues label={formattedOperation} />
      <PrognosisValues label={createUser} isShortName />
      <ApproveValue
        approver={approveUser}
        mainApprover={mainApprover}
        backupApprover={backupApprover}
        currentTab={currentTab}
      />
      <Icon
        data={chevron_right}
        color={colors.interactive.primary__resting.rgba}
      />
    </SubCardContent>
  );
};
