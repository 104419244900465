import { FC, useEffect, useMemo, useState } from 'react';

import {
  CircularProgress,
  colors,
  Typography,
} from '@equinor/amplify-component-lib';
import { ParentSize } from '@visx/responsive';

import {
  AxisTextX,
  AxisTextY,
  Card,
  ChartContainer,
  LoadingWrapper,
} from '../Chart.styles';
import { Chart } from './Chart';
import { PrognosisDetailDto } from 'src/api';
import { useApp } from 'src/hooks';
import { useGetOptionalAttributes } from 'src/pages/EditPrognosis/hooks/api/useGetOptionalAttributes';
import { CHART_HEIGHT } from 'src/pages/ViewPrognosis/constants/chart';
import { useViewPrognosis } from 'src/pages/ViewPrognosis/hooks/useViewPrognosis';
import { Unit } from 'src/types/prognosisDetails';
import { convertPrognosisToSG } from 'src/utils/unitConversion';

import { ChartHeader } from 'components/Charts/XYChart/Header/ChartHeader';
import {
  AddedPrognosisDto,
  ChartDataCategory,
  ChartSelect,
} from 'components/Charts/XYChart/Utils/Chart.types';
import { useGetReservoirZoneDetails } from 'hooks/useGetReservoirZoneDetails';
import { useGetWellboreByIdentifier } from 'hooks/useGetWellbores';
import styled from 'styled-components';

const Info = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

interface TimeChartProps {
  prognosisDetailData: PrognosisDetailDto[];
}

export const TimeChart: FC<TimeChartProps> = ({ prognosisDetailData }) => {
  const { contextRowSelection } = useViewPrognosis();
  const { unit } = useApp();
  const { data: wellbore } = useGetWellboreByIdentifier();
  const { data: optionalAttributes } = useGetOptionalAttributes();

  const [selectedData, setSelectedData] = useState<ChartSelect | undefined>(
    // Shows the first detail in the table by default
    prognosisDetailData && prognosisDetailData.length > 0
      ? {
          id: prognosisDetailData[0].id,
          name: prognosisDetailData[0].reservoirZone,
          stratColumn: prognosisDetailData[0].stratColumnIdentifier,
        }
      : undefined
  );

  const { data: reservoirZone, isFetching } = useGetReservoirZoneDetails({
    wellboreIdentifier: wellbore?.uniqueWellboreIdentifier || '',
    stratColumn: selectedData?.stratColumn || '',
    reservoirZone: selectedData?.name || '',
  });

  const [cardElement, setCardElement] = useState<HTMLDivElement | null>(null);
  const [selected, setSelected] = useState<ChartSelect>({
    id: ChartDataCategory.REFERENCE_ID as number,
    name: ChartDataCategory.REFERENCE as string,
  });

  const reservoirZoneDetails = useMemo(() => {
    return (
      reservoirZone?.map((prognosis) => {
        if (unit === Unit.SG && wellbore) {
          return convertPrognosisToSG({
            prognosis,
            depthReferenceElevation: wellbore.depthReferenceElevation || 0,
          }) as AddedPrognosisDto;
        }
        return prognosis as AddedPrognosisDto;
      }) || []
    );
  }, [unit, reservoirZone, wellbore]);

  const selectedRow = Number(Object.keys(contextRowSelection)[0]);

  // select the first row
  const selectedZoneInDetails = prognosisDetailData?.find(
    (item) => selectedRow && item.id === selectedRow
  );

  // TODO this doesn’t have to be a useEffect if we instead can do this inside a handler that sets the contextRowSelection
  useEffect(() => {
    if (selectedZoneInDetails) {
      setSelectedData({
        id: selectedZoneInDetails.id,
        name: selectedZoneInDetails.reservoirZone,
        stratColumn: selectedZoneInDetails.stratColumnIdentifier,
      });
    }
  }, [selectedZoneInDetails]);

  const elements = [
    {
      id: ChartDataCategory.REFERENCE_ID as number,
      name: ChartDataCategory.REFERENCE as string,
    },
  ];

  if (optionalAttributes?.datumDepth) {
    elements.push({
      id: ChartDataCategory.DATUM_ID as number,
      name: ChartDataCategory.DATUM as string,
    });
  }

  return (
    <Card ref={setCardElement}>
      {isFetching ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <>
          <ChartHeader
            cardElement={cardElement}
            selected={selected}
            setSelected={setSelected}
            elements={elements}
            title={
              selectedData
                ? `${selectedData?.name} - Approved over time`
                : 'Approved over time'
            }
          />
          <ChartContainer>
            {selectedData ? (
              <ParentSize ignoreDimensions="height">
                {({ width }) => {
                  if (reservoirZoneDetails.length > 0) {
                    return (
                      <>
                        <Chart
                          width={width}
                          height={CHART_HEIGHT}
                          data={reservoirZoneDetails}
                          selectedZone={selectedZoneInDetails?.id ?? 0}
                          useDatumData={
                            selected.id === ChartDataCategory.DATUM_ID
                          }
                        />
                        <AxisTextY
                          group="input"
                          variant="label"
                          color={colors.text.static_icons__default.hex}
                        >
                          Pressure
                        </AxisTextY>
                        <AxisTextX
                          group="input"
                          variant="label"
                          color={colors.text.static_icons__default.hex}
                        >
                          Date
                        </AxisTextX>
                      </>
                    );
                  }

                  return (
                    <Info
                      group="table"
                      variant="cell_text"
                      color={colors.text.static_icons__default.rgba}
                    >
                      No data available for this zone
                    </Info>
                  );
                }}
              </ParentSize>
            ) : (
              <Info
                group="table"
                variant="cell_text"
                color={colors.text.static_icons__default.rgba}
              >
                Select a zone to display data, either in the select above or in
                the table below
              </Info>
            )}
          </ChartContainer>
        </>
      )}
    </Card>
  );
};
