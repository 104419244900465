import { CSSProperties, forwardRef, useMemo } from 'react';

import {
  colors,
  elevation,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { useMapData } from 'src/pages/MapView/hooks';
import { WellborePurpose } from 'src/types';

import styled from 'styled-components';

const Label = styled.div`
  cursor: pointer;
  position: absolute;
  transform: translateX(-50%);
  background-color: ${colors.text.static_icons__primary_white.rgba};
  color: ${colors.text.static_icons__secondary.rgba};
  padding: 0 ${spacings.small};
  height: ${spacings.medium};
  box-shadow: ${elevation.raised};
  border-radius: 100px;
  z-index: 20;
`;

const Text = styled(Typography)`
  margin: auto;
  text-align: center;
`;

interface NameLabelProps {
  style: CSSProperties;
  onClick: () => void;
  wellboreUuid?: string | undefined;
  dhpg?: number | undefined;
  wellboreName?: string | undefined | null;
  purpose?: string;
}

export const NameLabel = forwardRef<HTMLDivElement, NameLabelProps>(
  ({ onClick, wellboreUuid, dhpg, wellboreName, style, purpose }, ref) => {
    const { pressurePoints } = useMapData();
    const name = useMemo(() => {
      const pps = pressurePoints.filter(
        (pp) => pp.wellboreUuid === wellboreUuid
      );
      if (pps.length > 1) {
        return `${wellboreName}:${dhpg}`;
      }
      return wellboreName ?? '-';
    }, [dhpg, pressurePoints, wellboreName, wellboreUuid]);

    const purposeText = useMemo(() => {
      if (!purpose) return undefined;
      return purpose === (WellborePurpose.INJECTION as string)
        ? 'Injectors'
        : 'Producers';
    }, [purpose]);

    return (
      <Label ref={ref} style={style} onClick={onClick}>
        <Text group="paragraph" variant="overline">
          {purpose !== undefined ? purposeText : name}
        </Text>
      </Label>
    );
  }
);

NameLabel.displayName = 'WellboreLabel';
