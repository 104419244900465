import { FC } from 'react';

import { DatePicker } from '@equinor/amplify-component-lib';

import { getDateForDaysIntoPast } from 'src/utils';

import { useMeasuredData } from 'hooks/useMeasuredData';
import { styled } from 'styled-components';

const StyledDatePicker = styled(DatePicker)`
  width: 160px;
`;

export const RangeDatePicker: FC = () => {
  const { mapDateRangeOptions, handleSetMapDateRangeOptions } =
    useMeasuredData();

  const handleOnChange = (date: Date | null) => {
    if (!date) return;
    handleSetMapDateRangeOptions({ dateForRange: date, selectedDate: date });
  };

  return (
    <StyledDatePicker
      label="Range center"
      onChange={handleOnChange}
      value={mapDateRangeOptions.dateForRange}
      locale="en-GB"
      maxValue={getDateForDaysIntoPast(1)}
      formatOptions={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
      hideClearButton
    />
  );
};
