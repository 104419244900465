import { FC } from 'react';

import { colors, spacings } from '@equinor/amplify-component-lib';

import { TabType } from 'src/constants';
import { usePrognosisDashboard } from 'src/pages/PrognosisDashboard/hooks/useDashboard';

import { BaseChip } from 'components/BaseChip/BaseChip';
import { useGetPrognoses } from 'hooks/useGetPrognoses';
import { styled } from 'styled-components';

interface TabWithCountProps {
  tabName: TabType;
}

const Wrapper = styled.div`
  display: flex;
  gap: ${spacings.small};
  align-items: center;
`;

export const TabWithCount: FC<TabWithCountProps> = ({ tabName }) => {
  const { filterValue } = usePrognosisDashboard();
  const { data } = useGetPrognoses({
    currentTab: tabName,
    filterValue,
  });

  return (
    <Wrapper data-testid={`tab-${tabName}`}>
      {tabName}
      <BaseChip
        backgroundColor={colors.ui.background__scrim.rgba}
        borderColor="inherit"
        textColor={colors.text.static_icons__primary_white.rgba}
        label={data?.pages[0].totalCount.toString() ?? '0'}
      />
    </Wrapper>
  );
};
