import { FC, useRef } from 'react';

import { SkeletonBase, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { shape } = tokens;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  padding: ${spacings.medium};
`;

const ParagraphSkeleton = styled(SkeletonBase)`
  height: ${spacings.medium};
  border-radius: ${shape.corners.borderRadius};
`;

export const TextSkeleton: FC = () => {
  const randomValues = useRef<number[]>(
    new Array(10).fill(0).map(() => Math.random())
  );

  return (
    <Container data-testid={'text-skeleton'}>
      {new Array(7).fill(0).map((skeleton, index) => (
        <ParagraphSkeleton
          key={`p-skeleton-${skeleton + index}`}
          $offset={index * 200}
          style={{
            width: `${100 - index * 10 * randomValues.current[index]}%`,
          }}
        />
      ))}
    </Container>
  );
};
