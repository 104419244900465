import { FC } from 'react';

import { SkeletonBase } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { Container } from './HistoryItem.styles';
import { getRandomInt } from 'src/utils/randomInt';

import styled from 'styled-components';

const { shape } = tokens;

const DateSkeleton = styled(SkeletonBase)`
  height: 1rem;
  width: 164px;
  border-radius: ${shape.corners.borderRadius};
`;

interface SkeletonProps {
  $width: number;
}
const MessageSkeleton = styled(SkeletonBase)<SkeletonProps>`
  height: 1rem;
  width: ${({ $width }) => `${$width}rem`};
  border-radius: ${shape.corners.borderRadius};
`;

interface HistoryItemSkeletonProps {
  offset: number;
}

export const HistoryItemSkeleton: FC<HistoryItemSkeletonProps> = ({
  offset,
}) => {
  const randomWidth = getRandomInt(20, 30);
  return (
    <Container
      role="progressbar"
      aria-label="Progress bar"
      data-testid={`history-skeleton-${offset}`}
    >
      <DateSkeleton $offset={offset * 100} />
      <MessageSkeleton $offset={offset * 200} $width={randomWidth} />
    </Container>
  );
};
