import { FC, useMemo, useState } from 'react';

import {
  Button,
  CircularProgress,
  colors,
  Dialog,
  Icon,
  Typography,
} from '@equinor/amplify-component-lib';
import {
  delete_forever,
  edit,
  info_circle,
  visibility,
  visibility_off,
} from '@equinor/eds-icons';

import {
  useDeleteFieldValues,
  useGetFieldValues,
  useUpdateFieldActiveStatus,
} from '../hooks/useFieldValues';
import { Container, EditBox, WarningBanner } from './PrognosisValues.styles';
import { FieldValues } from 'src/api';

import { ViewFieldValues } from 'components/ViewFieldValues/ViewFieldValues';
import { useGetUserAccess } from 'hooks/useGetUserAccess';

const excludeKeys = [
  'id',
  'fieldUuid',
  'fieldIdentifier',
  'isGeneral',
  'isActive',
  'depthUnit',
  'pressureUnit',
  'pressureGradientDepthUnit',
];

interface ValuesViewProps {
  setEditId: (id: number) => void;
}

export const ValuesView: FC<ValuesViewProps> = ({ setEditId }) => {
  const { data: FieldValues, isLoading } = useGetFieldValues();
  const { mutateAsync: deleteFieldValue, isPending } = useDeleteFieldValues();
  const [open, setOpen] = useState(false);
  const [deleteField, setDeleteField] = useState<FieldValues | null>(null);
  const { mutate: updateFieldStatus, isPending: isUpdatePending } =
    useUpdateFieldActiveStatus();
  const { userAccessForField } = useGetUserAccess();

  const isApprover = userAccessForField?.userRoles?.approver;

  const generalFieldValues = useMemo(() => {
    return FieldValues?.filter((field) => field.isGeneral)[0];
  }, [FieldValues]);

  const remappedFieldValues = useMemo(() => {
    return FieldValues?.sort((a, b) => {
      if (a.isGeneral && !b.isGeneral) return -1;
      if (!a.isGeneral && b.isGeneral) return 1;
      return 0;
    });
  }, [FieldValues]);

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleDelete = async () => {
    if (deleteField) {
      try {
        await deleteFieldValue(deleteField.id);
      } finally {
        setOpen(false);
      }
    }
  };

  const handleVisibility = (field: FieldValues) => {
    updateFieldStatus({
      ...field,
      isActive: !field.isActive,
    });
  };

  const showInfoBanner =
    remappedFieldValues?.some((field) => field.isGeneral) &&
    remappedFieldValues?.length > 1;

  const keysWithValue: (keyof FieldValues)[] = generalFieldValues
    ? (Object.keys(generalFieldValues) as (keyof FieldValues)[]).filter(
        (key) => !excludeKeys.includes(key) && generalFieldValues[key]
      )
    : [];

  return (
    <Container>
      {remappedFieldValues?.map((fieldValue) => {
        return (
          <ViewFieldValues
            key={fieldValue.id}
            values={fieldValue}
            generalKeys={keysWithValue}
          >
            <EditBox hidden={!isApprover}>
              <Button
                variant="ghost_icon"
                color="primary"
                onClick={() => handleVisibility(fieldValue)}
                disabled={isUpdatePending}
              >
                {isUpdatePending ? (
                  <CircularProgress size={24} />
                ) : (
                  <Icon
                    data={fieldValue.isActive ? visibility : visibility_off}
                    size={24}
                  />
                )}
              </Button>
              <Button
                variant="ghost_icon"
                color="primary"
                onClick={() => setEditId(fieldValue.id)}
              >
                <Icon data={edit} size={24} />
              </Button>
              <Button
                variant="ghost_icon"
                color="primary"
                onClick={() => {
                  setOpen(true);
                  setDeleteField(fieldValue);
                }}
              >
                <Icon data={delete_forever} />
              </Button>
            </EditBox>
          </ViewFieldValues>
        );
      })}
      {showInfoBanner && (
        <WarningBanner data-spacing-mode="extra-compact">
          <Icon
            data={info_circle}
            size={24}
            color={colors.text.static_icons__tertiary.hex}
          />
          <Typography group="navigation" variant="label">
            Replaces broader general values in another data set
          </Typography>
        </WarningBanner>
      )}
      <Dialog
        title={`Delete ${deleteField?.stratColumnIdentifier} ${deleteField?.stratUnitIdentifier}`}
        open={open}
        onClose={() => setOpen(false)}
        width={400}
        withBorders
        actions={[
          {
            position: 'right',
            onClick: () => setOpen(false),
            text: 'Cancel',
            variant: 'outlined',
          },
          {
            position: 'right',
            onClick: handleDelete,
            isLoading: isPending,
            text: 'Delete',
            color: 'danger',
          },
        ]}
      >
        <Typography group="navigation" variant="label">
          Are you sure you want to delete this data set?
        </Typography>
      </Dialog>
    </Container>
  );
};
