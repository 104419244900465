import { Field } from '@equinor/amplify-component-lib';
import { useQuery } from '@tanstack/react-query';

import { useApp } from './useApp';
import { UserService } from 'src/api';
import { USER_ACCESS_KEY } from 'src/constants/queryKeys';

export const useGetUserAccess = () => {
  const { field } = useApp();

  const { data: userAccesses, isLoading } = useQuery({
    queryKey: [USER_ACCESS_KEY],
    queryFn: () => UserService.getApiV1UserUserAccess(),
  });

  const fields: Field[] | undefined = userAccesses?.map((useracces) => {
    return {
      uuid: useracces.fieldUuid,
      name: useracces.fieldIdentifier
        ?.split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
      country: 'NO',
    };
  });

  const userAccessForField = userAccesses?.find(
    (useraccess) => useraccess.fieldUuid === field?.uuid
  );

  return { userAccessForField, fields, isLoadingUseAccess: isLoading };
};
