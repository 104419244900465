import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Banner,
  colors,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { useQueryClient } from '@tanstack/react-query';

import { OverlappingPrognosis } from './OverlappingPrognosis';
import { OVERLAPPING_PROGNOSES } from 'src/constants';
import { StatusNameEnum } from 'src/types';
import { CommentsStepFormType } from 'src/utils';

import { useOverlappingPrognoses } from 'hooks/useOverlappingPrognoses';
import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';
import { styled } from 'styled-components';

const color = colors.interactive.secondary__resting.rgba;

const Container = styled.div`
  border: 1px solid transparent;
  border-radius: ${spacings.x_small};
  overflow: hidden;
  width: 100%;
  min-width: 600px;
`;

const List = styled.div`
  grid-column: -1 / 1;
`;

const SpacingTop = styled.div`
  margin-top: ${spacings.medium};
`;

export const OverlappingNotification = () => {
  const { data: overlappingPrognoses } = useOverlappingPrognoses();
  const usingId = usePrognosisIDFromParams();
  const { watch } = useFormContext<CommentsStepFormType>();
  const { validFrom, validTo } = watch();

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [OVERLAPPING_PROGNOSES, usingId],
    });
  }, [usingId, queryClient, validFrom, validTo]);

  const approvedPrognoses = useMemo(() => {
    return (
      overlappingPrognoses?.filter(
        (prognosis) =>
          prognosis.status === StatusNameEnum.APPROVED.toString() &&
          prognosis.id !== usingId
      ) || []
    );
  }, [overlappingPrognoses, usingId]);

  const submittedPrognoses = useMemo(() => {
    return (
      overlappingPrognoses?.filter(
        (prognosis) =>
          prognosis.status === StatusNameEnum.SUBMITTED.toString() &&
          prognosis.id !== usingId
      ) || []
    );
  }, [overlappingPrognoses, usingId]);

  const hasRelevantOverlappingPrognoses =
    approvedPrognoses.length > 0 || submittedPrognoses.length > 0;

  if (!hasRelevantOverlappingPrognoses) {
    return null;
  }

  return (
    <Container>
      <Banner variant="info">
        <Typography color={color} data-testid="overlapping-notification">
          <strong>Operation and Validity Dates Overlap:</strong> Overlapping
          dates on a submitted and approved prognosis on the same operation.
          Submitting a new prognosis will move the existing approved prognosis
          to historical.
        </Typography>
        <List>
          {approvedPrognoses.length > 0 && (
            <>
              <Typography group="input" variant="label" color={color}>
                <strong>
                  Overlapping approved
                  {approvedPrognoses.length === 1 ? ` prognosis` : ` prognoses`}
                </strong>
              </Typography>
              {approvedPrognoses.map((prognosis) => (
                <OverlappingPrognosis
                  key={prognosis.id}
                  prognosis={prognosis}
                />
              ))}
              <SpacingTop />
            </>
          )}
          {submittedPrognoses.length > 0 && (
            <>
              <Typography group="input" variant="label" color={color}>
                <strong>
                  Overlapping submitted{' '}
                  {submittedPrognoses.length === 1
                    ? ` prognosis`
                    : ` prognoses`}
                </strong>
              </Typography>
              {submittedPrognoses.map((prognosis) => (
                <OverlappingPrognosis
                  key={prognosis.id}
                  prognosis={prognosis}
                />
              ))}
            </>
          )}
        </List>
      </Banner>
    </Container>
  );
};
