/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StratColumn } from '../models/StratColumn';
import type { Stratigraphy } from '../models/Stratigraphy';
import type { Wellbore } from '../models/Wellbore';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SmdaService {
  /**
   * Get wellbore by identifier
   * @param wellboreIdentifier
   * @returns Wellbore Success
   * @throws ApiError
   */
  public static getApiV1SmdaWellbores(
    wellboreIdentifier: string
  ): CancelablePromise<Wellbore> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Wellbores/{wellboreIdentifier}',
      path: {
        wellboreIdentifier: wellboreIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get planned wellbores by field Uuid
   * @param fieldUuid
   * @returns Wellbore Success
   * @throws ApiError
   */
  public static getApiV1SmdaFieldsWellboresPlanned(
    fieldUuid: string
  ): CancelablePromise<Array<Wellbore>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Fields/{fieldUuid}/Wellbores/Planned',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get drilled wellbores by field Uuid
   * @param fieldUuid
   * @returns Wellbore Success
   * @throws ApiError
   */
  public static getApiV1SmdaFieldsWellboresDrilled(
    fieldUuid: string
  ): CancelablePromise<Array<Wellbore>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Fields/{fieldUuid}/Wellbores/Drilled',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get planned and drilled wellbores by field Uuid
   * @param fieldUuid
   * @returns Wellbore Success
   * @throws ApiError
   */
  public static getApiV1SmdaFieldsWellboresAll(
    fieldUuid: string
  ): CancelablePromise<Array<Wellbore>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Fields/{fieldUuid}/Wellbores/All',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get stratigraphic columns for a field
   * @param fieldUuid
   * @returns StratColumn Success
   * @throws ApiError
   */
  public static getApiV1SmdaFieldsStratColumns(
    fieldUuid: string
  ): CancelablePromise<Array<StratColumn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Fields/{fieldUuid}/StratColumns',
      path: {
        fieldUuid: fieldUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get stratigraphic columns by wellbore Uuid
   * @param wellboreUuid
   * @returns StratColumn Success
   * @throws ApiError
   */
  public static getApiV1SmdaWellboresStratColumns(
    wellboreUuid: string
  ): CancelablePromise<Array<StratColumn>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Wellbores/{wellboreUuid}/StratColumns',
      path: {
        wellboreUuid: wellboreUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get all stratigraphic units by strat column identifier
   * @param stratColumnIdentifier
   * @returns Stratigraphy Success
   * @throws ApiError
   */
  public static getApiV1SmdaWellboresStratColumnsStratUnitsAll(
    stratColumnIdentifier: string
  ): CancelablePromise<Array<Stratigraphy>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Wellbores/StratColumns/{stratColumnIdentifier}/StratUnits/All',
      path: {
        stratColumnIdentifier: stratColumnIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get planned stratigraphic units by wellbore Uuid and strat column identifier (currently all)
   * @param wellboreUuid
   * @param stratColumnIdentifier
   * @returns Stratigraphy Success
   * @throws ApiError
   */
  public static getApiV1SmdaWellboresStratColumnsStratUnitsPlanned(
    wellboreUuid: string,
    stratColumnIdentifier: string
  ): CancelablePromise<Array<Stratigraphy>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Wellbores/{wellboreUuid}/StratColumns/{stratColumnIdentifier}/StratUnits/Planned',
      path: {
        wellboreUuid: wellboreUuid,
        stratColumnIdentifier: stratColumnIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get drilled stratigraphic units by wellbore Uuid and strat column identifier
   * @param wellboreUuid
   * @param stratColumnIdentifier
   * @returns Stratigraphy Success
   * @throws ApiError
   */
  public static getApiV1SmdaWellboresStratColumnsStratUnitsDrilled(
    wellboreUuid: string,
    stratColumnIdentifier: string
  ): CancelablePromise<Array<Stratigraphy>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Smda/Wellbores/{wellboreUuid}/StratColumns/{stratColumnIdentifier}/StratUnits/Drilled',
      path: {
        wellboreUuid: wellboreUuid,
        stratColumnIdentifier: stratColumnIdentifier,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
