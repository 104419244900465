/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EquipmentCollection } from '../models/EquipmentCollection';
import type { EquipmentCollectionDto } from '../models/EquipmentCollectionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EquipmentMappingService {
  /**
   * Get equipment for a wellbore from ssdl
   * @param wellboreUuid
   * @returns EquipmentCollection Success
   * @throws ApiError
   */
  public static getEquipment(
    wellboreUuid: string
  ): CancelablePromise<EquipmentCollection> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/EquipmentMapping/{wellboreUuid}/equipment',
      path: {
        wellboreUuid: wellboreUuid,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @param fieldUuid
   * @param wellboreUuid
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static addEquipment(
    fieldUuid: string,
    wellboreUuid: string,
    requestBody?: EquipmentCollectionDto
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/EquipmentMapping/field/{fieldUuid}/wellbore/{wellboreUuid}/equipment',
      path: {
        fieldUuid: fieldUuid,
        wellboreUuid: wellboreUuid,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
