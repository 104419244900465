import { FieldValues, PrognosisDetailDto } from 'src/api';

export const filterKeys: (keyof FieldValues)[] = [
  'id',
  'fieldUuid',
  'stratUnitUuid',
  'stratColumnUuid',
  'isActive',
  'isGeneral',
];
export const titleKeys: (keyof FieldValues)[] = [
  'fieldIdentifier',
  'stratColumnIdentifier',
  'stratUnitIdentifier',
];

export const keysForDepthUnit: (keyof FieldValues)[] = ['datumDepth'];

export const keysForPressureUnit: (keyof FieldValues)[] = [
  'pressureDatum',
  'pressureInitial',
];

export const keysForPressureGradientDepthUnit: (keyof FieldValues)[] = [
  'gradientOil',
  'gradientGas',
  'gradientWater',
  'oilGasContactDepth',
  'oilWaterContactDepth',
  'gasWaterContactDepth',
];

export const unitKeys: (keyof FieldValues)[] = [
  'depthUnit',
  'pressureUnit',
  'pressureGradientDepthUnit',
];

export const vitalCalculationKeys: (keyof FieldValues)[] = [
  'datumDepth',
  'pressureDatum',
  'pressureInitial',
  'gradientOil',
  'gradientGas',
  'gradientWater',
];

export const calculationExcludeKeys = [
  'id',
  'fieldUuid',
  'fieldIdentifier',
  'isGeneral',
  'isActive',
  'depthUnit',
  'pressureUnit',
  'pressureGradientDepthUnit',
];

export const vitalPrognosisKeys: (keyof PrognosisDetailDto)[] = [
  'referenceDepth',
  'pressureGradientDepth',
  'pressureDatumExpected',
  'pressureDatumLow',
  'pressureDatumHigh',
];

export const keysToBeCalculated: (keyof PrognosisDetailDto)[] = [
  'pressureInitial',
  'pressureExpected',
  'pressureLow',
  'pressureHigh',
];

export const readonlyKeys: (keyof PrognosisDetailDto)[] = [
  'pressureExpected',
  'pressureHigh',
  'pressureLow',
  'pressureInitial',
  'datumDepth',
];

export const measuredDataKeys: (keyof FieldValues)[] = [
  'gasFormationVolFactor',
  'gasSolution',
  'oilFormationVolFactor',
  'waterFormationVolFactor',
];

export const factorUnitKeys: (keyof FieldValues)[] = [
  'gasFormationVolFactor',
  'oilFormationVolFactor',
  'waterFormationVolFactor',
];
