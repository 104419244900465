import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useZoneSelectionStepForm } from '../form/useZoneSelectionStepForm';
import { PrognosisDto, PrognosisPatchDto, PrognosisService } from 'src/api';
import { PROGNOSIS_KEY } from 'src/constants';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const usePutZoneSelection = () => {
  const { watch, reset } = useZoneSelectionStepForm();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const usingId = usePrognosisIDFromParams();

  const { stratColumnIdentifier } = watch();

  const body: PrognosisPatchDto = {
    stratColumnIdentifier: stratColumnIdentifier,
  };

  return useMutation({
    mutationFn: async () => {
      return await PrognosisService.patchApiV1Prognoses(usingId, body);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_KEY, usingId],
      });

      const previousPrognosis: PrognosisDto | undefined =
        queryClient.getQueryData([PROGNOSIS_KEY, usingId]);

      queryClient.setQueryData([PROGNOSIS_KEY, usingId], {
        ...previousPrognosis,
        ...body,
      } as PrognosisDto);

      return { previousPrognosis };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_KEY, usingId],
        context?.previousPrognosis ?? []
      );
      showSnackbar('Failed to update prognosis.');
      reset();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_KEY, usingId],
      });
    },
  });
};
