import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useEditDetailsStepForm } from '../form/useEditDetailsStepForm';
import { PrognosisDetailDto, PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants';

import { usePrognosisIDFromParams } from 'hooks/usePrognosisIDFromParams';

export const useDeleteBulkDetails = () => {
  const { resetField, clearErrors } = useEditDetailsStepForm();
  const usingId = usePrognosisIDFromParams();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (ids: number[]) => {
      return await PrognosisDetailService.deleteApiV1PrognosesDetailsList(ids);
    },
    onMutate: async (ids: number[]) => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId],
      });

      const previousDetails: PrognosisDetailDto[] =
        queryClient.getQueryData([PROGNOSIS_DETAIL, usingId]) ?? [];

      const updatedDetails = previousDetails.filter(
        (detail) => !ids.includes(detail.id)
      );

      queryClient.setQueryData([PROGNOSIS_DETAIL, usingId], updatedDetails);

      resetField('prognosisDetails', {
        defaultValue: updatedDetails,
      });
      clearErrors('prognosisDetails');

      return { previousDetails };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, usingId],
        context?.previousDetails ?? []
      );
      resetField('prognosisDetails', {
        defaultValue: context?.previousDetails ?? [],
      });
      showSnackbar('Failed to delete details: ' + err.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_DETAIL, usingId],
      });
    },
  });
};
