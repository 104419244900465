import { useEffect, useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import { formatDate } from '@equinor/amplify-component-lib';

import { usePutStep } from '../api/usePutStep';
import {
  useGetBackupApprovers,
  useGetMainApprovers,
  useGetPrognosis,
} from 'src/hooks';
import { CommentsStepFormType } from 'src/utils/zodSchema';

interface UseInitializePrognosisZone {
  formReset: UseFormReset<CommentsStepFormType>;
}

export function useInitializeCommentsStep({
  formReset,
}: UseInitializePrognosisZone) {
  const { data: existingPrognosis } = useGetPrognosis();
  const { data: mainApprovers } = useGetMainApprovers();
  const { data: backupApprovers } = useGetBackupApprovers();
  const { mutate: putStep } = usePutStep();

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const initializeForm = () => {
      const formValues: CommentsStepFormType = {
        comment: existingPrognosis?.comment ?? '',
        validFrom:
          formatDate(new Date(existingPrognosis?.validFromDate ?? ''), {
            format: 'YYYY-MM-DD',
          }) ?? '',
        validTo:
          formatDate(new Date(existingPrognosis?.validToDate ?? ''), {
            format: 'YYYY-MM-DD',
          }) ?? '',
        mainApprover:
          mainApprovers?.length === 1
            ? mainApprovers[0].value
            : (existingPrognosis?.mainApprover ?? ''),
        backupApprover: existingPrognosis?.backupApprover ?? '',
      };

      formReset(formValues, {
        keepErrors: true,
      });
      setIsInitializing(false);
    };

    if (
      existingPrognosis &&
      isInitializing &&
      mainApprovers &&
      backupApprovers
    ) {
      initializeForm();
      putStep();
    }
  }, [
    backupApprovers,
    existingPrognosis,
    formReset,
    isInitializing,
    mainApprovers,
    putStep,
  ]);

  return { isInitializing };
}
