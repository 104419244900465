import { PrognosisDetailDto } from 'src/api';
import {
  HeaderDescriptionContent,
  PrognosisDetailColumnData,
} from 'src/types/prognosisDetails';

export const ZONE_AND_SEGMENT_KEYS: (keyof PrognosisDetailDto)[] = [
  'lithostratSubzone',
  'segment',
];

export const SEGMENT_SPAN = 4;
export const REFERENCE_SPAN = 5;
export const GRADIENT_SPAN = 2;
export const DATUM_SPAN = 5;
export const NUM_GRADIENT_COLUMNS = 2; // Constant for Container's $amount
export const NUM_ADDITIONAL_ROWS = 3;

export const PROGNOSIS_DETAILS_SEGMENT_COLUMN: PrognosisDetailColumnData = {
  header: '',
  columnSpan: SEGMENT_SPAN,
  headerDescriptions: [
    {
      content: HeaderDescriptionContent.SEGMENT_DESCRIPTION,
      columnSpan: SEGMENT_SPAN,
    },
  ],
  prognosisDetailKeys: ['lithostratSubzone'],
};

export const PROGNOSIS_DETAILS_TABLE_COLUMNS: PrognosisDetailColumnData[] = [
  {
    header: 'Reference',
    columnSpan: REFERENCE_SPAN,
    headerDescriptions: [
      { content: HeaderDescriptionContent.DEPTH, columnSpan: 1 },
      {
        content: HeaderDescriptionContent.REFERENCE_PRESSURE,
        columnSpan: 4,
      },
    ],
    prognosisDetailKeys: [
      'referenceDepth',
      'pressureInitial',
      'pressureExpected',
      'pressureLow',
      'pressureHigh',
    ],
  },
  {
    header: 'Gradients',
    columnSpan: GRADIENT_SPAN,
    headerDescriptions: [
      { content: HeaderDescriptionContent.GRADIENT_TYPE, columnSpan: 1 },
      {
        content: HeaderDescriptionContent.PRESSURE_VALUE,
        columnSpan: 1,
      },
    ],
    prognosisDetailKeys: ['fluidType', 'pressureGradientDepth'],
  },
  {
    header: 'Datum',
    columnSpan: DATUM_SPAN,
    headerDescriptions: [
      { content: HeaderDescriptionContent.DEPTH, columnSpan: 1 },
      {
        content: HeaderDescriptionContent.DATUM_PRESSURE,
        columnSpan: 3,
      },
      {
        content: HeaderDescriptionContent.PRESSURE_VALUE,
        columnSpan: 1,
      },
    ],
    prognosisDetailKeys: [
      'datumDepth',
      'pressureDatumExpected',
      'pressureDatumLow',
      'pressureDatumHigh',
    ],
  },
];

export const headerTitles = [
  'Title',
  'Submitter',
  'Approver',
  'Status',
  'Operation',
  'Validity',
];

export const EMPTY_DETAIL: Partial<PrognosisDetailDto> = {
  reservoirZoneNickname: '',
  comment: '',
  referenceDepth: null,
  pressureInitial: null,
  pressureExpected: null,
  pressureLow: null,
  pressureHigh: null,
  fluidType: 'Oil',
  pressureGradientDepth: null,
  datumDepth: null,
  pressureDatumExpected: null,
  pressureDatumLow: null,
  pressureDatumHigh: null,
  pressureMax: null,
  pressureMin: null,
};
