/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StratigraphicUnit } from '../models/StratigraphicUnit';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReservoirZoneService {
  /**
   * Get top used reservoir zones
   * @param wellboreIdentifier
   * @param targetUuid
   * @param stratColumnIdentifier
   * @param operationId
   * @returns StratigraphicUnit Success
   * @throws ApiError
   */
  public static getApiV1ReservoirZonesTopUsed(
    wellboreIdentifier?: string,
    targetUuid?: string,
    stratColumnIdentifier?: string,
    operationId?: number
  ): CancelablePromise<Array<StratigraphicUnit>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/ReservoirZones/topUsed',
      query: {
        WellboreIdentifier: wellboreIdentifier,
        TargetUuid: targetUuid,
        StratColumnIdentifier: stratColumnIdentifier,
        OperationId: operationId,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get last used reservoir zones
   * @param wellboreIdentifier
   * @param targetUuid
   * @param stratColumnIdentifier
   * @param operationId
   * @returns StratigraphicUnit Success
   * @throws ApiError
   */
  public static getApiV1ReservoirZonesLastUsed(
    wellboreIdentifier?: string,
    targetUuid?: string,
    stratColumnIdentifier?: string,
    operationId?: number
  ): CancelablePromise<Array<StratigraphicUnit>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/ReservoirZones/lastUsed',
      query: {
        WellboreIdentifier: wellboreIdentifier,
        TargetUuid: targetUuid,
        StratColumnIdentifier: stratColumnIdentifier,
        OperationId: operationId,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }
}
