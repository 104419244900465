import { Dispatch, SetStateAction } from 'react';

import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { ChangeFlags, CompositeLayer } from '@deck.gl/core';

import { PressureDataSimple } from 'src/api';
import {
  HEATMAP_LAYER_RADIUS_PIXELS,
  INTERPOLATED_MAP_COLOR_DOMAIN,
  WEIGHTS_TEXTURE_SIZE,
} from 'src/pages/MapView/MapView.constants';
import { DataSet, FieldOutlineFeature } from 'src/pages/MapView/MapView.types';
import { getDataGridInPolygon } from 'src/pages/MapView/MapView.utils';
import { mapToRange } from 'src/utils';

interface InterpolatedMapLayerState {
  data: DataSet | undefined;
  range: [number, number] | undefined;
  valueDomain: [number, number] | undefined;
  stepSize: number;
  [key: string]: unknown;
}

interface InterpolatedMapLayerProps {
  id: string;
  showHeatmap: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  zoom: number | undefined;
  pressurePoints?: PressureDataSimple[];
  fieldOutlines?: FieldOutlineFeature[];
}

export class InterpolatedMapLayer extends CompositeLayer<InterpolatedMapLayerProps> {
  state: InterpolatedMapLayerState = {
    data: undefined,
    range: undefined,
    valueDomain: undefined,
    stepSize: 0,
  };

  private async getData() {
    this.props.setIsLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 100));
    const { data, min, max } = getDataGridInPolygon(
      this.props.pressurePoints,
      this.props.zoom,
      this.props.fieldOutlines
    );
    if (data.length === 0) return;

    this.props.setIsLoading(false);
    const stepSize = (max - min) / (INTERPOLATED_MAP_COLOR_DOMAIN.length - 1);
    this.setState({ data, valueDomain: [min - stepSize, max], stepSize }); // Adding a extra "step" below min value to accommodate for the extra hidden grey color that will blend the edge with background
  }

  updateState({ changeFlags }: { changeFlags: ChangeFlags }) {
    if (
      (changeFlags.propsChanged.toString().includes('pressurePoints') ||
        this.state.data === undefined) &&
      this.props.pressurePoints &&
      this.props.pressurePoints.length > 0 &&
      this.props.showHeatmap
    ) {
      this.getData();
    }
  }

  weightAccessor(): (d: [number, number, number]) => number {
    return (d: [number, number, number]) => {
      const weight =
        Math.floor(d[2] / this.state.stepSize) * this.state.stepSize +
        this.state.stepSize / 2; // Offset by half a step to be in the "center" of the color bucket to ensure correct color

      return Math.max(
        0.001,
        mapToRange(
          weight,
          {
            start: this.state.valueDomain![0],
            end: this.state.valueDomain![1],
          },
          { start: 0, end: 1 }
        )
      );
    };
  }

  // Removed ContourLayer for now, but keeping this as a CompositeLayer in case of future needs of additional layers
  renderLayers() {
    return [
      new HeatmapLayer({
        id: `${this.props.id}/heatmap`,
        data: this.state.data ?? [],
        getWeight: this.weightAccessor(),
        getPosition: (d: [number, number, number]) => [d[1], d[0]],
        radiusPixels: HEATMAP_LAYER_RADIUS_PIXELS,
        weightsTextureSize: WEIGHTS_TEXTURE_SIZE,
        colorRange: INTERPOLATED_MAP_COLOR_DOMAIN,
        colorDomain: [0, 1],
        aggregation: 'MEAN',
        pickable: true,
        visible: this.props.showHeatmap,
        updateTriggers: {
          getWeight: [this.state.valueDomain],
          colorDomain: [this.state.valueDomain],
        },
      }),
    ];
  }
}

InterpolatedMapLayer.layerName = 'InterpolatedMapLayer';
InterpolatedMapLayer.defaultProps = {
  ...HeatmapLayer.defaultProps,
};
