import { FC } from 'react';

import { colors, Typography } from '@equinor/amplify-component-lib';

import { MeasuredValuesForm } from './MeasuredValues.Form';
import {
  SkeletonButton,
  SkeletonForm,
  SkeletonInput,
} from './MeasuredValues.styles';

import { BorderedSection } from 'components/BorderedSection/BorderedSection';
import { useGetFieldConfig } from 'hooks/useGetFieldConfig';

export const MeasuredValues: FC = () => {
  const { data: fieldConfig, isLoading } = useGetFieldConfig();

  return (
    <BorderedSection title="Measured data map general filtering">
      <Typography
        variant="text"
        group="input"
        color={colors.text.static_icons__secondary.hex}
      >
        Define the range of data to be displayed in the map, data outside the
        range will be excluded.
      </Typography>
      {isLoading ? (
        <SkeletonForm>
          <SkeletonInput />
          <SkeletonInput />
          <SkeletonButton />
        </SkeletonForm>
      ) : (
        <MeasuredValuesForm fieldConfig={fieldConfig} />
      )}
    </BorderedSection>
  );
};
